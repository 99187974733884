/**
 * enums.js
 *
 * This module defines several enumerated types (enums) used throughout the application. These enums
 * categorize and standardize various message types, states, actions, and other configurations to
 * ensure consistency and clarity across the codebase.
 *
 * Enums:
 * - `ControllerMessageType`: Specifies the types of messages exchanged between the mower and the server,
 *   the server and the mower, and from the app to the server.
 *
 * - `SocketMessageType`: Defines the types of messages for socket communication, particularly between
 *   the app and the server.
 *
 * - `MowerState`: Describes the possible states a mower can be in.
 *
 * - `SourceFlags`: Specifies flags that help determine the source of a certain action or message.
 *
 * - `DestinationFlags`: Used for specifying different kinds of destinations.
 *
 * - `PlanSortMethod`: Describes the various methods to sort the mowing plans.
 *
 * - `CutPattern`: Enumerates different cutting patterns a mower can follow.
 *
 * - `ActionButtonState`: Defines the possible states of the action button in the mower tabs. Helps in
 *   determining actions that can be taken on the mower based on its current state.
 *
 * @module enums
 */

export const ControllerMessageType = Object.freeze({
  //Sent from mower to the server
  MowerUpdate: 1000,
  AggregateMowerUpdate: 1001,
  MowerInfo: 10000,
  NotifyUser: 20,
  UploadPlan: 2000,
  CurrentPlan: 69,
  NewMowerPlan: 50,
  ControllingUserChange: 70,
  CurrentBounds: 169,
  CurrentFaults: 99,
  ObstacleBounds: 170,
  MowerConfiguration: 75,
  MowerVersionInfo: 180,
  KickReason: 254,
  MowerVersionCheck: 210,
  MowerRuntimeSettings: 71,
  MowerDiagnostics: 220,
  MowerLogResponse: 223,
  PlanHistoryResponse: 12346,
  PinNumber: 111,
  MowerHomePosition: 225,
  MowerVehicleInfo: 3235,

  //sent from Server to Mower
  UserInfo: 0,
  LoadPlan: 1,
  CommandPause: 2,
  CommandResume: 3,
  CommandGoHome: 4,
  CommandPlanAround: 5,
  CommandCancelPlan: 6,
  CommandUpdate: 7,
  CommandCheckUpdates: 15,
  CommandSoftRestart: 8,
  CommandHardRestart: 9,
  CommandRequestMowerLogs: 222,
  CommandRequestPlanHistory: 12345,
  CommandStartEngine: 6788,
  CommandGoTo: 7302,
  CommandPairActuators: 5959,
  MowerRegistration: 10,
  Heartbeat: 100,
  UserConnect: 200,
  UserDisconnect: 201,
  ProtocolVersion: 255,
  SetMowerConfiguration: 76,
  PhoneInfo: 27,
  SetMowerRuntimeSettings: 72,
  MowerVersionReply: 211,
  ChangePinNumber: 112,
  SetHomePosition: 3920,
  SetMowerUserPreferences: 73,

  //Sent from App to Server
  ChangeUserCompany: 11511,
});

export const SocketMessageType = Object.freeze({
  CheckConnected: 0,
  ConnectedMowers: 1,
  AnyMowersConnected: 2,
  GetCenterPosition: 3,
  UpdateUserPreferences: 4,
  PauseAllMowers: 5,
  ResumeAllMowers: 6,
  ConnectToMower: 7,
  DisconnectFromMower: 8,
  PauseMower: 9,
  SendCommendMessage: 10,
  MowerGoTo: 11,
  MowerSetHome: 12,
  UpdateMower: 13,
  CancelMower: 14,
  SoftRestartMower: 15,
  PairActuatorMower: 16,
  CheckUpdatesMower: 17,
  HardRestartMower: 18,
  ResumeMower: 19,
  StartMowerEngine: 20,
  ReturnHomeMower: 21,
  PlanAroundMower: 22,
  RequestMowerLogs: 23,
  RequestMowerPlans: 24,
  GetMowers: 25,
  SendPlan: 26,
  SendSplitPlan: 27,
  SendSettings: 28,
  SendCompanyJoin: 29,
  SendNotificationToken: 30,
  SendConfig: 31,
  SendMowSection: 32,
  GetMower: 33,
});

export const MowerState = Object.freeze({
  Idle: 0,
  Mowing: 1,
  Paused: 2,
  Busy: 3,
  Ready: 4,
  Finished: 5,
  Faulted: 6,
  ReturningHome: 7,
  Manual: 8,
  Recording: 9,
  Stuck: 10,
  FaultCleared: 11,
  LocalPause: 12,
  Updating: 13,
});

export const SourceFlags = Object.freeze({
  IsUser: 1,
  IsMower: 2,
  Technician: 4,
});

export const DestinationFlags = Object.freeze({
  Latched: 1,
});

export const PlanSortMethod = Object.freeze({
  Distance: 0,
  Name: 1,
  DateLatest: 2,
  DateEarliest: 3,
  RecentPlans: 4,
  ArchivedPlans: 5,
  MaxValue: 5,
});

export const PlanSortMethodString = Object.freeze({
  0: " Distance",
  1: " Name (A-B)",
  2: " Date (Latest)",
  3: " Date (Earliest)",
  4: " Recently Mowed",
  5: " Archived",
  MaxValue: 5,
});

export const CutPattern = Object.freeze({
  Stripe: 0,
  CrossHatch: 1,
  Spiral: 2,
  MaxValue: 3,
});

export const ActionButtonState = Object.freeze({
  //Helps to  determine the state of the mower
  //thus allowing the app to determine the state of the action button
  //in the mower tabs

  //named in the tense of what should be done to the mower
  //Mower has not been put into any state yet
  Nothing: 0,
  //Mower is mowing and should be paused
  Pause: 1,
  //Mower was paused and should be resumed
  Resume: 2,
  //Mower engine isnt running and can be started
  StartEngine: 3,
  //Mower is faulted and user can read fault logs
  Faulted: 4,
});
