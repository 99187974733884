import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import AuthService from "../components/services/AuthService";
import { WebSocketContext } from "../components/services/AppConnection";
import { PlanSortMethod, SocketMessageType } from "../components/models/enums";
import Constants from "../constants";
import { MowingPlan } from "../components/models/mowingPlan";
import { ListGroup, Row, Col } from "react-bootstrap";
import {
  Menu,
  MenuItem,
  TextField,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import { Utilities } from "../components/utility";
import { SortedMowingPlan } from "../components/models/sortedMowingPlans";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { grey, red } from "@mui/material/colors";
import { Divider } from "@mui/material";
import { withRouter } from "../components/withRouter";
import { useToast } from "../components/customToast/ToastContext";
import { useTranslation } from "react-i18next";

/**
 * The PlanManagePage component represents a page where users can manage different plans.
 * It initializes a connection to a WebSocket, fetches a list of plans and archived plans,
 *  and provides options for sorting and searching.
 * @name PlanManagePage
 */
const PlanManagePage = (props) => {
  const connection = useContext(WebSocketContext);
  const { t, i18n } = useTranslation();
  const [state, setState] = useState({
    list_of_plans: [],
    list_of_archived_plans: [],
    displayed_plans: [],
    SortMethod: 0,
    SearchValue: "",
    SortPosition: null,
  });

  useEffect(() => {
    if (!connection) {
      alert(t("alerts.serverLost"), "warning");
    }
    //verify the user is still logged in
    let auth = new AuthService();
    try {
      auth.enforceLogin();
    } catch (error) {
      console.error("Error enforcing login:", error);
    }

    PageSetUp();
  }, []);

  /**
   * This function, using asynchronous operations, fetches mowing plans,
   * archived plans, and a center position to sort mowing plans based on distance.
   * This sorting operation occurs during the initial load of the page.
   * @name PageSetUp
   */
  const PageSetUp = useCallback(async () => {
    //Retrieve a list of mowing plans using GetDBMowingPlans().
    //plan_list: Stores the mowing plans fetched from the database.
    try {
      var plan_list = await GetDBMowingPlans();
    } catch (error) {
      throw new Error("Failed to fetch mowing plans:", error);
    }

    //Retrieve a list of archived mowing plans using GetArchivedMowingPlans().
    //archived_plan_list: Contains archived mowing plans.
    try {
      var archived_plan_list = await GetArchivedMowingPlans();
    } catch (error) {
      throw new Error("Failed to fetch archived mowing plans:", error);
    }

    //Fetch the center position using the socket connection with the GetCenterPosition message type.
    //sortPosition: Holds the center position used for sorting mowing plans.
    try {
      var sortPosition = await connection.SocketMessage(
        SocketMessageType.GetCenterPosition
      );
    } catch (error) {
      throw new Error("Failed to fetch center position:", error);
    }

    //On the initial load, mowing plans are sorted based on location relative to the center position.
    //sort_plans: Array that will contain mowing plans sorted by location.
    let sort_plans = [];
    try {
      await plan_list.forEach((plan) => {
        sort_plans.push(new SortedMowingPlan(plan, sortPosition));
      });
    } catch (error) {
      throw new Error("Failed to convert to sortable plans:", error);
    }

    //util: Instance of Utilities class, which presumably contains methods for sorting and other utility operations.
    let util = new Utilities();
    try {
      sort_plans = await util.SortPlan(sort_plans, PlanSortMethod.Distance);
    } catch (error) {
      throw new Error("Failed to sort plans:", error);
    }

    //The plans are further sorted using the SortPlan utility function.
    //mowing_plans: Array that stores mowing plans after sorting.
    let mowing_plans = [];
    try {
      await sort_plans.forEach((plan) => {
        mowing_plans.push(plan.mowing_plan);
      });
    } catch (error) {
      throw new Error("Failed to sort plans:", error);
    }

    //The state is then updated with the sorted list of mowing plans and archived plans.
    setState((s) => ({
      ...s,
      list_of_plans: mowing_plans,
      list_of_archived_plans: archived_plan_list,
      displayed_plans: mowing_plans,
    }));
  }, []);

  /**
   * This functionasynchronously fetches a list of mowing plans from a backend API.
   * These plans are then converted into instances of the MowingPlan class. The function
   * returns this array of converted plans.
   *
   * @name GetDBMowingPlans
   * @returns array of these converted plans.
   */
  const GetDBMowingPlans = async () => {
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //list_of_plans: Initially an empty array, it will hold the fetched plans from the API.
    let list_of_plans = [];
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI = encodeURI(baseAddress) + "/api/MowingPlans/list";
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    //Fetch the mowing plans from the backend API using the constructed request URI and appropriate headers.
    try {
      await fetch(requestURI, { method: "GET", headers: Headers })
        .then((response) => {
          //Check for a successful response.
          //If the response is not successful, an error is thrown with the status code.
          if (!response.ok) {
            throw new Error(
              "There was an error connecting to the plan database. HTTP Status: " +
                response.status
            );
          }
          return response.json();
        })
        .then((data) => {
          //Parse the fetched data as JSON.
          if (data === null) {
            alert(t("alerts.errorPlans"), "error");
            return;
          }
          list_of_plans = data;
        });
    } catch (ex) {
      console.error(ex);
    }

    //Convert the list of plans into instances of the MowingPlan class.
    //newPlans: Array that will store the converted plans as instances of MowingPlan.
    let newPlans = list_of_plans.map((plan) => {
      return new MowingPlan(plan);
    });

    //Return the array of converted plans.
    return newPlans;
  };

  /**
   * This function Fetches a list of archived mowing plans from a backend API and converts the fetched plans into instances of the MowingPlan class.
   * @name GetArchivedMowingPlans
   * @returns An array of MowingPlan instances constructed from the fetched archived mowing plans.
   */
  const GetArchivedMowingPlans = async () => {
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    var mowingPlans = [];
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI = encodeURI(baseAddress) + "/api/MowingPlans/archive/list";
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    //Fetch the archived mowing plans from the backend API using the constructed request URI and appropriate headers.
    try {
      await fetch(requestURI, {
        method: "GET",
        headers: Headers,
      })
        .then((response) => {
          //Check for a successful response.
          //If the response is not successful, an error is thrown with the status code.
          if (!response.ok) {
            throw new Error(
              "There was an error connecting to the plan database. HTTP Status: " +
                response.status
            );
          }
          return response.json();
        })
        .then((data) => {
          //Parse the fetched data as JSON.
          if (data === null) {
            console.dir("No archived plans");
          }
          mowingPlans = data;
        });
    } catch (ex) {
      console.error(ex);
    }

    //Convert the list of plans into instances of the MowingPlan class.
    //newPlans: Array that will store the converted plans as instances of MowingPlan.
    let newPlans = mowingPlans.map((plan) => {
      return new MowingPlan(plan);
    });

    //Return the array of converted plans.
    return newPlans;
  };

  /**
   * This function Sends a DELETE request to a backend API to remove a mowing plan specified by the provided Id parameter.
   * @name DeleteItemAsync
   * @param {string} Id - Identifier for the item to be deleted.
   * @returns A boolean value indicating the success (true) or failure (false) of the deletion operation.
   */
  const DeleteItemAsync = async (Id) => {
    //ensure that a valid Id is passed to the function.
    if (!Id) {
      console.error("Item identifier (Id) missing or invalid.");
      return false;
    }
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI = encodeURI(baseAddress) + "/api/mowingplans/delete/" + Id;
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    //ok: Boolean that determines whether the request was successful or not
    var ok = false;

    //Remove the mowing plan from the backend API using the constructed request URI and appropriate headers.
    try {
      await fetch(requestURI, {
        method: "DELETE",
        headers: Headers,
      }).then((response) => {
        //Check for a successful response.
        //If the response is not successful, an error is thrown with the status code.
        if (!response.ok) {
          throw new Error(
            `Failed to delete item with Id: ${Id}. HTTP Status: ${response.status}`
          );
        }
        return (ok = response.ok);
      });
    } catch (ex) {
      console.error(`Error while trying to delete item with Id: ${Id}`, ex);
    }

    //Return whether the response was succcessful or not
    return ok;
  };

  /**
   * This function sends a DELETE request to a backend API to restore a mowing plan specified by the provided Id parameter.
   * Although DELETE is typically used for removal operations, in this context, it seems to be used for restoring an archived or deleted plan.
   * @name RestoreItemAsync
   * @param {string} Id -  Identifier for the item to be restored.
   * @returns A boolean value indicating the success (true) or failure (false) of the restoration operation.
   */
  const RestoreItemAsync = async (Id) => {
    //ensure that a valid Id is passed to the function.
    if (!Id) {
      console.error("Item identifier (Id) missing or invalid.");
      return false;
    }
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI = encodeURI(baseAddress) + "/api/mowingplans/restore/" + Id;
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    //ok: Boolean that determines whether the request was successful or not
    var ok = false;

    //Remove the mowing plan from the backend API using the constructed request URI and appropriate headers.
    try {
      await fetch(requestURI, {
        method: "DELETE",
        headers: Headers,
      }).then((response) => {
        //Check for a successful response.
        //If the response is not successful, an error is thrown with the status code.
        if (!response.ok) {
          throw new Error(
            `Failed to restore item with Id: ${Id}. HTTP Status: ${response.status}`
          );
        }
        return (ok = response.ok);
      });
    } catch (ex) {
      console.error(`Error while trying to restore item with Id: ${Id}`, ex);
    }

    //Return whether the response was succcessful or not
    return ok;
  };

  /**
   * This React component renders a canvas element and draws polygons on it.
   * The main polygon is drawn using data from polygonData and any interior or 'Keep Out' zones are drawn from polygonInteriorData.
   * @name PolygonCanvas
   * @param {Object} props - passed objects
   * @param {Array} props.polygonData - An array of coordinates (latitude, longitude) defining the vertices of the main polygon.
   * @param {Number} props.canvasSize -  Integer representing both the height and width of the canvas in pixels.
   * @param {Array} props.polygonInteriorData -  An array of arrays. Each nested array consists of coordinates (latitude, longitude)
   * defining the vertices of an interior or 'Keep Out' zone.
   *
   */
  const PolygonCanvas = ({ polygonData, canvasSize, polygonInteriorData }) => {
    const canvasRef = useRef(null);

    /**
     * Scales a given value from one range to another.
     * @name scaleCoords
     *
     */
    const scaleCoords = (value, in_min, in_max, out_min, out_max) => {
      if (in_max === in_min) {
        console.error("Invalid scaling values.");
        return;
      }
      return (
        ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
      );
    };

    /**
     * Draws a polygon onto the canvas using the provided data and scales the data to fit within the canvas dimensions.
     * @name drawPolygon
     *
     */
    const drawPolygon = (
      context,
      polygonData,
      minLat,
      maxLat,
      minLng,
      maxLng,
      canvasSize,
      fillColor
    ) => {
      if (!polygonData || polygonData.length === 0) {
        return null; // return a default render
      }
      if (!context) {
        console.error("Failed to get canvas 2D context.");
        return;
      }
      context.beginPath();

      //draw polygon
      polygonData.forEach((point, index) => {
        //convert latitude to longitude
        let x = scaleCoords(point.lng, minLng, maxLng, 0, canvasSize);
        let y = scaleCoords(point.lat, minLat, maxLat, canvasSize, 0);
        if (index === 0) {
          context.moveTo(x, y);
        } else {
          context.lineTo(x, y);
        }
      });

      context.closePath();
      context.fillStyle = fillColor;
      context.fill();
    };

    useEffect(() => {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (canvasSize <= 0) {
        console.error("Invalid canvas size.");
        return null;
      }
      context.clearRect(0, 0, canvasSize, canvasSize); // clear canvas
      context.beginPath();

      //Find min and max for lat and lng to correctly scale the points
      let minLat = Math.min(...polygonData.map((p) => p.lat));
      let maxLat = Math.max(...polygonData.map((p) => p.lat));
      let minLng = Math.min(...polygonData.map((p) => p.lng));
      let maxLng = Math.max(...polygonData.map((p) => p.lng));

      //draw main polygon
      drawPolygon(
        context,
        polygonData,
        minLat,
        maxLat,
        minLng,
        maxLng,
        canvasSize,
        "#AAAAAA"
      );

      context.lineWidth = 0.5;
      context.strokeStyle = "#000000";
      context.stroke();

      //draw the Keep Outs
      //check if there are any keep-outs at all
      if (polygonInteriorData.length !== 0) {
        polygonInteriorData.forEach((zone, index) => {
          //for each Keep Out get points
          //draw the zone
          drawPolygon(
            context,
            zone,
            minLat,
            maxLat,
            minLng,
            maxLng,
            canvasSize,
            "#FFFFFF"
          );
        });
      }
    }, [polygonData, canvasSize, polygonInteriorData]);

    return (
      <canvas
        ref={canvasRef}
        width={canvasSize}
        height={canvasSize}
        style={{ background: "#FFFFFF" }}
      />
    );
  };

  /**
   * This React component renders a list of mowing plans based on data stored in state.displayed_plans.
   *  It provides users the functionality to search through the plans, archive plans, and restore archived plans.
   * Plans are filtered by their human-readable identifier and address.
   * @name MowingPlans
   */
  const MowingPlans = () => {
    var plan_list = state.displayed_plans;
    var NoneFound = false;
    var searchbarval = state.SearchValue;

    if (searchbarval !== "") {
      searchbarval = searchbarval.toLocaleLowerCase();
      let newList = [];
      plan_list.forEach((p) => {
        if (
          p
            .HumanReadableIdentifier()
            .toLocaleLowerCase()
            .replace(/\s/g, "")
            .includes(searchbarval.replace(/\s/g, ""))
        ) {
          newList.push(p);
        } else if (
          p
            .Address()
            .toLocaleLowerCase()
            .replace(/\s/g, "")
            .includes(searchbarval.replace(/\s/g, ""))
        ) {
          newList.push(p);
        }
      });
      if (newList.length === 0) {
        NoneFound = true;
      } else {
        plan_list = newList;
      }
    }

    /**
     * Handles the action when a user clicks to archive or restore a plan.
     * @name handleArchiveButtonClick
     * @param {event} event - the event associated with the click
     * @param {MowingPlan} plan - the plan clicked on
     */
    const handleArchiveButtonClick = async (event, plan) => {
      var okay = false;
      if (!plan) {
        return false;
      }
      if (plan.DeletionTime === 0) {
        if (
          window.confirm(
            t("window.archive") +
              plan.HumanReadableIdentifier() +
              t("window.plan")
          )
        ) {
          plan.DeletionTime = 0;
          okay = await DeleteItemAsync(plan.Id.toString());
          if (okay) {
            window.location.reload();
          } else {
            alert(t("alerts.deleting"), "error");
          }
        }
      } else {
        if (
          window.confirm(
            t("window.restore") + plan.HumanReadableIdentifier() + "?"
          )
        ) {
          okay = await RestoreItemAsync(plan.Id.toString());
          if (okay) {
            window.location.reload();
          } else {
            alert(t("alerts.restoring"), "error");
          }
        }
      }

      event.stopPropagation();
    };

    /**
     * Handles the action when a user clicks on a plan. If the plan is active, it routes to the plan's details page.
     * @name handlePlanItemClick
     * @param {event} event - the event associated with the click (not being utitilized but typically provided with click events)
     * @param {MowingPlan} plan - the plan clicked on
     */
    const handlePlanItemClick = (event, plan) => {
      if (!plan) {
        return;
      }
      if (plan.DeletionTime === 0) {
        const { router } = props;
        router.navigate("/Plans/" + plan.Id);
      } else if (plan.DeletionTime === 1) {
        return;
      }
    };

    return (
      <div>
        {searchbarval !== "" && (
          <Typography style={{ marginLeft: "20px", fontSize: "16px" }}>
            <span>
              <b>Search Term:</b> <em>"{state.SearchValue}"</em>
            </span>
          </Typography>
        )}
        <Divider variant="middle" />

        <ListGroup>
          {!NoneFound &&
            plan_list.map((p, i) => {
              return (
                <ListGroup.Item
                  key={i}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePlanItemClick(e, p);
                  }}
                >
                  <Row style={{ alignItems: "center" }}>
                    <Col style={{ maxWidth: "80px" }}>
                      {p.GetPoints().length > 2 && (
                        <PolygonCanvas
                          polygonData={p.GetPoints()}
                          polygonInteriorData={p.GetExclusionPoints()}
                          canvasSize={50}
                        />
                      )}
                    </Col>
                    <Col
                      style={{
                        maxWidth: "80%",
                        textAlign: "start",
                      }}
                    >
                      <Row>{p.HumanReadableIdentifier()}</Row>
                      <Row>{p.Address()}</Row>
                      <Row>{p.Description()}</Row>
                    </Col>
                    <Col
                      style={{
                        maxWidth: "fit-content",
                      }}
                    >
                      <Button
                        style={{
                          minWidth: "fit-content",
                        }}
                        className="btn amr-btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          handleArchiveButtonClick(e, p);
                        }}
                      >
                        {p.DeleteButtonText() === "Archive" && <DeleteIcon />}
                        {p.DeleteButtonText() === "Restore" && (
                          <RestoreFromTrashIcon />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              );
            })}
          {NoneFound && (
            <center>
              <Typography style={{ fontSize: "34px", marginTop: "50px" }}>
                <span>No Plans Found </span>
                <SentimentVeryDissatisfiedIcon
                  style={{ fontSize: "30px", color: red[600] }}
                />
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  color: grey[700],
                }}
              >
                <span>
                  <em>Check your search term and try again</em>
                </span>
              </Typography>
            </center>
          )}
        </ListGroup>
      </div>
    );
  };

  /**
   * The SortByDropDown component provides a search bar, a search button, and a dropdown menu to sort a list of plans.
   *  The sorting options include "Location", "Plan Name", "Date (Newest)", "Date (Oldest)", and "Archived Plans".
   * @name SortByDropDown
   */
  const SortByDropDown = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [option, setOption] = useState(0);
    const [searchvalue, setSearchValue] = useState("");
    const open = Boolean(anchorEl);
    const ITEM_HEIGHT = 48;
    const options = [
      t("selectPlan.location"),
      t("selectPlan.planName"),
      t("selectPlan.dateNewest"),
      t("selectPlan.dateOldest"),
      t("selectPlan.archivedPlan"),
    ];

    /**
     * Opens the dropdown menu when the icon button is clicked.
     * @name handleDropDownClick
     */
    const handleDropDownClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    /**
     * Closes the dropdown menu and sorts the list of plans based on the selected option.
     * @name handleDropDownClose
     */
    const handleDropDownClose = async (event) => {
      var util = new Utilities();
      var method = event.currentTarget.value;
      var newPlans = [];
      switch (method) {
        case PlanSortMethod.Distance:
          var sortPosition = connection.SocketMessage(
            SocketMessageType.GetCenterPosition
          );
          let sort_plans = [];
          state.list_of_plans.forEach((plan) => {
            sort_plans.push(new SortedMowingPlan(plan, sortPosition));
          });

          sort_plans = util.SortPlan(sort_plans, PlanSortMethod.Distance);
          try {
            await sort_plans.forEach((plan) => {
              newPlans.push(plan.mowing_plan);
            });
          } catch (error) {
            console.error("An error occurred while sorting the plans:", error);
          }
          break;
        case PlanSortMethod.Name:
          newPlans = util.SortPlan(state.list_of_plans, PlanSortMethod.Name);
          break;

        case PlanSortMethod.DateLatest:
          newPlans = util.SortPlan(
            state.list_of_plans,
            PlanSortMethod.DateLatest
          );
          break;
        case PlanSortMethod.DateEarliest:
          newPlans = util.SortPlan(
            state.list_of_plans,
            PlanSortMethod.DateEarliest
          );
          break;
        case 4:
          newPlans = state.list_of_archived_plans;
          break;
        default:
          setAnchorEl(null);
          return;
      }

      setAnchorEl(null);

      setState({
        ...state,
        displayed_plans: newPlans,
      });
    };

    /**
     * Updates the search value as the user types.
     * @name handleSearchChange
     */
    const handleSearchChange = (event) => {
      const val = event.currentTarget.value;
      setSearchValue(val);
    };

    /**
     * Updates the global state with the current search value.
     * @name handleSearchButtonClick
     */
    const handleSearchButtonClick = () => {
      setState({
        ...state,
        SearchValue: searchvalue,
      });
    };

    return (
      <div>
        <TextField
          id="standard-basic"
          label={t("selectPlan.enterName")}
          variant="standard"
          type="search"
          className="search-bar-item"
          style={{ marginLeft: "10px" }}
          value={searchvalue}
          onChange={(e) => {
            handleSearchChange(e);
          }}
        />

        <Button
          className="btn amr-btn-primary "
          style={{ marginLeft: "10px", marginTop: "10px" }}
          onClick={handleSearchButtonClick}
        >
          {state.SearchValue.length > 0 ? <SearchOffIcon /> : <SearchIcon />}
        </Button>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleDropDownClick}
          style={{ marginTop: "10px", paddingLeft: "4px", paddingRight: "4px" }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          value={option}
          onClose={(e) => handleDropDownClose(e)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "17ch",
              maxWidth: "100%",
            },
          }}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={option}
                value={index}
                onClick={(e) => handleDropDownClose(e)}
              >
                {option}
              </MenuItem>
            );
          })}
        </Menu>
      </div>
    );
  };

  return (
    <Row className="mt-3">
      <div className="mb-2">
        <div style={{ marginBottom: "10px" }}>
          <Row>
            <center>
              <SortByDropDown />
            </center>
          </Row>
        </div>
      </div>

      <div className="p-3">
        <MowingPlans />
      </div>
    </Row>
  );
};

export default withRouter(PlanManagePage);
