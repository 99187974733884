import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Row, ListGroup, ListGroupItem } from "react-bootstrap";
import React from "react";

export const FaultListPopup = (props) => {
  const { visible, closePopup, fault_array } = props;

  return (
    <Dialog open={visible} onClose={closePopup}>
      <DialogTitle>Faults</DialogTitle>
      <DialogContent>
        <ListGroup>
          {fault_array.map((fault, i) => {
            let split = fault.split("~");
            return (
              <ListGroupItem key={i}>
                <Row>
                  <Typography style={{ fontSize: "15px" }}>
                    <span>
                      <b>{split[0]}</b>
                    </span>
                  </Typography>

                  <Typography style={{ fontSize: "14px" }}>
                    <span>{split[1]}</span>
                  </Typography>
                </Row>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </DialogContent>
    </Dialog>
  );
};
