import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useCallback,
} from "react";
import { Col, Row } from "react-bootstrap";
import AuthService from "../components/services/AuthService";
import { WebSocketContext } from "../components/services/AppConnection";
import { withRouter } from "../components/withRouter";
import { CircularProgress, Typography } from "@mui/material";
import { MowerState, SocketMessageType } from "../components/models/enums";
import { MowerDetails } from "../components/pages/monitorPage/simpleComponents";
import MapComponent from "../components/map/mapComponent";
import { MowerPlanType } from "./mowPage";
import { MapMower } from "../components/map/mapMower";
import GoogleApiHOC from "../components/map/GoogleApiHOC";
import { LoadingBackdrop } from "../components/pages/MowPage/SimpleComponents";
import TopBar from "../components/pages/monitorPage/TopBar";
import { Position } from "../components/models/mowingPlan";
import { useTranslation } from "react-i18next";

/**
 * MonitorPage Component
 *The MonitorPage component serves as a monitoring interface, providing
 * functionality for tracking and managing mowers in real-time using WebSocket communications.
 *
 *
 */
const MonitorPage = (props) => {
  const { t, i18n } = useTranslation();
  //An instance of WebSocket communication, provided via context.
  const connection = useContext(WebSocketContext);
  //Tracks whether a WebSocket connection is currently active.
  const [appeared, setAppeared] = useState(true);
  //Contains a list of mowers.
  const [mowers_list, setMowerList] = useState(null);
  // Tracks the current selection ID.
  const [selection, setSelection] = useState(0);
  //selectedSerialNumber and selectedSerialNumberRef: Both store the serial number of the currently selected mower.
  //The ref is used to persist the value across renders.
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(0);
  const selectedSerialNumberRef = useRef(0);
  // Represents the current mower selected by the user.
  const [selected_mower, setSelectedMower] = useState(null);
  //Used to check whether tracking is active.
  const TrackingRef = useRef(false);
  // /A reference to the list of mowers.
  const mowerListRef = useRef(null);
  // Holds dimensions for various screen components (e.g., map height, toolbar height, etc.).
  const [ScreenDimension, setScreenDimension] = useState({
    ScreenHeight: 0,
    ScreenWidth: 0,
    MapHeight: 0,
    TopBarHeight: 0,
    ToolbarHeight: 0,
  });
  //Stores various properties related to the mower, such as speed, status, fuel level, and more.
  const [mower_props, setMowerProps] = useState({
    moweroverview: "",
    moweroperator: "",
    mowerstatus: "",
    mowerspeed: "",
    mowervoltage: "",
    mowerfuellevel: "",
    mowerlatitude: "",
    mowerlongitude: "",
    mowerheading: "",
  });
  // Indicates whether an update is needed, triggering certain actions/re-renders.
  const [update, setUpdate] = useState(false);

  /**
   * useEffect
   * Purpose: This hook carries out multiple tasks once the component mounts:
   * User Authorization: Uses AuthService to ensure the user is authorized. Redirects unauthorized users to the login page.
   * Session Storage: Resets the selected mower in session storage to an empty string.
   * Mowers List: Sets the mowers_list state to the list of mowers available from the WebSocket connection.
   * Connection Monitoring: Every millisecond, checks if the WebSocket connection is active and updates the appeared state accordingly.
   * Application Update: Every 200 milliseconds, fetches an updated list of mowers and updates the application's data.
   * Screen Dimensions: Calculates and sets the dimensions for various screen components based on the current viewport size.
   * The hook also ensures that the intervals it starts are cleared once the component unmounts to prevent memory leaks.
   */
  useEffect(() => {
    //verify the user is authorized
    let auth = new AuthService();
    auth.enforceLogin();
    if (sessionStorage.getItem("SelectedMower"))
      sessionStorage.setItem("SelectedMower", "");
    let mowers;
    if (connection) {
      setMowerList(connection.mowers_);

      var disconnectInterval = setInterval(() => {
        if (!connection.SocketMessage(SocketMessageType.CheckConnected)) {
          setAppeared(false);
        } else {
          setAppeared(true);
        }
      }, 500);

      var AppUpdate = setInterval(() => {
        mowers = connection.SocketMessage(SocketMessageType.GetMowers);
        AppMowerDataUpdate(mowers);
      }, 200);
    }

    //First, set the dimensions of the components based on the screen height
    var mapHeight, toolbarHeight, screenWidth, screenHeight, topbarHeight;
    //get the height/width of the screen
    screenHeight = document.documentElement.clientHeight;
    screenWidth = document.documentElement.clientWidth;
    //Set TopBar, Map, and ToolBar height
    topbarHeight = Math.round((screenHeight * 0.05).toString()) + "px";
    mapHeight = Math.round((screenHeight * 0.65).toString()) + "px";
    toolbarHeight = Math.round((screenHeight * 0.3).toString()) + "px";
    //Set entire screen height and width
    screenHeight = screenHeight.toString() + "px";
    screenWidth = Math.round(screenWidth).toString() + "px";

    setScreenDimension({
      ScreenHeight: screenHeight,
      ScreenWidth: screenWidth,
      MapHeight: mapHeight,
      TopBarHeight: topbarHeight,
      ToolbarHeight: toolbarHeight,
    });

    return () => {
      clearInterval(disconnectInterval);
      clearInterval(AppUpdate);
    };
  }, []);

  /**
   * Updates the list of mowers based on the provided data.
   *Removes any mowers that have gone offline.
   *If the mower already exists in the mowerlist, it will update its information. If not, it will create a new mower entry.
   *
   * @function AppMowerDataUpdate
   * @param {Array} obj -  The list of mower objects received for the update.
   *
   */
  function AppMowerDataUpdate(obj) {
    let mowerlist;
    if (obj) {
      RemoveOfflineMowers(obj);
    }
    if (mowerListRef.current) {
      mowerlist = mowerListRef.current;
    }

    obj.forEach((m) => {
      let mower;
      if (mowerlist.has(m.serial_number)) {
        mower = new MapMower(mowerlist.get(m.serial_number));
      } else {
        mower = new MapMower(m);
        mowerlist.set(m.serial_number, mower);
      }
    });

    mowerListRef.current = mowerlist;
  }

  /**
   * Removes mowers that have gone offline.
   *Filters out mowers that are not present in the provided mowerObjects list.
   * It also handles the case where the selected mower goes offline by deselecting it.
   *
   * @function RemoveOfflineMowers
   * @param {Array} mowerObjects -  The list of mower objects received for the update.
   *
   */
  function RemoveOfflineMowers(mowerObjects) {
    let mowers;
    if (!mowerObjects) {
      return;
    }
    if (mowerListRef.current) {
      mowers = mowerListRef.current;
    }

    let newMowers = new Map();
    if (mowers) {
      mowerObjects.forEach((m) => {
        if (mowers.has(m.serial_number)) {
          newMowers.set(m.serial_number, mowers.get(m.serial_number));
          mowers.delete(m.serial_number);
        }
      });
      mowers.forEach((m) => {
        if (m.serial_number === selectedSerialNumberRef.current) {
          DeselectMower();
        }
      });
    }

    mowerListRef.current = newMowers;
  }

  /**
   * Handles the change event for quick selecting a mower from a dropdown or similar component.
   * Depending on the selection, it will either select a mower for tracking or deselect the currently tracked mower.
   *
   * @function QuickselectMowerChanged
   * @param {Object} event -  The event object which contains information about the change.
   *
   */
  const QuickselectMowerChanged = (event) => {
    if (!event) {
      return;
    }
    var selection = event.target.value;
    if (selection !== 0) {
      [...mowers_list].forEach((m) => {
        if (m[0] === selection) {
          SelectMower(selection);
          setSelection(selection);
        }
      });
    } else {
      //REMOVE SELECTED MOWER AND TECKING
      DeselectMower();
      setSelection(selection);
    }
  };

  /**
   * Handles the logic for selecting a specific mower for tracking based on its serial number.
   * If the provided serial number is different from the currently selected mower, the function
   * will attempt to select and track it, updating various references and states. If the mower does
   * not have valid coordinates, it will deselect any currently tracked mower.
   *
   * @function SelectMower
   * @param {number} serial_num - The serial number of the mower to be selected.
   *
   */
  function SelectMower(serial_num) {
    // if (!serial_num || !selectedSerialNumberRef.current) {
    //   return;
    // }
    if (serial_num !== selectedSerialNumberRef.current) {
      let selected_mower;
      if (mowerListRef.current.has(serial_num)) {
        selected_mower = mowerListRef.current.get(serial_num);
        if (
          selected_mower.pin.position.lat !== 0.0 ||
          selected_mower.pin.position.lng !== 0.0
        ) {
          TrackingRef.current = true;
          setSelectedSerialNumber(serial_num);
          setSelection(serial_num);
          selectedSerialNumberRef.current = serial_num;
        }
      } else {
        TrackingRef.current = false;
        setSelection(0);
        setSelectedSerialNumber(0);
        selectedSerialNumberRef.current = 0;
        return false;
      }
    } else {
      selectedSerialNumberRef.current = serial_num;
      SelectMower(serial_num);
    }
  }

  /**
   * Handles the logic for deselecting a tracked mower.
   * Resets tracking references and states to their default values.
   *
   * @function DeselectMower
   *
   */
  function DeselectMower() {
    if (!selectedSerialNumberRef.current) {
      TrackingRef.current = false;
      return;
    }

    if (selection === selectedSerialNumberRef.current) {
      setSelection(0);
      selectedSerialNumberRef.current = 0;
      setSelectedSerialNumber(0);
    }
  }

  /**
   * This component acts as a wrapper for the TopBar component
   *  and passes predefined properties to it.
   *
   * @function TopBarComponent
   * @param {number} height - Specifies the height of the top bar, derived from ScreenDimension.TopBarHeight.
   * @param {number} selection - Represents the currently selected mower or state in the component.
   * @param {function} QuickselectMowerChanged - A callback function that is triggered when a mower is selected or deselected.
   *  This function handles the logic for updating the selection.
   * @param {array} mowerlist - A list of mowers available for selection. This list is presumably sourced from a connection object.
   * @param {boolean} update - a state variable indicating whether an update is needed.
   */
  const TopBarComponent = () => {
    return (
      <TopBar
        height={ScreenDimension.TopBarHeight}
        selection={selection ? selection : 0}
        QuickselectMowerChanged={QuickselectMowerChanged}
        mowerlist={connection ? connection.mowers_ : []}
        update={update}
      />
    );
  };

  /**
   * MapObject Component
   *
   * This component serves as a wrapper for the MapComponent and sends objects to it to be displayed
   * @param {string} height - representation of the height of the google map to be sent to the style tag
   * @param {string} width - representation of the width of the google map to be sent to the style tag
   * @param {map} mowers - List of connected mowers sent from the websocket connection
   */
  const MapObject = ({ height, width, mowers }) => {
    const [mower_list, setMowerList] = useState(null);
    const [selected_map_mower, setSelectedMapMower] = useState(null);
    const [tracking, setTracking] = useState(TrackingRef.current);
    const [homePosition, setHomePosition] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [recordingPlan, setRecordingPlan] = useState(null);
    const [planType, setPlanType] = useState(null);
    const [userPosition, setUserPosition] = useState(null);
    const [loading, setLoading] = useState(true);
    const userPosRef = useRef(null);

    /**
     * A callback function triggered when the map is dragged.
     * The Tracking state variable is set to false so the centered object is no longer followed
     *
     * @function onMapDrag
     */
    const onMapDrag = () => {
      setTracking(false);
    };

    /**
     * A callback function triggered when a pin representing a mower on the map is single-tapped.
     * If the selected serial number matches the mower's serial number of the tapped pin, tracking
     *  is set to true. Otherwise, various states reflecting the selected mower are updated.
     *
     * @function onPinSingleTap
     * @param {event} event - Represents the event object containing details about the tap event.
     * @param {object} marker - Contains the information about the tapped mower.
     */
    const onPinSingleTap = (event, marker) => {
      if (selectedSerialNumberRef.current === marker.mower.serial_number) {
        setTracking(true);
      } else {
        setTracking(true);
        selectedSerialNumberRef.current = marker.mower.serial_number;
        setSelectedSerialNumber(marker.mower.serial_number);
        setSelectedMapMower(marker.mower.serial_number);
        setSelection(marker.mower.serial_number);
      }
    };

    /**
     * A callback function that is triggered when the map's zoom level is changed.
     * It stops tracking by setting the tracking state to false.
     *
     * @function onZoomChanged
     */
    const onZoomChanged = () => {
      setTracking(false);
    };

    /**
     * A callback function for handling the event when a mouse button is pressed on a polygon.
     * Currently not implemented.
     *
     * @function onPolyMouseDown
     */
    const onPolyMouseDown = () => {};

    /**
     * A callback function for handling the event when a mouse button is released  on a polygon.
     * Currently not implemented.
     *
     * @function onPolyMouseUp
     */
    const onPolyMouseUp = () => {};

    /**
     * Determines the type of plan a mower is currently executing based on its state and other details.
     *
     * @function DeterminePlanType
     * @param {object} mower - Contains the information about a particular mower.
     */
    function DeterminePlanType(mower) {
      if (
        mower.state !== MowerState.Idle &&
        mower.state !== MowerState.Manual &&
        mower.state !== MowerState.Recording
      ) {
        if (mower.details.current_plan) {
          SetPlanDisplay(MowerPlanType.CurrentPlan, mower);
        }
      } else if (mower.state === MowerState.Recording) {
        SetPlanDisplay(MowerPlanType.RecordingPlan, mower);
      } else {
        SetPlanDisplay(MowerPlanType.NoPlan, mower);
      }
    }

    /**
     * Sets the display of a mower's plan based on the provided plan type.
     *
     * @function SetPlanDisplay
     * @param {number} plantype - Specifies the type of plan to display.
     * @param {object} mower - Contains details about a particular mower.
     */
    function SetPlanDisplay(plantype, mower) {
      if (plantype === MowerPlanType.CurrentPlan) {
        setCurrentPlan(mower.details.current_plan);
        setRecordingPlan(null);
        setPlanType(MowerPlanType.CurrentPlan);
      } else if (plantype === MowerPlanType.RecordingPlan) {
        setCurrentPlan(null);
        setRecordingPlan(mower.details.recorded_plan);
        setPlanType(MowerPlanType.RecordingPlan);
      } else {
        setPlanType(MowerPlanType.NoPlan);
        setCurrentPlan(null);
        setRecordingPlan(null);
      }
    }

    /**
     * Updates the display of the mower's home position.
     *
     * @function DisplayHomePosition
     * @param {object} homepos -  The position to be displayed as the home position.
     */
    function DisplayHomePosition(homepos) {
      if (homePosition !== homepos) {
        setHomePosition(homepos);
      }
    }

    /**
     * If a selection is available, sets the selected map mower and starts tracking.
     *
     * @function SelectMapMower
     */
    function SelectMapMower() {
      if (selection !== 0) {
        setSelectedMapMower(selectedSerialNumberRef.current);
        setTracking(true);
      }
    }

    /**
     * Initializes or updates markers representing mowers on the map.
     *
     * @function SetUpMowerMarkers
     */
    const SetUpMowerMarkers = useCallback(() => {
      try {
        //Init empty array for mowers that are not selected
        let temp = [];

        //Loop through each mower
        mowers.forEach((m) => {
          //Instantiate a MapMower object using the current Mower Data
          let t = new MapMower(m);

          //If the serial number of the current mower matched the selected serial number
          //Set the current mower as the selected serial mower
          if (t.data.serial_number === selectedSerialNumberRef.current) {
            setSelectedMower(t.data.serial_number);
            DisplayHomePosition(t.data.details.home_position);
            temp.push(t);
          } else {
            //If the current mower is not the selected mower but the user is connected to it, add it to the temp array
            temp.push(t);
          }
        });

        //Set the list of mowers to the temp array, which contains all mowers not selected
        setMowerList(temp);
      } catch (ex) {
        console.error(ex.message);
      }
    }, []);

    /**
     * Gets the current geolocation of the user.
     *
     * @function GetUserPosition
     */
    // const GetUserPosition = useCallback(() => {
    //   return new Promise((resolve, reject) => {
    //     if (navigator.geolocation) {
    //       navigator.geolocation.getCurrentPosition(
    //         (position) => {
    //           // if geolocation is available, set the center of the map to the user location
    //           const { latitude: lat, longitude: lng } = position.coords;
    //           resolve(new Position(lat, lng));
    //         },
    //         (error) => {
    //           reject(error);
    //         }
    //       );
    //     } else {
    //       // if geolocation isn't available, reject the promise
    //       console.log("Geolocation is not supported by this browser");
    //       reject(new Error("Geolocation is not supported by this browser"));
    //     }
    //   });
    // }, []);
    async function GetUserPosition() {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => resolve(position),
            (error) => reject(error)
          );
        });

        // If we've reached this point, the user has granted permission.
        const { latitude: lat, longitude: lng } = position.coords;
        return new Position(lat, lng);
      } catch (error) {
        // Handle the specific error when the user denies permission.
        if (error.code === error.PERMISSION_DENIED) {
          console.error("User denied geolocation permission.");
          // You can show a message to the user indicating that geolocation is required for your app to function.
        } else {
          console.error(error);
        }

        throw error;
      }
    }

    /**
     * useEffect
     *Purpose: Called once when the component mounts. It sets up mower markers, selects the mower on the map, and changes the loading state to false.
     * Dependencies: This effect has no dependencies, meaning it only runs once when the component is first mounted.
     */
    useEffect(() => {
      SetUpMowerMarkers();
      SelectMapMower();
      setLoading(false);
    }, []);

    /**
     * Mowers Update useEffect
     *Purpose: Monitors changes in the mowers state and updates the marker list based on those changes every second.
     * Dependencies: Dependent on the mowers state, meaning it will run every time the mowers state changes.
     *
     */
    useEffect(() => {
      let markerList = [];

      let MarkerUpdateInterval = setInterval(() => {
        markerList = [...mowers.entries()].map((m) => {
          return new MapMower(m[1]);
        });
        let temp = [];

        if (markerList.length <= 0) {
          setLoading(true);
          DeselectMower();
          if (userPosRef.current === null) {
            GetUserPosition()
              .then((position) => {
                setUserPosition(position);
                userPosRef.current = position;
                setLoading(false);
              })
              .catch((error) => {
                console.error(error);
                setUserPosition(null);
              });
          } else {
            setLoading(false);
          }
          return;
        }

        markerList.forEach((m) => {
          temp.push(m);

          if (selectedSerialNumberRef.current === m.data.serial_number) {
            DisplayHomePosition(m.data.details.home_position);
            DeterminePlanType(m.data);
          }
        });

        setMowerList(temp);
      }, 1000);

      return () => clearInterval(MarkerUpdateInterval);
    }, [mowers]);

    return (
      <div>
        {loading && (
          <div style={{ margin: "50px", height: height }}>
            <center>
              <CircularProgress size={60} />
            </center>
          </div>
        )}
        {!loading && (
          <div>
            <MapComponent
              google={props.google}
              height={height}
              width={width}
              centerLat={
                mowers !== undefined && mowers !== null && mowers.size > 0
                  ? [...mowers.entries()][0][1].lat
                  : null
              }
              centerLng={
                mowers !== undefined && mowers !== null && mowers.size > 0
                  ? [...mowers.entries()][0][1].lon
                  : null
              }
              mowers={mower_list}
              onMapDrag={onMapDrag}
              onPinSingleTap={onPinSingleTap}
              onPolyMouseDown={onPolyMouseDown}
              onPolyMouseUp={onPolyMouseUp}
              onZoomChanged={onZoomChanged}
              selectedMower={selected_map_mower}
              isTracking={tracking}
              homePosition={homePosition}
              currentPlan={currentPlan}
              recordingPlan={recordingPlan}
              planType={planType}
              containerId={"map1"}
              userPosition={userPosition}
            />
          </div>
        )}
        <ToolBar mowerProps={mower_props} />
      </div>
    );
  };

  /**
   * This function represents a toolbar that displays information about a selected mower.
   * @name ToolBar
   * @returns rendering a toolbar that shows different sections based on the state of showDetails and selected_mower.
   */
  const ToolBar = () => {
    const [mower, setMower] = useState(() => selectedSerialNumberRef.current);

    /**
     * The useEffect hook is responsible for continuously fetching updated data about the selected mower every second.
     *
     * Logic:
     * An interval is set to run every 1000 milliseconds (1 second).
     * If a mower is selected (i.e., mower !== 0), the component fetches updated mowers' data via connection.SocketMessage(SocketMessageType.GetMowers).
     * The component then checks the fetched list of mowers to find the currently selected mower by matching serial numbers.
     * If a match is found, the mower state is updated with the details of the matched mower.
     * Cleanup: The interval is cleared when the component unmounts.
     */
    useEffect(() => {
      let isMounted = true;
      const interval = setInterval(() => {
        try {
          if (mower !== 0) {
            if (connection) {
              let updatedMowers = connection.SocketMessage(
                SocketMessageType.GetMowers
              );
              updatedMowers.forEach((m) => {
                if (m.serial_number === mower) {
                  if (isMounted) {
                    setMower({ mower: m, selected_mower: true });
                  }
                }
              });
            }
          }
        } catch (error) {
          //
        }
      }, 1000);

      return () => {
        isMounted = false;
        clearInterval(interval);
      };
    }, []);

    /**
     * Rendering Logic:
     *
     * If no mower is selected or the mower state is null or 0, a prompt for the user to tap a mower for more information is displayed.
     * Otherwise, the details of the selected mower are displayed using the MowerDetails component.
     */
    if (mower === null || mower === 0) {
      return (
        <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
          <Col style={{ minWidth: "fit-content" }}>
            <Typography>{t("monitor.tapMower")}</Typography>
          </Col>
        </Row>
      );
    }
    return <MowerDetails selected_mower={mower.mower} />;
  };

  return (
    <div>
      <LoadingBackdrop loading={!appeared} />
      <Row style={{ height: ScreenDimension.ScreenHeight }}>
        <Col style={{ width: ScreenDimension.ScreenWidth }}>
          <center>
            <Col style={{ maxWidth: "300px" }}>
              <TopBarComponent />
            </Col>
          </center>
          <Row style={{ height: ScreenDimension.MapHeight }}>
            <MapObject
              height={ScreenDimension.MapHeight}
              width={ScreenDimension.ScreenWidth}
              mowers={connection.mowers_}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default GoogleApiHOC(withRouter(MonitorPage));
