import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Slider,
  Typography,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import MapComponent from "../../map/mapComponent";
import { MowerPlanType } from "../../../pages/mowPage";
import { red } from "@mui/material/colors";
import GoogleApiHOC from "../../map/GoogleApiHOC";

const StripeAngleEditor = (props) => {
  const {
    visible,
    closePopup,
    initPlan,
    newProps,
    currentProps,
    AngleApplyButtonClicked,
    AngleCancelButtonClicked,
  } = props;
  const [angle, setAngle] = useState(newProps.stripeangle);
  const originalAngle = useRef(0);
  const [Sizing, setSizing] = useState({ MapSize: "305px" });

  /**
   * useEffect hook
   *
   * initializes the originalAngle and Sizing states
   * based on the provided newProps or currentProps.
   *
   */
  useEffect(() => {
    let props;
    if (newProps !== null) {
      props = newProps;
    } else {
      props = currentProps;
    }

    if (!props || typeof props.stripeangle !== "number") {
      throw new Error("Invalid properties provided to StripeAngleEditor");
    }
    originalAngle.current = Math.round(props.stripeangle);

    setSizing({
      MapSize: getMapWidth().toString() + "px",
    });
  }, []);

  /**
   *
   * Calculates and returns the width of the map based on the screen size.
   *
   * @name getMapWidth
   */
  function getMapWidth() {
    const devScreenWidth = 428; // screen width during development
    const devPopupWidth = 350; // popup width during development
    const devMapWidth = 300; // map width during development

    const ratio = devPopupWidth / devScreenWidth;

    const currentScreenWidth = document.documentElement.clientWidth; // get current screen width
    const currentPopupWidth = ratio * currentScreenWidth;

    const mapWidthRatio = devMapWidth / devPopupWidth;
    const currentMapWidth = mapWidthRatio * currentPopupWidth;

    if (currentMapWidth > 300) {
      return 300;
    }
    return currentMapWidth;
  }

  /**
   *
   * A functional component that returns a rendered map preview with the selected stripe angle.
   *
   * @name MapObject
   */
  const MapObject = useCallback(() => {
    const tempPlan = useMemo(() => {
      if (initPlan !== null) {
        let props;
        if (newProps !== null) {
          props = newProps;
        } else {
          props = currentProps;
        }

        if (props.stripeangle !== angle) {
          props.stripeangle = angle;
        }
        let p = initPlan;
        p.Properties = JSON.stringify(props);
        return p;
      }
      return null;
    }, [angle, initPlan, newProps, currentProps]);

    const CenteredRef = useRef(true);
    useEffect(() => {
      CenteredRef.current = true;
    }, [angle]);

    return (
      <Row style={{ height: "305px" }}>
        <center>
          <MapComponent
            key={angle}
            style={{ margin: "0px", padding: "0px" }}
            google={props.google}
            height={"300px"}
            width={Sizing.MapSize}
            mowers={[]}
            selectedMower={null}
            onMapDrag={() => {
              CenteredRef.current = false;
            }}
            onPinSingleTap={() => {}}
            onPolyMouseDown={() => {}}
            onPolyMouseUp={() => {}}
            onZoomChanged={() => {
              CenteredRef.current = false;
            }}
            isTracking={null}
            homePosition={null}
            currentPlan={null}
            recordingPlan={null}
            planType={MowerPlanType.PlanPreview}
            plan={tempPlan}
            userPosition={null}
            containerId={"map2"}
            centered={CenteredRef.current}
            stripeAngle={angle}
          />
        </center>
      </Row>
    );
  }, [angle, initPlan, newProps, currentProps, props.google, Sizing.MapSize]);

  return (
    <Dialog open={visible} onClose={closePopup}>
      <DialogTitle style={{ minWidth: "350px" }}>Edit Stripe Angle</DialogTitle>
      <DialogContent style={{ maxWidth: "350px", height: "450px" }}>
        <MapObject angle={angle} />
        <Row>
          <Col>
            <Typography style={{ width: "200px" }}>
              <span>Slide to set stripe angle: </span>
            </Typography>
          </Col>
          <Col>
            <Typography>
              <span>{angle}°</span>
            </Typography>
          </Col>
        </Row>
        <Row>
          <center>
            <Slider
              sx={{ color: red[700], width: "250px" }}
              value={angle}
              min={0}
              max={180}
              step={1}
              valueLabelDisplay="off"
              onChange={(e) => setAngle(e.target.value)}
            ></Slider>
          </center>
        </Row>
        <Row style={{ textAlign: "center" }}>
          <Col>
            <Button
              className="btn amr-btn-primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => {
                try {
                  AngleApplyButtonClicked(angle);
                } catch (error) {
                  console.error("Error applying the stripe angle:", error);
                }
              }}
            >
              Apply
            </Button>
          </Col>
          <Col>
            <Button
              className="btn amr-btn-primary"
              style={{
                textTransform: "capitalize",
              }}
              onClick={() => {
                try {
                  AngleApplyButtonClicked(originalAngle.current);
                } catch (error) {
                  console.error("Error applying the stripe angle:", error);
                }
              }}
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};

export default GoogleApiHOC(StripeAngleEditor);
