import React, { createContext, useContext, useState } from "react";
import CustomToast from "./CustomToast";

const ToastContext = createContext();

export const useToast = () => {
  return useContext(ToastContext);
};

export const ToastProvider = ({ children }) => {
  const [toastInfo, setToastInfo] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const showToast = (message, severity = "success") => {
    setToastInfo({ open: true, message, severity });
  };

  const hideToast = () => {
    setToastInfo({ open: false });
  };

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      {toastInfo.open && <CustomToast {...toastInfo} onClose={hideToast} />}
    </ToastContext.Provider>
  );
};
