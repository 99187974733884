import { Position } from "./mowingPlan";
import { MathUtil } from "../utility";

export class SortedMowingPlan {
  constructor(plan, currentPosition) {
    if (!plan || !currentPosition) {
      return;
    }
    let math_util = new MathUtil();
    let planPoints = plan.GetPoints();
    let currentPoint = new Position(currentPosition.lat, currentPosition.lng);

    //Determine if the mower location is in the mowing plan
    this.in_polygon = PointInPlan(currentPoint, planPoints);
    //Determine the mower length to the center of the plan
    this.plan_distance = math_util.GetHaversineDistance(
      currentPosition.lat,
      currentPosition.lng,
      plan.CenterLat,
      plan.CenterLon
    );
    this.plan_latitude = plan.CenterLat;
    this.plan_longitude = plan.CenterLon;

    this.mowing_plan = plan;
  }
}

export const PointInPlan = (position, polygon) => {
  if (!position) {
    return false;
  }

  if (!Array.isArray(polygon)) {
    return false;
  }
  const x = position.lng,
    y = position.lat;
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i]?.lng,
      yi = polygon[i]?.lat;
    const xj = polygon[j]?.lng,
      yj = polygon[j]?.lat;

    if (xi !== undefined && yi !== undefined) {
      const intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }
  }

  return inside;
};
