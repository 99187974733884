import { LocalXYUtil } from "../../components/utility";

export class MowingPlan {
  constructor(plan) {
    if (plan) {
      this.CenterLat = plan.centerLat || plan.CenterLat;
      this.CenterLon = plan.centerLon || plan.CenterLon;
      this.CompanyId = plan.companyId || plan.CompanyId;
      this.CreationTime = plan.creationTime || plan.CreationTime;
      this.CreatorName = plan.creatorName || plan.CreatorName;
      this.Id = plan.id || plan.Id;
      this.ModificationTime = plan.modificationTime || plan.ModificationTime;
      this.PlanName = plan.planName || plan.PlanName;
      this.Properties = plan.properties || plan.Properties;
      this.StreetAddress = plan.streetAddress || plan.StreetAddress;
      this.CityName = plan.cityName || plan.CityName;
      this.ZipCode = plan.zipCode || plan.ZipCode;
      this.Type = plan.type || plan.Type;
      this.Points = plan.points || plan.Points;
      this.ExclusionPoints = plan.interiorPoints || plan.ExclusionPoints;
      this.MowSections = plan.MowSections || null;
      this.DeletionTime = plan.deletionTime || plan.DeletionTime || 0;

      try {
        this.OriginalMowPlan =
          new MowSection(plan.points, plan.interiorPoints) ||
          new MowSection(plan.Points || plan.ExclusionPoints);
      } catch (error) {
        console.error("Error creating OriginalMowPlan", error);
      }
    }
  }
  Id;
  CompanyID;
  CreatorName;
  PlanName;
  CenterLat;
  CenterLon;
  CreationTime;
  ModificationTime;
  //Original region for the plan
  OriginalMowPlan;
  //Split or modified plan sections
  //If non-null use this instead of original on mow page
  MowSections = null;
  StreetAddress;
  ZipCode;
  Properties;
  DeletionTime;
  //Set true if the user is inside of the plan
  InPlan;
  PlanCircleSource;
  //the index of the selected polygon if there are multiple mow sections
  SelectedPolygon = 0;
  //If the plan is partially complete and to be resumed, this wil be non-null
  MowedPlan = null;
  Points;
  ExclusionPoints;

  /**
   * Generates a human-readable identifier for the mowing plan.
   * @returns {string} The human-readable identifier.
   */
  HumanReadableIdentifier() {
    if (!this.PlanName || this.PlanName === null) {
      return "#" + this.Id.toString() + " by " + this.CreatorName.toString();
    }
    return this.PlanName.toString() + " by " + this.CreatorName.toString();
  }

  /**
   * Generates a description for the mowing plan based on the creation time and the plan area.
   * @returns {string} The mowing plan description.
   */
  Description() {
    let date = new Date(this.CreationTime + "Z");
    const num = this.OriginalMowPlan.Area();
    const acres = num.toFixed(2);
    const options = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const localString = date.toLocaleString(undefined, options);
    let textName = localString + " (" + acres + " acres)";
    return textName;
  }

  /**
   * Generates a formatted address string.
   * @returns {string} The formatted address.
   */
  Address() {
    if (
      this.StreetAddress === null ||
      this.StreetAddress === undefined ||
      this.ZipCode === undefined ||
      this.ZipCode === null
    ) {
      return "";
    }
    return this.StreetAddress.toString() + ", " + this.ZipCode.toString();
  }

  /**
   * Calculates the area of the plan.
   * @returns {number} The calculated area.
   */
  Area() {
    this.Area = Math.abs(ComputeSignedArea(this.GetPoints())) * 0.00002296;
    return this.Area;
  }

  /**
   * Determines the appropriate button text for delete functionality.
   * @returns {string} The button text.
   */
  DeleteButtonText() {
    return this.DeletionTime === 0 ? "Archive" : "Restore";
  }

  /**
   * Parses and returns the mowing plan properties.
   * @returns {object} The mowing plan properties.
   */
  GetProperties() {
    if (this.Properties) {
      try {
        return JSON.parse(this.Properties);
      } catch (ex) {
        // console.dir(ex);
      }
    } else {
      return new MowingPlanJsonProperties();
    }
  }

  /**
   * This function retrieves the points of a mowing plan.
   * @name GetPoints
   * @returns  retrieved points
   */
  GetPoints() {
    this.OriginalMowPlan = new MowSection(this.Points, this.ExclusionPoints);
    return this.OriginalMowPlan.GetPoints();
  }

  /**
   * This function retrieves the exclusion points of a mowing plan.
   * @name GetExclusionPoints
   * @returns  exclusion points
   */
  GetExclusionPoints() {
    this.OriginalMowPlan = new MowSection(this.Points, this.ExclusionPoints);
    return this.OriginalMowPlan.GetExclusionPoints();
  }

  /**
   * This function used to process and modify polygonal data in geographical coordinates.
   * @name FilterPerimeter
   * @param poly -An array of points representing a polygon, where each point is an object with lat and lng properties (latitude and longitude coordinates).
   * @param interior - A boolean flag that indicates whether the poly array represents an interior or exterior boundary. If interior is true, it means the polygon is an interior boundary, and the function will perform a union operation with other polygons. If interior is false, it means the polygon is an exterior boundary.
   * @param offset - A floating-point number representing the distance by which to offset the resulting polygon. A positive value will create an outward offset, and a negative value will create an inward offset.
   * @returns offsetted or simplified points are converted back to latitude and longitude coordinates, and the resulting array of points
   */
  FilterPerimeter(poly, interior, offset) {
    const ClipperLib = require("js-clipper");
    if (poly.length === 0) {
      return poly;
    }

    var xy = new LocalXYUtil(poly[0].lat, poly[0].lng);
    var points = [];
    poly.forEach((pt) => {
      let xny = xy.fromLatLon(pt.lat, pt.lng);
      points.push({ X: xny.X, Y: xny.Y });
    });
    var list = [[]];
    list.push(points);

    let co = new ClipperLib.Clipper();
    ClipperLib.JS.ScaleUpPaths(list, 1000.0);
    if (!interior) {
      co.AddPaths(list, ClipperLib.PolyType.ptSubject, true);
      var solution_paths = new ClipperLib.Paths();
      var succeeded = co.Execute(
        ClipperLib.ClipType.ctUnion,
        solution_paths,
        ClipperLib.PolyFillType.pftNonZero
      );
      if (succeeded) {
        list = solution_paths;
      }
    }
    list = this.ramerDouglasPeucker(list, 0.05);
    ClipperLib.JS.ScaleUpPaths(list, 1.0 / 1000.0);
    if (offset !== 0.0) {
      var clipperoff = new ClipperLib.ClipperOffset(2, 4);
      clipperoff.AddPaths(
        list,
        ClipperLib.JoinType.jtMiter,
        ClipperLib.EndType.etClosedPolygon
      );
      var offsetted_paths = new ClipperLib.Paths();
      clipperoff.Execute(offsetted_paths, offset);
    }

    //convert back to lat lng
    var latlng = [];
    list[0].forEach((pt) => {
      let xny = xy.toLatLon(pt.X, pt.Y);
      latlng.push(new Position(xny.lat, xny.lng));
    });

    return latlng;
  }

  /**
   * This function finds the shortest distance between a point and a line segment by calculating a perpendicular line from the point to the line and measuring the length of that line segment.
   * @name perpendicularDistance
   * @param point - An array representing the coordinates of the point (x, y) for which we want to find the perpendicular distance to the line segment.
   * @param lineStart - An array representing the coordinates of the starting point (x1, y1) of the line segment.
   * @param lineEnd - An array representing the coordinates of the ending point (x2, y2) of the line segment.
   * @returns perpendicular distance is computed as the Euclidean distance between the point (ax, ay) and the original point.
   */
  perpendicularDistance(point, lineStart, lineEnd) {
    let [x, y] = point;
    let [x1, y1] = lineStart;
    let [x2, y2] = lineEnd;

    let dx = x2 - x1;
    let dy = y2 - y1;

    // Normalize
    let mag = Math.hypot(dx, dy);
    if (mag > 0.0) {
      dx /= mag;
      dy /= mag;
    }
    let pvx = x - x1;
    let pvy = y - y1;

    // Get dot product (project pv onto normalized direction)
    let pvdot = dx * pvx + dy * pvy;

    // Scale line direction vector and subtract it from pv
    let ax = pvx - pvdot * dx;
    let ay = pvy - pvdot * dy;

    return Math.hypot(ax, ay);
  }

  /**
   * This function simplifies a curve or path by recursively removing points that do not significantly affect the shape of the curve.
   * @name ramerDouglasPeucker
   * @param pointList -  An array containing the list of points (each represented as an array [x, y]) that define the curve or path.
   * @param epsilon - The tolerance parameter that determines how much simplification is allowed. Smaller values result in more points being removed.
   * @returns the result, which is the simplified list of points representing the curve.
   */
  ramerDouglasPeucker(pointList, epsilon) {
    // Find the point with the maximum distance
    let dmax = 0;
    let index = 0;
    let end = pointList.length - 1;

    for (let i = 1; i < end; i++) {
      let d = this.perpendicularDistance(
        pointList[i],
        pointList[0],
        pointList[end]
      );
      if (d > dmax) {
        index = i;
        dmax = d;
      }
    }

    // If max distance is greater than epsilon, recursively simplify
    let result = [];
    if (dmax > epsilon) {
      // Recursive call
      let recResults1 = this.ramerDouglasPeucker(
        pointList.slice(0, index + 1),
        epsilon
      );
      let recResults2 = this.ramerDouglasPeucker(
        pointList.slice(index, end + 1),
        epsilon
      );

      // Build the result list
      result = recResults1.slice(0, recResults1.length - 1).concat(recResults2);
    } else {
      result = [pointList[0], pointList[end]];
    }

    // Return the result
    return result;
  }
}

export class MowerPlan {
  exterior_bounds = [];
  keepout_bounds = [];
  props;
  Properties;

  /**
   * Retrieves the properties of the mower plan from the serialized `Properties` attribute.
   * @returns {Object} A MowingPlanJsonProperties object representing the mower plan properties.
   * If deserialization fails, returns a new MowingPlanJsonProperties object.
   */
  GetProperties() {
    let props = null;
    try {
      if (this.Properties || typeof this.Properties === "string") {
        props = MowingPlanJsonProperties.Deserialize(this.Properties);
      }
    } catch (ex) {
      props = new MowingPlanJsonProperties();
    }
    return props;
  }

  /**
   * Serializes the `props` attribute and stores the result in the `Properties` attribute.
   *
   * @returns {string} Serialized string representation of the mower plan properties.
   * If serialization fails, returns an empty string.
   */
  SerializeProps() {
    try {
      if (this.props || typeof this.props === "object") {
        this.Properties = JSON.stringify(this.props);
      }
    } catch (ex) {
      this.Properties = "";
    }
    return this.Properties;
  }
}

export class MowSection {
  constructor(points, keepout) {
    this.Points = points;
    this.ExclusionPoints = keepout;
  }

  /**
   * Creates a MowSection object based on the provided exterior points and keepout bounds.
   *
   * @param {Array} exterior_points - Array of position objects containing each point's latitude and longitude values.
   * @param {Array} keepout_bounds - Array of arrays containing exclusion points.
   * @returns {MowSection} A MowSection object with the exterior and exclusion points set.
   */
  FromPoints(exterior_points, keepout_bounds) {
    if (!Array.isArray(exterior_points) || !Array.isArray(keepout_bounds)) {
      //throw new Error('Both exterior_points and keepout_bounds should be arrays.');
      return;
    }

    var mow_section = new MowSection();
    let buffer = new ArrayBuffer(exterior_points.length * 8 * 2);
    let msgview = new DataView(buffer);

    for (let i = 0; i < exterior_points.length; i++) {
      //take the point coordinate
      msgview.setFloat64(i * 16, exterior_points[i].Lat, true);
      msgview.setFloat64(i * 16 + 8, exterior_points[i].Lng, true);
    }
    mow_section.Points = msgview.buffer;

    var interior_size = 0;
    keepout_bounds.forEach((bound) => {
      interior_size += 4 + bound.length * 8 * 2;
    });

    buffer = new ArrayBuffer(interior_size);
    msgview = new DataView(buffer);

    let offset = 0;

    keepout_bounds.forEach((bound) => {
      msgview.setUint32(offset, bound.length, true);
      offset += 4;
      for (let j = 0; j < bound.length; j++) {
        msgview.setUint8(offset, bound[j].Lat, true);
        offset += 8;
        msgview.setUint8(offset, bound[j].Lng, true);
        offset += 8;
      }
    });

    mow_section.ExclusionPoints = msgview.buffer;
    return mow_section;
  }

  /**
   * Calculates the area of a polygon represented by this object.
   *
   * @returns {number} The calculated area in acres.
   */
  Area() {
    let area = Math.abs(ComputeSignedArea(this.GetPoints())) * 0.00002296;
    return area;
  }

  /**
   * Retrieves the points stored in binary format and converts them into an array of positions.
   *
   * @returns {Array} Array containing the positions.
   */
  GetPoints() {
    if (this.Points === undefined || this.Points.byteLength === 0) {
      return [];
    }

    let binaryData = atob(this.Points);
    let points = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      points[i] = binaryData.charCodeAt(i);
    }
    let buffer = points.buffer;
    let reader = new DataView(buffer);
    let arr = [];
    let offset = 0;
    while (offset + 16 <= points.byteLength) {
      let lat = reader.getFloat64(offset, true);
      let lon = reader.getFloat64(offset + 8, true);

      arr.push(new Position(lat, lon));
      offset += 16;
    }

    return arr;
  }

  /**
   * Retrieves the exclusion points stored in binary format and converts them into an array of arrays of Position objects representing latitude and longitude coordinates.
   *
   * @returns {Array} Array containing the arrays of Position objects representing the exclusion points.
   */
  GetExclusionPoints() {
    if (
      this.ExclusionPoints === undefined ||
      this.ExclusionPoints.length === 0
    ) {
      return [];
    }
    const binaryData = atob(this.ExclusionPoints);
    const points = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      points[i] = binaryData.charCodeAt(i);
    }
    const buffer = points.buffer;
    const view = new DataView(buffer);
    let position = 0;
    const arr = [];

    while (position + 4 <= buffer.byteLength) {
      let num_pts = view.getUint32(position, true);

      position += 4;

      const pts = [];
      for (let i = 0; i < num_pts; i++) {
        if (position + 16 > buffer.byteLength) {
          break;
        }
        let lat = view.getFloat64(position, true);

        let lon = view.getFloat64(position + 8, true);

        pts.push(new Position(lat, lon));
        position += 16;
      }

      arr.push(pts);
    }

    return arr;
  }
}

export class PlanToSplit {
  constructor(MowSection, PropString) {
    this.MowPlan = MowSection;
    this.Properties = PropString;
  }
}

export class SplitPlan {
  constructor() {
    this.MowPlans = [];
  }
  MowPlans;
}

export class Position {
  constructor(lat, long) {
    this.lat = lat;
    this.lng = long;
  }
}

export class SpiralPolygon {
  constructor(id) {
    this.id = id;
  }
  parent_id = -1;
  depth = 0;
  points = [];
  visited = false;
}

export class MowingPlanJsonProperties {
  constructor(properties) {
    this.percentspeed = properties?.percentspeed ?? 70;
    this.overlapwidth = properties?.overlapwidth ?? 0;
    this.returntostart = properties?.returntostart ?? true;
    this.cutpattern = properties?.cutpattern ?? 0;
    this.stripeangle = properties?.stripeangle ?? 0;
    this.enablepto = properties?.enablepto ?? true;
    this.remowperimeter = properties?.remowperimeter ?? false;
    this.disablelidar = properties?.disablelidar ?? false;
    this.sleepwhencomplete = properties?.sleepwhencomplete ?? false;
    this.perimetermode = properties?.perimetermode ?? 0;
  }

  /**
   * Deserializes a JSON string to an instance of MowingPlanJsonProperties.
   *
   * @param {string} string - JSON string representing the properties of the mowing plan.
   * @returns {MowingPlanJsonProperties} - An instance of MowingPlanJsonProperties.
   * @throws {Error} - Throws an error if the provided string cannot be parsed as JSON.
   */
  Deserialize(string) {
    try {
      return string
        ? new MowingPlanJsonProperties(JSON.parse(string))
        : new MowingPlanJsonProperties();
    } catch (error) {
      return;
    }
  }

  /**
   * Serializes the instance properties to a JSON string.
   *
   * @returns {string} - A JSON string representation of the mowing plan properties.
   */
  Serialize() {
    return JSON.stringify(this);
  }
}

/**
 * This function calculates the area of a triangle on a sphere using polar coordinates.
 *
 * @name PolarTriangleArea
 * @param {number} tan1 - Tangent of the latitude of the first point on the sphere.
 * @param {number} lng1 - Longitude of the first point on the sphere.
 * @param {number} tan2 - Tangent of the latitude of the second point on the sphere.
 * @param {number} lng2 - Longitude of the second point on the sphere.
 * @returns {number} - Area of the polar triangle.
 * @throws {Error} - Throws an error if input parameters are not of type 'number'.
 */
function PolarTriangleArea(tan1, lng1, tan2, lng2) {
  var deltalng = lng1 - lng2;
  var t = tan1 * tan2;

  return 2 * Math.atan2(t * Math.sin(deltalng), 1 + t * Math.cos(deltalng));
}

/**
 * Convert an angle from degrees to radians.
 *
 * @name DegreesToRadians
 * @param {number} angle - Angle in degrees.
 * @returns {number} - Angle in radians.
 * @throws {Error} - Throws an error if the input parameter is not a number.
 */
function DegreesToRadians(angle) {
  return (angle * Math.PI) / 180.0;
}

/**
 * Calculates the signed area of a polygon defined by a given path of points on the Earth's surface.
 *
 * @name ComputeSignedArea
 * @param {Array} path - Array of points defining the polygon.
 * @param {number} [radius=6378137.0] - Radius of the Earth.
 * @returns {number} - Calculated signed area.
 * @throws {Error} - Throws an error if the path is not an array or if it contains fewer than 3 points.
 */
function ComputeSignedArea(path, radius = 6378137.0) {
  var size = path.length;
  if (!Array.isArray(path) || path.length < 3) {
    return 0;
  }
  var total = 0;
  var prev = path[size - 1];
  var prevTanLat = Math.tan((Math.PI / 2 - DegreesToRadians(prev.lat)) / 2);
  var prevLng = DegreesToRadians(prev.lng);

  path.forEach((point) => {
    var tanLat = Math.tan((Math.PI / 2 - DegreesToRadians(point.lat)) / 2);
    var lng = DegreesToRadians(point.lng);
    total += PolarTriangleArea(tanLat, lng, prevTanLat, prevLng);
    prevTanLat = tanLat;
    prevLng = lng;
  });

  return Math.round(total * radius * radius * 10.7639, 3);
}
