import AuthService from "../services/AuthService";

export default class User {
  auth = new AuthService();
  jSONstruct = null;
  isInitialized = false; // New property to track initialization

  /**
   * Initializes the user's data from the AuthService.
   */
  async initialize() {
    try {
      if (!this.isInitialized) {
        const token = this.auth.getTokenFromCookie();
        if (token) {
          this.jSONstruct = await this.auth.getProfile();
          this.isInitialized = true;
        }
      }
    } catch (ex) {
      console.error("Error initializing user data:", ex);
    }
  }

  /**
   * Checks if the user has a 'Support' role.
   * @returns {boolean} True if the user has 'Support' role, false otherwise.
   */
  async IsSupport() {
    await this.initialize();
    return this.jSONstruct && this.jSONstruct.role === "Support";
  }

  /**
   * Checks if the user is a 'Technician' or has a 'Support' role.
   * @returns {boolean} True if the user is a 'Technician' or has 'Support' role, false otherwise.
   */
  async IsTechnician() {
    await this.initialize();
    return (
      this.jSONstruct &&
      (this.jSONstruct.role === "Technician" ||
        this.jSONstruct.role === "Support")
    );
  }

  /**
   * Gets the associated User's stored name
   * @returns {string} string user first and last name.
   */
  async getName() {
    await this.initialize();
    return this.jSONstruct
      ? `${this.jSONstruct.first} ${this.jSONstruct.last}`
      : "";
  }

  /**
   * Gets the associated User's stored Role
   * @returns {string} string user role
   */
  async getRole() {
    await this.initialize();
    return this.jSONstruct ? this.jSONstruct.role : "";
  }

  /**
   * Gets the associated User's stored company name
   * @returns {string} string user company name
   */
  async getCompany() {
    await this.initialize();
    return this.jSONstruct ? this.jSONstruct.company : "";
  }

  /**
   * Gets the associated User's stored branch
   * @returns {string} string user company branch
   */
  async getBranch() {
    await this.initialize();
    return this.jSONstruct ? this.jSONstruct.branch : "";
  }

  /**
   * Gets the associated User's stored ID
   * @returns {string} string user ID
   */
  async getUserID() {
    await this.initialize();
    return this.jSONstruct ? this.jSONstruct.user_id : "";
  }

  /**
   * Gets the associated User's log in status
   * @returns {boolean} true if user is logged in
   */
  async isLoggedIn() {
    try {
      await this.initialize();
      return this.auth.loggedIn();
    } catch (ex) {
      console.error("Error checking login status:", ex);
      return false;
    }
  }
}
