import React, { useCallback, useEffect, useState } from "react";
import { MowerState } from "../../models/enums";
import { Col, Row } from "react-bootstrap";
import { Divider, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const MowerDetails = (props) => {
  const { selected_mower } = props;
  const { t, i18n } = useTranslation();
  const [showDetails, setShowDetails] = useState(false);
  const [moweroverview, setMowerOverview] = useState("");
  const [moweroperator, setMowerOperator] = useState("");
  const [mowerstatus, setMowerStatus] = useState("");
  const [mowerspeed, setMowerSpeed] = useState("");
  const [mowervoltage, setMowerVoltage] = useState("");
  const [mowerfuellevel, setMowerFuelLevel] = useState("");
  const [mowerlatitude, setMowerLatitude] = useState("");
  const [mowerlongitude, setMowerLongitude] = useState("");
  const [mowerheading, setMowerHeading] = useState("");

  const updateMowerDetailLabel = useCallback(() => {
    if (!selected_mower) return; // Exit if no mower is selected.

    // Initial status.
    let statusString = t("monitor.statusLoading");
    let overviewLabel = "";

    // Check if state exists.
    const stateEnum = selected_mower.state || "ERROR";

    switch (stateEnum) {
      case MowerState.Idle:
        statusString = t("monitor.statusIdle");
        break;
      case MowerState.Manual:
        statusString = t("monitor.statusManual");
        break;
      case MowerState.Ready:
        statusString = t("monitor.statusReady");
        break;
      case MowerState.Busy:
        statusString = t("monitor.statusBusy");
        break;
      case MowerState.Finished:
        statusString = t("monitor.statusfinished");
        break;
      case MowerState.Mowing:
        statusString = `${t("monitor.statusMoving")} ${
          selected_mower.progress
        }%`;
        break;
      case MowerState.Paused:
        statusString = t("monitor.statusPaused");
        break;
      case MowerState.LocalPause:
        statusString = t("monitor.statusLocallyPaused");
        break;
      case MowerState.Faulted:
        statusString = t("monitor.statusFault");
        break;
      case MowerState.FaultCleared:
        statusString = t("monitor.statusFaultCleared");
        break;
      case MowerState.ReturningHome:
        statusString = t("monitor.statusReturning");
        break;
      case MowerState.Recording:
        statusString = t("monitor.statusRecording");
        break;
      case MowerState.Updating:
        statusString = `${t("monitor.statusUpdating")} ${
          selected_mower.progress
        }%`;
        break;
      default:
        statusString = t("monitor.error");
    }

    // Extract controller name if available. Otherwise, an empty string.
    const operatorLabel = selected_mower.details?.controller?.name
      ? `${t("monitor.operated")} ${selected_mower.details.controller.name}`
      : "";

    // Ensure mower has a serial number.
    if (selected_mower.serial_number) {
      overviewLabel = `${selected_mower.serial_number} ${statusString}`;
    } else {
      console.error("Selected mower lacks a serial number.");
      overviewLabel = statusString; // fallback to just the status.
    }

    // Calculate speed in mph.
    const speedMph = (selected_mower.speed || 0) * 2.236;
    const speedLabel = `${t("monitor.speed")} ${speedMph.toFixed(2)} mph`;

    // Get mower's voltage, default to '0' if undefined.
    const voltageLabel = `${t("monitor.voltage")} ${
      selected_mower.voltage || 0
    } V`;

    // Calculate fuel level in gallons.
    const gallons = (2.0 * (selected_mower.fuel_level || 0)) / 100.0;
    let fuelLevelLabel = "";
    if (gallons < 1.9) {
      fuelLevelLabel =
        t("monitor.fuel") +
        gallons.toFixed(1).toString() +
        " gal (" +
        selected_mower.fuel_level +
        "%)";
    } else if (gallons > 5) {
      fuelLevelLabel = t("monitor.fuelError");
    } else {
      fuelLevelLabel =
        t("monitor.fuelLow") +
        gallons.toFixed(1).toString() +
        " gal (" +
        selected_mower.fuel_level +
        "%)";
    }

    // Extract and format GPS coordinates.
    const latitudeLabel = `${t("monitor.latitude")} ${(
      selected_mower.lat || 0
    ).toFixed(6)}`;
    const longitudeLabel = `${t("monitor.longitude")} ${(
      selected_mower.lon || 0
    ).toFixed(6)}`;

    // Extract and format heading.
    const headingLabel = `${t("monitor.heading")} ${(
      selected_mower.heading || 0
    ).toFixed(1)}`;

    // Update state with the extracted details
    setMowerOverview(overviewLabel);
    setMowerOperator(operatorLabel);
    setMowerFuelLevel(fuelLevelLabel);
    setMowerHeading(headingLabel);
    setMowerLatitude(latitudeLabel);
    setMowerLongitude(longitudeLabel);
    setMowerSpeed(speedLabel);
    setMowerStatus(statusString);
    setMowerVoltage(voltageLabel);
  }, [selected_mower]);

  /**
   * Effect that calls the `updateMowerDetailLabel` function whenever the
   * `moweroverview` state/prop changes. This indicates that the details of the
   * mower might have been modified and need to be updated.
   */
  useEffect(() => {
    updateMowerDetailLabel();
  }, [moweroverview]);

  /**
   * Toggles the visibility of mower details.
   *
   * @function detailsButtonClicked
   */
  const detailsbutonclicked = () => {
    setShowDetails(!showDetails);
  };

  /**
   * Effect that updates mower's detail labels based on the selected mower's state.
   *
   * When `selected_mower` is updated, this effect will run and extract various pieces
   * of information from the mower object, format them, and then use them to update
   * a series of state variables (e.g., `setMowerOverview`, `setMowerOperator`).
   */
  useEffect(() => {
    if (selected_mower) {
      // Initial status string.
      let status_string = "Status loading...";
      let overviewLabel = "";

      // Check if state exists and handle potential undefined mower states.
      var state_enum = selected_mower.state || "ERROR";

      if (state_enum === MowerState.Idle) {
        status_string = "Status: Idle";
      } else if (state_enum === MowerState.Manual) {
        status_string = "Status: Manual";
      } else if (state_enum === MowerState.Ready) {
        status_string = "Status: Ready";
      } else if (state_enum === MowerState.Busy) {
        status_string = "Status: Busy";
      } else if (state_enum === MowerState.Finished) {
        status_string = "Status: Finished";
      } else if (state_enum === MowerState.Mowing) {
        status_string =
          "Status: Mowing " + selected_mower.progress.toString() + "%";
      } else if (state_enum === MowerState.Paused) {
        status_string = "Status: Paused";
      } else if (state_enum === MowerState.LocalPause) {
        status_string = "Status: Locally Paused";
      } else if (state_enum === MowerState.Faulted) {
        status_string = "Status: Fault";
      } else if (state_enum === MowerState.FaultCleared) {
        status_string = "Status: Fault Cleared";
      } else if (state_enum === MowerState.ReturningHome) {
        status_string = "Status: Returning Home";
      } else if (state_enum === MowerState.Recording) {
        status_string = "Status: Recording Plan";
      } else if (state_enum === MowerState.Updating) {
        status_string =
          "Status: Updating " + selected_mower.progress.toString() + "%";
      } else {
        status_string = "ERROR: Unknown Status";
      }

      // Extract operator label, if available.
      const operatorExists = selected_mower.details?.controller?.name;
      let operatorlabel = operatorExists
        ? `Operated By: ${selected_mower.details.controller.name}`
        : "";

      if (selected_mower.serial_number) {
        overviewLabel = `${selected_mower.serial_number} ${status_string}`;
      } else {
        //console.error("Selected mower lacks a serial number.");
        overviewLabel = status_string;
      }
      let statuslabel = status_string;

      //determine the speed labelling
      let speed_mph = selected_mower.speed * 2.236; //conver to mph
      let speedlabel = "Speed: " + Math.round(speed_mph, 2) + " mph";
      let voltagelabel = "Voltage: " + selected_mower.voltage + " V";
      let gallons = (2.0 * selected_mower.fuel_level) / 100.0;
      let mowerfuellabel;
      if (gallons < 1.9) {
        mowerfuellabel =
          "Fuel Level: " + gallons.toFixed(1).toString() + " gal ";
      } else if (gallons > 5) {
        mowerfuellabel = " Fuel Level: Error";
      } else {
        mowerfuellabel =
          "Fuel Level is less than " + gallons.toFixed(1).toString() + " gal";
      }
      let mowerlatitudelabel = "Latitude: " + Math.round(selected_mower.lat, 6);
      let mowerlongitudelabel =
        "Longitude: " + Math.round(selected_mower.lon, 6);
      let mowerheadinglabel =
        "Heading: " + Math.round(selected_mower.heading, 1);

      setMowerOverview(overviewLabel);
      setMowerOperator(operatorlabel);
      setMowerFuelLevel(mowerfuellabel);
      setMowerHeading(mowerheadinglabel);
      setMowerLatitude(mowerlatitudelabel);
      setMowerLongitude(mowerlongitudelabel);
      setMowerSpeed(speedlabel);
      setMowerStatus(statuslabel);
      setMowerVoltage(voltagelabel);
    }
  }, [selected_mower]);

  return (
    <div>
      {selected_mower === null && (
        <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
          <Col style={{ minWidth: "fit-content" }}>
            <Typography>Tap a Mower for More Information</Typography>
          </Col>
        </Row>
      )}
      {!showDetails && selected_mower !== null && (
        <div>
          <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
            <Col style={{ minWidth: "fit-content" }}>
              <Typography>{moweroverview}</Typography>
              <Typography>{moweroperator}</Typography>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <Link
                className="btn amr-btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  detailsbutonclicked();
                }}
              >
                Details
              </Link>
            </Col>
          </Row>
        </div>
      )}
      {showDetails && selected_mower !== null && (
        <div>
          <Row style={{ marginTop: "10px", marginLeft: "10px" }}>
            <Col style={{ minWidth: "fit-content" }}>
              <Typography>{moweroverview}</Typography>
              <Typography>{moweroperator}</Typography>
            </Col>
            <Col style={{ textAlign: "end" }}>
              <Link
                className="btn amr-btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  detailsbutonclicked();
                }}
              >
                Collapse
              </Link>
            </Col>
          </Row>
          <Divider />
          <Row style={{ marginLeft: "10px" }}>
            <Col>
              <Typography>{mowerstatus}</Typography>
              <Typography>{mowerspeed} </Typography>
              <Typography>{mowervoltage} </Typography>
              <Typography>{mowerfuellevel}</Typography>
              <Typography>{mowerheading} </Typography>
              <Typography>{mowerlatitude}</Typography>
              <Typography>{mowerlongitude}</Typography>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default { MowerDetails };
