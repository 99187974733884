import { Dialog, DialogContent, Button } from "@mui/material";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

export const DisplayActionSheet = (props) => {
  const { visible, closePopup, SetHomeClicked, GoToClicked } = props;

  const { t, i18n } = useTranslation();

  return (
    <Dialog open={visible} onClose={closePopup}>
      <DialogContent style={{ width: "300px", margin: "0px", padding: "0px" }}>
        <ListGroup>
          <ListGroupItem>
            <Button
              style={{ color: "black", fontFamily: "var(--amr-font-body)" }}
              onClick={() => {
                GoToClicked();
                closePopup();
              }}
            >
              {t("mow.goTo")}
            </Button>
          </ListGroupItem>
          <ListGroupItem>
            <Button
              style={{ color: "black", fontFamily: "var(--amr-font-body)" }}
              onClick={() => {
                SetHomeClicked();
                closePopup();
              }}
            >
              {t("mow.setHome")}
            </Button>
          </ListGroupItem>
        </ListGroup>
      </DialogContent>
    </Dialog>
  );
};
