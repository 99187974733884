import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { withRouter } from "../withRouter";
import { Image } from "react-bootstrap";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.router = props.router;
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidMount() {
    window.addEventListener("popstate", this.handlePopstate);
  }

  componentWillUnmount() {
    this.setState({ hasError: false });
    window.removeEventListener("popstate", this.handlePopstate);
  }

  handlePopstate = () => {
    window.location.reload(); // Trigger manual page refresh on back arrow
  };

  componentDidCatch(error, errorInfo) {
    //log the error to an error reporting service
    // console.dir({ error: error, errorInfo: errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Card style={{ maxWidth: "fit-content" }}>
          <CardMedia component="img" alt="RCM Logo" image="/Images/logo.png" />
          <CardContent>
            <Typography style={{ textAlign: "center", fontSize: "30px" }}>
              <em>
                <b>Uh Oh!</b>
              </em>
            </Typography>
            <Typography
              style={{
                textAlign: "center",
                fontSize: "15px",
                color: grey[600],
              }}
            >
              We ran into an issue trying to complete your request.
            </Typography>
            <Typography style={{ fontSize: "18px", textAlign: "center" }}>
              <b>Error:</b> <em>{this.state.error.message}</em>
            </Typography>
            <Typography
              style={{
                fontSize: "15px",
                marginTop: "16px",
                textAlign: "center",
                color: grey[600],
              }}
            >
              If issue persists, contact customer service.
            </Typography>
            <div style={{ textAlign: "center" }}>
              <span>
                <Button
                  className="btn amr-btn-primary"
                  style={{ textDecoration: "capitalize" }}
                  onClick={() => {
                    this.setState({ hasError: false });
                    this.router.navigate("/Home");
                  }}
                >
                  Go Home
                </Button>
              </span>
              <Image
                src="/Images/rcm_badge_horiz_cust_supp_phone_invert.png"
                alt="Contact Customer Service at 920-634-2227"
                height={80}
              />
            </div>
          </CardContent>
        </Card>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
