import { Dialog, DialogContent } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import React from "react";

export const StatusLightGuide = (props) => {
  const { visible, closePopup } = props;

  return (
    <div>
      <Dialog open={visible} onClose={closePopup}>
        <DialogContent
          style={{ margin: "0px", width: "fit-content", padding: "0px" }}
        >
          <Col>
            <Row>
              <img
                src="Images/StatusLightGuide2.png"
                alt="Depiction of status lights guide."
              />
            </Row>
          </Col>
        </DialogContent>
      </Dialog>
    </div>
  );
};
