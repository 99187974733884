import { Typography, Dialog, DialogTitle, DialogContent } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const MowerInfoDialog = (props) => {
  const { t, i18n } = useTranslation();
  const { isOpen, ClosePopup, mower, date } = props;
  var DateObj;
  const months = [
    t("machine.jan"),
    t("machine.feb"),
    t("machine.mar"),
    t("machine.apr"),
    t("machine.may"),
    t("machine.jun"),
    t("machine.jul"),
    t("machine.aug"),
    t("machine.sep"),
    t("machine.oct"),
    t("machine.nov"),
    t("machine.dec"),
  ];

  try {
    DateObj = new Date(date);
  } catch (ex) {
    console.error("Failed to convert the date:", ex);
  }

  const year = DateObj?.getUTCFullYear();
  const month = months[DateObj?.getUTCMonth()];
  const day = DateObj?.getUTCDate();
  const hour = DateObj?.getUTCHours();
  const minute = DateObj?.getUTCMinutes();

  try {
    var mowerhours = mower.details.mower_vehicle_info;
    mowerhours = JSON.parse(mowerhours);
  } catch (err) {
    mowerhours = null;
  }

  return (
    <Dialog open={isOpen} onClose={ClosePopup}>
      <DialogTitle>{t("machine.mowerInfo")}</DialogTitle>
      <DialogContent>
        <Typography>
          {t("machine.serialNumber")} {mower.MachineSerialNumber}
        </Typography>
        <Typography>
          {t("machine.onlineTime")} {day} {month}, {year} {hour}:{minute}
        </Typography>
        <Typography>
          {t("machine.currentVersion")} {mower.CurrentVersion}
        </Typography>
        <Typography>
          {t("machine.hours")}{" "}
          {!mowerhours || mowerhours.Hours === ""
            ? t("machine.failed")
            : mowerhours.Hours}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};
