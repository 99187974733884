import React, { useCallback, useEffect, useState } from "react";
import { Slider, Checkbox, Typography, Link, Button } from "@mui/material";
import SettingsJsonProperties from "./SettingsJSONProperties";
import { red, grey } from "@mui/material/colors";
import { useToast } from "../../customToast/ToastContext";
import { useTranslation } from "react-i18next";

export const MowSettingsTabPanel = (props) => {
  const { t, i18n } = useTranslation();
  const { mower, SaveMowSettings } = props;
  //mowSpeed: (int) Indicates the value on the slider that represents the mow speed
  const [mowSpeed, setMowSpeed] = useState(14);
  //transportSpeed: (int) Indicates the value on the slider that represents the mower transport speed
  const [transportSpeed, setTransportSpeed] = useState(14);
  //enablePTO: (boolean) represents whether the enablePTO checkbox is checked.
  const [enablePTO, setEnablePTO] = useState(true);

  /*
   * setSpeedText
   *
   *  Handles the slider value and determines the speed to actually be displayed
   */
  const setSpeedText = (num) => {
    //Take the slider value, round it and multiply it by five to get a percentage out of 100
    let val = Math.round(num) * 5.0;
    let val2 = 6 * (val / 100);
    //Then multiply it by the mower's max speed to determine the mph the mower is being set to
    val2 = Math.ceil(val2 * 100) / 100;

    //return the speed text
    let text = val.toString() + "%";
    return text;
  };

  /*
   * saveBtnClicked
   *
   *  Handles the user click on the save button
   * validates values before saving and sending them to a component to be sent to the mower
   */
  const saveBtnClicked = useCallback(() => {
    try {
      //Check for bad input
      if (transportSpeed < 4 || mowSpeed < 4) {
        alert(t("alerts.mowSpeed"), "error");
      } else {
        let props = new SettingsJsonProperties();
        props.transport_speed_percent = transportSpeed * 5.0;
        props.mow_speed_percent = mowSpeed * 5.0;
        props.enable_pto = enablePTO;

        let serializedJSON = props.Serialize();

        if (serializedJSON !== null) {
          SaveMowSettings({
            serializedJSON: serializedJSON,
          });
        }
      }
    } catch (ex) {
      console.error(ex);
    }
  }, [transportSpeed, mowSpeed, enablePTO]);

  useEffect(() => {
    if (mower) {
      try {
        let givenRuntime = JSON.parse(mower.details.runtime_settings);
        if (
          givenRuntime &&
          "mow_speed_percent" in givenRuntime &&
          "transport_speed_percent" in givenRuntime &&
          "enable_pto" in givenRuntime
        ) {
          let runtime_settings = null;
          if (givenRuntime !== null) {
            runtime_settings = new SettingsJsonProperties(givenRuntime);
          }

          setMowSpeed(Math.round(runtime_settings.mow_speed_percent) / 5.0);
          setTransportSpeed(
            Math.round(runtime_settings.transport_speed_percent) / 5.0
          );
          setEnablePTO(runtime_settings.enable_pto);
        } else {
          console.warn("Unexpected mower runtime settings format.");
        }
      } catch (error) {
        console.error("Error parsing mower details", error);
      }
    }
  }, [mower]);

  /* RENDER UI COMPONENT */
  return (
    <div>
      <Typography>
        <span>
          {" "}
          {t("mowerSettings.transport")} {setSpeedText(transportSpeed)}
        </span>
      </Typography>
      <Slider
        sx={{
          color: red[700],
          width: "95%",
        }}
        aria-label="transportspeed-slider"
        value={transportSpeed}
        min={0}
        max={20}
        step={1}
        valueLabelDisplay="off"
        onChange={(e, value) => {
          setTransportSpeed(value);
        }}
      />
      <Typography>
        <span>
          {t("mowerSettings.mow")} {setSpeedText(mowSpeed)}{" "}
        </span>
      </Typography>
      <Slider
        sx={{
          color: red[700],
          width: "95%",
        }}
        aria-label="mowspeed-slider"
        value={mowSpeed}
        min={0}
        max={20}
        step={1}
        valueLabelDisplay="off"
        onChange={(e, value) => {
          setMowSpeed(value);
        }}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography>
          <span>{t("mowerSettings.enablePTO")}</span>
        </Typography>
        <Checkbox
          checked={enablePTO}
          onChange={(e) => setEnablePTO(e.target.checked)}
          sx={{
            marginLeft: "200px",
            "& .MuiSvgIcon-root": {
              color: enablePTO ? "red" : "inherit",
            },
          }}
        />
      </div>
      <div style={{ marginTop: "350px" }}>
        <center>
          <Button
            style={{
              textDecorationLine: "none",
              textTransform: "capitalize",
              alignItems: "center",
            }}
            className="btn amr-btn-primary"
            onClick={(e) => {
              window.history.go(-1);
            }}
          >
            {t("mowerSettings.cancel")}
          </Button>
          <Button
            style={{
              textDecorationLine: "none",
              textTransform: "capitalize",
              alignItems: "center",
            }}
            className="btn ms-4 amr-btn-primary"
            onClick={(e) => saveBtnClicked()}
          >
            {t("mowerSettings.save")}
          </Button>
        </center>
      </div>
      <div style={{ marginTop: "15px" }}>
        <center>
          <Typography sx={{ fontSize: "13px", color: grey[600] }}>
            <span>{t("mowerSettings.note")}</span>
          </Typography>
        </center>
      </div>
    </div>
  );
};
