import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Typography,
  LinearProgress,
  linearProgressClasses,
} from "@mui/material";
import { green, grey, red } from "@mui/material/colors";
import { Col, Row } from "react-bootstrap";

export const MowerTab = (props) => {
  const { mower, details, mowertapped, screenwidth } = props;
  const [status_color, setStatusColor] = useState(details.line2TextColor);
  const [moweroverview, setMowerOverview] = useState(details.line1Text);
  const [mowerstatusstring, setMowerStatusString] = useState(details.line2Text);
  const [moweractionimage, setMowerActionImage] = useState(
    details.mowerImageSource
  );
  const [mowersignalstrength, setMowerSignalStrength] = useState(
    details.signalImageSource
  );
  const [mowergascanimage, setMowerGasCanImage] = useState(
    details.fuelImageSource
  );
  const [prog, setProg] = useState(details.progress);
  var [Sizing, setSizing] = useState({
    MowerImage: "60px",
    SignalImage: "20px",
    TopLineSize: "15px",
    SecondLineSize: "14px",
    FuelImage: "45px",
  });
  var SizingNum = {
    MowerImage: 60,
    SignalImage: 20,
    TopLineSize: 15,
    SecondLineSize: 14,
    FuelImage: 45,
  };

  useEffect(() => {
    if (screenwidth !== 0) {
      let w = Number(screenwidth.replace("px", ""));
      let num = 0;
      num = w / 6.9;
      if (num > 60) {
        SizingNum.MowerImage = 60;
      } else {
        SizingNum.MowerImage = Math.round(num);
      }
      num = Math.round(num) / 3;
      if (num > 20) {
        SizingNum.SignalImage = 20;
        SizingNum.TopLineSize = 15;
        SizingNum.SecondLineSize = 14;
      } else {
        SizingNum.SignalImage = Math.round(num);
        SizingNum.TopLineSize = Math.round(num) - 5;
        SizingNum.SecondLineSize = Math.round(num) - 6;
      }
      num = w / 9.6;
      if (num > 45) {
        SizingNum.FuelImage = 45;
      } else {
        SizingNum.FuelImage = Math.round(num);
      }

      setSizing({
        ...Sizing,
        MowerImage: SizingNum.MowerImage.toString() + "px",
        SignalImage: SizingNum.SignalImage.toString() + "px",
        TopLineSize: SizingNum.TopLineSize.toString() + "px",
        SecondLineSize: SizingNum.SecondLineSize.toString() + "px",
        FuelImage: SizingNum.FuelImage.toString() + "px",
      });
    }
  }, []);

  useEffect(() => {
    if (mower !== null && details !== null) {
      setMowerOverview(details.line1Text);
      setMowerStatusString(details.line2Text);
      setMowerActionImage(details.mowerImageSource);
      setMowerSignalStrength(details.signalImageSource);
      setMowerGasCanImage(details.fuelImageSource);
      setProg(details.progress);
      setStatusColor(details.line2TextColor);
    }
  }, [details, mower]);

  /**
   * LinearProgressWithLabel Component
   *
   * This is a sub-component to show a custom-styled linear progress bar
   * that indicates the current progress of the mower's task.
   *
   * @param {Object} props
   * @param {number} props.value - Current progress value in percentage (0 to 100).
   *
   * @returns JSX.Element - Rendered progress bar component.
   */
  function LinearProgressWithLabel(props) {
    const { value } = props;
    let val;
    if (value === undefined) {
      val = 0;
    } else val = value;
    return (
      <Box sx={{ minWidth: "100%", ml: 1, mt: -1.5 }}>
        <LinearProgress
          sx={{
            height: 10,
            borderRadius: 5,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: grey[200],
            },
            [`& .${linearProgressClasses.barColorPrimary}`]: {
              borderRadius: 5,
              backgroundColor: green[500],
            },
          }}
          variant="determinate"
          value={val}
        />
      </Box>
    );
  }

  return (
    <div>
      {mower !== null && (
        <Box
          sx={{ maxWidth: screenwidth, display: "flex" }}
          style={{
            borderRadius: "7px",
            height: "77px",
            alignItems: "center",
            justifyItems: "center",
            backgroundColor: red[700],
          }}
        >
          <Col>
            <Card
              sx={{ display: "flex" }}
              style={{
                marginLeft: "7px",
                marginRight: "7px",
                height: "70px",
                backgroundColor: "white",
              }}
            >
              <Col>
                <Row
                  style={{
                    height: "100%",
                  }}
                >
                  <Col
                    style={{
                      maxWidth: "fit-content",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      style={{ margin: "0px", padding: "0px" }}
                      onClick={() => {
                        mowertapped();
                      }}
                    >
                      <img
                        alt="mower action button"
                        src={moweractionimage}
                        height={Sizing.MowerImage}
                      />
                    </Button>
                    <img
                      alt="mower cellular strength"
                      src={mowersignalstrength}
                      height={Sizing.SignalImage}
                      style={{
                        position: "absolute",
                        transform: "translate(0%, 5%)",
                        padding: 0,
                      }}
                    />
                  </Col>
                  <Col
                    style={{
                      minWidth: "fit-content",
                      height: Sizing.MowerImage,
                      alignItems: "center",
                    }}
                  >
                    <Row>
                      <Typography style={{ fontSize: Sizing.TopLineSize }}>
                        <span>{moweroverview}</span>
                      </Typography>
                    </Row>
                    <Row>
                      <Typography
                        style={{
                          fontSize: Sizing.SecondLineSize,
                          color: status_color,
                        }}
                      >
                        <span>{mowerstatusstring}</span>
                      </Typography>
                    </Row>
                  </Col>
                  <Col
                    style={{
                      display: "grid",
                      maxWidth: "fit-content",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="Fuel level"
                      src={mowergascanimage}
                      height={Sizing.FuelImage}
                    />
                  </Col>
                  {prog !== 0 && prog !== 100 && (
                    <Row style={{ alignItems: "center" }}>
                      <LinearProgressWithLabel value={prog} />
                    </Row>
                  )}
                </Row>
              </Col>
            </Card>
          </Col>
        </Box>
      )}
    </div>
  );
};
