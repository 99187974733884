import { blue, green, grey, lightGreen, purple } from "@mui/material/colors";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { MowSection, Position } from "../models/mowingPlan";
import { CutPattern } from "../models/enums";
import { GeoLine, GeoPolygon, LocalXYUtil, MathUtil } from "../utility";
import { SpiralPolygon } from "../models/mowingPlan";
import { MowerPlanType } from "../../pages/mowPage";

/**
 * Ensures that a given array of points is in a clockwise order.
 * @param {Array} points - Array of points.
 * @returns {Array} - The points in clockwise order.
 */
function ensureClockwise(points) {
  // Ensure points is an array and has at least 3 points
  if (!Array.isArray(points) || points.length < 3) {
    throw new Error("Input should be an array of at least 3 points.");
  }

  for (let point of points) {
    if (typeof point.lat !== "number" || typeof point.lng !== "number") {
      throw new Error("Each point should have numeric lat and lng properties.");
    }
  }

  let sum = 0;
  for (let i = 0; i < points.length; i++) {
    let point1 = points[i];
    let point2 = points[(i + 1) % points.length];
    sum += (point2.lng - point1.lng) * (point2.lat + point1.lat);
  }

  const epsilon = 1e-10;

  if (Math.abs(sum) < epsilon) {
    //sim os effectively zero, polygon is degenerate
    return [...points]; // return a new array, instead of modifying the original
  } else if (sum > 0) {
    //points are in clockwise order, send them back
    return [...points];
  } else {
    //points are in counter-clockwise order, reverse them
    return [...points].reverse(); // return a reversed new array
  }
}

/**
 * Ensures that each array of points (zone) within the input array is in counter-clockwise order.
 * @param {Array} points - Array of arrays of points.
 * @returns {Array} - The zones in counter-clockwise order.
 */
function ensureCounterClockwise(points) {
  if (!Array.isArray(points)) {
    throw new Error("Input should be an array of zones.");
  }

  let newArray = [];

  points.forEach((zone) => {
    if (!Array.isArray(zone)) {
      throw new Error("Each zone should be an array of at least 3 points.");
    }

    for (let point of zone) {
      if (typeof point.lat !== "number" || typeof point.lng !== "number") {
        throw new Error(
          "Each point should have numeric lat and lng properties."
        );
      }
    }

    let sum = 0; // reset the sum for each zone

    for (let i = 0; i < zone.length; i++) {
      let point1 = zone[i];
      let point2 = zone[(i + 1) % zone.length];
      sum += (point2.lng - point1.lng) * (point2.lat + point1.lat);
    }
    const epsilon = 1e-10;
    if (Math.abs(sum) < epsilon) {
      //sum is effectively zero, polygon is degenerate
      newArray.push(zone);
    } else if (sum > 0) {
      //points in clockwise order, reverse them using the function that we wrote for keepouts
      newArray.push([...zone].reverse());
    } else {
      //points are in counter-clockwise order, return as is.
      newArray.push(zone);
    }
  });

  return newArray;
}

export const MowerPin = (props) => {
  const { google, pin, map, mower } = props;

  if (!google || !pin || !map || !mower) {
    throw new Error(
      "Required properties missing: google, pin, map, mower are mandatory."
    );
  }

  let color;
  switch (pin.accent_color) {
    case "b":
      color = blue[300];
      break;
    case "w":
      color = grey[100];
      break;
    default:
      color = purple[400];
  }

  const markerConfig = {
    path: "M20,0 L40,24 L0,24Z",
    fillColor: color.toString(),
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: pin.rotation, // default rotation if missing
    scale: 0.75,
    anchor: new google.maps.Point(20, 12),
  };

  return new google.maps.Marker({
    map: map,
    google: google,
    position: pin.position,
    icon: markerConfig,
    mower: mower,
  });
};

export const HomePin = (props) => {
  const { google, map, position } = props;

  if (!google || !map || !position) {
    throw new Error(
      "Required properties missing: google, map, position are mandatory."
    );
  }

  const icon = {
    url: "/Images/home_pin_white.png",
    scaledSize: new google.maps.Size(35, 50), // scale the image down
    anchor: new google.maps.Point(17.5, 50), // anchor the image at the bottom middle
  };

  return new google.maps.Marker({
    map: map,
    google: google,
    icon: icon,
    position: position,
  });
};

export const AdvancedPolyline = (props) => {
  const { google, ZIndex, map, path } = props;

  if (!map || !path || !Array.isArray(path)) {
    throw new Error(
      "Required properties missing or invalid. 'map' and 'path' are mandatory, and 'path' should be an array with at least 2 points."
    );
  }

  return new google.maps.Polyline({
    map: map,
    google: google,
    zIndex: ZIndex,
    path: path,
    strokeColor: grey[100],
  });
};

export const AdvancedPolygon = (props) => {
  const {
    google,
    map,
    perim,
    holes,
    outlinecolor,
    fillcolor,
    isclicked,
    clickedid,
    zindex,
  } = props;

  if (!map || !perim || !Array.isArray(perim) || perim.length < 2) {
    throw new Error(
      "Required properties missing or invalid. 'map' and 'path' are mandatory, and 'path' should be an array with at least 2 points."
    );
  }

  try {
    var perimeter = ensureClockwise(perim);
    var exclusions = ensureCounterClockwise(holes);
    var color = fillcolor;
    if (isclicked) {
      color = lightGreen[500];
    }
  } catch (error) {
    console.error("Error with Advanced Polygon Object", error);
    return;
  }

  return new google.maps.Polygon({
    map: map,
    google: google,
    paths: [perimeter, ...exclusions],
    strokeWidth: 16,
    strokeColor: outlinecolor,
    fillColor: color,
    fillOpacity: 0.75,
    clickable: true,
    zIndex: zindex,
    id: clickedid,
  });
};

/**
 * MapComponent: A React functional component for displaying and managing mowing plans on a map.
 *
 * Props:
 * - google: The Google Maps object.
 * - height, width: Dimensions for the map component.
 * - plan: The overall mowing plan object.
 * - mowers: Array of mower objects.
 * - onMapDrag, onPinSingleTap, onPolyMouseDown, onPolyMouseUp: Event handlers for different map interactions.
 * - selectedMower: The currently selected mower.
 * - isTracking: Whether the map is tracking something (e.g., the user or a mower).
 * - homePosition: The home position on the map.
 * - currentPlan, recordingPlan: Different plans the map might display.
 * - planType: The type of the mowing plan.
 * - userPosition: The current position of the user.
 * - containerId: A unique ID for the container element.
 * - centered: A flag indicating if the map should center on the polygons.
 * - clickedPoly: The ID of a clicked polygon (if any).
 *
 * Returns:
 * - A rendered map component with mowing plans, mowers, etc.
 *
 *
 */
const MapComponent = (props) => {
  const {
    google,
    height,
    width,
    plan,
    mowers,
    onMapDrag,
    onPinSingleTap,
    onPolyMouseDown,
    onPolyMouseUp,
    onZoomChanged,
    selectedMower,
    isTracking,
    homePosition,
    currentPlan,
    recordingPlan,
    planType,
    userPosition,
    containerId,
    centered,
    clickedPoly,
  } = props;
  const MapRef = useRef(null);
  const MowersRef = useRef(null);
  const PlanRef = useRef(null);
  const userRef = useRef(userPosition);
  const FirstLoadRef = useRef(true);
  const [mapMarkers, setMapMarkers] = useState([]);
  const [homePin, setHomePin] = useState(null);
  const [mowingPlan, setMowingPlan] = useState(null);
  const [recordedPlan, setRecordedPlan] = useState(null);
  const [displayedStripes, setStripes] = useState(null);

  /**
   * Creates and returns an array of polygons representing mow sections and their respective exclusion zones.
   * Uses the Google Maps API to manage and display polygons.
   *
   * @param {boolean} mow_perimeter - Determines the outline color of the polygon. True for green, false for grey.
   * @param {Object} plan - Contains details of the mowing plan, including mow sections and exclusion points.
   * @param {Object} map - Represents the Google map instance where the polygons will be displayed.
   * @returns {Array} polygons - Array of polygons to be displayed on the Google map.
   */
  function MakePolygon(mow_perimeter, plan, map) {
    var polygons = [];

    var bounds = new google.maps.LatLngBounds();

    var outline_color;
    if (mow_perimeter) {
      outline_color = green[400];
    } else {
      outline_color = grey[400];
    }
    //Instantiate a polygon
    var outer_polygon;
    if (
      plan.MowSections &&
      plan.MowSections !== null &&
      plan.MowSections.length > 1
    ) {
      let red = 0;
      let green = 0;
      let blue = 255;

      let ident = 1;
      plan.MowSections.forEach((mowsection, i) => {
        // shift colors
        if (i % 3 === 0) {
          red = (red + 64) % 256;
        } else if (i % 3 === 1) {
          green = (green + 64) % 256;
        } else {
          blue = (blue + 64) % 256;
        }

        let color = ((red << 16) | (green << 8) | blue).toString(16);

        while (color.length < 6) {
          color = "0" + color; // pad with leading zeros
        }

        mowsection = new MowSection(
          mowsection.Points,
          mowsection.ExclusionPoints === ""
            ? undefined
            : mowsection.ExclusionPoints
        );

       
        //Build exclusion zones and filter them so we have valid holes
        var exclusions = mowsection.GetExclusionPoints();
        var filtered_exclusions = [];
        exclusions.forEach((exclusion) => {
          filtered_exclusions.push(plan.FilterPerimeter(exclusion, true, 0.01));
        });

        outer_polygon = AdvancedPolygon({
          google: google,
          map: map,
          perim: mowsection.GetPoints(),
          holes: filtered_exclusions,
          outlinecolor: outline_color,
          fillcolor: "#" + color,
          isclicked: ident === clickedPoly,
          clickedid: ident,
          zindex: 1000,
        });

        polygons.push(outer_polygon);
        ident++;
      });
    } else {
      outer_polygon = AdvancedPolygon({
        google: google,
        map: map,
        perim: plan.GetPoints(),
        holes: plan.GetExclusionPoints(),
        outlinecolor: outline_color,
        fillcolor: "#881BA1E2",
        isclickable: false,
        clickedid: 0,
        zindex: 100,
      });
      //build exclusion zones and filter them so have valid holes
      var exclusions = plan.GetExclusionPoints();
      var filtered_exclusions = [[]];
      exclusions.forEach((exclusion) => {
        filtered_exclusions.push(plan.FilterPerimeter(exclusion, true, 0.01));
      });
      outer_polygon.holes = filtered_exclusions;
      polygons.push(outer_polygon);
    }

    if (centered) {
      polygons.forEach((polygon) => {
        var points = polygon.getPaths().getArray()[0].getArray();
        let polygonBounds = getBoundsForPoints(points);
        bounds.union(polygonBounds);
      });

      map.fitBounds(bounds); //adjust map to show all polygons
    }

    return polygons;
  }

  /**
   * Calculates the bounds for a given set of points.
   * This is specifically used for a set of points on a Google Map.
   *
   * @param {Array} points - Array of points for which bounds are to be calculated.
   * @returns {Object} bounds - A google.maps.LatLngBounds object representing the bounds of the provided points.
   */
  function getBoundsForPoints(points) {
    if (!google) {
      console.error(
        "Google Maps library not loaded or LatLngBounds not available."
      );
      return null;
    }

    if (!Array.isArray(points) || points.length === 0) {
      console.error("Invalid or empty points array provided.");
      return new google.maps.LatLngBounds();
    }

    var bounds = new google.maps.LatLngBounds();
    for (var i = 0; i < points.length; i++) {
      if (
        typeof points[i].lat() !== "number" ||
        typeof points[i].lng() !== "number"
      ) {
        console.error(`Invalid point at index ${i}:`, points[i]);
        continue;
      }
      bounds.extend(points[i]);
    }

    return bounds;
  }

  /**
   * Uses the Clipper library to generate spiraling polygons from an initial polygon
   * while avoiding intersecting with specified keepout areas.
   *
   * @param {Object} xy - Provides functions to convert lat-long coordinates to X-Y map coordinates.
   * @param {Object} polygon - Represents the initial polygon with its points.
   * @param {boolean} clockwise - Determines the spiral direction: true for clockwise, false for counter-clockwise.
   * @param {Object} mow_section - Contains exclusion zones specified as lat-long coordinates.
   * @returns {Map} polygon_map - Contains generated polygons with their IDs, depths, and parent IDs.
   */
  function GenerateSpiralPerimeters({ xy, polygon }) {
    const ClipperLib = require("js-clipper");

    if (typeof ClipperLib === "undefined" || !ClipperLib.ClipperOffset) {
      console.error(
        "js-clipper library not loaded or ClipperOffset not available."
      );
      return new Map(); // Return an empty map
    }

    if (typeof xy.fromLatLon !== "function") {
      console.error("xy.fromLatLon is not a function.");
      return new Map();
    }

    if (!Array.isArray(polygon.lines)) {
      console.error("polygon.lines is not an array or is undefined.");
      return new Map();
    }

    let points = polygon.lines.map((pt) => {
      let result = xy.fromLatLon(pt.Start.lat, pt.Start.lng);
      return { X: result.X * 1000.0, Y: result.Y * 1000.0 };
    });

    // Create a map to store all the generated polygons
    let polygon_map = new Map();
    let current_polygon_id = 0;

    // Create the outer polygon and add it to the map
    let outer_polygon = new SpiralPolygon(current_polygon_id++);
    outer_polygon.parent_id = -1;
    outer_polygon.points = points;
    polygon_map.set(-1, outer_polygon);

    // Initialize the loop counter
    let loop = 0;
    const MAX_LOOPS = 1000; // adjust accordingly

    // Define the initial set of polygons for the current loop
    let current_loop_polygons = [outer_polygon];

    // Start generating spirals and avoid keepout areas
    do {
      if (loop !== 0) {
        var new_loop_polygons = [];

        // Loop through the current polygons and create offset polygons
        current_loop_polygons.forEach((poly) => {
          var co = new ClipperLib.ClipperOffset();
          var list = [];
          list.push(poly.points);

          co.AddPaths(
            list,
            ClipperLib.JoinType.jtMiter,
            ClipperLib.EndType.etClosedPolygon
          );
          var inner_polys = new ClipperLib.Paths();
          co.Execute(inner_polys, -1000);

          if (inner_polys.length !== 0) {
            inner_polys.forEach((ip) => {
              var new_p = new SpiralPolygon(current_polygon_id++);
              new_p.depth = loop;
              new_p.parent_id = poly.id;
              new_p.points = ip;
              polygon_map.set(poly.id, new_p);
              new_loop_polygons.push(new_p);
            });
          }
        });

        current_loop_polygons = new_loop_polygons;
      }

      loop++;
    } while (current_loop_polygons.length > 0 && loop < MAX_LOOPS);

    if (loop === MAX_LOOPS) {
      console.warn("Max loop count while generating spiral perimeters");
    }

    return polygon_map;
  }

  /**
   * Calculates stripe polygons representing the mowing path for a mower.
   *
   * @param {number} desired_cut_angle - Desired angle for the mower to cut grass (in degrees).
   * @param {number} cut_width - Width of the stripe for the mower to cover in each pass (in meters).
   * @param {Object} mow_section - Contains coordinates of the mowing area as lat-long points.
   * @param {string} color - Color of the stripe polygons. Note: Not explicitly used in this function.
   * @returns {Array} finished_poly - Array of stripe polygons representing the mowing path.
   */
  function PreviewStripe({ desired_cut_angle, cut_width, mow_section, color }) {
    const CONVERSION_FACTOR = 3280.8399;
    const ITERATION_LIMIT = 170;
    let returned_stripes = [];
    let math_util = new MathUtil();
    const ClipperLib = require("js-clipper");
    if (typeof ClipperLib === "undefined") {
      throw new Error("js-clipper library not loaded.");
    }
    //Normalize the stripe angle
    desired_cut_angle = desired_cut_angle % 180.0;
    if (desired_cut_angle < 0.0) {
      desired_cut_angle += 180.0;
    }

    //get the outer poly.
    var my_pts = mow_section.GetPoints();
    //First get the corner-most points
    var northMost = my_pts[0];
    var southMost = my_pts[0];
    var eastMost = my_pts[0];
    var westMost = my_pts[0];
    my_pts.forEach((pt) => {
      if (pt.lat > northMost.lat) {
        northMost = pt;
      }
      if (pt.lat < southMost.lat) {
        southMost = pt;
      }
      if (pt.lng > eastMost.lng) {
        eastMost = pt;
      }
      if (pt.lng < westMost.lng) {
        westMost = pt;
      }
    });

    //Use the NE, NW, SE, SW corners as absolute edges of polygon
    //get the NorthEast & NorthWest Corners
    var northWesternCorner = new Position(northMost.lat, westMost.lng);
    var northEasternCorner = new Position(northMost.lat, eastMost.lng);
    //get the SouthEast & SouthWest Corners
    var southWesternCorner = new Position(southMost.lat, westMost.lng);

    var distance45 = math_util.GetDistance(
      southWesternCorner.lat,
      southWesternCorner.lng,
      northEasternCorner.lat,
      northEasternCorner.lng
    );
    const normalizedDistance = distance45 / CONVERSION_FACTOR;
    var bearing = 90 - desired_cut_angle;
    // Produce a line big enough and at the correct angle to sweep through the mow area
    var extendedPointStart = math_util.GetHaversineCoordinate(
      northWesternCorner,
      180.0 + bearing,
      normalizedDistance
    );
    var extendedPointEnd = math_util.GetHaversineCoordinate(
      northWesternCorner,
      bearing,
      normalizedDistance
    );
    if (desired_cut_angle > 90.0) {
      //use south west corner
      extendedPointStart = math_util.GetHaversineCoordinate(
        southWesternCorner,
        180.0 + bearing,
        normalizedDistance
      );
      extendedPointEnd = math_util.GetHaversineCoordinate(
        southWesternCorner,
        bearing,
        normalizedDistance
      );
    }

    var sweepingLine = new GeoLine(
      extendedPointStart.lat,
      extendedPointStart.lng,
      extendedPointEnd.lat,
      extendedPointEnd.lng
    );
    var sweep_distance = normalizedDistance;

    var xy = new LocalXYUtil(my_pts[0].lat, my_pts[0].lng);
    var clip_polygons = [[]];
    //Add perimeter
    var outer_poly = GeopointsToPoints(my_pts, xy, 1000.0);
    if (!ClipperLib.Clipper.Orientation(outer_poly)) {
      outer_poly.reverse();
    }
    clip_polygons.push(outer_poly);

    //Add keepouts
    var kps = mow_section.GetExclusionPoints();
    if (kps.length > 0) {
      kps.forEach((keepout) => {
        var poly = GeopointsToPoints(keepout, xy, 1000.0);
        if (ClipperLib.Clipper.Orientation(poly)) {
          poly.reverse();
        }
        if (poly !== undefined && poly.length > 0) {
          clip_polygons.push(poly);
        }
      });
      clip_polygons.pop();
    }
    clip_polygons.shift();

    // Create a separate array for the sweeping lines
    var sweeping_lines = [];

    // Now sweep through the line throughout the cut area and produce "slices"
    var distance = 0;
    while (distance < sweep_distance) {
      var line_list = [];
      line_list.push(sweepingLine.Start);
      for (let i = 0; i <= ITERATION_LIMIT; i++) {
        let t = i / ITERATION_LIMIT;
        let interpolatedPoint = new Position(
          extendedPointStart.lat +
            t * (extendedPointEnd.lat - extendedPointStart.lat),
          extendedPointStart.lng +
            t * (extendedPointEnd.lng - extendedPointStart.lng)
        );
        line_list.push(interpolatedPoint);
      }
      line_list.push(sweepingLine.End);
      let lines = GeopointsToPoints(line_list, xy, 1000.0);

      sweeping_lines.push(lines);

      //move the line by a cut width for the next iteration
      extendedPointStart = math_util.GetHaversineCoordinate(
        extendedPointStart,
        bearing + 90.0,
        cut_width
      );

      extendedPointEnd = math_util.GetHaversineCoordinate(
        extendedPointEnd,
        bearing + 90.0,
        cut_width
      );

      sweepingLine = new GeoLine(
        extendedPointStart.lat,
        extendedPointStart.lng,
        extendedPointEnd.lat,
        extendedPointEnd.lng
      );

      distance += cut_width;
    } // end while

    // Clip the sweeping lines with the clip_polygons (perimeter + keepouts)
    var co = new ClipperLib.Clipper();
    co.AddPaths(sweeping_lines, ClipperLib.PolyType.ptSubject, false);
    co.AddPaths(clip_polygons, ClipperLib.PolyType.ptClip, true);
    var offsetted_paths = new ClipperLib.PolyTree();
    co.Execute(ClipperLib.ClipType.ctIntersection, offsetted_paths);
    var paths = ClipperLib.Clipper.PolyTreeToPaths(offsetted_paths);
    returned_stripes = paths;

    // Convert lines_to_convert to finished_poly
    var finished_poly = [];
    returned_stripes.forEach((poly) => {
      let polygon = PointsToGeoPoints(poly, xy, 1000.0);
      polygon.push(polygon[0]);
      finished_poly.push(polygon);
    });

    // Check if there are no keepout areas and add the individual lines of sweeping_lines to finished_poly
    if (kps.length === 0 && returned_stripes.length > 0) {
      returned_stripes.forEach((poly) => {
        let polygon = PointsToGeoPoints(poly, xy, 1000.0);
        polygon.push(polygon[0]);
        finished_poly.push(polygon);
      });
    }
    return finished_poly;
  }

  /**
   * Generates a preview of the cutting pattern for mowing operations
   * within a given mowing area based on the specified cut pattern.
   *
   * @function
   * @name PreviewCut
   * @param {Object} parameters - The parameters for the function.
   * @param {number} parameters.desired_cut_angle - The desired angle at which
   *        the mower should cut the grass (in degrees).
   * @param {Object} parameters.props - Properties related to mowing including overlapwidth,
   *        cutpattern, and cut_width.
   * @param {Object} parameters.mow_section - Object containing the coordinates
   *        of the mowing area as latitude and longitude points.
   * @returns {Array} An array of polylines representing the cutting pattern.
   */
  function PreviewCut({ desired_cut_angle, props, mow_section }) {
    var polylines = [];
    //determine cut_width in km
    let deck_width = 60.0;
    let cut_width = deck_width - props.overlapwidth;
    cut_width = Math.max(10, cut_width); //limit us to a sane minimum
    cut_width = Math.min(deck_width, cut_width);
    cut_width = cut_width * 0.0000254; //back to km

    //Display the spiral angle
    if (props.cutpattern === CutPattern.Spiral) {
      var points = mow_section.GetPoints();
      let polygon = new GeoPolygon(points);
      let xy = new LocalXYUtil(points[0].lat, points[0].lng);
      var perims = GenerateSpiralPerimeters({
        xy: xy,
        polygon: polygon,
        clockwise: true,
        mow_section: mow_section,
      });
      perims.forEach((per) => {
        let lines = [];
        if (per.depth !== 0) {
          lines = PointsToGeoPoints(per.points, xy, 1000.0);
          lines.push(new Position(lines[0].lat, lines[0].lng));
          polylines.push(lines);
        }
      });
    } else if (props.cutpattern === CutPattern.CrossHatch) {
      let lines = [];
      lines.push(
        PreviewStripe({
          desired_cut_angle: desired_cut_angle,
          cut_width: cut_width,
          mow_section: mow_section,
        })
      );
      lines.push(
        PreviewStripe({
          desired_cut_angle: desired_cut_angle + 90,
          cut_width: cut_width,
          mow_section: mow_section,
        })
      );
      lines.forEach((line) => {
        line.forEach((ln) => {
          polylines.push(ln);
        });
      });
    } else {
      polylines = PreviewStripe({
        desired_cut_angle: desired_cut_angle,
        cut_width: cut_width,
        mow_section: mow_section,
      });
    }
    return polylines;
  }

  /**
   * Converts geographical points to points in a local XY coordinate system
   * using the provided LocalXYUtil object and scales the resulting points.
   *
   * @function
   * @name GeopointsToPoints
   * @param {Array} poly - An array of geographical points with lat and lng properties.
   * @param {Object} xy - An object of type LocalXYUtil for coordinate conversion.
   * @param {number} scale - A scaling factor to apply after conversion.
   * @returns {Array} An array of points in the local XY coordinate system.
   */
  function GeopointsToPoints(poly, xy, scale) {
    if (!Array.isArray(poly)) {
      throw new Error("Input poly must be an array.");
    }

    var points = [];
    if (poly.length > 0) {
      poly.forEach(({ lat, lng }) => {
        const results = xy.fromLatLon(lat, lng);
        points.push({ X: results.X * scale, Y: results.Y * scale });
      });
    }
    return points;
  }

  /**
   * Converts points in a local XY coordinate system back to geographical points.
   *
   * @function
   * @name PointsToGeoPoints
   * @param {Array} poly - An array of points in a local XY coordinate system with X and Y properties.
   * @param {Object} xy - An object of type LocalXYUtil for coordinate conversion.
   * @param {number} scale - The scaling factor used during the conversion to local XY coordinates.
   * @returns {Array} An array of converted geographical points.
   */
  function PointsToGeoPoints(poly, xy, scale) {
    if (!Array.isArray(poly)) {
      throw new Error("Input poly must be an array.");
    }

    var points = [];
    if (poly.length > 0) {
      poly.forEach(({ X, Y }) => {
        const result = xy.toLatLon(X / scale, Y / scale);
        points.push(new Position(result.lat, result.lng));
      });
    }

    return points;
  }

  /**
   * Initializes a new Google Map or retrieves an existing one. It adjusts the map's center
   * based on user or mower positions. It also sets map behaviors and listens for drag events.
   *
   * @function
   * @name CreateMap
   * @global
   * @param {string} containerId - The id of the container for the map.
   * @param {Array} MowersRef - Reference to mowers (if any).
   * @param {Object} userRef - Reference to user position.
   * @param {Object} userPosition - User's current position.
   * @param {Object} selectedMower - The currently selected mower.
   * @param {Object} FirstLoadRef - Indicates if it's the first load.
   */
  function CreateMap() {
    const DEFAULT_ZOOM = 20;
    let map;
    if (MapRef.current === null) {
      const mapContainer = document.getElementById(containerId.toString());
      if (!mapContainer) {
        throw new Error("Map container not found.");
      }

      map = new google.maps.Map(mapContainer, {
        zoom: DEFAULT_ZOOM,
        rotateControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        google: google,
        mapTypeId: google.maps.MapTypeId.HYBRID,
      });

      if (!map) {
        throw new Error("Failed to initialize the map.");
      }

      MapRef.current = map;
    } else map = MapRef.current;

    if (
      selectedMower === null &&
      (MowersRef.current === null || MowersRef.current.length === 0)
    ) {
      if (userRef.current !== null) {
        map.setCenter(userPosition);
      }
    } else if (selectedMower !== null && isTracking) {
      FollowMapMarker();
    } else {
      if (FirstLoadRef.current) {
        InitialCenterMapMarkers();
        FirstLoadRef.current = false;
      }
    }

    google.maps.event.clearListeners(map, "dragstart");
    google.maps.event.clearListeners(map, "zoom_changed");
    google.maps.event.addListener(map, "dragstart", onMapDrag);
    google.maps.event.addListener(map, "zoom_changed", onZoomChanged);
  }

  /**
   * Removes all map markers from the map and clears the map markers state.
   *
   * @function
   * @name RemoveMapMarkers
   * @global
   * @var {Array} mapMarkers - Array of Google map markers.
   * @var {function} setMapMarkers - Function to update the map markers state.
   */
  function RemoveMapMarkers() {
    if (Array.isArray(mapMarkers)) {
      mapMarkers.forEach((marker) => {
        if (marker) {
          marker.setMap(null);
        }
      });
    }
    setMapMarkers([]);
  }

  /**
   * Creates and displays map markers on the map for each mower.
   * It also centers the map and adds event listeners for each marker.
   *
   * @function
   * @name DisplayMapMarkers
   * @global
   * @var {Object} MapRef - Reference to the map.
   * @var {Array} MowersRef - Reference to the mowers.
   * @var {Array} mowers - Array of mowers to be displayed.
   * @var {function} MowerPin - Function to create a mower pin.
   * @var {function} setMapMarkers - Function to update the map markers state.
   * @var {function} CenterMapMarker - Function to center the map to the marker.
   * @var {function} onPinSingleTap - Event listener for pin tap.
   */
  function DisplayMapMarkers() {
    let map = MapRef.current;
    if (map) {
      let newMarkers = [];
      MowersRef.current = mowers;
      if (Array.isArray(mowers) && mowers.length > 0) {
        mowers.forEach((m) => {
          if (m && m.data && m.pin) {
            let marker = MowerPin({
              google: google,
              mower: m.data,
              map: map,
              pin: m.pin,
            });
            newMarkers.push(marker);
          }
        });

        setMapMarkers(newMarkers);
      } else setMapMarkers([]);

      CenterMapMarker(newMarkers);

      if (Array.isArray(newMarkers)) {
        //Add listeners to map markers
        newMarkers.forEach((m) => {
          if (m) {
            google.maps.event.addListener(m, "click", (event) => {
              onPinSingleTap(event, m);
            });
          }
        });
      }
    }
  }

  /**
   * Centers the map based on the position of the selected mower if in tracking mode.
   * If no mower is selected and not in tracking mode, it centers on initial map markers.
   *
   * @function
   * @name CenterMapMarker
   * @param {Array} newMarkers - An array of new map markers to be centered on.
   * @global
   * @var {Object} MapRef - Reference to the map.
   * @var {boolean} FirstLoadRef - Indicates if it's the first map load.
   * @var {function} InitialCenterMapMarkers - Function to center on initial map markers.
   * @var {string} selectedMower - Serial number of the selected mower.
   * @var {boolean} isTracking - Indicates if tracking mode is active.
   * @var {Array} MowersRef - Reference to the mowers.
   */
  function CenterMapMarker(newMarkers) {
    let map = MapRef.current;

    if (!map) return;

    if (
      Array.isArray(newMarkers) &&
      newMarkers.length > 0 &&
      selectedMower === null &&
      !isTracking
    ) {
      if (FirstLoadRef.current) {
        InitialCenterMapMarkers();
        FirstLoadRef.current = false;
      }
    } else if (selectedMower !== null && isTracking) {
      MowersRef.current.forEach((m) => {
        if (
          m &&
          m.data &&
          m.data.serial_number === selectedMower &&
          m.pin &&
          m.pin.position
        ) {
          map.setCenter({
            lat: m.pin.position.lat,
            lng: m.pin.position.lng,
          });
        }
      });
    }
  }

  /**
   * Adjusts the map center to follow a selected mower if it's being tracked.
   *
   * @function
   * @name FollowMapMarker
   * @global
   * @var {Object} MapRef - Reference to the map.
   * @var {Array} MowersRef - Reference to the mowers.
   * @var {string} selectedMower - Serial number of the selected mower.
   * @var {boolean} isTracking - Indicates if tracking mode is active.
   */
  function FollowMapMarker() {
    let map = MapRef.current;
    if (
      !map ||
      !MowersRef.current ||
      MowersRef.current.length === 0 ||
      !selectedMower ||
      !isTracking
    ) {
      return; // exit early if conditions aren't met
    }

    mowers.forEach((m) => {
      if (m && m.data && m.data.serial_number === selectedMower && isTracking) {
        map.setCenter({
          lat: m.data.lat,
          lng: m.data.lon,
        });
        return; // break out once mower is found and centered
      }
    });
  }

  /**
   * Adjusts the map's view to fit markers within 2 miles of the first marker in MowersRef.
   * Uses Google Maps Geometry library for distance calculations.
   *
   * @function
   * @name InitialCenterMapMarkers
   * @returns {void}
   */
  const InitialCenterMapMarkers = useCallback(async () => {
    const map = MapRef.current;
    const mowers = MowersRef.current;

    // Exit early if conditions aren't met

    if (!map || !mowers || mowers.length === 0) return;

    const { spherical } = await google.maps.importLibrary("geometry");
    if (!spherical) {
      console.error("Failed to import Google Maps Geometry Library");
      return;
    }
    //convert 2miles to meters
    const twoMilesinMeters = 2 * 1609.34;
    const bounds = new google.maps.LatLngBounds();
    const centerLatLng = new google.maps.LatLng({
      lat: mowers[0]?.pin?.position?.lat,
      lng: mowers[0]?.pin?.position?.lng,
    });

    mowers.forEach((marker) => {
      const markerLatLng = new google.maps.LatLng({
        lat: marker?.pin?.position?.lat,
        lng: marker?.pin?.position?.lng,
      });

      const distanceFromCenter = spherical.computeDistanceBetween(
        markerLatLng,
        centerLatLng
      );

      //If the marker is within 2 miles from the center of the map, extend the bounds to include the marker's position
      if (distanceFromCenter <= twoMilesinMeters) {
        bounds.extend(marker.pin.position);
      }
    });

    //Now fit the map to the new bounds
    map.fitBounds(bounds);
  }, []);

  /**
   * Removes all plan-related elements such as polylines and markers from the map.
   *
   * @function
   * @name RemoveAllPlanElements
   * @param {Object} map - The Google Map object where the elements should be removed from.
   */
  function RemoveAllPlanElements(map) {
    if (!map) return;
    if (PlanRef.current !== null) {
      PlanRef.current.forEach((poly) => poly.setMap(null));
    }
    if (displayedStripes !== null) {
      displayedStripes.setMap(null);
    }
    if (recordedPlan !== null) {
      recordedPlan.forEach((line) => line.setMap(null));
    }
    if (mowingPlan !== null) {
      mowingPlan.setMap(null);
    }
  }

  /**
   * Renders the plan preview on the map by creating polygons and polylines
   * based on the `plan` properties. It also sets up event listeners on each
   * polygon for mouse down and mouse up events.
   *
   * @function
   * @name DisplayPlanPreview
   */
  function DisplayPlanPreview() {
    if (!plan) return;
    let map = MapRef.current;
    if (!map) return;

    RemoveAllPlanElements(map);

    let props = plan.GetProperties();
    let polygons = MakePolygon(props.remowperimeter, plan, map);

    //Now create polylines to overlay on top of the polygon
    let preview = PreviewCut({
      desired_cut_angle: props.stripeangle,
      props: props,
      mow_section: plan,
    });

    let lines;
    preview.forEach((stripe) => {
      lines = AdvancedPolyline({
        map: map,
        google: google,
        path: stripe,
      });
    });
    polygons.forEach((polygon) => {
      google.maps.event.addListener(polygon, "mousedown", (event) =>
        onPolyMouseDown(event)
      );
      google.maps.event.addListener(polygon, "mouseup", (event) =>
        onPolyMouseUp(event, polygon)
      );
    });

    PlanRef.current = polygons;
    setStripes(lines);
  }

  /**
   * Renders the home pin on the map. If a previous home pin exists, it's removed
   * and a new one is set based on the `homePosition` value.
   *
   * @function
   * @name DisplayHomePin
   */
  function DisplayHomePin() {
    let map = MapRef.current;
    if (!map || !homePosition) return;

    if (homePin) {
      homePin.setMap(null);
    }

    let home = HomePin({
      google: google,
      map: map,
      position: homePosition,
    });
    setHomePin(home);
  }

  /**
   * Displays the current plan on the map by creating polylines.
   * It also sets up event listeners on each polyline for mouse down and mouse up events.
   * If there's an associated home pin for the current plan, it will be rendered as well.
   *
   * @function
   * @name DisplayCurrentPlan
   */
  function DisplayCurrentPlan() {
    let map = MapRef.current;
    if (!map || !currentPlan) return;

    RemoveAllPlanElements(map);

    if (currentPlan.length > 0) {
      DisplayHomePin();
      let stripes = AdvancedPolyline({
        google: google,
        map: map,
        path: currentPlan,
      });

      google.maps.event.addListener(stripes, "mousedown", (event) =>
        onPolyMouseDown(event)
      );
      google.maps.event.addListener(stripes, "mouseup", (event) =>
        onPolyMouseUp(event, stripes)
      );

      setMowingPlan(stripes);
    }
  }

  /**
   * Renders the recorded plan on the map. This involves creating polylines
   * for the exterior boundaries and any exclusion zones.
   * After rendering, it updates the `recordedPlan` state with the rendered elements.
   *
   * @function
   * @name DisplayRecordedPlan
   */
  function DisplayRecordedPlan() {
    let map = MapRef.current;
    if (!map) return;

    if (recordedPlan) {
      RemoveAllPlanElements(map);
    }
    if (recordingPlan !== null) {
      let plan = [];

      plan.push(
        AdvancedPolyline({
          google: google,
          map: map,
          path: recordingPlan.exterior_bounds,
        })
      );

      recordingPlan.exclusion_zones.forEach((zones) => {
        plan.push(
          AdvancedPolyline({
            google: google,
            map: map,
            path: zones,
          })
        );
      });

      setRecordedPlan(plan);
    }
  }

  /**
   * This effect handles the initialization of the map when the component first mounts.
   * The primary action is to create the map, and this action only happens once
   * (similar to the componentDidMount lifecycle method in class components).
   *
   * 1. Checks if the map exists.
   * 2. Creates the map if it doesn't exist.
   *
   * @hook
   * @name useEffect
   * @listens component mount - The effect will only run once when the component mounts.
   */
  useEffect(() => {
    // Only create the map if it doesn't exist.
    if (!MapRef.current) {
      CreateMap();
    }
  }, []);

  /**
   * This effect handles the initialization and updates to the map based on changes
   * to `mowers` and `planType` dependencies.
   *
   * Actions:
   * 1. Removes previous map markers.
   * 2. Displays and centers the new markers.
   * 3. Initiates the FollowMapMarker function.
   *
   * @hook
   * @name useEffect
   * @listens mowers - Re-runs the effect whenever there are changes to the `mowers` data.
   */
  useEffect(() => {
    RemoveMapMarkers();
    DisplayMapMarkers();

    if (isTracking && !centered) {
      FollowMapMarker();
    }
  }, [mowers]);

  /**
   * This effect is dedicated to handling plan-related changes and updates.
   * Depending on the `planType`, different plan visualization functions are triggered.
   *
   * Actions:
   * 1. Checks the `planType` to decide which function to invoke:
   *    - Displays a plan preview.
   *    - Displays the current plan.
   *    - Displays the recorded plan.
   *    - Or removes all plan elements if there's no valid plan type.
   *
   * @hook
   * @name useEffect
   * @listens plan, planType, currentPlan, recordingPlan - Re-runs the effect whenever there are changes to any plan-related props.
   */
  useEffect(() => {
    switch (planType) {
      case MowerPlanType.PlanPreview:
        DisplayPlanPreview();
        break;
      case MowerPlanType.CurrentPlan:
        DisplayCurrentPlan();
        break;
      case MowerPlanType.RecordingPlan:
        DisplayRecordedPlan();
        break;
      default:
        RemoveAllPlanElements(MapRef.current);
    }
  }, [plan, planType, currentPlan, recordingPlan]);

  return (
    <div style={{ maxWidth: "100%", maxHeight: "100%", position: "relative" }}>
      <div id={containerId} style={{ height: height, width: width }}></div>
    </div>
  );
};

export default MapComponent;
