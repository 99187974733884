import React, { useEffect, useState } from "react";
import {
  Slider,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
} from "@mui/material";
import { red, grey, green } from "@mui/material/colors";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MountPointPopup } from "./MountPointPopup";

export const GeneralSettingsTabPanel = (props) => {
  const {
    mower,
    user,
    enablePTO,
    generalsettings,
    selectedNTRIP,
    ntriplist,
    onUpdateMowerClicked,
    onCheckMowerUpdateHandle,
    onSoftRestartYes,
    onHardRestartYes,
    onPairActClicked,
    onSaveButtonClicked,
  } = props;
  const { t, i18n } = useTranslation();

  const [correctionsSource, setCorrectionsSource] = useState(0);
  const [NTRIPlogin, setNTRIPLogin] = useState(generalsettings.selected_ntrip_login);
  const [NTRIPloginlist, setNTRIPLoginList] = useState(ntriplist || []);
  const [displayContrast, setDisplayContrast] = useState(1);
  const [editButtonDisabled, setEditButtonDisabled] = useState(true);
  const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
  const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
  const [lowFuelReturn, setLowFuelReturn] = useState(true);
  const [currentVersion, setCurrentVersion] = useState("");
  const [latestVersion, setLatestVersion] = useState("");
  const [latestVersionColor, setLatestVersionColor] = useState(grey[700]);
  const [managementVersion, setManagementVersion] = useState("");
  const [pickerItems, setPickerItems] = useState([]);
  const [isTechnician, setIsTechnician] = useState(false);
  const [selected_ntrip, setSelectedNtrip] = useState({ name: "", login: "" });


  /**
     * Pop-up visibility states: showAddNtripLoginDialog, showUpdatePagePopup, showMountPointPopup.
     * NTRIP related states: server, port, mountpoint, username, password, selected_ntrip, ntrip_logins, selected_ntrip_login.
     * value, runtime_settings, general_settings, user: Various settings and configurations for the mower.
     */
  const [state, setState] = useState({
    showAddNtripLoginDialog: false,
    showUpdatePagePopup: false,
    showMountPointPopup: false,
    AddNewNtripFlag: false,
    name: "",
    server: "",
    port: "",
    mountPoint: "",
    username: "",
    password: "",
    ntrip_logins: [],
    selected_ntrip_login: 0,
    selected_ntrip: { name: "", login: "" },
  });

  useEffect(() => {
    const checkTechnicianStatus = async () => {
      const status = await user.IsTechnician();
      //console.log("Technician status: ", status);
      setIsTechnician(status);
    };
    checkTechnicianStatus();
  }, [user]);

  useEffect(() => {
    if (mower && generalsettings) {
      setCorrectionsSource(generalsettings.corrections_source);
      setManagementVersion(mower.details.current_management_version.toString());
      setNTRIPLoginList(generalsettings.ntrip_logins);
      if (mower.details.update_available) {
        setLatestVersionColor(red[700]);
      } else if (mower.details.current_version < mower.details.latest_version) {
        setLatestVersionColor(green[700]);
      } else {
        setLatestVersionColor(grey[700]);
      }

      setUpdateButtonDisabled(!mower.details.update_available);

      let currentversion =
        mower.details.current_version.toString() +
        " (" +
        mower.details.latest_release_channel +
        ")";
      let latestversion;
      if (mower.details.latest_version < 0.0) {
        latestversion =
          "None newer in '" + mower.details.latest_release_channel + "'";
      } else {
        latestversion =
          mower.details.latest_version.toString() +
          " (" +
          mower.details.latest_release_channel +
          ")";
      }
      setLatestVersion(latestversion);
      setCurrentVersion(currentversion);

      setCorrectionsSource(generalsettings.corrections_source);
      setDisplayContrast(Math.round(generalsettings.display_contrast));
      setLowFuelReturn(generalsettings.low_fuel_return);
    }
  }, [mower, generalsettings, isTechnician]);

  useEffect(() => {
    let logins = NTRIPloginlist || [];
    let picker_items = [];

    for (let i = 0; i < logins.length; i += 2) {
      if (logins[i] && logins[i + 1]) {
        picker_items.push({
          name: logins[i],
          login: logins[i + 1],
        });
      }
    }

    if (picker_items.length === 0) {
      setNTRIPLogin(0);
      setEditButtonDisabled(true);
      setDeleteButtonDisabled(true);
      setSelectedNtrip({ name: "", login: "" });
      picker_items.push({ name: "None", login: "" });
    } else {
      setNTRIPLogin(generalsettings.selected_ntrip_login);
      let idx = generalsettings.selected_ntrip_login;
      let newName = picker_items[idx].name;
      let newLogin = picker_items[idx].login;
      console.log("idx: ", idx, " name: ", newName, " login: ", newLogin);
      console.log("picker_items: ", picker_items);
      setSelectedNtrip({ name: newName, login: newLogin });
      setEditButtonDisabled(!isTechnician);
      setDeleteButtonDisabled(!isTechnician);
    }
    if (isTechnician) {
      picker_items.push({ name: "Add New Login", login: "" });
    }
    setPickerItems(picker_items);
  }, [NTRIPlogin, NTRIPloginlist, selectedNTRIP, isTechnician]);

  /**
     * Handles the event when a particular mount point is clicked or selected.
     * @name UpdateNTRIPloginlist
     * */
  const updateNTRIPloginlist = (props) => {
    const newNTRIPloginlist = props;
    setState({
      ...state,
      ntrip_logins: newNTRIPloginlist,
    });
    setNTRIPLoginList(newNTRIPloginlist);
  };

  /**
     * Handles the event when a particular mount point is clicked or selected.
     * @name updatePickerItems
     * */
  const updatePickerItems = (props) => {
    const newNTRIPloginlist = props;
    let logins = newNTRIPloginlist || [];
    let picker_items = [];

    for (let i = 0; i < logins.length; i += 2) {
      if (logins[i] && logins[i + 1]) {
        picker_items.push({
          name: logins[i],
          login: logins[i + 1],
        });
      }
    }

    if (picker_items.length === 0) {
      setNTRIPLogin(0);
      setEditButtonDisabled(true);
      setDeleteButtonDisabled(true);
      picker_items.push({ name: "None", login: "" });
    } else {
      setNTRIPLogin(generalsettings.selected_ntrip_login);
      setEditButtonDisabled(!isTechnician);
      setDeleteButtonDisabled(!isTechnician);
    }
    if (isTechnician) {
      picker_items.push({ name: "Add New Login", login: "" });
    }
    setPickerItems(picker_items);
  };

  /**
     * Represents a popup/dialog that displays a list of mount points.
     * @name MountPoints
     * @function PopupClosed - Handles the event when the popup/dialog is closed.
     * @function MountPointClicked - Handles the event when a particular mount point is clicked or selected.
     * @returns the MountPointPopup component, passing necessary properties to control visibility, handle events, and pass data.
     */
  const MountPoints = () => {
    /**
     * Handles the event when the popup/dialog is closed.
     * @name PopupClosed
     * */
    const PopupClosed = () => {
      setState({
        ...state,
        showMountPointPopup: false,
      });
    };

    /**
     * Handles the event when a particular mount point is clicked or selected.
     * @name MountPointClicked
     * */
    const MountPointClicked = (props) => {
      const { name } = props;

      setState({
        ...state,
        mountPoint: name,
        showMountPointPopup: false,
      });
    };

    return (
      <MountPointPopup
        showMountPointPopup={state.showMountPointPopup}
        closePopup={PopupClosed}
        MountPointClicked={MountPointClicked}
        server={state.server}
        port={state.port}
      />
    );
  };

  /**
   * This component provides a dialog box or popup to facilitate the addition of a new NTRIP login.
   * The component allows users to input details like server, port, mount point, username, and password to create an NTRIP login.
   * @name NTRIPCreationPopUp
   * @returns provides a dialog box or popup for adding a new NTRIP login.
   */
  const NTRIPCreationPopUp = () => {
    //name: Holds the name value.
    const [name, setName] = useState(state.name);
    //server: Holds the server value.
    const [server, setServer] = useState(state.server);
    //port: Holds the port value.
    const [port, setPort] = useState(state.port);
    //mountPoint: Holds the mount point value.
    const [mountPoint, setMountPoint] = useState(state.mountPoint);
    //username: Holds the username.
    const [username, setUsername] = useState(state.username);
    //password: Holds the password.
    const [password, setPassword] = useState(state.password);
    //selected_ntrip: Holds the selected NTRIP login.
    const [selected_ntrip, setSelectedNtrip] = useState(state.selected_ntrip);
    //NTRIPloginlist: Holds the list of NTRIP logins.
    const [ntrip_list, setntrip_list] = useState(state.ntrip_logins);

    //Checks if a selected NTRIP login exists and parses its details to set the state variables.
    useEffect(() => {
      if (state.AddNewNtripFlag) {
        return;
      }
      let login = selected_ntrip;
      if (login.name.length > 0) {
        setName(login.name);
      } else {
        setName("");
      }
      if (login.login.length !== 0) {
        login = login.login.split(":");
        setServer(login[2]);
        setPort(login[3]);
        if (mountPoint === "") {
          setMountPoint(login[4]);
        } else {
          setMountPoint(mountPoint);
        }
        setUsername(login[5]);
        setPassword(login[6]);
      } else {
        setServer("");  // Clear the server value
        setPort("");    // Clear the port value
        setMountPoint(""); // Clear the mount point value
        setUsername(""); // Clear the username value
        setPassword(""); // Clear the password value
      }
    }, [selected_ntrip]);

    /**
     * Closes the dialog box and resets the relevant states.
     * @name handleCancelAddNtripLoginDialog
     */
    const handleCancelAddNtripLoginDialog = () => {
      setState({
        ...state,
        showAddNtripLoginDialog: false,
        AddNewNtripFlag: false,
        selected_ntrip_login: generalsettings.selected_ntrip_login,
        name: "",
        server: "",
        port: "",
        username: "",
        password: "",
        mountPoint: "",
        selected_ntrip: { name: "", login: "" },
      });
    };

    /**
     * Constructs the new login string.
     * Checks if an existing login needs to be updated or a new login should be added.
     * Updates the state with the new or updated login..
     * @name handleNTRIPsubmit
     */
    const handleNTRIPsubmit = () => {
      //CREATE NEW LOGIN HERE  
      var loginString =
        "TCP:RTCM32:" +
        server +
        ":" +
        port +
        ":" +
        mountPoint +
        ":" +
        username +
        ":" +
        password;

      // Update the ntriplogins array
      const updatedNtripLogins = NTRIPloginlist;
      if (updatedNtripLogins.length === 0) {
        state.selected_ntrip_login = 0;
      }
      //we have a new login for the end of the list
      if (state.selected_ntrip_login * 2 > NTRIPloginlist.length - 1) {
        updatedNtripLogins.push(name);
        updatedNtripLogins.push(loginString);
      }
      else {
        updatedNtripLogins[NTRIPlogin * 2] = name;
        updatedNtripLogins[NTRIPlogin * 2 + 1] = loginString;
      }
      // Update the NTRIPloginlist state using setNTRIPloginlist
      //this is hacky but I couldn't figure out how to make the picker updatre without this
      updateNTRIPloginlist(updatedNtripLogins);
      updatePickerItems(updatedNtripLogins);
      generalsettings.ntrip_logins = updatedNtripLogins;
      setNTRIPLogin(state.selected_ntrip_login);
      generalsettings.selected_ntrip_login = state.selected_ntrip_login;

      //reset the state
      setState({
        ...state,
        ntrip_logins: updatedNtripLogins,
        selected_ntrip_login: 0,
        name: "",
        server: "",
        port: "",
        username: "",
        password: "",
        mountPoint: "",
        selected_ntrip: { name: "", login: "" },
        showAddNtripLoginDialog: false,
        AddNewNtripFlag: false,
      });
    };


    //Displays a dialog box containing text fields for user inputs and buttons to select mount points,
    //save the new login, or cancel the operation.
    return (
      <Dialog
        open={state.showAddNtripLoginDialog}
        onClose={handleCancelAddNtripLoginDialog}
      >
        {state.showMountPointPopup && <MountPoints />}

        <DialogTitle>
          <center>{t("mowerSettings.addNew")}</center>
        </DialogTitle>
        <DialogContent>
          <center>
            <TextField
              label={t("mowerSettings.name")}
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              label={t("mowerSettings.server")}
              variant="standard"
              value={server}
              onChange={(e) => setServer(e.target.value)}
            />
            <TextField
              label={t("mowerSettings.Port")}
              variant="standard"
              value={port}
              onChange={(e) => setPort(e.target.value)}
            />
            <TextField
              label={t("mowerSettings.mountPoint")}
              variant="standard"
              value={mountPoint}
              onChange={(e) => setMountPoint(e.target.value)}
            />
            <TextField
              label={t("mowerSettings.username")}
              variant="standard"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label={t("mowerSettings.password")}
              variant="standard"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Row>
              <center>
                <Button
                  style={{
                    textTransform: "capitalize",
                    textDecorationLine: "none",
                    marginTop: "10px",
                  }}
                  className="btn amr-btn-primary"
                  onClick={() => {
                    if (server.length === 0 || port.length === 0) {
                      alert(t("alerts.server"), "warning");
                    } else {
                      setState({
                        ...state,
                        name: name,
                        server: server,
                        port: port,
                        username: username,
                        password: password,
                        showMountPointPopup: true,
                      });
                    }
                  }}
                >
                  {t("mowerSettings.mountPoints")}
                </Button>
              </center>
            </Row>

            <Row>
              <center>
                <Button
                  style={{
                    textTransform: "capitalize",
                    textDecorationLine: "none",
                    marginTop: "10px",
                    width: "10%",
                    marginRight: "5px",
                  }}
                  className="btn amr-btn-primary"
                  onClick={handleCancelAddNtripLoginDialog}
                >
                  {t("mowerSettings.cancel")}
                </Button>
                <Button
                  style={{
                    textTransform: "capitalize",
                    textDecorationLine: "none",
                    marginTop: "10px",
                    width: "10%",
                    marginLeft: "5px",
                  }}
                  className="btn amr-btn-primary"
                  onClick={handleNTRIPsubmit}
                >
                  {t("mowerSettings.save")}
                </Button>
              </center>
            </Row>
          </center>
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * set relevasnt states for edit dialog.
   * @name handleEditNtripDialog
   */
  const handleEditNtripDialog = (props) => {
    const selected_ntrip = props;
    state.selected_ntrip = selected_ntrip;
    let login = selected_ntrip;
    if (login.name.length > 0) {
      state.name = login.name;
    } else {
      state.name = "";
    }
    if (login.login.length !== 0) {
      login = login.login.split(":");
      state.server = login[2];
      state.port = login[3];
      if (state.mountPoint === "") {
        state.mountPoint = login[4];
      } else {
        state.mountPoint = state.mountPoint;
      }
      state.username = login[5];
      state.password = login[6];
    } else {
      state.server = "";  // Clear the server value
      state.port = "";    // Clear the port value
      state.mountPoint = ""; // Clear the mount point value
      state.username = ""; // Clear the username value
      state.password = ""; // Clear the password value
    }
  };

  /**
  * Allows editing of an NTRIP login.
  * @name EditNTRIPLogin
  */
  const EditNTRIPLogin = () => {
    const list = NTRIPloginlist || []; // Ensure list is always an array
    // Parse the NTRIP login list to create a structured list
    const structuredList = [];
    for (let i = 0; i < list.length; i += 2) {
      structuredList.push({ name: list[i], login: list[i + 1] });
    }
    // Validate selected_ntrip_login
    const selectedIndex = NTRIPlogin;
    const selectedNtrip = structuredList[selectedIndex];
    if (selectedNtrip) {
      setState({
        ...state,
        showAddNtripLoginDialog: true,
        selected_ntrip_login: selectedIndex,
        selected_ntrip: selectedNtrip,
      });
      setSelectedNtrip({ name: selectedNtrip.name, login: selectedNtrip.login })
    } else {
      console.error("Invalid selected_ntrip_login index: ", selectedIndex);
    }
    handleEditNtripDialog(selectedNtrip);
  };



  /**
   * Deletes an NTRIP login after seeking confirmation.
   * @name DeleteNTRIPLogin
   */
  const DeleteNTRIPLogin = () => {
    var list = [...NTRIPloginlist];
    var nameIndex = NTRIPlogin * 2;
    var index = NTRIPlogin * 2 + 1;
    if (nameIndex < list.length) {
      var selectedName = list[nameIndex];
      if (window.confirm(t("window.ntrip") + selectedName + "?")) {
        if (nameIndex !== -1 && index - 1 !== -1) {
          list.splice(nameIndex, 2);
        }
        setNTRIPLoginList(list);
        generalsettings.ntrip_logins = list;
        if (list.length === 0) {
          setState({
            ...state,
            ntrip_logins: [],
            selected_ntrip_login: 0,
            name: "",
            server: "",
            port: "",
            username: "",
            password: "",
            mountPoint: "",
            selected_ntrip: { name: "", login: "" },
            showAddNtripLoginDialog: false,
          });
        }

        if (NTRIPlogin - 1 >= 0) {
          let newIndex = NTRIPlogin - 1;
          setNTRIPLogin(newIndex);
          generalsettings.selected_ntrip_login = newIndex;

        } else {
          setNTRIPLogin(0);
          generalsettings.selected_ntrip_login = 0;
        }
      }
    }
  };

  /**
   * Provides functionality to add a new NTRIP login.
   * @name AddNTRIPLogin
   */
  const AddNTRIPLogin = (props) => {
    const { showPopup, selection, list } = props;
    setState({
      ...state,
      showAddNtripLoginDialog: showPopup,
      AddNewNtripFlag: true,
      selected_ntrip_login: selection,
      ntrip_logins: list,
    });
    setNTRIPLogin(selection);
  };



  const handleNTRIPLoginChange = (event) => {
    const selection = event.target.value;
    const list = NTRIPloginlist || []; // Ensure list is always an array
    const pickerList = pickerItems;
    if (selection === (pickerList.length - 1) && isTechnician) { // Adjust for pairs
      AddNTRIPLogin({
        showPopup: true,
        selection: selection,
        list: list,
      });
    } else {
      generalsettings.selected_ntrip_login = selection;
      setNTRIPLogin(selection);
    }
  };


  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ marginRight: "45px" }}>
          <span>{t("mowerSettings.correction")} </span>
        </Typography>
        <FormControl sx={{ minWidth: 150 }} size="small">
          <InputLabel id="ntrip-select-label">
            {t("mowerSettings.current")}
          </InputLabel>
          <Select
            labelId="ntrip-select-label"
            id="ntrip-select-label"
            label={t("mowerSettings.current")}
            value={correctionsSource}
            onChange={(e) => setCorrectionsSource(e.target.value)}
          >
            <MenuItem value={0}>{t("mowerSettings.ntrip")}</MenuItem>
            <MenuItem value={1}>{t("mowerSettings.radio")}</MenuItem>
            <MenuItem value={2}>{t("mowerSettings.satellite")}</MenuItem>
          </Select>
        </FormControl>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Typography sx={{ textAlign: "center" }}>
          <span>
            <strong>{t("mowerSettings.ntripLogin")}</strong>
          </span>
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FormControl variant="standard" sx={{ minWidth: 190 }}>
          <InputLabel id="select-NTRIP-login">
            {t("mowerSettings.selectNtrip")}
          </InputLabel>
          <Select
            labelId="select-NTRIP-login"
            id="select-NTRIP-login"
            label="Select NTRIP Login"
            value={NTRIPlogin}
            onChange={handleNTRIPLoginChange}
          >
            {pickerItems.map((item, index) => (
              <MenuItem key={index} value={index}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          style={{
            textTransform: "capitalize",
            textDecorationLine: "none",
            marginLeft: "5px",
            marginTop: "5px",
          }}
          className="btn amr-btn-primary"
          disabled={editButtonDisabled}
          onClick={EditNTRIPLogin}
        >
          {t("mowerSettings.edit")}
        </Button>
        <Button
          style={{
            textTransform: "capitalize",
            textDecorationLine: "none",
            marginLeft: "5px",
            marginTop: "5px",
          }}
          className="btn amr-btn-primary"
          disabled={deleteButtonDisabled}
          onClick={DeleteNTRIPLogin}
        >
          {t("mowerSettings.delete")}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Typography>
          <span>{t("mowerSettings.lowFuel")}</span>
        </Typography>
        <Checkbox
          checked={lowFuelReturn}
          onChange={(e) => setLowFuelReturn(e.target.checked)}
          sx={{
            marginLeft: "180px",
            "& .MuiSvgIcon-root": {
              color: enablePTO ? "red" : "inherit",
            },
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <Col>
          <Typography>
            <span>
              {t("mowerSettings.display")} {displayContrast}
            </span>
          </Typography>
        </Col>
        <Col>
          <Slider
            sx={{
              color: red[700],
              width: "150px",
            }}
            value={displayContrast}
            min={0}
            max={1}
            step={0.1}
            valueLabelDisplay="off"
            onChange={(e) => {
              setDisplayContrast(e.target.value);
            }}
          />
        </Col>
      </div>
      <div>
        <center>
          <Typography
            sx={{
              marginTop: "15px",
            }}
          >
            <span>
              <strong>{t("mowerSettings.mowerSoftware")}</strong>
            </span>
          </Typography>
        </center>
      </div>
      <div>
        <center>
          <Button
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
              marginTop: "5px",
            }}
            className="btn amr-btn-primary"
            onClick={onCheckMowerUpdateHandle}
          >
            {t("mowerSettings.checkUpdates")}
          </Button>
          <Button
            onClick={() => onUpdateMowerClicked()}
            className="btn amr-btn-primary"
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
              marginTop: "5px",
              marginLeft: "5px",
            }}
            disabled={updateButtonDisabled}
          >
            {t("mowerSettings.update")}
          </Button>
        </center>
      </div>
      <div style={{ marginTop: "20px" }}>
        <Row>
          <Col style={{ minWidth: "fit-content", textAlign: "left" }}>
            <Typography sx={{ fontSize: "14px" }}>
              <span>{t("mowerSettings.currentMower")}</span>
            </Typography>
            <Typography sx={{ fontSize: "14px", color: grey[600] }}>
              <span>{t("mowerSettings.latestMower")}</span>
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              <span>{t("mowerSettings.software")}</span>
            </Typography>
          </Col>
          <Col style={{ minWidth: "fit-content", textAlign: "end" }}>
            <Typography sx={{ fontSize: "14px" }}>
              <span>{currentVersion}</span>
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                color: latestVersionColor,
              }}
            >
              <span>{latestVersion}</span>
            </Typography>
            <Typography sx={{ fontSize: "14px" }}>
              <span>{managementVersion}</span>
            </Typography>
          </Col>
        </Row>
      </div>
      <div>
        <center>
          <Button
            className="btn amr-btn-primary"
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
              marginTop: "20px",
            }}
            onClick={() => {
              if (window.confirm(t("window.restart"))) {
                onSoftRestartYes();
              }
            }}
          >
            {t("mowerSettings.restart")}
          </Button>
          <Button
            className="btn amr-btn-primary"
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
              marginTop: "20px",
              marginLeft: "5px",
            }}
            onClick={() => {
              if (window.confirm(t("window.reboot"))) {
                onHardRestartYes();
              }
            }}
          >
            {t("mowerSettings.restartHardware")}
          </Button>
        </center>
      </div>
      <div>
        <center>
          <Button
            className="btn amr-btn-primary"
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
              marginTop: "20px",
            }}
            disabled={!isTechnician}
            onClick={onPairActClicked}
          >
            {t("mowerSettings.pairActuators")}
          </Button>
        </center>
      </div>
      <div style={{ marginTop: "100px" }}>
        <center>
          <Button
            className="btn amr-btn-primary"
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
            }}
            onClick={() => {
              if (window.confirm(t("window.refresh"))) {
                window.location.reload();
              }
            }}
          >
            {t("mowerSettings.refresh")}
          </Button>
          <Button
            className="btn amr-btn-primary"
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
              marginLeft: "30px",
            }}
            onClick={() => {
              onSaveButtonClicked({
                NTRIPloginlist: NTRIPloginlist,
                NTRIPlogin: NTRIPlogin,
                correctionsSource: correctionsSource,
                lowFuelReturn: lowFuelReturn,
                displayContrast: displayContrast,
              });
            }}
          >
            {t("mowerSettings.save")}
          </Button>
        </center>
      </div>
      <NTRIPCreationPopUp />
    </div>
  );
};
