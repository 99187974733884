import Constants from "../../../constants";
import AuthService from "../../services/AuthService";
import { useTranslation } from "react-i18next";

export const SendPINCode = async (phoneNumber, pinNumber) => {
  //ensure that a valid Id is passed to the function.
  if (!phoneNumber || !pinNumber) {
    console.error("Item identifier missing or invalid.");
    return false;
  }
  //Set up necessary variables for the fetch request using the Constants and AuthService classes.
  //constants: Instance of Constants class, which presumably holds constant values like URLs.
  let constants = new Constants();
  //baseAddress: Backend API's base URL.
  var baseaddress = constants.BackendURL();
  //requestURI: Complete URI for the mowing plans list endpoint.
  var requestUri =
    encodeURI(baseaddress) +
    "/api/Login/finish/" +
    phoneNumber +
    "/" +
    pinNumber;

  //Send the pin code to the backend and receive a token back if successful.
  //Save the token in cookies
  try {
    const response = await fetch(requestUri, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    });

    if (!response.ok) {
      //Check for a successful response.
      //If the response is not successful, an error is thrown with the status code.
      throw new Error(
        "There was an error connecting to the plan database. HTTP Status: " +
          response.status
      );
    }

    //store the cookie and return true for success
    const data = await response.text();

    var auth = new AuthService();
    auth.setTokenInCookie(data);
    return true;
  } catch (ex) {
    console.log(ex);
  }
};

export const sendLoginCode = (phoneNumber) => {
  return async () => {
    //ensure that a valid Id is passed to the function.
    if (!phoneNumber) {
      console.error("Item identifier (phoneNumber) missing or invalid.");
      return false;
    }
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    var constants = new Constants();
    //baseAddress: Backend API's base URL.
    var baseaddress = constants.BackendURL();
    //secretkey: The necessary key to allow us to access Text Magic
    var secretkey = constants.LoginSecretKey();
    //requestURI: Complete URI for the mowing plans list endpoint.
    var requestUri =
      encodeURI(baseaddress) +
      "/api/Login/start/" +
      phoneNumber +
      "/" +
      secretkey;

    //Sent the request to the API to send a text to the user
    try {
      await fetch(requestUri, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }).then((response) => {
        //Check for a successful response.
        //If the response is not successful, an error is thrown with the status code.
        if (!response.ok) {
          alert(t("alerts.error"));
          //return false to allow the UI to take care of the response
          return false;
        }
      });

      //Return true to allow the UI to take care of the response
      return true;
    } catch (ex) {
      console.log(ex);
    }
  };
};

export function RemoveNonNumeric(text) {
  //Check for an empty string, return empty string to show the error
  if (text === "" || text === null) {
    return "";
  }
  //Now take the input and remove anything that may be non-numeric. Then we can convert to a number
  var inputText = text;
  var regex = new RegExp("[^0-9]", "gm");
  var numericInput = "";
  numericInput = inputText.replace(regex, "");

  return numericInput;
}
