import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { WebSocketContext } from "../components/services/AppConnection";
import { Link } from "react-router-dom";
import Mower from "../components/models/mower";
import { Row, Col } from "react-bootstrap";
import AuthService from "../components/services/AuthService";
import { SocketMessageType } from "../components/models/enums";
import { withRouter } from "../components/withRouter";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Divider } from "@mui/material";
import { ConnectMowerPopup } from "../components/pages/mowerConnectPage/ConnectMowerPopup";
import {
  LoadingScreen,
  MowerItems,
  NoMowers,
} from "../components/pages/mowerConnectPage/MowersList";
import { useToast } from "../components/customToast/ToastContext";
import { Position } from "../components/models/mowingPlan";
import { useTranslation } from "react-i18next";

/**
 * This function akes a map of mowerObjs, filters out invalid or disconnected mowers, creates a new Mower object for each valid connected mower, and
 * populates the properties of the Mower object with the corresponding data from mower objects.
 * @name convertToMowers
 * @param {map} mowerObjs -  A map containing mower objects.
 * @returns the map (mowerArray) containing converted Mower objects.
 **/
async function convertToMowers(mowerObjs) {
  if (!mowerObjs) {
    throw new Error("Invalid mower objects provided.");
  }
  var sortedMowers = new Map([...mowerObjs.entries()].sort());
  var mowerArray = new Map();

  for (let m of sortedMowers) {
    if (m === undefined) break;
    if (!m[1].connected) {
      continue;
    }
    var mower = new Mower();
    mower.Id = 1;
    mower.MachineSerialNumber = m[1].serial_number;
    mower.accentColor = m[1].accent_color;
    if (mower.accentColor === "p") {
      mower.mowersPageImage = "/Images/mower_active_purple.png";
    } else if (mower.accentColor === "w") {
      mower.mowersPageImage = "/Images/mower_active_grey.png";
    } else {
      mower.mowersPageImage = "/Images/mower_active_blue.png";
    }
    //NaN signifies that the vehicle's location is invalid
    var location_ = await GetUserPosition();
    if (isNaN(m[1].lat) || isNaN(m[1].lon)) {
      mower.distance = NaN;
    } else if (location_ != null) {
      var distance = calculateDistance(location_, {
        lat: m[1].lat,
        lon: m[1].lon,
      });
      mower.distance = isNaN(distance) ? -1 : distance;
    } else {
      mower.distance = -1;
    }
    mower.connected = m[1].connected;
    mower.otherUserConnected = m[1].other_user_connected;
    mower.online = true;
    mower.data = m[1];

    mowerArray.set(mower.MachineSerialNumber, mower);
  }

  return mowerArray;
}

function fixPosition(position) {
  return {
    lat: position.lat,
    lon: position.lng, // Rename lng to lon
  };
}

/**
 * Gets the current geolocation of the user.
 *
 * @function GetUserPosition
 */
async function GetUserPosition() {
  try {
    const position = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => reject(error)
      );
    });

    // If we've reached this point, the user has granted permission.
    const { latitude: lat, longitude: lon } = position.coords;
    const originalPosition = new Position(lat, lon);
    const correctedPosition = fixPosition(originalPosition);
    return correctedPosition;
  } catch (error) {
    // Handle the specific error when the user denies permission.
    if (error.code === error.PERMISSION_DENIED) {
      console.error("User denied geolocation permission.");
      // You can show a message to the user indicating that geolocation is required for your app to function.
    } else {
      console.error(error);
    }

    throw error;
  }
}

function calculateDistance(location1, location2) {
  const R = 6371e3; // Earth's radius in meters
  const lat1 = (location1.lat * Math.PI) / 180; // Convert latitude to radians
  const lat2 = (location2.lat * Math.PI) / 180; // Convert latitude to radians
  const deltaLat = ((location2.lat - location1.lat) * Math.PI) / 180; // Difference in latitude in radians

  const deltaLon = ((location2.lon - location1.lon) * Math.PI) / 180; // Difference in longitude in radians

  // Haversine formula
  const a =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.cos(lat1) *
      Math.cos(lat2) *
      Math.sin(deltaLon / 2) *
      Math.sin(deltaLon / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Final distance in meters
  return distance;
}

/**
 * The MapsAreEqual function determines if two provided Map objects are equal.
 * @name MapsAreEqual
 * @param {map} map1 -  The first map object.
 * @param {map} map2 - The second map object.
 * @returns true if they are equal (same keys with the same corresponding values) or false if they are not, and -1 if either map is null or undefined.
 */
function MapsAreEqual(map1, map2) {
  if (!map1 || !map2) {
    return -1;
  }

  if (map1.size !== map2.size) {
    return false;
  }

  for (let [key, value] of map1) {
    if (!map2.has(key) || map2.get(key) !== value) {
      return false;
    }
  }

  return true;
}

/**
 * Represents a page where a user can connect to mowers.
 *
 * The component manages various state properties to handle different UI scenarios, such as showing a list of mowers,
 * displaying loading screens, and managing connection popups. The component enforces user login upon loading and provides
 * functionality to navigate to the mowing page once a mower is successfully connected.
 *
 * @function MowerConnectPage
 * @param {Object} props - Properties passed to this component.
 *
 */
const MowerConnectPage = (props) => {
  const connection = useContext(WebSocketContext);
  const { router } = props;
  const { t, i18n } = useTranslation();

  /**
   * mowersConnected: A boolean indicating if any mowers are currently connected.
   * mowers: A Map object to store mower-related information.
   * newData: A boolean indicating whether there's new data to be processed.
   * showNoMowersMessage: A boolean indicating if the "No Mowers" message should be displayed.
   * showConnectMowerPU: A boolean determining the visibility of the connect mower popup.
   * showMowerList: A boolean determining if the list of mowers should be displayed.
   * showLoadingScreen: A boolean controlling the visibility of the loading screen.
   */
  const [state, setState] = useState({
    mowersConnected: false,
    mowers: new Map(),
    newData: false,
    showNoMowersMessage: false,
    showConnectMowerPU: false,
    showMowerList: false,
    showLoadingScreen: true,
  });

  useEffect(() => {
    let auth = new AuthService();
    auth.enforceLogin();
  }, []);

  /**
   * This function is triggered when the "Next" button is clicked.
   * It checks if any mower is connected. If connected, it navigates the user to the mowing page;
   * otherwise, it alerts the user to connect a mower before proceeding.
   * @name NextBtnClicked
   */
  const NextBtnClicked = () => {
    let connected = connection.SocketMessage(
      SocketMessageType.AnyMowersConnected
    );

    if (connected) {
      router.navigate("/Mow");
    } else {
      alert(t("alerts.mustConnect"), "error");
    }
  };

  /**
   * The Mowers component renders a list of mowers. It provides functionality to disconnect
   * from a selected mower and handles different states such as loading, no mowers, or a list of mowers.
   * @name Mowers
   * @returns MowerItems component if there are mowers and it's not loading.
   * NoMowers component if there are no mowers and it's not loading.
   * LoadingScreen component if it's in the loading state.
   */
  const Mowers = () => {
    const [noMowers, setNoMowers] = useState(true);
    const mowerlistref = useRef(new Map());
    const [loading, setLoading] = useState(true);

    /**
     *  This function is triggered when a disconnect button for a mower is clicked.
     *  It takes in a serial number (sn) of the mower and sends a message to disconnect from that mower.
     * @name DisconnectBtnClicked
     */
    const DisconnectBtnClicked = (sn) => {
      //disconnect from the mower by sending the serial number of the selected mower
      try {
        if (!connection) {
          throw new Error("Server connection has been lost.");
        }
        connection.SocketMessage(SocketMessageType.DisconnectFromMower, {
          serial_number: sn,
        });
        setLoading(true);
      } catch (ex) {
        console.error(ex);
      }
    };

    /**
     *  This function checks if the application is connected, then fetches the list of mowers,
     *  checks if it has changed, and updates the state accordingly.
     * @name fetchMowers
     */
    const fetchMowers = useCallback(async () => {
      let mowerObjs;
      if (!connection) {
        throw new Error("Server connection has been lost.");
      }
      if (connection.SocketMessage(SocketMessageType.CheckConnected)) {
        let mowers_ = await connection.SocketMessage(
          SocketMessageType.GetMowers
        );
        if (mowers_.size === 0) {
          setLoading(false);
          setNoMowers(true);
        }
        if (MapsAreEqual(mowers_, mowerlistref.current) !== -1) {
          if (!MapsAreEqual(mowers_, mowerlistref.current)) {
            let connected = connection.SocketMessage(
              SocketMessageType.AnyMowersConnected
            );
            if (mowers_.size > 0 && connected) {
              mowerObjs = await convertToMowers(mowers_);
              mowerlistref.current = mowerObjs;
              setNoMowers(false);
              setLoading(false);
            } else {
              setNoMowers(true);
              setLoading(false);
              mowerlistref.current = mowers_;
            }
          } else {
            return;
          }
        }
      }
    }, []);

    useEffect(() => {
      let intervalid;
      try {
        intervalid = setInterval(() => {
          fetchMowers();
        }, 1000);
      } catch (ex) {
        clearInterval(intervalid);
      }

      return () => {
        clearInterval(intervalid);
      };
    }, [fetchMowers]);

    return (
      <div>
        {!loading && !noMowers && (
          <MowerItems
            mowerlist={mowerlistref.current}
            DisconnectBtnClicked={DisconnectBtnClicked}
          />
        )}
        {!loading && noMowers && <NoMowers />}
        {loading && <LoadingScreen />}
      </div>
    );
  };

  /**
   * The ConnectMower component presents a dialog to the user, allowing them to connect to a mower by entering a PIN number.
   * @name ConnectMower
   * @returns The component renders the ConnectMowerPopup dialog. It passes down the visibility state, the close function,
   *  and the connect function as props.
   */
  const ConnectMower = () => {
    /**
     *  This function is invoked when the connect button is clicked. It checks the length of the entered PIN.
     * If the length of the PIN is less than 6 characters, the function returns without
     * executing any further logic. However, if the PIN's length is correct, it sends a message to the connection to
     *  connect to the mower. After attempting to connect, it hides the ConnectMowerPopup.
     * @name ConnectBtnClicked
     * @param {object} props - An object containing the property pin, which is the entered PIN by the user.
     */
    const ConnectBtnClicked = (props) => {
      const { pin } = props;
      if (pin.length < 6) {
        alert(t("alerts.please"), "error"); // you can replace alert with a more user-friendly message mechanism
        return; //todo properly validate this
      } else {
        //send the pin as 0 so that the user can connect to the mower using just the pin #
        try {
          connection.SocketMessage(SocketMessageType.ConnectToMower, {
            id: 0,
            pin: pin,
          });
        } catch (ex) {
          console.error(ex);
        }
        setState({
          ...state,
          showConnectMowerPU: false,
        });
      }
    };

    /**
     *  This function sets the showConnectMowerPU state to false, essentially hiding the popup.
     * @name onClosePopup
     */
    const onClosePopup = () => {
      setState({
        ...state,
        showConnectMowerPU: false,
      });
    };

    return (
      <ConnectMowerPopup
        visible={state.showConnectMowerPU}
        closePopup={onClosePopup}
        ConnectBtnClicked={ConnectBtnClicked}
      />
    );
  };

  return (
    <Row>
      <Col>
        <center>
          <div className="pageheader">
            {state.showConnectMowerPU && <ConnectMower />}
            <Row style={{ margin: "10px" }}>
              <Col>
                <Link
                  onClick={() => {
                    setState({
                      ...state,
                      showConnectMowerPU: true,
                    });
                  }}
                  className="btn amr-btn-primary"
                >
                  {t("mowerConnect.connect")}
                </Link>
              </Col>
              <Col>
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    NextBtnClicked();
                  }}
                  className="btn amr-btn-primary"
                >
                  <ArrowRightAltIcon />
                </Link>
              </Col>
              <Divider style={{ marginTop: "10px" }} />
            </Row>
          </div>
          <Row>
            <center>
              <Mowers />
            </center>
          </Row>
        </center>
      </Col>
    </Row>
  );
};

export default withRouter(MowerConnectPage);
