import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "../components/withRouter";
import {
  MowingPlan,
  MowingPlanJsonProperties,
  Position,
} from "../components/models/mowingPlan";
import AuthService from "../components/services/AuthService";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Select,
  Slider,
  TextField,
  Typography,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import { Row, Col } from "react-bootstrap";
import Constants from "../constants";
import { grey, red } from "@mui/material/colors";
import MapComponent from "../components/map/mapComponent";
import { MowerPlanType } from "./mowPage";
import StripeAngleEditor from "../components/pages/planPage/StripeAngleEditor";
import GoogleApiHOC from "../components/map/GoogleApiHOC";
import CustomModal from "../components/customModal/CustomModal";
import { useToast } from "../components/customToast/ToastContext";
import { useTranslation } from "react-i18next";

const PlanPage = (props) => {
  const { router } = props;
  const { t, i18n } = useTranslation();
  const [planID, setPlanID] = useState(null);
  const [planSelected, setPlanSelected] = useState(false);
  const plan_ref = useRef(null);
  const properties_ref = useRef(null);
  const [newProps, setNewProps] = useState(null);
  const [screenSize, setScreenSize] = useState({
    ScreenHeight: 0,
    ScreenWidth: 0,
    MapHeight: 0,
    ToolbarHeight: 0,
  });
  const [state, setState] = useState({
    showRenamePlanPU: false,
    showEditPlanPropsPU: false,
    showAngleEditorPU: false,
    planName: "",
    stripeangle: 0,
    nameChangesMade: false,
    propChangesMade: false,
    cutPattern: 0,
  });
  const CenteredRef = useRef(false);

  useEffect(() => {
    //verify user is logged in
    let auth = new AuthService();
    auth.enforceLogin();

    let screenHeight = document.documentElement.clientHeight;
    let screenWidth = document.documentElement.clientWidth;
    var mapHeight = Math.round((screenHeight * 0.75).toString()) + "px";
    var toolbarHeight = Math.round((screenHeight * 0.25).toString()) + "px";
    screenHeight = screenHeight.toString() + "px";
    screenWidth = Math.round(screenWidth).toString() + "px";
    setScreenSize({
      ScreenHeight: screenHeight,
      ScreenWidth: screenWidth,
      MapHeight: mapHeight,
      ToolbarHeight: toolbarHeight,
    });

    PageSetUp();
  }, []);

  /**
   * The PageSetUp function acts as an asynchronous callback that initializes certain data based on the
   * presence of an id in the router.params. This function appears to be tailored for a web page where specific
   * mowing plans are fetched and displayed based on the provided ID.
   * @name PageSetUp
   */
  const PageSetUp = useCallback(async () => {
    var id = router && router.params ? router.params.id : undefined;
    //first, determine if a plan was sent to the page
    if (id) {
      setPlanID(id);
      setPlanSelected(true);
      if (CenteredRef) {
        CenteredRef.current = true;
      }
    } else {
      setPlanSelected(false);
    }

    //Now get the plan if we need to
    let mowplan = [];
    try {
      if (id !== null && id !== undefined) {
        mowplan = await GetCompanyMowingPlan(id);
      }
    } catch (error) {
      console.error("Error fetching the mowing plan:", error);
    }
    if (mowplan.length > 0) {
      let name = "";
      if (mowplan[0].PlanName !== null) {
        name = mowplan[0].PlanName;
      } else {
        name = "#" + mowplan[0].Id;
      }

      try {
        var tempProperties = JSON.parse(mowplan[0].Properties);
      } catch (error) {
        console.error("Error parsing mowing plan properties:", error);
      }
      try {
        var properties = convertToJSONProperties(tempProperties);
      } catch (error) {
        console.error("Error converting to JSON properties:", error);
      }

      if (properties_ref) {
        properties_ref.current = properties;
      }

      if (plan_ref) {
        plan_ref.current = mowplan[0];
      }

      setState({
        ...state,
        stripeangle: properties.stripeangle,
        planName: name,
      });
    }
  }, []);

  /**
   * This function fetches mowing plan data for a specific company from a backend server via a RESTful API.
   * The function establishes a connection with the server using a GET request and receives the mowing plan details.
   * The function then processes the received data and transforms it into an array of MowingPlan objects.
   * @name GetCompanyMowingPlan
   * @param {String} planID - The unique ID of the plan to retrieve.
   * @returns The function returns an array of MowingPlan objects.
   */
  const GetCompanyMowingPlan = async (planID) => {
    //ensure that a valid Id is passed to the function.
    if (!planID) {
      console.error("Item identifier (planID) missing or invalid.");
      return false;
    }
    //MowingPlans: Initially an empty array, it will hold the fetched plans from the API.
    var MowingPlans = [];
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI =
      encodeURI(baseAddress) + "/api/mowingplans/get/" + planID.toString();
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    //Fetch the mowing plans from the backend API using the constructed request URI and appropriate headers.
    try {
      await fetch(requestURI, {
        method: "GET",
        headers: Headers,
      })
        .then((response) => {
          //Check for a successful response.
          //If the response is not successful, an error is thrown with the status code.
          if (!response.ok) {
            throw new Error(
              "There was an error connecting to the plan database. HTTP Status: " +
                response.status
            );
          }
          return response.json();
        })
        .then((data) => {
          //Parse the fetched data as JSON.
          if (data === null) {
            alert(t("alerts.errorPlan"), "error");
            return;
          }
          MowingPlans = data;
        });
    } catch (ex) {
      console.error(ex);
    }

    //Convert the list of plans into instances of the MowingPlan class.
    //newPlans: Array that will store the converted plans as instances of MowingPlan.
    let newPlans = MowingPlans.map((plan) => {
      return new MowingPlan(plan);
    });

    //Return the array of converted plans.
    return newPlans;
  };

  /**
   * This function renames a mowing plan on a backend server through a RESTful API.
   *  The function establishes a connection with the server using a POST request,
   *  sending the new plan name, and expects a positive response if the rename operation was successful.
   * @name RenameMowingPlan
   * @param {String} planid - The unique ID of the plan to rename.
   * @param {String} newPlanName - The new name that will be given to the specified plan.
   * @returns The function returns a boolean value (true if the renaming was successful and false otherwise).
   */
  const RenameMowingPlan = async (planid, newPlanName) => {
    //ensure that valid pqrams are passed to the function.
    if (!planid || !newPlanName) {
      throw new Error("Invalid identifier (planid or new plan name)");
    }
    //ok: Boolean that determines whether the request was successful or not
    let ok = false;
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI =
      encodeURI(baseAddress) + "/api/mowingplans/rename/" + planid.toString();
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //content: the new plan name to be applied to the selected name, sent over as a JSON obj
    let content;
    try {
      content = JSON.stringify(newPlanName);
    } catch (err) {
      console.error("Error serializing property JSON:", err);
      return false;
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    //Rename the selected mowing plan in the backend API using the constructed request URI and appropriate headers
    try {
      await fetch(requestURI, {
        method: "POST",
        headers: Headers,
        body: content,
      }).then((response) => {
        //Check for a successful response.
        //If the response is not successful, an error is thrown with the status code.
        if (!response.ok) {
          throw new Error(
            `Failed to rename item with Id: ${planid}. HTTP Status: ${response.status}`
          );
        }
        return (ok = response.ok);
      });
    } catch (ex) {
      console.error(`Error while trying to delete item with Id: ${planid}`, ex);
    }
    //Return whether the response was succcessful or not
    return ok;
  };

  /**
   * This function updates the properties of a specified mowing plan on the
   * backend server via a RESTful API. The function sends a POST request with the
   * updated properties and expects a positive response if the update operation was successful.
   * @name UpdateMowingPlanProperties
   * @param {String} planid - The unique ID of the plan whose properties need to be updated.
   * @param {Object} propertyjson - The new properties of the specified plan represented in JSON format.
   */
  const UpdateMowingPlanProperties = async (planid, propertyjson) => {
    //ensure that a valid Id is passed to the function.
    if (!planid || !propertyjson) {
      console.error(
        "Item identifier (planid, propertyjson) missing or invalid."
      );
      return false;
    }
    //ok: Boolean that determines whether the request was successful or not
    let ok = false;
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI =
      encodeURI(baseAddress) +
      "/api/mowingplans/" +
      planid.toString() +
      "/properties";
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //content: properties in JSON string form to be sent to the API
    let content;
    try {
      content = JSON.stringify(propertyjson);
    } catch (err) {
      console.error("Error serializing property JSON:", err);
      return false;
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };

    //handle potential parsing issues.
    try {
      await fetch(requestURI, {
        method: "POST",
        headers: Headers,
        body: content,
      }).then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to update item with Id: ${planid}. HTTP Status: ${response.status}`
          );
        }
        return (ok = response.ok);
      });
    } catch (ex) {
      console.error(`Failed to update item with Id: ${planid}.`);
    }

    //Return whether the response was succcessful or not
    return ok;
  };

  /**
   * The function convertToJSONProperties is designed to convert the given properties
   * (likely in a custom data structure or format) into an instance of a standardized JSON-friendly
   *  object, specifically an instance of the MowingPlanJsonProperties class.
   * @name convertToJSONProperties
   * @param {Object} properties - The properties of a mowing plan that are in a non-JSON format.
   * These properties include fields such as percentspeed, overlapwidth, returntostart, etc.
   *@returns {Object} newproperties -  An instance of the MowingPlanJsonProperties class containing the standardized properties.
   */
  const convertToJSONProperties = (properties) => {
    if (!properties) {
      return new MowingPlanJsonProperties();
    }
    let newproperties = new MowingPlanJsonProperties({
      percentspeed: properties.percentspeed,
      overlapwidth: properties.overlapwidth,
      returntostart: properties.returntostart,
      cutpattern: properties.cutpattern,
      stripeangle: properties.stripeangle,
      enablepto: properties.enablepto,
      remowperimeter: properties.remowperimeter,
      sleepwhencomplete: properties.sleepwhencomplete,
      perimetermode: properties.perimetermode,
    });

    return newproperties;
  };

  /**
   * The StripeAngleEditorComponent is a React functional component that renders a dialog box,
   *  allowing users to edit the stripe angle of a mowing plan. The component interacts with an embedded
   *  StripeAngleEditor component to achieve its functionality. It has handlers for apply, cancel, and close actions.
   * @name StripeAngleEditorComponent
   */
  const StripeAngleEditorComponent = () => {
    /**
     * Triggered when the user decides to apply the edited angle.
     * Rounds the provided angle to the nearest integer.
     * Updates the properties object with the new angle and hides the popup.
     * @name AngleApplyButtonClicked
     */
    const AngleApplyButtonClicked = (angle) => {
      try {
        var props;
        if (!newProps || newProps === null) {
          props = new MowingPlanJsonProperties();
        } else {
          props = newProps;
        }
        //round to ten
        var value = Math.round(angle);
        props.stripeangle = value;
        setNewProps(props);
        setState({ ...state, showAngleEditorPU: false });
      } catch (error) {
        throw new Error("There was an error applying the stripe angle", error);
      }
    };

    /**
     * Triggered when the user decides to cancel the angle editing.
     * Resets the stripe angle to its original value.
     * Hides the popup.
     * @name AngleCancelButtonClicked
     */
    const AngleCancelButtonClicked = (originalAngle) => {
      try {
        var props;
        if (newProps !== null) {
          props = newProps;
        } else {
          if (properties_ref.current) {
            props = properties_ref.current;
          }
        }

        if (originalAngle !== null) {
          props.stripeangle = originalAngle;
        }

        setState({ ...state, showAngleEditorPU: false });
      } catch (error) {
        throw new Error("Encountered an error", error);
      }
    };

    /**
     * A utility function to close the stripe angle editor popup.
     * @name closePopup
     */
    const closePopup = () => {
      setState({ ...state, showAngleEditorPU: false });
    };

    return (
      <div>
        <StripeAngleEditor
          visible={state.showAngleEditorPU}
          closePopup={closePopup}
          initPlan={plan_ref.current}
          newProps={newProps}
          currentProps={properties_ref.current}
          AngleApplyButtonClicked={AngleApplyButtonClicked}
          AngleCancelButtonClicked={AngleCancelButtonClicked}
        />
      </div>
    );
  };

  /**
   * The SelectPlanBar component is a React functional component designed as a UI row-based element.
   * It displays information about the selected mowing plan. When no plan is selected, it prompts the user
   * to "Select a Plan." Additionally, the component provides a "Select Plan" button which navigates users to
   * the ManagePlans page for further plan selections.
   * @name SelectPlanBar
   */
  const SelectPlanBar = () => {
    return (
      <Row style={{ alignItems: "center" }}>
        <Col
          style={{
            textAlign: "center",

            marginLeft: "10px",
            marginTop: "10px",
          }}
        >
          <Typography>
            {plan_ref.current !== null ? (
              <span>{plan_ref.current.HumanReadableIdentifier()}</span>
            ) : (
              <span>{t("plan.selectAPlan")}</span>
            )}
          </Typography>
        </Col>
        <Col>
          <Button
            onClick={() => {
              try {
                router.navigate("/ManagePlans");
              } catch (error) {
                // console.dir("Failed to navigate to Plan Manage page", error);
              }
            }}
            className="btn amr-btn-primary"
            style={{
              textTransform: "capitalize",
              marginLeft: "20px",
              marginTop: "10px",
              width: "fit-content",
            }}
          >
            {t("plan.selectPlan")}
          </Button>
        </Col>
      </Row>
    );
  };

  /**
   * The RenamePlanPopUp component is a React functional component that presents a dialog box (or modal)
   *  allowing users to rename a selected mowing plan.
   * The dialog provides an input field to enter a new name for the plan and two buttons: "OK"
   * for confirming the changes and "Cancel" to close the dialog without saving changes.
   * @name RenamePlanPopUp
   */
  const RenamePlanPopUp = () => {
    const [planName, setPlanName] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);

    return (
      <div>
        <CustomModal
          isVisible={isModalVisible}
          message="Plan name cannot be empty"
          onConfirm={() => {
            setIsModalVisible(false);
          }}
        />
        <Dialog
          open={state.showRenamePlanPU}
          onClose={() => {
            setState({ ...state, showRenamePlanPU: false });
          }}
        >
          <DialogTitle>
            <center>{t("plan.renamePlan")}</center>
          </DialogTitle>
          <DialogContent>
            <center>
              <TextField
                label={t("plan.newName")}
                variant="standard"
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
              />
            </center>
            <center>
              <Button
                className="btn amr-btn-primary"
                style={{
                  margin: "5px",
                  marginTop: "10px",
                  textTransform: "capitalize",
                  width: "fit-content",
                }}
                onClick={() => {
                  if (planName.length < 1) {
                    setIsModalVisible(true);
                    return;
                  } else {
                    setState({
                      ...state,
                      planName: planName,
                      showRenamePlanPU: false,
                      nameChangesMade: true,
                    });
                  }
                }}
              >
                {t("plan.ok")}
              </Button>
              <Button
                className="btn amr-btn-primary"
                style={{
                  margin: "5px",
                  marginTop: "10px",
                  textTransform: "capitalize",
                  width: "fit-content",
                }}
                onClick={() => {
                  setState({ ...state, showRenamePlanPU: false });
                }}
              >
                {t("plan.cancel")}
              </Button>
            </center>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  /**
   *The PlanPropertiesPopUp is a modal dialog box that provides an interface for users 
   to modify and customize different attributes of a selected mowing plan.
   * @name PlanPropertiesPopUp
   * @returns The component renders a Dialog component which contains various controls for editing the properties of the mowing plan.
   */
  const PlanPropertiesPopUp = () => {
    //planSpeed and planSpeedText: Reflect the speed of the mowing plan.
    const [planSpeed, setPlanSpeed] = useState(0);
    const [planSpeedText, setPlanSpeedText] = useState("0%");
    //planSpeedError: Indicates if there is an error related to the speed input.
    const [planSpeedError, setPlanSpeedError] = useState(false);
    //overlapDistance: The overlap distance for mowing.
    const [overlapDistance, setOverlapDistance] = useState("");
    //overlapError: Indicates if there is an error related to the overlap input.
    const [overlapError, setOverlapError] = useState(false);
    //cutPatternInput, stripeAngle, perimeterMode, returnToStart, remowPerim, enablePTO, sleepWhenFinished: Various attributes of the mowing plan.
    const [cutPatternInput, setCutPatternInput] = useState(0);
    const [stripeAngle, setStripeAngle] = useState("0");
    const [perimeterMode, setPerimeterMode] = useState(0);
    const [returnToStart, setReturntoStart] = useState(true);
    const [remowPerim, setRemowPerimeter] = useState(false);
    const [enablePTO, setEnablePTO] = useState(true);
    const [sleepWhenFinished, setSleepWhenFinished] = useState(false);

    //Declare constants for indexes
    const CutPattern = [t("plan.stripe"), t("plan.cross"), t("plan.spiral")];

    const PerimeterMode = [
      t("plan.perimeterFirst"), //Mow the perimeter before striping
      t("plan.perimeterLast"), //Mow the perimeter after striping
      t("plan.skip"), //Don't mow the perimeter at all
    ];

    useEffect(() => {
      if (newProps === null) {
        setProperties(properties_ref.current);
      } else {
        setProperties(newProps);
      }
    }, []);

    /**
     * Sets the properties of the mowing plan based on provided data.
     * @name setProperties
     */
    const setProperties = (data) => {
      if (!data) {
        // Handle the case when the data is null or undefined
        console.error("Data is not provided.");
        return;
      }
      setSpeedInfo(data.percentspeed / 5);
      setOverlapDistance(data.overlapwidth);
      setCutPatternInput(data.cutpattern);
      setStripeAngle(Math.round(data.stripeangle));
      setEnablePTO(data.enablepto);
      setReturntoStart(data.returntostart);
      setRemowPerimeter(data.remowperimeter);
      setSleepWhenFinished(data.sleepwhencomplete);
      setPerimeterMode(data.perimetermode);
    };

    /**
     *Handles the slider's change event to update the plan's speed.
     * @name handlePlanSpeedSliderChange
     */
    const handlePlanSpeedSliderChange = (event, num) => {
      setSpeedInfo(num);
    };

    /**
     *Sets the speed information text.
     * @name setSpeedInfo
     */
    const setSpeedInfo = (num) => {
      let val = Math.round(num) * 5.0;
      let val2 = 6 * (val / 100);
      val2 = Math.ceil(val2 * 100) / 100;

      let text = val.toString() + "% ";

      setPlanSpeed(num);
      setPlanSpeedText(text);
    };

    /**
     *Validates and saves the edited properties.
     * @name saveButtonClicked
     */
    const saveButtonClicked = () => {
      //validate inputted props
      if (planSpeed < 4) {
        setPlanSpeedError(true);
      } else if (Number(overlapDistance) < 0 || Number(overlapDistance) > 50) {
        setOverlapError(true);
      } else {
        let newprops = new MowingPlanJsonProperties({
          percentspeed: Number(planSpeed * 5),
          overlapwidth: Number(overlapDistance),
          cutpattern: cutPatternInput,
          returntostart: returnToStart,
          stripeangle: stripeAngle,
          enablepto: enablePTO,
          remowperimeter: remowPerim,
          sleepwhencomplete: sleepWhenFinished,
          perimetermode: perimeterMode,
        });

        plan_ref.current.Properties = JSON.stringify(newprops);
        setNewProps(newprops);
        setState({
          ...state,
          propChangesMade: true,
          stripeAngle: stripeAngle,
          showEditPlanPropsPU: false,
        });
      }
    };

    /**
     *Handles the checkbox validation.
     * @name handleCheckBoxValidate
     */
    const handleCheckBoxValidate = (e) => {
      let val = e.target.checked;
      if (perimeterMode === 2) {
        setRemowPerimeter(false);
      } else {
        setRemowPerimeter(val);
      }
    };

    /**
     * Handles the dropdown validation for perimeter mode.
     * @name handleDropDownValidate
     */
    const handleDropDownValidate = (e) => {
      let val = e.target.value;
      if (val === 2) {
        setRemowPerimeter(false);
      }
      setPerimeterMode(val);
    };

    /**
     *Allows editing of the properties.
     * @name editButtonClicked
     */
    const editButtonClicked = () => {
      let newprops = new MowingPlanJsonProperties({
        percentspeed: Number(planSpeed * 5),
        overlapwidth: Number(overlapDistance),
        cutpattern: cutPatternInput,
        returntostart: returnToStart,
        stripeangle: stripeAngle,
        enablepto: enablePTO,
        remowperimeter: remowPerim,
        sleepwhencomplete: sleepWhenFinished,
        perimetermode: perimeterMode,
      });

      setNewProps(newprops);
    };
    return (
      <Dialog
        open={state.showEditPlanPropsPU}
        onClose={() => {
          setNewProps(null);
          setState({ ...state, showEditPlanPropsPU: false });
        }}
      >
        <DialogTitle style={{ minWidth: "300px" }}>
          <center>{t("plan.editPlan")}</center>
          <center>
            <Typography>
              {plan_ref.current.HumanReadableIdentifier()}
            </Typography>
          </center>
        </DialogTitle>
        <DialogContent style={{ minWidth: "300px" }}>
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.speed")} </span>
              </Typography>
              <Typography>
                <span>{planSpeedText}</span>
              </Typography>
            </Col>
            <Col>
              <Slider
                sx={{ color: red[700] }}
                value={planSpeed}
                min={0}
                max={20}
                step={1}
                valueLabelDisplay="off"
                onChange={handlePlanSpeedSliderChange}
              ></Slider>
              {planSpeedError && (
                <center>
                  <Typography style={{ fontSize: "12px", color: red[700] }}>
                    <span>{t("plan.less")}</span>
                  </Typography>
                </center>
              )}
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.overlap")}</span>
              </Typography>
            </Col>
            <Col>
              <TextField
                error={overlapError}
                helperText={
                  overlapError ? "Must be more than 0 and less than 50" : ""
                }
                required
                id="standard-required"
                label="(in)"
                type="number"
                min={5.0}
                max={50.0}
                value={overlapDistance}
                onChange={(e) => setOverlapDistance(e.currentTarget.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              ></TextField>
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.cutPattern")}</span>
              </Typography>
            </Col>
            <Col>
              <Select
                onChange={(e) => setCutPatternInput(e.target.value)}
                value={cutPatternInput}
                size="small"
                style={{ width: "146px" }}
              >
                {CutPattern.map((val, i) => {
                  return (
                    <MenuItem value={i} key={i}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>
                  {t("plan.stripeAngle")} {stripeAngle}°
                </span>
              </Typography>
            </Col>
            <Col>
              <Button
                className="btn amr-btn-primary"
                style={{
                  width: "146px",
                  textTransform: "capitalize",
                }}
                disabled={cutPatternInput === 2}
                onClick={(e) => {
                  editButtonClicked();
                  setState({
                    ...state,
                    showAngleEditorPU: true,
                    cutPattern: cutPatternInput,
                  });
                }}
              >
                {t("plan.edit")}
              </Button>
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.perimeterMode")} </span>
              </Typography>
            </Col>
            <Col>
              <Select
                onChange={(e) => handleDropDownValidate(e)}
                value={perimeterMode}
                size="small"
                style={{ width: "146px", fontSize: "14px" }}
              >
                {PerimeterMode.map((val, i) => {
                  return (
                    <MenuItem value={i} key={i}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.return")}</span>
              </Typography>
            </Col>
            <Col>
              <Checkbox
                checked={returnToStart}
                onChange={(e) => setReturntoStart(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: returnToStart ? "red" : "inherit",
                  },
                }}
              />
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.remow")}</span>
              </Typography>
            </Col>
            <Col>
              <Checkbox
                checked={remowPerim}
                onChange={(e) => handleCheckBoxValidate(e)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: remowPerim ? "red" : "inherit",
                  },
                }}
              />
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.enablePTO")}</span>
              </Typography>
            </Col>
            <Col>
              <Checkbox
                checked={enablePTO}
                onChange={(e) => setEnablePTO(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: enablePTO ? "red" : "inherit",
                  },
                }}
              />
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Typography>
                <span>{t("plan.sleep")}</span>
              </Typography>
            </Col>
            <Col>
              <Checkbox
                checked={sleepWhenFinished}
                onChange={(e) => setSleepWhenFinished(e.target.checked)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": {
                    color: sleepWhenFinished ? "red" : "inherit",
                  },
                }}
              />
            </Col>
          </Row>
          <Divider style={{ margin: "10px" }} />
          <Row style={{ alignItems: "center" }}>
            <Col>
              <center>
                <Button
                  className="btn amr-btn-primary"
                  style={{
                    minWidth: "fit-content",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    setNewProps(null);
                    setState({
                      ...state,
                      propChangesMade: false,
                      showEditPlanPropsPU: false,
                    });
                  }}
                >
                  {t("plan.cancel")}
                </Button>
              </center>
            </Col>
            <Col>
              <center>
                <Button
                  className="btn amr-btn-primary"
                  style={{
                    minWidth: "fit-content",
                    textTransform: "capitalize",
                  }}
                  onClick={() => {
                    saveButtonClicked();
                  }}
                >
                  {t("plan.apply")}
                </Button>
              </center>
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginTop: "5px" }}>
            <Typography style={{ color: grey[600], fontSize: "12px" }}>
              <span>{t("plan.noteChanges")}</span>
            </Typography>
          </Row>
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * The ButtonToolBar component is a toolbar UI component that provides various buttons to perform actions related
   * to a selected mowing plan. The toolbar contains buttons for editing plan properties, renaming the plan, saving
   * changes made to the plan, and sending the current plan to another page.
   * @name ButtonToolBar
   */
  const ButtonToolBar = () => {
    /**
     * Checks and sends any changes made to the plan's name and properties to a database.
     * Provides an alert message on successful updates or errors.
     * @name handleSaveChangesClicked
     */
    const handleSaveChangesClicked = async () => {
      let ok = true;

      //first rename the plan if necessary
      if (state.nameChangesMade) {
        try {
          ok = true;
          await RenameMowingPlan(planID, state.planName);
        } catch (ex) {
          ok = false;
          throw new Error(
            `There was an error renaming the plan with the id ${planID}` + ex
          );
        }
      }

      //Now change the plan properties if necessary
      if (state.propChangesMade) {
        //send props to db
        try {
          ok = true;
          await UpdateMowingPlanProperties(planID, newProps);
        } catch (ex) {
          ok = false;
          throw new Error(
            `There was an error updating the properties of the plan with the id ${planID}` +
              ex
          );
        }
      }

      if (ok) {
        alert(t("alerts.successFully"), "success");
      } else {
        alert(
          t("alerts.errorRequest"),
          "error"
        );
      }
    };

    /**
     * Sends the current plan to the Mow Page by saving it to the session storage and navigating to the Mow Page.
     * @name handleSendToMowClicked
     */
    const handleSendToMowClicked = () => {
      if (plan_ref.current !== null && plan_ref.current !== undefined) {
        var plan = plan_ref.current;
        try {
          sessionStorage.setItem("SelectedPlan", JSON.stringify(plan));
          router.navigate("/Mow");
        } catch (error) {
          throw new Error(
            "Unexpected issue when trying to navigate to the Mow Page"
          );
        }
      }
    };

    return (
      <div>
        <Row
          style={{
            alignItems: "center",
            marginLeft: "10px",
          }}
        >
          <Col>
            <Row>
              <Button
                className="btn amr-btn-primary"
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() => {
                  setState({ ...state, showEditPlanPropsPU: true });
                }}
              >
                {t("plan.planProperties")}
              </Button>
            </Row>
            <Row>
              <Button
                className="btn amr-btn-primary"
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  textAlign: "center",
                }}
                onClick={handleSendToMowClicked}
              >
                {t("plan.sendMower")}
              </Button>
            </Row>
          </Col>
          <Col
            style={{
              alignItems: "center",
              marginLeft: "10px",
            }}
          >
            <Row>
              <Button
                className="btn amr-btn-primary"
                style={{
                  width: "100%",
                  textTransform: "capitalize",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() =>
                  setState({
                    ...state,
                    showRenamePlanPU: true,
                  })
                }
              >
                {t("plan.renamePlan")}
              </Button>
            </Row>
            <Row>
              <Button
                className="btn amr-btn-primary"
                style={{ width: "100%", textTransform: "capitalize" }}
                disabled={!(state.propChangesMade || state.nameChangesMade)}
                onClick={() => {
                  handleSaveChangesClicked();
                }}
              >
                {t("plan.saveChanges")}
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  /**
   * The MapObject component renders a map, displaying various objects, by utilizing the MapComponent component.
   * @name MapObject
   * @param height - Height of the map. It is mandatory.
   * @param width - Width of the map. It is mandatory.
   * @param plan - The mowing plan data which will be displayed on the map. This is optional.
   * If not provided, the component will default to using the user's current position to center the map.
   * @returns MapContainer - the map container
   */
  const MapObject = ({ height, width, plan }) => {
    //userPosition: Holds the current geolocation of the user. Defaults to null.
    const [userPosition, setUserPosition] = useState(null);
    //loading: A boolean indicating if the map is in the loading state. Defaults to true.
    const [loading, setLoading] = useState(true);
    //centered: A boolean determining whether the map should be centered. It's initially set based on CenteredRef.current.
    const [centered, setCentered] = useState(CenteredRef.current);
    //onMapDrag: Sets the map's centered state to false when the map is dragged.
    const onMapDrag = () => {
      setCentered(false);
    };
    //onPinSingleTap: An empty function to handle a tap on a map marker.
    const onPinSingleTap = () => {};
    //onPolyMouseDown: An empty function to handle the mouse-down event on a polygon.
    const onPolyMouseDown = () => {};
    //onPolyMouseUp: An empty function to handle the mouse-up event on a polygon.
    const onPolyMouseUp = () => {};
    //onZoomChanged: Sets the map's centered state to false when the zoom level of the map changes.
    const onZoomChanged = () => {
      setCentered(false);
    };

    /**
     *  A function that returns a Promise to fetch the user's current geolocation.
     *  If successful, it resolves with the position. If the browser does not support geolocation or there's an error, the promise is rejected.
     * @name GetUserPosition
     */
    const GetUserPosition = useCallback(() => {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // if geolocation is available, set the center of the map to the user location
              const { latitude: lat, longitude: lng } = position.coords;
              resolve(new Position(lat, lng));
            },
            (error) => {
              reject(error);
            },
            {
              timeout: 10000, //10 second timeout
            }
          );
        } else {
          // if geolocation isn't available, reject the promise
          reject(new Error("Geolocation is not supported by this browser"));
        }
      });
    }, []);

    useEffect(() => {
      //If no userPosition or plan is provided, it fetches the user's geolocation using GetUserPosition and updates the userPosition state.
      if (userPosition === null && plan === null) {
        GetUserPosition()
          .then((position) => {
            setUserPosition(position);
            setLoading(false);
          })
          .catch((error) => {
            //If there's an error fetching the user's geolocation, it logs the error and sets the userPosition state to null.
            console.error(error);
            setUserPosition(null);
          });
      } else {
        //Once the geolocation is obtained or if a plan is provided, it sets the loading state to false.
        setLoading(false);
      }
    }, [userPosition, GetUserPosition]);

    //A loading spinner (CircularProgress) is displayed when the map is in the loading state.
    //Once loading is complete, the map (MapComponent) is rendered.
    return (
      <div>
        {loading && (
          <div style={{ margin: "50px", alignItems: "center" }}>
            <CircularProgress size={60} />
            <Typography>
              <span>Fetching your Location...</span>
            </Typography>
          </div>
        )}
        {!loading && (
          <MapComponent
            google={props.google}
            height={height}
            width={width}
            mowers={[]}
            onMapDrag={onMapDrag}
            onPinSingleTap={onPinSingleTap}
            onPolyMouseDown={onPolyMouseDown}
            onPolyMouseUp={onPolyMouseUp}
            onZoomChanged={onZoomChanged}
            selectedMower={null}
            isTracking={null}
            homePosition={null}
            currentPlan={null}
            recordingPlan={null}
            planType={MowerPlanType.PlanPreview}
            plan={plan}
            userPosition={
              userPosition === null
                ? new Position(44.513332, -88.015831)
                : userPosition
            }
            containerId={"map1"}
            centered={centered}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      {state.showEditPlanPropsPU && <PlanPropertiesPopUp />}
      {state.showRenamePlanPU && <RenamePlanPopUp />}
      {state.showAngleEditorPU && <StripeAngleEditorComponent />}
      <Row style={{ height: screenSize.ScreenHeight, padding: "0px" }}>
        <Row
          style={{
            height: screenSize.MapHeight,
            padding: "0px",
            margin: "0px",
          }}
        >
          <Col style={{ padding: "0px" }}>
            <center>
              <MapObject
                height={screenSize.MapHeight}
                width={screenSize.ScreenWidth}
                plan={plan_ref.current}
              />
            </center>
          </Col>
        </Row>

        <Row style={{ height: screenSize.ToolbarHeight, padding: "0px" }}>
          <Col>
            <SelectPlanBar style={{ marginTop: "10px", padding: "0px" }} />

            {planSelected && <ButtonToolBar />}
          </Col>
        </Row>
      </Row>
    </div>
  );
};

export default GoogleApiHOC(withRouter(PlanPage));
