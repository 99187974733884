import { CircularProgress } from "@material-ui/core";
import { ListGroup, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

export const MowerItems = (props) => {
  const { DisconnectBtnClicked, mowerlist } = props;
  if (!mowerlist || !(mowerlist instanceof Map)) {
    return <p>Error: Invalid mower list provided.</p>;
  }
  const { t, i18n } = useTranslation();

  return (
    <div>
      <ListGroup id="mower-list" className=" ms-2 me-2">
        {[...mowerlist.entries()].map(([key, mower]) => {
          return (
            <ListGroup.Item key={key}>
              <Row className="mower-list-item-row">
                <Col className="mower-list-item-col-1">
                  <img
                    alt=""
                    className="mower-list-item-image"
                    src={mower.mowersPageImage}
                  ></img>
                </Col>
                <Col className="mower-list-item-col-2">
                  <Row>
                    <div>
                      <p className="mower-list-item-label">
                        {t("machine.mower")} {mower.MachineSerialNumber}
                      </p>
                      <p className="mower-list-item-label-2">
                        {mower.distanceString}
                      </p>
                    </div>
                  </Row>
                </Col>
                <Col className="mower-list-item-col-3">
                  <Link
                    onClick={(e) =>
                      DisconnectBtnClicked(mower.MachineSerialNumber)
                    }
                    className="btn amr-btn-secondary"
                  >
                    {t("machine.disconnect")}
                  </Link>
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};

export const NoMowers = () => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <center>
        <h2 className="mt-5 w-100">{t("mowerConnect.noMower")}</h2>
        <p style={{ fontSize: 17, marginBottom: "10px" }}>
          {t("mowerConnect.tapConnect")}
        </p>
        <p style={{ fontSize: 15, marginTop: 0 }}>
          {t("mowerConnect.incorrect")}
        </p>
      </center>
    </div>
  );
};

export const LoadingScreen = () => {
  return <CircularProgress style={{ marginTop: "30px" }}></CircularProgress>;
};
