import { SocketMessageType } from "../../models/enums";
import { Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const MowerButtons = (props) => {
  const { connection, mower, router } = props;
  const { t, i18n } = useTranslation();

  const ConfigureButtonClicked = (mower, e) => {
    e.preventDefault(); // Prevent the default link behavior
    try {
      sessionStorage.setItem("configureMower", JSON.stringify(mower));
      router.navigate("/MowerSettings/" + mower.MachineSerialNumber);
    } catch (error) {
      alert(t("alerts.failed"), "error");
    }
  };

  const DisconnectBtnClicked = (mower) => {
    if (mower.connected) {
      connection.SocketMessage(SocketMessageType.DisconnectFromMower, {
        serial_number: mower.MachineSerialNumber,
      });
    } else {
      alert(t("alerts.mowerConnect"), "info");
    }
  };

  return (
    <div>
      {mower.connected && (
        <Row>
          <Link
            onClick={(e) => DisconnectBtnClicked(mower)}
            className="btn amr-btn-secondary"
          >
            {t("mowerConnect.disconnect")}
          </Link>
        </Row>
      )}
      {mower.otherUserConnected && (
        <Row>
          <Typography className="text-center"> In Use</Typography>
        </Row>
      )}
      {(mower.connected || (mower.canConfigure && mower.online)) && (
        <Row>
          <Link
            onClick={(e) => ConfigureButtonClicked(mower, e)}
            className="btn amr-btn-secondary mt-1"
          >
            {t("mowerConnect.configure")}
          </Link>
        </Row>
      )}
    </div>
  );
};
