import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem, IconButton, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useTranslation } from "react-i18next";

export const SortByDropDown = (props) => {
  const {
    SearchValue,
    selected_mower,
    handleSearchButtonClick,
    sortPlans,
    setSortMethod,
    Dimensions,
  } = props;
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [option, setOption] = useState(0);
  const [searchvalue, setsearchvalue] = useState("");
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const options = [
    t("selectPlan.location"),
    t("selectPlan.planName"),
    t("selectPlan.dateNewest"),
    t("selectPlan.dateOldest"),
    t("selectPlan.recentlyMowed"),
  ];
  const handleDropDownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSearchChange = (event) => {
    const val = event.currentTarget.value;
    setsearchvalue(val);
  };

  const handleDropDownClose = async (event) => {
    var method = event.currentTarget.value;
    if (method === null) {
      setAnchorEl(null);
      return;
    }
    let op = await sortPlans(method, option);
    setOption(op);
    setAnchorEl(null);
  };

  useEffect(() => {
    setSortMethod(option);
  }, [option, setSortMethod]);

  return (
    <div>
      <TextField
        id="standard-basic"
        label={t("selectPlan.enterName")}
        variant="standard"
        type="search"
        className="search-bar-item"
        style={{ marginLeft: "10px", maxWidth: Dimensions.SearchBarWidth }}
        value={searchvalue}
        onChange={(e) => {
          handleSearchChange(e);
        }}
      />

      <Button
        className="btn amr-btn-primary "
        style={{ marginLeft: "20px", marginTop: "10px" }}
        onClick={() =>
          handleSearchButtonClick && handleSearchButtonClick(searchvalue)
        }
      >
        {SearchValue?.length > 0 ? <SearchOffIcon /> : <SearchIcon />}
      </Button>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleDropDownClick}
        style={{ marginTop: "10px" }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        value={option}
        onClose={handleDropDownClose}
      >
        {options.map((option, index) => {
          if (option === "Recently Mowed" && selected_mower === null) {
            return null;
          }
          return (
            <MenuItem
              key={option}
              value={index}
              onClick={(e) => handleDropDownClose(e)}
            >
              {option}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};
