import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthService from "../components/services/AuthService";
import { Row, Col, Container } from "react-bootstrap";
import { TextField } from "@mui/material";
import { withRouter } from "../components/withRouter";
import {
  RemoveNonNumeric,
  SendPINCode,
  sendLoginCode,
} from "../components/pages/loginPage/LoginPageUtil";
import { useTranslation } from "react-i18next";

/**
 * LoginPage Component
 *
 * This functional component renders a login page where users can input their phone number
 * to receive a PIN code and then use the PIN code to authenticate themselves.
 *
 * Variables:
 * @param {AuthService} auth - a class that contains methods related to authentication, such as token handling.
 * @param {string} token -  token fetched from a cookie to check its validity.
 * @param {string} phoneinput - The input value of the phone number field.
 * @param {string} pininput - The input value of the PIN code field.
 * @param {boolean} numbersent - A boolean value determining if the PIN code has been sent to the provided phone number.
 * @param {string} sendpinbtntext - Text displayed on the button for sending or re-sending the PIN code.
 * @param {boolean} phonenumerror - A boolean to indicate if there's an error with the phone number input.
 * @param {boolean} pinnumerror - A boolean to indicate if there's an error with the PIN input.
 * @param {boolean} pinSubmitError - A boolean to indicate if there's an error with PIN submission.
 * @example
 * <LoginPage />
 */
const LoginPage = (props) => {
  const { router } = props;
  const { t, i18n } = useTranslation();
  const [phoneinput, setPhoneInput] = useState("");
  const [pininput, setPinInput] = useState("");
  const [numbersent, setNumberSent] = useState(false);
  const [sendpinbtntext, setSendPinBtnText] = useState(t("login.sendPin"));
  const [phonenumerror, setPhoneNumError] = useState(false);
  const [pinnumerror, setPinNumError] = useState(false);
  const [pinSubmitError, setPinSubmitError] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("SelectedMower") !== "")
      sessionStorage.setItem("SelectedMower", "");
    const auth = new AuthService();
    const token = auth.getTokenFromCookie();
    if (token !== null) {
      if (!auth.isTokenExpired(token)) {
        window.location = "/Home";
      }
    }
  }, [router]);

  async function HandlePinCodeSendClicked(phoneInput, pinInput) {
    if (pinnumerror || phonenumerror) {
      setPinNumError(false);
      setPhoneNumError(false);
      setPinSubmitError(false);
    }

    var phoneNumber = RemoveNonNumeric(phoneInput);
    var pinNumber = RemoveNonNumeric(pinInput);

    if (phoneNumber.length !== 10) {
      setPhoneNumError(true);
      return;
    }

    if (pinNumber.length !== 6) {
      setPinNumError(true);
      return;
    }

    if (!pinNumber || pinNumber === null || pinNumber === "") {
      return;
    }

    let ok = await SendPINCode(phoneNumber, pinNumber);
    if (!ok) {
      setPinSubmitError(true);
      return;
    } else {
      var auth = new AuthService();
      if (auth.getTokenFromCookie() !== null) {
        router.navigate("/");
      }
    }
  }

  async function HandlePhonNumSendClicked(phoneInput) {
    if (phonenumerror) {
      setPhoneNumError(false);
    }
    var phoneNumber = RemoveNonNumeric(phoneInput);
    if (phoneNumber.length !== 10) {
      setPhoneNumError(true);
      return;
    }

    const sendCode = sendLoginCode(phoneNumber, t);
    let ok = await sendCode();
    if (ok) {
      setNumberSent(true);
      setSendPinBtnText(t("login.reSendPin"));
    }
  }

  return (
    <Container className="d-flex flex-column align-items-center">
      <Row>
        <Col style={{ textAlign: "center" }}>
          <img
            className="w-75 mt-100 mb-10"
            src="/Images/logo.png"
            alt="RC Mowers cover art"
          />
        </Col>
      </Row>
      <Row>
        <Col style={{ textAlign: "center" }}>
          <TextField
            error={phonenumerror}
            helperText={phonenumerror ? t("login.digitNumber") : ""}
            value={phoneinput}
            id="standard-basic"
            label={t("login.phone")}
            variant="standard"
            className="search-bar-item"
            onChange={(e) => {
              setPhoneInput(e.currentTarget.value);
            }}
          />
          <div>
            {numbersent && (
              <TextField
                error={pinnumerror}
                helperText={pinnumerror ? t("login.digitPin") : ""}
                value={pininput}
                id="standard-basic"
                label={t("login.pin")}
                variant="standard"
                className="search-bar-item"
                onChange={(e) => {
                  setPinInput(e.currentTarget.value);
                }}
              />
            )}
          </div>
          <div>
            <Link
              style={{ width: "150px" }}
              className="btn amr-btn-primary mt-10 text-center"
              onClick={(e) => HandlePhonNumSendClicked(phoneinput)}
            >
              {sendpinbtntext}
            </Link>
            {numbersent && (
              <div>
                <Link
                  style={{ width: "150px" }}
                  className="btn amr-btn-primary mt-10"
                  onClick={(e) =>
                    HandlePinCodeSendClicked(phoneinput, pininput)
                  }
                >
                  {t("login.signIn")}
                </Link>
                {/* Display the error message when pinnumerror is true */}
                {pinSubmitError && (
                  <p className="pt-3 fw-bold" style={{ color: "red" }}>
                    {t("login.incorrectPin")}
                  </p>
                )}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(LoginPage);
