import { use } from "i18next";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

/**
 * HelpPage Component
 *
 * This component renders a section for viewing useful resources, specifically a link
 * to manuals. It leverages react-bootstrap for layout and styling.
 *
 * - The component uses the Row and Col components from react-bootstrap to layout content.
 * - It provides a "View" button linking to the "amroperator" website's help section.
 *
 * @component
 * @example
 * <HelpPage />
 */
function HelpPage() {
  const { t, i18n } = useTranslation();
  return (
    <div className="px-3 py-4 p-lg-3">
      <h2 className="text-decoration-underline fw-bold">{t("help.useful")}</h2>
      <Row className="align-items-center pt-2">
        <Col>
          <p className="fw-bold mb-0">{t("help.manuals")}</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <a
            href="https://www.amroperator.com/help"
            target="_blank"
            className="amr-btn-primary btn"
            rel="noreferrer"
            title="Opens in a new tab. If you're unable to access the link, please let us know."
            aria-label="View Manuals on AMROperator website"
          >
            {t("help.view")}
          </a>
        </Col>
      </Row>
    </div>
  );
}
export default HelpPage;
