import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import User from "../components/models/user";
import AuthService from "../components/services/AuthService";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function HomePage() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchUser = async () => {
      const auth = new AuthService();
      auth.enforceLogin();
      const loggedinUser = new User();
      try {
        const name = await loggedinUser.getName();
        const role = await loggedinUser.getRole();
        const company = await loggedinUser.getCompany();
        const branch = await loggedinUser.getBranch();
        setUser({ name, role, company, branch });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setError("Failed to load user details. Please try again later.");
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  const screenHeight = document.documentElement.clientHeight - 10;
  const userContent = loading ? (
    <p>Loading user information...</p>
  ) : error ? (
    <p>{error}</p>
  ) : (
    <>
      <p>
        {t("home.hello")}, {user.name} ({user.role})
      </p>
      <p>
        {t("home.company")} {user.company}
      </p>
      <p>
        {t("home.branch")} {user.branch}
      </p>
    </>
  );

  return (
    <Row style={{ height: screenHeight }}>
      <Col>
        <img
          className="w-100"
          src="/Images/graphics_homepage.png"
          alt="AMR cover art"
        />
        <div className="pt-4 ps-3">{userContent}</div>

        <div className="ps-2 pe-2">
          <Link
            className="btn amr-btn-primary d-flex"
            to="/Mow"
            aria-label="Get Started with AMR"
          >
            {t("home.getStarted")}
          </Link>
        </div>
        <div className="pt-200 ps-2 pe-2"></div>
      </Col>
      <footer className="border-top footer text-muted mt-5">
        <div className="container">
          &copy; 2023 - RCMowers -{" "}
          <a
            style={{ textDecorationLine: "underline" }}
            href="tel: +19206342227"
            aria-label="Contact RCMowers support via phone"
          >
            {t("home.contactSupport")}{" "}
          </a>
        </div>
      </footer>
    </Row>
  );
}

export default HomePage;
