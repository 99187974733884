import { Col, Row, ListGroup } from "react-bootstrap";
import { Typography, Divider, CircularProgress, Button } from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react"; //added React
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { grey, red } from "@mui/material/colors";
import { MowSection, MowingPlan } from "../../models/mowingPlan";
import { SortedMowingPlan } from "../../models/sortedMowingPlans";
import { PlanSortMethod, PlanSortMethodString } from "../../models/enums";

export const MowingPlans = (props) => {
  const {
    list_of_plans,
    SearchValue,
    loading,
    PlanClicked,
    sortMethod,
    refreshMethod,
  } = props;
  //const connection = useContext(WebSocketContext);
  const [plan_list, setPlanList] = useState(list_of_plans);
  const [storedPlanList, setStoredPlanList] = useState(null);
  const [NoneFound, setNoneFound] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const sv = useRef("");
  useEffect(() => {
    try {
      var storedplan = sessionStorage.getItem("SplitPlan");
      if (storedplan !== null && storedplan !== "") {
        storedplan = new MowingPlan(JSON.parse(storedplan));
        setStoredPlanList(storedplan);
      } else {
        setStoredPlanList(null);
      }
    } catch (ex) {}
  }, []);

  /**
   * Effect that updates displayed plans based on search value
   *
   * When "SearchValue" is updated, the component renders and the useEffect
   * filters out plans based on the provided search value
   */
  useEffect(() => {
    let filteredList = SearchValue
      ? list_of_plans.filter((p) => {
          let svlc = SearchValue.toLowerCase().replace(/\s/g, "");
          return (
            p.mowing_plan
              ?.HumanReadableIdentifier()
              ?.toLowerCase()
              .replace(/\s/g, "")
              .includes(svlc) ||
            p.mowing_plan
              ?.Address()
              ?.toLowerCase()
              .replace(/\s/g, "")
              .includes(svlc)
          );
        })
      : list_of_plans;
    setPlanList(filteredList);
    setNoneFound(filteredList.length === 0);
  }, [SearchValue, list_of_plans]);

  /**
   * Takes a plan and converts the nested execution time to a string
   *
   * @returns {string} Representation of plan Execution Date.
   */
  function ConvertTimetoString(plan) {
    if (plan !== null && plan.execution_time) {
      const convDate = new Date(plan.execution_time);
      if (!isNaN(convDate.getTime())) {
        return "Mowed Date: " + convDate.toDateString();
      }
    }
    return "";
  }

  const SplitPlanList = (planlist) => {
    return (
      <div>
        {
          <ListGroup>
            {storedPlanList && storedPlanList !== null && (
              <ListGroup.Item onClick={() => PlanClicked(storedPlanList)}>
                <Row>
                  <Col style={{ maxWidth: "60px" }}>
                    <PolygonCanvas
                      polygonData={storedPlanList.GetPoints()}
                      polygonInteriorData={storedPlanList.GetExclusionPoints()}
                      canvasSize={50}
                    />
                  </Col>
                  <Col style={{ maxWidth: "50px", textAlign: "center" }}>
                    {storedPlanList.MowSections && (
                      <Typography>
                        <span>{storedPlanList.MowSections.length}</span>
                      </Typography>
                    )}
                  </Col>
                  <Col style={{ minWidth: "fit-content", textAlign: "start" }}>
                    <Row>{storedPlanList.HumanReadableIdentifier()}</Row>
                    <Row>{storedPlanList.Address()}</Row>
                    <Row>{storedPlanList.Description()}</Row>
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
          </ListGroup>
        }
      </div>
    );
  };

  return (
    <div>
      {SearchValue !== "" && (
        <Typography style={{ marginLeft: "20px", fontSize: "16px" }}>
          <span>
            <b>Search Term:</b> <em>"{SearchValue}"</em>
          </span>
        </Typography>
      )}
      <Divider variant="middle" />
      {storedPlanList && (
        <div>
          <Typography>Recently Split Plan/s</Typography>
          <SplitPlanList />
          <Divider variant="middle" />
          <Typography>
            Other Plans (Sorted by:
            {PlanSortMethodString[sortMethod.toString()]})
          </Typography>
        </div>
      )}
      <ListGroup>
        {!NoneFound &&
          sortMethod !== 4 &&
          plan_list.map((p, i) => {
            return (
              <ListGroup.Item
                key={i}
                onClick={() => {
                  PlanClicked(p);
                }}
              >
                <Row>
                  <Col style={{ maxWidth: "60px" }}>
                    <PolygonCanvas
                      polygonData={p.mowing_plan.GetPoints()}
                      polygonInteriorData={p.mowing_plan.GetExclusionPoints()}
                      canvasSize={50}
                    />
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    {p.PlanCircleSource !== null && (
                      <img
                        src={"/Images/" + p.PlanCircleSource}
                        width="30px"
                        height="30px"
                        alt="Inside Plan?"
                      />
                    )}
                  </Col>
                  <Col style={{ minWidth: "fit-content", textAlign: "start" }}>
                    <Row>{p.mowing_plan.HumanReadableIdentifier()}</Row>
                    <Row>{p.mowing_plan.Address()}</Row>
                    <Row>{p.mowing_plan.Description()}</Row>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        {!NoneFound &&
          sortMethod === 4 &&
          plan_list.map((p, i) => {
            return (
              <ListGroup.Item
                key={i}
                onClick={() => {
                  PlanClicked(p);
                }}
              >
                <Row>
                  <Col style={{ maxWidth: "60px" }}>
                    <PolygonCanvas
                      polygonData={p.mowing_plan.GetPoints()}
                      polygonInteriorData={p.mowing_plan.GetExclusionPoints()}
                      canvasSize={50}
                    />
                  </Col>
                  <Col style={{ maxWidth: "50px" }}>
                    {p.PlanCircleSource !== null && (
                      <img
                        src={"/Images/" + p.PlanCircleSource}
                        width="30px"
                        height="30px"
                        alt="Inside Plan?"
                      />
                    )}
                  </Col>
                  <Col style={{ minWidth: "fit-content", textAlign: "start" }}>
                    <Row>{p.mowing_plan.PlanName}</Row>
                    <Row>{ConvertTimetoString(p.mowing_plan.MowedPlan)}</Row>
                    <Row></Row>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        {NoneFound && !loading && sortMethod !== 4 && (
          <center>
            <Typography style={{ fontSize: "34px", marginTop: "50px" }}>
              <span>No Plans Found </span>
              <SentimentVeryDissatisfiedIcon
                style={{ fontSize: "30px", color: red[600] }}
              />
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                color: grey[700],
              }}
            >
              <span>
                <em>Check your search term and try again</em>
              </span>
            </Typography>
          </center>
        )}
        {NoneFound && !loading && sortMethod === 4 && (
          <center>
            <Typography style={{ fontSize: "34px", marginTop: "50px" }}>
              <span>No Plans Found </span>
              <SentimentVeryDissatisfiedIcon
                style={{ fontSize: "30px", color: red[600] }}
              />
            </Typography>
            <Typography
              style={{
                fontSize: "16px",
                color: grey[700],
              }}
            >
              <span>
                <em>
                  Loading plans may take a moment, <br /> refresh using button
                  below
                </em>
              </span>
            </Typography>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Button
                style={{ color: "white" }}
                className="btn amr-btn-primary m-3"
                onClick={() => {
                  refreshMethod();
                  setRefresh((prevRefresh) => !prevRefresh);
                }}
              >
                Refresh
              </Button>
            )}
          </center>
        )}
        {loading && !NoneFound && (
          <center>
            <CircularProgress className="m-5"></CircularProgress>
          </center>
        )}
      </ListGroup>
    </div>
  );
};

export const PolygonCanvas = ({
  polygonData,
  canvasSize,
  polygonInteriorData,
}) => {
  const canvasRef = useRef(null);

  const drawPolygon = (
    context,
    polygonData,
    minLat,
    maxLat,
    minLng,
    maxLng,
    canvasSize,
    fillColor
  ) => {
    context.beginPath();

    //draw polygon
    polygonData.forEach((point, index) => {
      //convert latitude to longitude
      let x = scaleCoords(point.lng, minLng, maxLng, 0, canvasSize);
      let y = scaleCoords(point.lat, minLat, maxLat, canvasSize, 0);
      if (index === 0) {
        context.moveTo(x, y);
      } else {
        context.lineTo(x, y);
      }
    });

    context.closePath();
    context.fillStyle = fillColor;
    context.fill();
  };

  const scaleCoords = (value, in_min, in_max, out_min, out_max) => {
    return (
      ((value - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min
    );
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    if (!context) {
      return;
    }
    context.clearRect(0, 0, canvasSize, canvasSize); // clear canvas
    context.beginPath();

    //Find min and max for lat and lng to correctly scale the points
    let minLat = Math.min(...polygonData.map((p) => p.lat || Infinity));
    let maxLat = Math.max(...polygonData.map((p) => p.lat || -Infinity));
    let minLng = Math.min(...polygonData.map((p) => p.lng || Infinity));
    let maxLng = Math.max(...polygonData.map((p) => p.lng || -Infinity));

    if (!canvasSize || canvasSize <= 0) {
      return;
    }
    //draw main polygon
    drawPolygon(
      context,
      polygonData,
      minLat,
      maxLat,
      minLng,
      maxLng,
      canvasSize,
      "#AAAAAA"
    );

    context.lineWidth = 0.5;
    context.strokeStyle = "#000000";
    context.stroke();

    //draw the Keep Outs
    //check if there are any keep-outs at all
    if (polygonInteriorData.length !== 0) {
      polygonInteriorData.forEach((zone, index) => {
        //for each Keep Out get points
        //draw the zone
        drawPolygon(
          context,
          zone,
          minLat,
          maxLat,
          minLng,
          maxLng,
          canvasSize,
          "#FFFFFF"
        );
      });
    }
  }, [polygonData, canvasSize, polygonInteriorData]);

  return (
    <canvas
      ref={canvasRef}
      width={canvasSize}
      height={canvasSize}
      style={{ background: "#FFFFFF" }}
    />
  );
};
