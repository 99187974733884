import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  CircularProgress,
  Tabs,
  Tab,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import User from "../components/models/user";
import { grey } from "@mui/material/colors";
import { WebSocketContext } from "../components/services/AppConnection";
import { Col, Row } from "react-bootstrap";
import { withRouter } from "../components/withRouter";
import { SocketMessageType } from "../components/models/enums";
import SettingsJsonProperties from "../components/pages/mowerSettingsPage/SettingsJSONProperties";
import MowerConfigurationJSONProperties from "../components/pages/mowerSettingsPage/MowerConfigurationJSONProperties";
import TabPanel from "../components/pages/mowerSettingsPage/TabPanel";
import { GeneralSettingsTabPanel } from "../components/pages/mowerSettingsPage/GeneralSettingsTabPanel";
import { MowSettingsTabPanel } from "../components/pages/mowerSettingsPage/MowSettingsTabPanel";
import { DiagnosticsTabPanel } from "../components/pages/mowerSettingsPage/DiagnosticsTabPanel";
import { LogTabPanel } from "../components/pages/mowerSettingsPage/LogTabPanel";
import { MountPointPopup } from "../components/pages/mowerSettingsPage/MountPointPopup";
import { useToast } from "../components/customToast/ToastContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/**
 * Represents a settings page for a particular mower.
 *
 * The component manages multiple state properties to handle different UI scenarios,
 * fetch and display mower settings, and manage various popups and user interactions.
 * This component retrieves saved mower details from the session storage upon loading and
 * processes the associated configuration details.
 *
 * @function MowerSettingsPage
 * @param {Object} props - Properties passed to this component.
 *
 */
const MowerSettingsPage = (props) => {
  const { t, i18n } = useTranslation();
  const connection = useContext(WebSocketContext);
  const { router } = props;
  /**
   * Pop-up visibility states: showAddNtripLoginDialog, showUpdatePagePopup, showMountPointPopup.
   * NTRIP related states: server, port, mountpoint, username, password, selected_ntrip, ntrip_logins, selected_ntrip_login.
   * value, runtime_settings, general_settings, user: Various settings and configurations for the mower.
   */
  const [state, setState] = useState({
    mowerSN: router.params.id,
    value: 0,
    user: new User(),
    //showAddNtripLoginDialog: false,
    showUpdatePagePopup: false,
    showMountPointPopup: false,
    name: "",
    server: "",
    port: "",
    mountpoint: "",
    username: "",
    password: "",
    ntrip_logins: [],
    selected_ntrip_login: 0,
  });
  const [general_settings, setGeneralSettings] = useState(
    new MowerConfigurationJSONProperties()
  );
  const [runtime_settings, setRuntimeSettings] = useState(
    new SettingsJsonProperties()
  );
  /**
   * mowerConnected: A boolean indicating if the mower is currently connected.
   * mowerSN: Serial Number of the mower, retrieved from the router's parameters.
   * loading: A boolean controlling the visibility of the loading screen.
   * mower: Object storing mower-related information.
   */
  const [mower, setMower] = useState(() => {
    const savedMower = sessionStorage.getItem("configureMower");
    return savedMower ? JSON.parse(savedMower) : null;
  });
  const [loading, setLoading] = useState(true);
  const [ntriplist, setNtripList] = useState([]);
  const [mowerconnected, setMowerConnection] = useState(false);

  useEffect(() => {
    if (mower) {
      setMowerConnection(mower.connected);

      let givenConfig = mower.details.configuration;
      let general_settings = new MowerConfigurationJSONProperties();
      if (givenConfig !== null) {
        try {
          general_settings = general_settings.Deserialize(givenConfig);
        } catch (error) {
          throw new Error("Unable to display Mower general settings");
        }
        //console.log("General Settings: ", general_settings);
        setGeneralSettings(general_settings);
      }

      //get the ntrip list and convert it into the objects we need
      let ntriplist = [];
      //console.log("general_settings.ntrip_logins: ", general_settings.ntrip_logins);
      if (general_settings.ntrip_logins && general_settings.ntrip_logins.length > 0) {
        ntriplist = general_settings.ntrip_logins;
        setNtripList(ntriplist);
      }
      setLoading(false);
    }
  }, [mower]);

  /**
   * The LoadingScreen component provides a visual representation
   *  that something is loading or in process. It uses a circular progress
   * component to indicate the loading state.
   *
   * @function LoadingScreen
   *
   */
  const LoadingScreen = () => {
    return (
      <center>
        <div>
          <CircularProgress className="mt-5" aria-label="Content Loading" />
        </div>
      </center>
    );
  };

  /**
   * This function compares two arrays and returns a boolean indicating whether the arrays are equal or not.
   * @name arraysEqual
   * @param {Array} array1 - The first array to be compared.
   * @param {Array} array2 - The second array to be compared.
   * @returns true if the arrays are equal and false otherwise.
   */
  const arraysEqual = (array1, array2) => {
    if (array1 === array2) return true;
    if (array1 === null || array2 === null) return false;
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }

    return true;
  };

  /**
   * This function is used to handle changes in certain input components, like when a user selects a value from a set of options.
   * @name handleChange
   * @param {Object} event - The event object that triggered the change. (Not being used in the current function but typically present in such handlers).
   * @param {Number} num - The new value that has been selected or changed to.
   * Updates the state's value property with the new value, num.
   */
  const handleChange = (event, num) => {
    setState({
      ...state,
      value: num,
    });
  };

  

  /**
   * This component provides a dialog box or popup that displays update information for a mower.
   *  It allows the user to initiate an update action if an update is available or cancel the action.
   * @name UpdatePopup
   */
  const UpdatePopup = () => {
    //changeLog: Holds the version and description details of the update. By default, it mentions no updates found.
    const [changeLog, setChangeLog] = useState([]);
    //updateAvailable: Boolean flag to determine if an update is available.
    const [updateAvailable, setUpdateAvailable] = useState(false);
    //history: Provides access to the history object, which allows the user to navigate the app's history.
    const navigate = useNavigate();

    /**
     * useEffect:
     * Checks if the mower object exists and if it has update information.
     * If updates are found, it processes the changelog and sets the update information in the changeLog state.
     * If no updates are found, it sets the updateAvailable flag to false.
     */
    useEffect(() => {
      const currentVersionString = mower.CurrentVersion.split(" ")[1]; // current version
      const currentVersion = parseFloat(currentVersionString);
      if (
        mower &&
        mower.details &&
        mower.details.update_changelog &&
        mower.details.update_changelog.length > 0
      ) {
        const versions = (mower.details.update_changelog || '')
          .split("\n\n")
          .map((version) => {
            if (!version.trim()) {
              return null; // Skip empty versions
            }
            const [versionNumber, ...descriptionParts] = version
              .split("\n")
              .filter(Boolean);
            const versionClean = versionNumber.split(" ")[1]; // Extracts version number as a string
            return {
              version: versionClean,
              numericVersion: parseFloat(versionClean), // Converts version string to a floating point number for comparison
              description: descriptionParts.join(" "),
            };
          })
          .filter(Boolean); // Remove null values
        // Determine if any version in the changelog is newer than the current version
        const isUpdateAvailable = versions.some(
          (version) => version.numericVersion > currentVersion
        );

        if (isUpdateAvailable) {
          setChangeLog(versions);
          setUpdateAvailable(true);
        } else {
          setChangeLog([
            { version: "No Updates Found", description: "You are up to date." },
          ]);
          setUpdateAvailable(false);
        }
      } else {
        setChangeLog([
          { version: "No Updates Found", description: "You are up to date." },
        ]);
        setUpdateAvailable(false);
      }
    }, [mower]); // Ensure this runs whenever mower is updated

    /**
     * Render:
     * Displays a dialog box that shows the update information.
     * Provides "Update" and "Cancel" buttons. The "Update" button is disabled if no updates are available.
     * Iterates through the changelog and displays each version and its associated description.
     */
    return (
      <Dialog
        open={state.showUpdatePagePopup}
        onClose={() => {
          setState({
            ...state,
            showUpdatePagePopup: false,
          });
        }}
      >
        <DialogTitle>Update Info</DialogTitle>
        <DialogContent>
          <center>
            <Button
              style={{
                textTransform: "capitalize",
                textDecorationLine: "none",
                marginLeft: "5px",
                marginTop: "5px",
              }}
              className="btn amr-btn-primary"
              sx={{ marginBottom: "5px" }}
              onClick={() => {
                connection.SocketMessage(SocketMessageType.UpdateMower, {
                  serial_number: mower.MachineSerialNumber,
                });
                setState({
                  ...state,
                  showUpdatePagePopup: false,
                });
                navigate("/machines");
              }}
              disabled={!updateAvailable}
            >
              Send Update
            </Button>
            <Button
              style={{
                textTransform: "capitalize",
                textDecorationLine: "none",
                marginLeft: "5px",
                marginTop: "5px",
              }}
              className="btn amr-btn-primary"
              sx={{ marginBottom: "5px" }}
              onClick={() => {
                setState({
                  ...state,
                  showUpdatePagePopup: false,
                });
              }}
            >
              Cancel
            </Button>
          </center>
          <Col>
            {Array.isArray(changeLog) ? (
              changeLog.map((log, index) => (
                <div key={index}>
                  <Typography>
                    <span>
                      <em>Version: {log.version}</em>
                    </span>
                  </Typography>
                  <Typography sx={{ marginBottom: "10px" }}>
                    <span>{log.description}</span>
                  </Typography>
                </div>
              ))
            ) : (
              <Typography>
                <span>No change log available</span>
              </Typography>
            )}
          </Col>
        </DialogContent>
      </Dialog>
    );
  };

  /**
   * This component displays the mowing settings of an AMR
   * and provides options for users to update these settings.
   * @name MowSettings
   * @returns the Mow Settings Tab component and handles user interactions.
   */
  const MowSettings = () => {
    /**
     *  Sends the updated settings to the server through a socket message.
     * @name SaveMowSettings
     */
    const SaveMowSettings = (props) => {
      const { serializedJSON } = props;
      try {
        connection.SocketMessage(SocketMessageType.SendSettings, {
          serial_number: mower.MachineSerialNumber,
          settings: serializedJSON,
        });
        setRuntimeSettings(props);
        setMower(() => {
          mower.details.runtime_settings = serializedJSON;
          localStorage.setItem("configureMower", JSON.stringify(mower));
          return mower;
        });
      } catch (ex) {
        throw new Error("There was an error saving new mow settings.");
      }
    };

    return (
      <div>
        <MowSettingsTabPanel
          mower={mower}
          runtimesettings={runtime_settings}
          SaveMowSettings={SaveMowSettings}
        />
      </div>
    );
  };

  /**
   * This component deals with general settings related to an AMR. It includes functions to check for updates,
   *  restart software or hardware, pair actuators, save settings, manage NTRIP logins, and more.
   * @name GeneralSettings
   * @returns the General Settings Tab component and handles user interactions.
   */
  const GeneralSettings = () => {
    /**
     * Checks if an update is available for the mower and alerts the user accordingly.
     * @name CheckMowerUpdate
     */
    const CheckMowerUpdate = async () => {
      try {
        if (mower.details.update_available) {
          alert(t("alerts.update"), "success");
        } else {
          // Assuming SocketMessage can be awaited or returns a promise
          await connection.SocketMessage(SocketMessageType.CheckUpdatesMower, {
            serial_number: mower.MachineSerialNumber,
          });

          // Assuming this returns a promise that resolves to the list of mowers
          let mowers = await connection.SocketMessage(
            SocketMessageType.GetMowers
          );

          mowers.forEach((m) => {
            if (m.serial_number === mower.MachineSerialNumber) {
              if (m.details.update_available) {
                alert(t("alerts.update"), "success");
              } else {
                alert(t("alerts.noUpdate"), "info");
              }
            }
          });
        }
      } catch (error) {
        console.error("Error checking for updates:", error);
        alert(`${t("alerts.errorUpdates")} ${error}`, "error");
      }
    };

    /**
     * Restarts the mower's software and updates the UI state accordingly.
     * @name RestartSoftware
     */
    const RestartSoftware = () => {
      try {
        connection.SocketMessage(SocketMessageType.SoftRestartMower, {
          serial_number: mower.MachineSerialNumber,
        });
        setState({
          ...state,
          showSoftRestartPopup: false,
        });
      } catch (error) {
        alert(`${t("alerts.errorRestart")} ${error}`, "error");
      }
    };

    /**
     * Restarts the mower's hardware and updates the UI state accordingly.
     * @name RestartHardware
     */
    const RestartHardware = () => {
      try {
        connection.SocketMessage(SocketMessageType.HardRestartMower, {
          serial_number: mower.MachineSerialNumber,
        });
        setState({
          ...state,
          showHardRestartPopup: false,
        });
      } catch (error) {
        alert(`${t("alerts.errorRestarting")} ${error}`, "error");
      }
    };

    /**
     * Pairs the mower's actuators. Prompts a confirmation dialog before executing.
     * @name PairActuators
     */
    const PairActuators = () => {
      let response = window.confirm(
        t("window.sure"),
        t("window.yes"),
        t("window.cancel")
      );
      if (response) {
        try {
          connection.SocketMessage(SocketMessageType.PairActuatorMower, {
            serial_number: mower.MachineSerialNumber,
          });
        } catch (error) {
          alert(`${t("alerts.errorPairing")} ${error}`, "error");
        }
      }
    };

    /**
     * Takes in props with multiple keys like NTRIPloginlist, NTRIPlogin, etc.
     * Updates the mower's general settings and saves the new configuration.
     * @name SaveGeneralSettings
     */
    const SaveGeneralSettings = (props) => {
      const {
        NTRIPloginlist,
        NTRIPlogin,
        correctionsSource,
        lowFuelReturn,
        displayContrast,
      } = props;
      let newSettings = new MowerConfigurationJSONProperties();
      let loginlist = NTRIPloginlist;
      if (loginlist !== undefined && loginlist.length > 1) {
        if (loginlist[1] !== undefined) {
          if (loginlist[1].name === "None") {
            loginlist = [];
          }
        }
      } else {
        loginlist = [];
      }
      newSettings.corrections_source = correctionsSource;
      newSettings.low_fuel_return = lowFuelReturn;
      newSettings.ntrip_logins = loginlist;
      newSettings.selected_ntrip_login = NTRIPlogin;
      newSettings.display_contrast = displayContrast;

      try {
        var updated_props = newSettings.Serialize();
        if (updated_props !== null) {
          connection.SocketMessage(SocketMessageType.SendConfig, {
            serial_number: mower.MachineSerialNumber,
            config: updated_props,
          });
          setGeneralSettings(JSON.parse(updated_props));
          setMower(() => {
            mower.details.configuration = updated_props;
            sessionStorage.setItem("configureMower", JSON.stringify(mower));
            return mower;
          });
        }
      } catch (error) {
        alert(`${t("alerts.errorSettings")} ${error}`, "error");
      }
    };



    /**
     * Triggers an update process for the mower.
     * @name UpdateMower
     */
    const UpdateMower = () => {
      setState({
        ...state,
        showUpdatePagePopup: true,
      });
    };
    // Render the component
    return (
      <div>
        <GeneralSettingsTabPanel
          mower={mower}
          selectedNTRIP={state.selected_ntrip_login}
          ntriplist={ntriplist}
          user={state.user}
          enablePTO={state.enablePTO}
          generalsettings={general_settings}
          onCheckMowerUpdateHandle={CheckMowerUpdate}
          onSoftRestartYes={RestartSoftware}
          onHardRestartYes={RestartHardware}
          onPairActClicked={PairActuators}
          onSaveButtonClicked={SaveGeneralSettings}
          onUpdateMowerClicked={UpdateMower}
        />
        <UpdatePopup />
      </div>
    );
  };

  /**
   * This  component fetches and updates the diagnostics data for a mower at regular intervals.
   * This information is rendered in a user-friendly format for display purposes. The diagnostic
   * data comprises detailed information about the mower's condition, stats, or potential issues.
   * @name Diagnostics
   * @returns fetches and updates the diagnostics data for a mower at regular intervals, and renders the information in a user-friendly format using React and Material-UI components.
   */
  const Diagnostics = () => {
    //This state variable holds the diagnostics data for the mower.
    const [diagnostics, setDiags] = useState(null);

    /**
     * Checks the equality of two objects. Useful for deep comparison.
     * Returns true if the objects are identical, otherwise false.
     * @name areObjectsEqual
     * @param {Object} obj1 - The first object to be compared.
     * @param {Object} obj2 - The second object to be compared.
     */
    const areObjectsEqual = useCallback((obj1, obj2) => {
      if (!obj1 || !obj2) {
        return false;
      }
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        const val1 = obj1[key];
        const val2 = obj2[key];
        const areNestedObjects = isObject(val1) && isObject(val2);

        if (
          (areNestedObjects && !areObjectsEqual(val1, val2)) ||
          (!areNestedObjects && val1 !== val2)
        ) {
          return false;
        }
      }

      return true;
    }, []);

    const DisplayMowerDiags = useCallback(() => {
      if (mower !== null) {
        let mowerlist = connection.SocketMessage(SocketMessageType.GetMowers);
        let diags;
        mowerlist.forEach((m) => {
          if (
            m.serial_number === mower.serial_number ||
            m.serial_number === mower.MachineSerialNumber
          ) {
            diags = m.details.mower_diagnostics;
          }
        });
        diags = JSON.parse(diags);
        if (!areObjectsEqual(diags, diagnostics)) {
          setDiags(diags);
        }
      }
    }, [areObjectsEqual, diagnostics]);

    /**
     * useEffect:
     * For the first render (when isFirstMount is true): If the mower's data is available, it fetches the mower's diagnostics and sets the state.
     * For subsequent renders: Sets up an interval that fetches the diagnostics data every 200 milliseconds and updates the state if the data changes.
     * Error handling: Any errors during the fetching process are logged to the console.
     * Cleanup: Clears the interval to prevent memory leaks.
     */
    useEffect(() => {
      let interval;
      DisplayMowerDiags();
      try {
        interval = setInterval(() => {
          DisplayMowerDiags();
        }, 1000);
      } catch (error) {
        alert(`${t("alerts.errorData")} ${error}`, "error");
      }

      return () => {
        clearInterval(interval);
      };
    }, [DisplayMowerDiags, diagnostics]);

    /**
     * Checks if a value is an object.
     * Returns true if the given value is an object, otherwise false.
     * @name isObject
     * @param {Object} obj - The object to be checked
     */
    function isObject(obj) {
      return obj !== null && typeof obj === "object";
    }

    //Renders the DiagnosticsTabPanel component and passes the diagnostics data as a prop.
    return <DiagnosticsTabPanel diagnostics={diagnostics} />;
  };

  /**
   * This component fetches and displays a list of log items related to faults in a mower.
   * This component frequently updates the list to ensure it displays the latest logs and renders them using a specialized LogTabPanel.
   * @name Logs
   * @returns code fetches and updates the list of log items for a mower at regular intervals and renders the information in a formatted list using React and Material-UI components.
   */
  const Logs = () => {
    //list: This state variable holds the list of logs/faults related to a mower.
    const [list, setList] = useState([]);
    //prevListval: This ref stores the previously fetched list to aid in comparison with the new data.
    const prevListval = useRef([]);
    //isFirstMount: Determines if the component is mounting for the first time.

    // Represents the structure of a log/fault. It has three properties:
    //item_time: Time of the log.
    //item_value: The value of the log item.
    //item_description: Description of the log.
    class LogItem {
      item_time;
      item_value;
      item_description;
    }

    /**
     * It fetches the mower's logs and stores them in state. If no logs are found, a default entry is added.
     *  It compares the new data with the previous list to determine if a state update is necessary.
     * @name DisplayFaults
     */
    const DisplayFaults = () => {
      // Ensure the mower data is available
      if (mower !== null) {
        // Fetch the current state of the mowers
        let mowers = connection.SocketMessage(SocketMessageType.GetMowers);
        let templist = [];
        // For each mower, check if the serial number matches the current mower
        // If yes, process the logs for that mower
        mowers.forEach((m) => {
          if (!m || !m.details || !m.details.faults) return;
          if (
            m.serial_number === mower.serial_number ||
            m.serial_number === mower.MachineSerialNumber
          ) {
            if (m.details.faults === null) {
              return;
            }

            // If no faults were found, insert a default item
            let logs = m.details.faults;

            logs.forEach((log) => {
              if (log.length !== 0) {
                let data = log.split("~");
                let di2 = new LogItem();
                if (data.length >= 2) {
                  di2.item_time = data[0];
                  di2.item_value = data[1];
                  di2.item_description = data[2];
                }
                templist.push(di2);
              }
            });

            if (templist.length === 0) {
              let di2 = new LogItem();
              di2.item_time = t("mowerSettings.noLogs");
              di2.item_value = "";
              di2.item_description = "";
              templist.push(di2);
            }
          }
        });

        // Update the local state with the list of faults
        // Check if the new list is different than the previous one
        if (!arraysEqual(templist, prevListval.current)) {
          // If it's different, update the list state
          setList(templist);
        }
        prevListval.current = templist;
      }
    };

    /**
     * useEffect:
     * On the first render: It fetches the mower's logs and stores them in state.
     * On subsequent renders: Sets an interval to fetch the data every 1000ms.
     * Cleanup: Clears the interval to prevent memory leaks.
     */
    useEffect(() => {
      let interval;
      DisplayFaults();

      // Set an interval to fetch the latest data every 500ms
      interval = setInterval(() => {
        DisplayFaults();
      }, 1000);

      // Cleanup: clear the interval when the component is unmounted or before it re-renders
      return () => clearInterval(interval);
    }, []); // Empty array as dependency means this effect will only run once (like componentDidMount)

    // Render the LogTabPanel with the list of faults
    return <LogTabPanel faultlist={list} />;
  };

  /**
   * This component sets up a tabbed interface where each tab corresponds to a different aspect of mower operations or settings.
   * The displayed tabs and their associated components are conditional based on the mower's connection status and the user's role.
   * @name TabPage
   * @returns sets up a tabbed interface where different components are rendered based on the selected tab, user's role, and mower connection status.
   */
  const TabPage = () => {
    //user: Instance of the User class to determine the role of the user.
    let user = new User();
    return (
      <div>
        <Tabs
          value={state.value}
          variant="scrollable"
          scrollButtons="auto"
          onChange={handleChange}
          sx={{ "& .MuiTabs-indicator": { backgroundColor: "black" } }}
        >
          {mowerconnected && (
            <Tab
              value={0}
              label={t("mowerSettings.mowSettings")}
              sx={{
                color: "inherit",
                "&.Mui-selected": { color: grey[600] },
              }}
            />
          )}
          <Tab
            value={mowerconnected ? 1 : 0}
            label={t("mowerSettings.generalSettings")}
            sx={{
              color: "inherit",
              "&.Mui-selected": { color: grey[600] },
            }}
          />
          {user.IsTechnician() && (
            <Tab
              value={mowerconnected ? 2 : 1}
              label={t("mowerSettings.diagnostic")}
              sx={{
                color: "inherit",
                "&.Mui-selected": { color: grey[600] },
              }}
            />
          )}
          {user.IsTechnician() && (
            <Tab
              value={mowerconnected ? 3 : 2}
              label={t("mowerSettings.logs")}
              sx={{
                color: "inherit",
                "&.Mui-selected": { color: grey[600] },
              }}
            />
          )}
        </Tabs>
        {mowerconnected && (
          <TabPanel value={state.value} index={0}>
            <MowSettings />
          </TabPanel>
        )}

        <TabPanel value={state.value} index={mowerconnected ? 1 : 0}>
          <GeneralSettings />
        </TabPanel>

        <TabPanel value={state.value} index={mowerconnected ? 2 : 1}>
          <Diagnostics />
        </TabPanel>

        <TabPanel value={state.value} index={mowerconnected ? 3 : 2}>
          <Logs />
        </TabPanel>
      </div>
    );
  };

  return (
    <div>
      {loading && <LoadingScreen />}
      {!loading && <TabPage />}
    </div>
  );
};

export default withRouter(MowerSettingsPage);
