import { useLocation, useNavigate, useParams } from "react-router-dom";
import React from "react";

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location, navigate, params;

    location = useLocation();
    navigate = useNavigate();
    params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
