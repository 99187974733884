import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import { PlanSortMethod, SocketMessageType } from "../components/models/enums";
import { WebSocketContext } from "../components/services/AppConnection";
import Constants from "../constants";
import AuthService from "../components/services/AuthService";
import {
  MowSection,
  MowingPlan,
  Position,
} from "../components/models/mowingPlan";
import { Row } from "react-bootstrap";
import { Utilities } from "../components/utility";
import {
  PointInPlan,
  SortedMowingPlan,
} from "../components/models/sortedMowingPlans";
import { withRouter } from "../components/withRouter";
import { MowingPlans } from "../components/pages/planSelectPage/MowingPlans";
import { SortByDropDown } from "../components/pages/planSelectPage/SortByDropDown";
import { useToast } from "../components/customToast/ToastContext";

/**
 * This is a React component representing the plan selection page.
 *
 * The component performs the following functionalities:
 *
 * 1. **Initialization**: On mount, it initializes states and refs.
 * 2. **Authentication Enforcement**: Verifies that a user is logged in.
 * 3. **Screen Dimension Calculation**: Calculates and sets the screen width and search bar width.
 * 4. **Fetching Mower Data**: Retrieves data about mowers using WebSocket connections and sets the selected mower based on the session storage.
 * 5. **Plan Retrieval**: Invokes functions to fetch mowing plans and recently mowed plans.
 *
 * @name PlanSelectPage
 * @function
 * @prop {Object} props - The props passed to the component.
 * @returns {JSX.Element} Renders the PlanSelectPage component.
 */
const PlanSelectPage = (props) => {
  const connection = useContext(WebSocketContext);
  const [list_of_plans, setListOfPlans] = useState([]);
  const [db_plans, setDBPlans] = useState([]);
  const [sortbymethod, setSortMethod] = useState(PlanSortMethod.Distance);
  const [SearchValue, setSearchValue] = useState("");
  const [mowed_plans_, setMowedPlans] = useState(new Map());
  const [loading, setLoading] = useState(true);
  const selected_mower_ref = useRef(null);
  const mower_list_ref = useRef(null);
  const sortByRef = useRef(0);
  const [state, setState] = useState({
    SearchValue: "",
    NumberofPlans: 0,
  });
  const [ScreenDimensions, setScreenDimensions] = useState({
    ScreenWidth: "0px",
    SearchBarWidth: "0px",
  });

  useEffect(() => {
    //verify the user is still logged in
    setLoading(true);
    let auth = new AuthService();
    auth.enforceLogin();

    try {
      let screenWidth = document.documentElement.clientWidth;
      if (!screenWidth) {
        console.error("Unable to get the screen width.");
      } else {
        let num = screenWidth / 1.664;
        num = num > 250 ? 250 : Math.round(num);
        setScreenDimensions({
          ScreenWidth: `${screenWidth}px`,
          SearchBarWidth: `${num}px`,
        });
      }

      sortByRef.current = 0;
      var mowers = connection.SocketMessage(SocketMessageType.GetMowers);
      if (!mowers) {
        console.error("Failed to get mowers from the socket message.");
      }

      let mower = sessionStorage.getItem("SelectedMower");
      if (mower && mower !== "") {
        let parsedMower = JSON.parse(mower);
        if (parsedMower) {
          selected_mower_ref.current = parsedMower;
        } else {
          console.error("Failed to parse mower from session storage.");
        }
      }

      mower_list_ref.current = mowers;
      getPlans();
      RefreshHistoricalPlans();
    } catch (err) {
      console.error(
        "An error occurred during the useEffect initialization:",
        err
      );
    }
  }, []);

  useEffect(() => {
    getRecentlyMowedPlans();
  }, [mowed_plans_]);

  /**
   * Fetches mowing plans from the database, sorts them based on proximity to a
   * center position, and updates the inside images of the plans.
   *
   * Flow:
   * 1. If the local `plan_list` is empty, it fetches mowing plans from the database.
   * 2. Determines the sort position based on either the selected mower's position
   *    or the center position.
   * 3. Each fetched plan is then transformed into a SortedMowingPlan object, which
   *    presumably contains the plan and its distance to the sort position.
   * 4. The plans are then sorted by a utility function based on a specified sorting method.
   * 5. After sorting, the inside images of the plans are updated.
   * 6. Finally, the sorted list of plans is set in the state, and the loading state is toggled off.
   *
   * @name getPlans
   * @function
   * @async
   * @returns {void} No return value.
   */
  const getPlans = useCallback(async () => {
    let plan_list = [];
    let num_plans = 0;
    var sortPosition;
    if (plan_list.length === 0) {
      try {
        plan_list = await GetDBMowingPlans();
        num_plans = plan_list.length;
        setDBPlans(plan_list);
      } catch (error) {
        console.error("Error fetching mowing plans:", error);
        throw error; // or handle it in a way that suits your needs
      }
    }
    if (selected_mower_ref.current === null) {
      try {
        sortPosition = await connection.SocketMessage(
          SocketMessageType.GetCenterPosition
        );
      } catch (error) {
        console.error("Error getting center position:", error);
        throw error;
      }
    } else {
      sortPosition = new Position(
        selected_mower_ref.current.lat,
        selected_mower_ref.current.lon
      );
    }

    //on initial load, the page will sort by location
    let sort_plans = [];

    plan_list.forEach((plan) => {
      sort_plans.push(new SortedMowingPlan(plan, sortPosition));
    });

    let util = new Utilities();
    try {
      sort_plans = util.SortPlan(sort_plans, sortbymethod);
    } catch (error) {
      console.error("Error sorting plans:", error);
      throw error; // or handle it in a way that suits your needs
    }

    sort_plans.forEach((p) => {
      try {
        UpdateInsideImage(p);
      } catch (error) {}
    });

    setListOfPlans(sort_plans);
    setLoading(false);
    setState({
      ...state,
      NumberofPlans: num_plans,
    });
  }, []);

  /**
   * Refreshes the historical plans associated with connected mowers.
   *
   * The function does the following:
   * 1. Fetches a list of mowers via a socket message.
   * 2. Iterates over each mower:
   *    a. If the mower is connected:
   *       - Checks if there are already stored mowing plans associated with the mower's serial number.
   *       - If plans exist, they are deleted from the internal map.
   *       - Requests the mower's plans via another socket message.
   * 3. After processing all mowers, it updates a reference to the list of mowers.
   *
   * Note: The function doesn't directly return the refreshed plans. Instead, it initiates requests
   * for the plans which might be processed elsewhere in the application.
   *
   * @name RefreshHistoricalPlans
   * @function
   * @async
   * @returns {void} No return value.
   */
  const RefreshHistoricalPlans = async () => {
    let mowers;
    try {
      mowers = await connection.SocketMessage(SocketMessageType.GetMowers);
    } catch (error) {
      console.error("Error fetching mowers:", error);
      throw error; // or handle it in a way that suits your needs
    }

    const requests = [];
    for (let [key, mower] of mowers.entries()) {
      if (mower.connected) {
        let m = mowed_plans_.get(mower.serial_number);
        if (m !== null && m !== undefined) {
          mowed_plans_.delete(mower.serial_number);
        }
        try {
          const request = connection.SocketMessage(
            SocketMessageType.RequestMowerPlans,
            {
              serial_number: mower.serial_number,
            }
          );
          requests.push(request);
        } catch (error) {
          console.error(
            `Error requesting plans for mower with serial number ${mower.serial_number}:`,
            error
          );
          // You might decide to continue with the next mower or abort based on this error.
        }
      }
    }
    await Promise.all(requests);
    mower_list_ref.current = Array.from(mowers.values());
    return;
  };
  /**
   * Refreshes and retrieves recently mowed plans for each mower.
   *
   * The function does the following:
   * 1. If the mowed_plans_ map is empty, it calls the RefreshHistoricalPlans function.
   * 2. Fetches a list of mowers via a socket message.
   * 3. Iterates over each mower:
   *    a. Checks the current mower's plan history.
   *    b. Compares this with the previously stored history in mowed_plans_ map.
   *    c. If there's a difference, the new history is added to the 'plans' map and a 'changed' flag is set to true.
   * 4. If there's any change in the mowing history of any mower, the new 'plans' map is set to the state.
   * 5. Calls another function, CreateRecentlyMowedPlans, which is not detailed in this code snippet.
   *
   * Note: It's important to note that the actual 'plans' map is not returned from the function. Instead,
   * changes are made to the internal state.
   *
   * @name getRecentlyMowedPlans
   * @function
   * @async
   * @returns {void} No return value.
   */
  const getRecentlyMowedPlans = useCallback(async () => {
    if (mowed_plans_.size < 1) {
      try {
        await RefreshHistoricalPlans();
      } catch (error) {
        console.error("Error refreshing historical plans:", error);
        throw error;
      }
    }

    let mowers;
    try {
      mowers = await connection.SocketMessage(SocketMessageType.GetMowers);
    } catch (error) {
      console.error("Error fetching mowers:", error);
      throw error;
    }

    var plans = new Map();
    var changed = false;

    for (let m of mowers) {
      var current = mowed_plans_.get(m[1].serial_number);
      if (m && m[1].details && m[1].details.mower_plan_history !== current) {
        changed = true;
        plans.set(m[1].serial_number, m[1].details.mower_plan_history);
      }
    }

    if (changed) {
      setMowedPlans(plans);
    }

    try {
      CreateRecentlyMowedPlans();
    } catch (error) {
      console.error("Error creating recently mowed plans:", error);
      throw error;
    }
  }, [connection, mowed_plans_]);

  /**
   * Creates a list of recently mowed plans.
   *
   * The function does the following:
   * 1. Determines the sorting position based on either the center position from a socket message or from the currently selected mower.
   * 2. Retrieves a list of mowers via a socket message.
   * 3. Iterates over each mower:
   *    a. Checks if there's a mowing plan for the mower.
   *    b. Parses the plan, creates a new MowingPlan object, and populates it with plan details.
   *    c. Checks if a specific point lies inside the plan and updates the 'InPlan' property.
   *    d. Creates a SortedMowingPlan object from the MowingPlan object and updates its image.
   *    e. Adds the sorted mowing plan to a list of new plans.
   * 4. Iterates over the list of new plans to update their images.
   * 5. Sets the new plans to the state.
   *
   * @name CreateRecentlyMowedPlans
   * @function
   * @async
   * @returns {void} No return value.
   */
  async function CreateRecentlyMowedPlans() {
    let newPlans = [];
    let sortPosition;
    try {
      if (selected_mower_ref.current === null) {
        sortPosition = await connection.SocketMessage(
          SocketMessageType.GetCenterPosition
        );
      } else {
        sortPosition = new Position(
          selected_mower_ref.current.lat,
          selected_mower_ref.current.lon
        );
      }
    } catch (error) {
      console.error("Error fetching center position:", error);
      throw error;
    }

    let mowers;
    try {
      mowers = await connection.SocketMessage(SocketMessageType.GetMowers);
    } catch (error) {
      console.error("Error fetching mowers:", error);
      throw error;
    }

    if (mowers !== 0) {
      mowers.forEach((m) => {
        if (mowed_plans_.has(m.serial_number)) {
          let plans_string = mowed_plans_.get(m.serial_number);

          try {
            let plans = JSON.parse(plans_string);

            if (plans !== null) {
              plans.forEach((plan) => {
                let mPlan = new MowingPlan(plan);
                mPlan.Id = -255;
                let num_points = plan.plan_points.length / 16;
                let progress = (plan.progress / num_points) * 100.0;
                mPlan.PlanName = "Plan (" + progress.toFixed(0) + "%)";
                mPlan.CreationTime = plan.execution_time;
                mPlan.CreatorName = plan.properties;
                mPlan.MowedPlan = plan;

                mPlan.Points = plan.exterior_points;
                mPlan.ExclusionPoints = plan.interior_points;
                var mow_section = new MowSection(
                  plan.exterior_points,
                  plan.interior_points
                );
                mPlan.MowSections = [mow_section];

                let points = mPlan.GetPoints();
                mPlan.InPlan = PointInPlan(sortPosition, points);
                let smplan = new SortedMowingPlan(mPlan, sortPosition);
                UpdateInsideImage(smplan);
                newPlans.push(smplan);
              });
            }
          } catch (parseError) {
            console.error(
              "Error parsing plan string for mower:",
              m.serial_number,
              parseError
            );
            // You can decide if you want to throw the error or continue with the next mower.
            // throw parseError;
          }
        }
      });
    }
    try {
      newPlans.forEach((p) => {
        UpdateInsideImage(p);
      });
    } catch (error) {
      console.error("Error updating inside images:", error);
      throw error;
    }

    setListOfPlans(newPlans);
  }

  /**
   * This functionasynchronously fetches a list of mowing plans from a backend API.
   * These plans are then converted into instances of the MowingPlan class. The function
   * returns this array of converted plans.
   *
   * @name GetDBMowingPlans
   * @returns array of these converted plans.
   */
  const GetDBMowingPlans = async () => {
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //list_of_plans: Initially an empty array, it will hold the fetched plans from the API.
    let list_of_plans = [];
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI = encodeURI(baseAddress) + "/api/MowingPlans/list";
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    //Fetch the mowing plans from the backend API using the constructed request URI and appropriate headers.
    try {
      await fetch(requestURI, { method: "GET", headers: Headers })
        .then((response) => {
          //Check for a successful response.
          //If the response is not successful, an error is thrown with the status code.
          if (!response.ok) {
            throw new Error(
              "There was an error connecting to the plan database. HTTP Status: " +
                response.status
            );
          }
          return response.json();
        })
        .then((data) => {
          //Parse the fetched data as JSON.
          if (data === null) {
            alert(t("alerts.errorPlans"), "error");
            return;
          }
          list_of_plans = data;
        });
    } catch (ex) {
      console.error(ex);
    }

    //Convert the list of plans into instances of the MowingPlan class.
    //newPlans: Array that will store the converted plans as instances of MowingPlan.
    let newPlans = list_of_plans.map((plan) => {
      return new MowingPlan(plan);
    });

    //Return the array of converted plans.
    return newPlans;
  };

  /**
   * Updates the `PlanCircleSource` property of a given mowing plan object. The function determines the appropriate image (or null) for the `PlanCircleSource` property based on the accent color of the selected mower and whether the plan is within a certain polygon.
   *
   * The function does the following:
   * 1. Checks if a mower is selected and if the given plan is inside a polygon.
   * 2. If the above condition is true, it updates the `PlanCircleSource` based on the `accent_color` of the selected mower.
   * 3. If no mower is selected or the plan is not inside the polygon, it sets `PlanCircleSource` to null.
   *
   * @name UpdateInsideImage
   * @function
   * @param {SortedMowingPlan} plan - The mowing plan object to be updated.
   */
  const UpdateInsideImage = (plan) => {
    if (!plan) {
      return;
    }

    if (selected_mower_ref.current !== null && plan.in_polygon) {
      switch (selected_mower_ref.current.accent_color) {
        case "w":
          plan.PlanCircleSource = "greycircle.png";
          break;
        case "p":
          plan.PlanCircleSource = "purplecircle.png";
          break;
        case "b":
          plan.PlanCircleSource = "bluecircle.png";
          break;
        default:
          plan.PlanCircleSource = null;
          break;
      }
    } else {
      plan.PlanCircleSource = null;
    }
  };

  /**
   * This function renders a component for displaying mowing plans. It provides an interface for the user to interact with the list of mowing plans.
   *
   * Key Functionalities:
   * 1. **Plan Click**: When a mowing plan is clicked, it gets saved to the session storage with key 'SelectedPlan', and then navigates the user to the "/Mow" route.
   * 2. **Display**: The function renders the `MowingPlans` component, passing down several props including the list of plans, the current search value, loading status, and various callback methods.
   *
   * @name DisplayMowingPlans
   * @function
   * @returns {JSX.Element} The `MowingPlans` component populated with the relevant props.
   */
  const DisplayMowingPlans = () => {
    /**
     * Saves the selected plan JSON string to the session storage and naviagtes to the Mow page when a plan is clicked
     *
     * The function does the following:
     * 1. Checks if the plan exists before saving it to session storage
     * 2. naviagtes to the mow page
     *
     * @name PlanClicked
     * @function
     * @param {SortedMowingPlan} plan - The mowing plan object to be passed.
     */
    const PlanClicked = (plan) => {
      if (!plan) {
        return;
      } else if (!plan.mowing_plan && !plan.MowSections) {
        return;
      }

      try {
        if (props && props.router) {
          if (plan.mowing_plan) {
            sessionStorage.setItem(
              "SelectedPlan",
              JSON.stringify(plan.mowing_plan)
            );
            props.router.navigate("/Mow");
          } else if (plan) {
            sessionStorage.setItem("SelectedPlan", JSON.stringify(plan));
            props.router.navigate("/Mow");
          }
        } else {
          throw new Error(
            "Unexpected error when trying to navigate to the Mow Page"
          );
        }
      } catch (err) {
        throw new Error(
          "Unexpected error when trying to navigate to the Mow Page",
          err
        );
      }
    };

    return (
      <MowingPlans
        list_of_plans={list_of_plans}
        SearchValue={SearchValue}
        loading={loading}
        PlanClicked={PlanClicked}
        sortMethod={sortByRef.current}
        refreshMethod={getRecentlyMowedPlans}
      />
    );
  };

  /**
   * This function renders a dropdown component to sort mowing plans based on different criteria.
   *
   * Key Functionalities:
   * 1. **Sort Plans**: Allows sorting of plans based on distance, name, date (latest/earliest), and recent plans.
   *    Each sorting method updates the list of mowing plans accordingly.
   * 2. **Search Plans**: Users can search plans based on a search value, which updates the `SearchValue` state.
   * 3. **Setup Sorting Method**: Users can set up the sorting method using the dropdown. This updates the `sortMethod` state.
   *
   * @name SortDropDown
   * @function
   * @returns {JSX.Element} The `SortByDropDown` component populated with the relevant props.
   */
  const SortDropDown = () => {
    /**
     * Allows sorting of plans based on distance, name, date (latest/earliest), and recent plans.
     *    Each sorting method updates the list of mowing plans accordingly.
     *
     * @name sortPlans
     * @function
     */
    const sortPlans = async (method, option) => {
      let newPlans = [];
      let op = 0;
      let util = new Utilities();
      let sortPosition;
      if (selected_mower_ref.current === null) {
        sortPosition = await connection.SocketMessage(
          SocketMessageType.GetCenterPosition
        );
      } else {
        sortPosition = new Position(
          selected_mower_ref.current.lat,
          selected_mower_ref.current.lon
        );
      }
      switch (method) {
        case PlanSortMethod.Distance:
          let sort_plans = [];

          db_plans.forEach((plan) => {
            sort_plans.push(new SortedMowingPlan(plan, sortPosition));
          });

          sort_plans = util.SortPlan(sort_plans, method); // Changed sortbymethod to method
          newPlans = sort_plans;
          op = PlanSortMethod.Distance;
          break;
        case PlanSortMethod.Name:
          var sorted = util.SortPlan(db_plans, method);
          sorted.forEach((p) => {
            newPlans.push(new SortedMowingPlan(p, sortPosition));
          });
          op = PlanSortMethod.Name;
          break;
        case PlanSortMethod.DateLatest:
          sorted = util.SortPlan(db_plans, method);
          sorted.forEach((p) => {
            newPlans.push(new SortedMowingPlan(p, sortPosition));
          });
          op = PlanSortMethod.DateLatest;
          break;
        case PlanSortMethod.DateEarliest:
          sorted = util.SortPlan(db_plans, method);
          sorted.forEach((p) => {
            newPlans.push(new SortedMowingPlan(p, sortPosition));
          });
          op = PlanSortMethod.DateEarliest;
          break;
        case PlanSortMethod.RecentPlans:
          var mowers = await connection.SocketMessage(
            SocketMessageType.GetMowers
          );
          if (mowers.length !== 0) {
            mowers.forEach((m) => {
              if (mowed_plans_.has(m.serial_number)) {
                let plans_string = mowed_plans_.get(m.serial_number);

                let plans = JSON.parse(plans_string);

                if (plans !== null) {
                  plans.forEach((plan) => {
                    let mPlan = new MowingPlan(plan);
                    mPlan.Id = -255;
                    let num_points = plan.plan_points.length / 16;
                    let progress = (plan.progress / num_points) * 100.0;
                    mPlan.PlanName = "Plan (" + progress.toFixed(0) + "%)";
                    mPlan.CreationTime = plan.execution_time;
                    mPlan.CreatorName = plan.properties;
                    mPlan.MowedPlan = plan;

                    mPlan.Points = plan.exterior_points;
                    mPlan.ExclusionPoints = plan.interior_points;
                    var mow_section = new MowSection(
                      plan.exterior_points,
                      plan.interior_points
                    );
                    mPlan.MowSections = [mow_section];

                    let points = mPlan.GetPoints();
                    mPlan.InPlan = PointInPlan(sortPosition, points);
                    let smplan = new SortedMowingPlan(mPlan, sortPosition);
                    UpdateInsideImage(smplan);
                    newPlans.push(smplan);
                  });
                }
              }
            });
          }
          op = PlanSortMethod.RecentPlans;
          break;
        default:
          return option;
      }

      await newPlans.forEach((p) => {
        UpdateInsideImage(p);
      });

      setListOfPlans(newPlans);
      sortByRef.current = op;
      return op;
    };

    /**
     * Users can search plans based on a search value, which updates the `SearchValue` state.
     * @name handleSearchButtonClick
     * @function
     */
    const handleSearchButtonClick = (searchvalue) => {
      setSearchValue(searchvalue);
    };

    /**
     * Users can set up the sorting method using the dropdown. This updates the `sortMethod` state.
     * @name SetUpSortMethod
     * @function
     */
    const SetUpSortMethod = (option) => {
      setSortMethod(option);
    };

    return (
      <SortByDropDown
        Dimensions={ScreenDimensions}
        SearchValue={SearchValue}
        selected_mower={selected_mower_ref.current}
        handleSearchButtonClick={handleSearchButtonClick}
        sortPlans={sortPlans}
        setSortMethod={SetUpSortMethod}
      />
    );
  };

  return (
    <div className="row mt-3">
      <div className="mb-2 ">
        <div style={{ marginBottom: "10px" }}>
          <Row>
            <center>
              <SortDropDown />
            </center>
          </Row>
        </div>
      </div>

      <div className="p-3">
        <DisplayMowingPlans />
      </div>
    </div>
  );
};

export default withRouter(PlanSelectPage);
