export default class SettingsJsonProperties {
  constructor(props = {}) {
    try {
      // Attempt to get saved settings from localStorage.
      const savedSettings = localStorage.getItem("configureMower");
      const initialProps = savedSettings
        ? JSON.parse(savedSettings)
        : props || {};

      // Validate expected structure of initialProps.
      if (
        initialProps &&
        "details" in initialProps &&
        "runtime_settings" in initialProps.details
      ) {
        const serializedInitialProps = JSON.parse(
          initialProps.details.runtime_settings
        );

        this.enable_pto = serializedInitialProps.enable_pto ?? true;
        this.transport_speed_percent =
          serializedInitialProps.transport_speed_percent ?? 100;
        this.mow_speed_percent = serializedInitialProps.mow_speed_percent ?? 80;
      } else {
        // Set defaults if structure is unexpected.
        this.enable_pto = true;
        this.transport_speed_percent = 100;
        this.mow_speed_percent = 80;
      }
    } catch (error) {
      console.error("Error initializing SettingsJsonProperties:", error);
      // Set defaults in case of any errors.
      this.enable_pto = true;
      this.transport_speed_percent = 100;
      this.mow_speed_percent = 80;
    }
  }

  /**
   * Deserializes a string to create a new instance of the SettingsJsonProperties class.
   * @param {string} string - The string to deserialize.
   * @returns {SettingsJsonProperties} A new instance of the SettingsJsonProperties class.
   */
  Deserialize(string) {
    try {
      if (!string) {
        return new SettingsJsonProperties();
      }
      const props = JSON.parse(string);

      return new SettingsJsonProperties({
        enable_pto: props.enable_pto,
        transport_speed_percent: props.transport_speed_percent,
        mow_speed_percent: props.mow_speed_percent,
      });
    } catch (err) {
      console.error("Failed to deserialize settings:", err);
      return null;
    }
  }

  /**
   * Serializes the current instance of the SettingsJsonProperties class to a string.
   * @returns {string} The serialized string.
   */
  Serialize() {
    try {
      return JSON.stringify(this);
    } catch (err) {
      console.error("Failed to serialize settings:", err);
      return null;
    }
  }
}
