import decode from "jwt-decode";
import Cookies from "universal-cookie";

const cookies = new Cookies();
export default class AuthService {
  constructor(domain) {
    this.domain = domain || "/api/Login/auth";
  }

  /**
   * This function enforces login for the user
   * @name enforceLogin
   */
  enforceLogin() {
    const token = this.getTokenFromCookie();
    if (!token || this.isTokenExpired(token)) {
      this.logout();
    }
  }

  /**
   * This function logs out the user
   * @name logout
   */
  logout() {
    this.deleteToken();
    window.location = "/";
  }

  /**
   * This function checks if the user is logged in
   * @name loggedIn
   * @returns true if the user is logged in, false otherwise
   */
  loggedIn() {
    const token = this.getTokenFromCookie();
    return !!token && !this.isTokenExpired(token);
  }

  /**
   * This function checks if a given token has expired.
   * @param token
   * @name isTokenExpired
   * @returns true if the token is expired, false otherwise, or if an error occurs during the decoding process.
   */
  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      return decoded.exp < Date.now() / 1000; // Compare against current time in seconds
    } catch (error) {
      return true;
    }
  }

  /**
   * This function clears the access token from the HTTP-only cookie.
   * @name deleteToken
   */
  deleteToken() {
    cookies.remove("accessToken");
    // document.cookie =
    //   "accessToken=; path=/; secure; HttpOnly; SameSite=Lax; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  }

  /**
   * This function gets the decoded token.
   * @name getProfile
   * @returns decoded token
   */
  getProfile() {
    const token = this.getTokenFromCookie();
    return token ? decode(token) : null;
  }

  /**
   * This function sets the value of an access token in a HTTP-only cookie.
   * @param accessToken
   * @name setTokenInCookie
   */
  setTokenInCookie(accessToken) {
    const encodedAccessToken = encodeURIComponent(accessToken);

    // Set cookie with the access token
    let date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000); // Cookie will expire in 24 hours
    //const expires = "; expires=" + date.toUTCString();
    document.cookie = "accessToken=" + encodedAccessToken + "; path=/";
  }

  /**
   * This function retrieves the access token from the HTTP-only cookie.
   * @name getTokenFromCookie
   * @returns accessToken
   */
  getTokenFromCookie() {
    const cookies = document.cookie.split(";").map((cookie) => cookie.trim());
    const accessTokenCookie = cookies.find((cookie) =>
      cookie.startsWith("accessToken=")
    );
    if (accessTokenCookie) {
      var token = accessTokenCookie.split("=")[1];
      token = decodeURIComponent(token);
      return token;
    }
    return null;
  }
}
