export default class MowerConfigurationJSONProperties {
  constructor(props) {
    this.corrections_source = props?.corrections_source ?? 0;
    this.selected_ntrip_login = props?.selected_ntrip_login ?? 0;
    this.low_fuel_return = props?.low_fuel_return ?? true;
    this.ntrip_logins = props?.ntrip_logins ?? [];
    this.display_contrast = props?.display_contrast ?? 245.0 / 255.0;
  }

  /**
   * Deserializes a string to instantiate the MowerConfigurationJSONProperties.
   *
   * @param {string} string - JSON string to be deserialized.
   * @returns {MowerConfigurationJSONProperties} - Instance of the MowerConfigurationJSONProperties.
   */
  Deserialize(string) {
    try {
      if (string !== null) {
        let config = JSON.parse(string);

        // Check if expected properties exist
        if (
          !config.hasOwnProperty("corrections_source") ||
          !config.hasOwnProperty("selected_ntrip_login")
        ) {
          //throw new Error("Invalid JSON structure.");
          return null;
        }

        let num_logins = config.ntrip_logins.length/2;
        if (config.selected_ntrip_login >= num_logins) {
          config.selected_ntrip_login = num_logins - 1;
        }
        if (config.selected_ntrip_login < 0) {
          config.selected_ntrip_login = 0;
        }

        if (config.corrections_source < 0) {
          config.corrections_source = 0;
        } else if (config.corrections_source > Number(2)) {
          config.corrections_source = Number(2); //SATELLITE
        }

        config.display_contrast = Math.max(
          0.0,
          Math.min(1.0, config.display_contrast)
        );

        return new MowerConfigurationJSONProperties({
          corrections_source: config.corrections_source,
          selected_ntrip_login: config.selected_ntrip_login,
          low_fuel_return: config.low_fuel_return,
          ntrip_logins: config.ntrip_logins,
          display_contrast: config.display_contrast,
        });
      } else {
        return new MowerConfigurationJSONProperties();
      }
    } catch (error) {
      console.error("Error parsing JSON:", error.message);
      // Handle the error gracefully, e.g., provide a user-friendly message
      return null; // or return a default object or appropriate value
    }
  }

  /**
   * Serializes the MowerConfigurationJSONProperties instance to a JSON string.
   *
   * @returns {string} - Serialized JSON string.
   */
  Serialize() {
    try {
      return JSON.stringify(this);
    } catch (error) {
      console.error("Error serializing JSON:", error.message);
      // Handle the error gracefully, e.g., provide a user-friendly message
      return null; // or return an appropriate value
    }
  }
}
