import React from "react";
import { GoogleApiWrapper } from "google-maps-react";

export default function GoogleApiHOC(WrappedComponent) {
  return GoogleApiWrapper({
    // The API key for accessing Google Maps services.
    // NOTE: For production code, it's crucial to secure this API key,
    // typically by setting it in environment variables or server-side configurations.

    apiKey: "AIzaSyDuhUzEm7ANak--Q7R-LVpPpiCqcn0SOlo",
  })((props) => <WrappedComponent {...props} />);
}
