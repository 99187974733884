import React from "react";
import { Typography } from "@mui/material";
import { Row, Col } from "react-flexbox-grid";
import { useTranslation } from "react-i18next";

export const DiagnosticsTabPanel = (props) => {
  const { diagnostics } = props;
  const { t, i18n } = useTranslation();

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Typography sx={{ fontSize: "16px", marginBottom: "15px" }}>
          <span>{t("mowerSettings.mowerDiagnostic")}</span>
        </Typography>
      </div>
      {diagnostics === null ? (
        <Typography
          sx={{
            fontSize: "16px",
            marginBottom: "15px",
            textAlign: "center",
          }}
        >
          Diagnostic data is not available.
        </Typography>
      ) : (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gridGap: "10px",
          }}
        >
          {Object.entries(diagnostics).map(([key, value]) => (
            <React.Fragment key={key}>
              <Typography style={{ marginBottom: "10px" }}>
                {t(`${key}`)}
              </Typography>
              <Typography style={{ marginBottom: "10px" }}>
                {value || "N/A"}
              </Typography>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
