import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import React from "react";
import { useTranslation } from "react-i18next";

export const SplitActionSheet = (props) => {
  const { visible, closePopup, SplitPlanAction } = props;
  const { t, i18n } = useTranslation();

  return (
    <Dialog open={visible} onClose={closePopup}>
      <DialogTitle>{t("mow.mowSections")}</DialogTitle>
      <DialogContent style={{ margin: "5px", padding: "5px" }}>
        <ListGroup>
          <ListGroupItem onClick={closePopup}>{t("mow.cancel")}</ListGroupItem>
          <ListGroupItem
            onClick={() => {
              SplitPlanAction(2);
            }}
          >
            2
          </ListGroupItem>
          <ListGroupItem
            onClick={() => {
              SplitPlanAction(3);
            }}
          >
            3
          </ListGroupItem>
        </ListGroup>
      </DialogContent>
    </Dialog>
  );
};
