import { Typography } from "@mui/material";
import { Col, ListGroup, Row } from "react-bootstrap";
import React from "react";

export const LogTabPanel = (props) => {
  const { faultlist } = props;

  return (
    <div>
      <ListGroup sx={{ marginTop: "15px" }}>
        {faultlist.map((item, i) => {
          return (
            <ListGroup.Item key={i}>
              <Row>
                <Col sx={{ alignItems: "left" }}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <span>{item.item_time}</span>
                  </Typography>
                </Col>
                <Col sx={{ alignItems: "right" }}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <span>
                      {item.item_value} {item.item_description}
                    </span>
                  </Typography>
                </Col>
              </Row>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    </div>
  );
};
