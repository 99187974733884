import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import { IconButton } from "@mui/material";
import { ListGroup, Row, Col } from "react-bootstrap";
import { MowerButtons } from "./MowerButtons";
import { useToast } from "../../customToast/ToastContext";
import { useTranslation } from "react-i18next";

export const MowerItem = (props) => {
  const { connection, mower, router, ViewMowerInfo } = props;
  const { t, i18n } = useTranslation();

  const MowerLabelClicked = (mower) => {
    try {
      if (mower.connected) {
        //If the user is the controller of the mower
        //Save the mower to the sessionstorage to be pulled by the mow page
        sessionStorage.setItem("SelectedMowerMow", JSON.stringify(mower));
        router.navigate("/Mow");
      } else if (!mower.connected && mower.online) {
        //If the mower is online and somebody else or nobopdy is connected
        //Save the mower to the sessionstorage to be pulled by the monitor page
        sessionStorage.setItem("SelectedMowerMonitor", JSON.stringify(mower));
        router.navigate("/Monitor");
      }
    } catch (error) {
      alert(t("alerts.failedNavigate"), "error");
    }
  };

  if (!mower) {
    return null; // or return some default UI
  }
  return (
    <ListGroup.Item>
      <Row className="mower-list-item-row">
        <Col className="mower-list-item-col-1">
          <img
            alt=""
            className="mower-list-item-image"
            src={mower.mowersPageImage}
          />
          <IconButton
            className="mower-list-item-info-icon"
            aria-label="more info"
            onClick={() => ViewMowerInfo({ mower })}
          >
            <InfoOutlined />
          </IconButton>
        </Col>
        <Col
          className="mower-list-item-col-2"
          onClick={(e) => MowerLabelClicked(mower)}
        >
          <Row>
            <div>
              <p className="mower-list-item-label">
                {t("monitor.mower")} {mower.MachineSerialNumber}
              </p>
              <p className="mower-list-item-label-2">{mower.CompanyName}</p>
            </div>
          </Row>
        </Col>
        <Col className="mower-list-item-col-3">
          <MowerButtons mower={mower} router={router} connection={connection} />
        </Col>
      </Row>
    </ListGroup.Item>
  );
};
