import AuthService from "./AuthService";
import {
  ControllerMessageType,
  MowerState,
  SourceFlags,
  SocketMessageType,
} from "../models/enums";
import User from "../models/user";
import Constants from "../../constants";
import { Source } from "@mui/icons-material";
import { MowSection, Position } from "../models/mowingPlan";
import { GetUserPreferences } from "../../pages/SettingsPage";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CustomToast from "../customToast/CustomToast";
import { useToast } from "../customToast/ToastContext";
import { useTranslation } from "react-i18next";

export class ControllerInfo {
  name;
  id;
}

export class MowerDetails {
  recorded_plan; //GOIGN TO BE RECORDED PLAN OBJECT
  controller = new ControllerInfo();
  faults = [];
  configuration;
  runtime_settings;
  objects;

  current_plan_is_new;
  current_plan; //GOIGN TO BE A MAPS POSITION

  current_version = -1;
  latest_version = -1;
  required_update = false;
  update_available = false;
  update_changelog = "";
  current_management_version = -1;
  current_release_channel = "";
  latest_release_channel = "";
  additional_version_info = "";

  home_position; //GOING TO BE A NEW 0.0 HOME POSITION;
  mower_diagnostics = "{}";
  mower_fault_log = null;
  mower_plan_history = null;
  mower_vehicle_info = null;

  Copy() {
    var clone = new Object();
    Object.assign(clone, MowerDetails);
    return clone;
  }
}

export class MowerData {
  lat;
  lon;
  heading;
  speed;
  serial_number;

  state;
  progress;
  connected;
  other_user_connected;

  plan_position;
  mode_change_reason;
  fuel_level;
  status_string;
  voltage;
  accent_color;
  flags;
  time_remaining;

  details = new MowerDetails();

  getAccentColor() {
    if (this.accent_color === "p") {
      return "#800080"; //purple
    } else if (this.accent_color === "b") {
      return "#2196F3"; //blue
    } else if (this.accent_color === "w") {
      return "#FFFFFF"; //white
    } else {
      return "#808080"; //gray
    }
  }

  getAccentColorRGB() {
    //wait to see if we need this
  }

  getAccentColorName() {
    if (this.accent_color === "p") {
      return "Purple"; //purple
    } else if (this.accent_color === "b") {
      return "Blue"; //blue
    } else if (this.accent_color === "w") {
      return "White"; //white
    } else {
      return "Unknown"; //gray
    }
  }
}

export class RecordedPlan {
  exterior_bounds;
  exclusion_zones;
}

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const websocketRef = useRef(null);
  const [reconnectAttempt, setReconnectAttempt] = useState(0);
  const MAX_ATTEMPTS = 10; // limit reconnection attempts
  const constants = new Constants();
  var current_user = new User();
  let details_dict_ = new Map();
  let states_dict_ = new Map();
  let mowers_ = new Map();

  const connectWebSocket = useCallback(() => {
    try {
      websocketRef.current = new WebSocket(constants.DataServerAddress());

      websocketRef.current.binaryType = "arraybuffer";
      var authentication_service_ = new AuthService();

      websocketRef.current.onopen = () => {
        try {
          var token = authentication_service_.getTokenFromCookie();

          if (!token) {
            console.error("Authentication token missing");
            return;
          }

          token = JSON.parse(token);

          const length = 16 + token.length;
          const buffer = new ArrayBuffer(length);
          var view = new DataView(buffer);

          // Setup initial buffer data
          view.setUint32(0, length, true);
          view.setUint16(4, 0, true);
          view.setUint8(6, 0);
          view.setInt8(7, 0);
          view.setUint32(8, 0, true);
          view.getInt32(12, 0, true);

          // Serialize the token into the buffer
          for (var i = 0; i < token.length; i++) {
            view.setUint8(16 + i, token.charCodeAt(i));
          }

          websocketRef.current.send(buffer); //SENDMESSAGE
          setReconnectAttempt(0);
          console.dir("Websocket connected");
        } catch (innerError) {
          console.error("Error while processing onopen event:", innerError);
        }
      };

      // Handling incoming messages from the WebSocket
      websocketRef.current.onmessage = async (message) => {
        // Extracting header data from the incoming message
        var user_ID = await current_user.getUserID();
        var constants = new Constants();
        var view = new DataView(message.data);
        var length = view.getUint32(0, true);
        var type = view.getUint16(4, true);
        var source_flags = view.getUint8(6);
        var destination_flags = view.getUint8(7);
        var source = view.getUint32(8, true);
        var destination = view.getUint32(12, true);
        var status_string = "";

        // Handling different message types based on the `type` extracted from the header
        try {
          switch (type) {
            case ControllerMessageType["AggregateMowerUpdate"]:
              // Process AggregateMowerUpdate messages
              // This message provides a comprehensive update about all the mowers
              mowers_.clear();
              try {
                var num = view.getUint32(16, true);
                // now read each
                var offset = 20;
                while (offset < length) {
                  var sn = view.getUint32(offset, true);
                  var lat = view.getFloat64(offset + 4, true);
                  var lon = view.getFloat64(offset + 12, true);
                  var hdg = view.getFloat32(offset + 20, true);
                  var speed = view.getFloat32(offset + 24, true);
                  var state = view.getUint8(offset + 28);
                  var progress = view.getUint8(offset + 29);
                  var position = view.getUint16(offset + 30, true);
                  var fuel = view.getUint8(offset + 32);
                  var voltage = view.getUint8(offset + 33);
                  var accent = String.fromCharCode(view.getUint8(offset + 34));
                  var flags = view.getUint8(offset + 35);
                  var time_remaining = view.getUint16(offset + 36, true);
                  var status_len = view.getUint8(offset + 38);
                  var status = "";
                  try {
                    for (var i = 0; i < status_len; i++) {
                      status += String.fromCharCode(
                        view.getUint8(offset + i + 39)
                      );
                    }
                  } catch (ex) {
                    break;
                  }
                  // now read status
                  var m = new MowerData();
                  m.serial_number = sn;
                  m.lat = lat;
                  m.lon = lon;
                  m.heading = hdg;
                  m.speed = speed;
                  m.state = state;
                  m.progress = progress;
                  //m.position
                  m.fuel_level = fuel;
                  m.flags = flags;
                  m.time_remaining = time_remaining;
                  if (voltage === 255) {
                    m.voltage = -1.0;
                  } else {
                    m.voltage = voltage / 10.0;
                  }
                  var strings = status.split("~");
                  m.status_string = strings[0];
                  if (strings.length > 1) {
                    m.mode_change_reason = strings[1];
                  } else {
                    m.mode_change_reason = null;
                  }
                  m.plan_position = position;
                  m.accent_color = accent;
                  var details = new MowerDetails();
                  details = GetMowerDetailStruct(sn, details_dict_);
                  m.details = details;
                  details.current_plan_is_new = false;
                  if (details.controller != null) {
                    m.connected = details.controller.id === parseInt(user_ID);
                    m.other_user_connected =
                      details.controller.id !== 0 &&
                      details.controller.id !== parseInt(user_ID);
                  } else {
                    m.connected = false;
                    m.other_user_connected = false;
                  }
                  mowers_.set(m.serial_number, m);
                  states_dict_.set(sn, state);

                  if (m.connected) {
                    if (m.state === MowerState.Mowing) {
                      status_string +=
                        sn.toString() +
                        ": Mowing " +
                        m.progress.toString() +
                        "%\n";
                    } else {
                      status_string +=
                        sn.toString() + ": " + m.state.toString() + "\n";
                    }
                  }

                  if (status_string.length === 0) {
                    status_string = "No Mowers Connected";
                  }
                  offset += 39 + status_len;
                }
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["ControllingUserChange"]:
              // Process ControllingUserChange messages
              // This message indicates a change in the controlling user for a mower
              var serial_number = source;
              var offset1 = 20;
              try {
                var controlling_user_id = view.getUint32(16, true);
                var string_len = view.getUint8(offset1);
                var controlling_user_name = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    controlling_user_name += String.fromCharCode(
                      view.getUint8(offset1 + i + 4)
                    );
                  }
                } catch (ex) {}
                var c = new ControllerInfo();
                c.id = controlling_user_id;
                c.name = controlling_user_name;
                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.controller = c;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["MowerDiagnostics"]:
              // Process MowerDiagnostics messages
              // This message provides diagnostic information about the mower
              serial_number = source;
              var offset2 = 20;
              try {
                string_len = view.getUint32(16, true);
                var diagnostics = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    diagnostics += String.fromCharCode(
                      view.getUint8(offset2 + i)
                    );
                  }
                } catch (ex) {}

                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.mower_diagnostics = diagnostics;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["MowerConfiguration"]:
              // Process MowerConfiguration messages
              // This message provides configuration information about the mower
              serial_number = source;
              var offset3 = 20;
              try {
                string_len = view.getUint32(16, true);

                var config = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    config += String.fromCharCode(view.getUint8(offset3 + i));
                  }
                } catch (ex) {}
                details = GetMowerDetailStruct(serial_number, details_dict_);

                details.configuration = config;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["MowerLogResponse"]:
              // Process MowerLogResponse messages
              // This message provides log information about the mower
              serial_number = source;
              var offset4 = 20;
              try {
                string_len = view.getUint8(16, true);
                var log = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    log += String.fromCharCode(view.getUint8(offset4 + i));
                  }
                } catch (ex) {}
                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.mower_fault_log = log;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["MowerRuntimeSettings"]:
              // Process MowerRuntimeSettings messages
              // This message provides runtime setting information about the mower
              serial_number = source;
              var offset5 = 20;
              try {
                string_len = view.getUint8(16, true);
                var settings = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    settings += String.fromCharCode(view.getUint8(offset5 + i));
                  }
                } catch (ex) {}
                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.runtime_settings = settings;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["NewMowerPlan"]:
              // Process NewMowerPlan messages
              // This message provides new plan  information from the mower

              serial_number = source;
              try {
                var plan_number = view.getUint32(16, true);

                alert(t("alerts.newPlan") + plan_number.toString(), "info");
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["NotifyUser"]:
              // Process NotifyUser messages
              // This message provides notifications from the mower

              //We are receiving a notification from the mower itself
              serial_number = source;
              var offset6 = 16;
              try {
                if (offset6 + 4 < view.byteLength) {
                  string_len = view.getUint32(offset6, true);
                } else {
                  //console.error("Insufficient data to read string length");
                  return;
                }
              } catch (ex) {
                console.error(ex.message);
              }
              offset6 += 4;
              var notif = "";
              try {
                if (offset6 + string_len < view.byteLength) {
                  for (i = 0; i < string_len; i++) {
                    notif += String.fromCharCode(view.getUint8(offset6 + i));
                  }
                } else {
                  // console.error(
                  //   "Insufficient data to read the notification string."
                  // );
                  return;
                }
              } catch (ex) {
                console.error(ex.message);
              }

              offset6 += string_len;
              try {
                if (offset6 <= view.byteLength) {
                  var notification_type = view.getUint8(offset6, true);
                } else {
                  // console.error(
                  //   "Insufficient data to read the notification type."
                  // );
                  return;
                }
              } catch (ex) {
                console.error(ex.message);
              }

              if (notification_type === 2) {
                alert(`${t("alerts.notice")} ${notif}`, "info");
              } else if (serial_number === 0) {
                alert(`${t("alerts.notice")} ${notif}`, "info");
              }

              var color = 0;
              var color_name = "";
              mowers_.forEach((m) => {
                if (m.serial_number === serial_number) {
                  color = m.getAccentColorRGB();
                  color_name = m.getAccentColorName();
                }
              });
              var title = "";
              if (color_name.length > 0) {
                title =
                  "Mower Message (" +
                  color_name +
                  ", " +
                  serial_number.toString() +
                  ")";
              } else {
                title = "Mower Message (" + serial_number.toString() + ")";
              }
              alert(`${title} ${notif}`);
              break;
            case ControllerMessageType["MowerHomePosition"]:
              // Process MowerHomePosition messages
              // This message provides home pin information from the mower
              serial_number = source;
              try {
                if (view.byteLength >= 32) {
                  lat = view.getFloat64(16, true);
                  let lng = view.getFloat64(24, true);
                  details = GetMowerDetailStruct(serial_number, details_dict_);
                  details.home_position = new Position(lat, lng);
                } else {
                  details = GetMowerDetailStruct(serial_number, details_dict_);
                  details.home_position = null;
                }
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["CurrentPlan"]:
              // Process CurrentPlan messages
              // This message provides current plan information from the mower
              serial_number = source;

              let thisoffset = 16;
              try {
                var num_pts = view.getUint32(thisoffset, true);

                thisoffset += 4;
                //Create an array of points
                var k = 0;
                var points = [];
                try {
                  for (i = 0; i < num_pts; i++) {
                    if (thisoffset + 16 <= view.byteLength) {
                      try {
                        lat = view.getFloat64(thisoffset, true);
                      } catch (ex) {}
                      try {
                        lon = view.getFloat64(thisoffset + 8, true);
                      } catch (ex) {}

                      points.push(new Position(lat, lon));
                      k++;
                      thisoffset += 16;
                    }
                  }
                } catch (ex) {
                  //      console.dir(ex);
                }

                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.current_plan_is_new = true;
                details.current_plan = points;
              } catch (ex) {
                console.error(ex.message);
              }
              //set details current plan to points
              break;
            case ControllerMessageType["CurrentBounds"]:
              // Process CurrentBounds messages
              // This message provides current bound information from the mower
              serial_number = source;
              try {
                num_pts = view.getUint32(16, true);
                var offset8 = 20;

                let points = [];
                try {
                  for (i = 0; i < num_pts; i++) {
                    lat = view.getFloat64(offset8, true);
                    lon = view.getFloat64(offset8 + 8, true);

                    points.push(new Position(lat, lon));
                    offset8 += 16;
                  }
                } catch (ex) {}

                let zones = [];
                var num_bounds = view.getUint32(offset8, true);
                offset8 += 4;
                try {
                  for (i = 0; i < num_bounds; i++) {
                    var num_zone_pts = view.getUint32(offset8, true);
                    offset8 += 4;
                    let pts = [];
                    for (var j = 0; j < num_zone_pts; j++) {
                      lat = view.getFloat64(offset8, true);
                      lon = view.getFloat64(offset8 + 8, true);
                      pts.push(new Position(lat, lon));
                      offset8 += 16;
                    }
                    zones.push(pts);
                  }
                } catch (ex) {}
                var plan = new RecordedPlan();
                plan.exterior_bounds = points;
                plan.exclusion_zones = zones;
                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.recorded_plan = plan;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["MowerVersionInfo"]:
              // Process MowerVersionInfo messages
              // This message provides current mower version information from the mower
              serial_number = source;
              var offset9 = 16;
              try {
                var management_version = view.getFloat64(offset9, true);
                var current_version = view.getFloat64((offset9 += 8), true);
                string_len = view.getUint8((offset9 += 8));
                offset9 += 4;
                var current_channel = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    current_channel += String.fromCharCode(
                      view.getUint8(offset9 + i)
                    );
                  }
                } catch (ex) {}
                offset9 += current_channel.length;

                var latest_version = view.getFloat64(offset9, true);
                string_len = view.getUint8((offset9 += 8), true);
                offset9 += 4;
                var latest_channel = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    latest_channel += String.fromCharCode(
                      view.getUint8(offset9 + i)
                    );
                  }
                } catch (ex) {}
                offset9 += latest_channel.length;

                var update_available =
                  view.getUint8(offset9) > 0 ? true : false;
                var required_update =
                  view.getUint8((offset9 += 1)) > 0 ? true : false;

                string_len = view.getUint16((offset9 += 1), true);
                offset9 += 4;
                var change_log = "";
                try {
                  for (i = 0; i < string_len; i++) {
                    change_log += String.fromCharCode(
                      view.getUint8(offset9 + i)
                    );
                  }
                } catch (ex) {}
                offset9 += change_log.length;

                var vehicle_info = "Unknown";
                if (offset9 < view.byteLength) {
                  vehicle_info = "";
                  string_len = view.getUint8(offset9);
                  offset9 += 4;
                  try {
                    for (i = 0; i < string_len; i++) {
                      vehicle_info += String.fromCharCode(
                        view.getUint8(offset9 + i)
                      );
                    }
                    offset9 += vehicle_info.length;
                  } catch (ex) {}
                }

                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.latest_version = latest_version;
                details.current_version = current_version;
                details.required_update = required_update;
                details.update_available = update_available;
                details.update_changelog = change_log;
                details.current_management_version = management_version;
                details.current_release_channel = current_channel;
                details.latest_release_channel = latest_channel;
                details.additional_version_info = vehicle_info;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["ObstacleBounds"]:
              // Process ObstacleBounds messages
              // This message provides obstacle information from the mower
              // objects for this mower
              serial_number = source;
              try {
                var num_objs = view.getUint32(16, true);

                var objs = [];
                try {
                  for (i = 0; i < num_objs; i++) {
                    var num_obj_pts = view.getUint32(20, true);
                    var obj;
                    obj.type = view.getUint32(20);
                    //obj.polygon = new array of positions
                    for (j = 0; j < num_obj_pts; j++) {
                      lat = view.getFloat64(24, true);
                      lon = view.getFloat64(32, true);

                      //obj.polygon.add new position with lat/lon
                    }
                    objs.push(obj);
                  }
                } catch (ex) {}

                details = GetMowerDetailStruct(serial_number, details_dict_);
              } catch (ex) {
                console.error(ex.message);
              }
              //details.objects = objs;
              break;
            case ControllerMessageType["KickReason"]:
              // Process KickReason messages
              // This message provides kick reason from the mower
              try {
                var reason_id = view.getUint32(16, true);
                var reason = "Unknown Reason";
                if (reason_id === 0) {
                  reason =
                    "Already connected. Please disconnect your other devices and refresh.";
                } else {
                  alert(t("alerts.kicked") + reason, "error");
                }
              } catch (ex) {
                console.error(ex.message);
              }
              //auth.logout();
              break;
            case ControllerMessageType["ProtocolVersion"]:
              // Process ProtocolVersion messages
              // This message provides protocol versions from the mower
              try {
                var protocol_version = view.getUint32(16, true);

                if (protocol_version !== constants.ProtocolVersion()) {
                  console.log("Protocol Mismatch");
                }
                return;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["CurrentFaults"]:
              // Process CurrentFaults messages
              // This message provides current faults from the mower
              serial_number = source;
              var offset10 = 20;
              try {
                var fault_count = view.getUint32(16, true);
                var faults = new Array([]);
                try {
                  for (i = 0; i < fault_count; i++) {
                    string_len = view.getUint16(offset10, true);
                    var fault = "";

                    for (j = 0; j < string_len; j++) {
                      fault += String.fromCharCode(
                        view.getUint8(offset10 + 4 + j)
                      );
                    }
                    faults.push(fault);

                    offset10 += string_len + 4;
                  }
                } catch (ex) {}
                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.faults = faults;
              } catch (ex) {
                console.error(ex.message);
              }
              break;
            case ControllerMessageType["PlanHistoryResponse"]:
              // Process PlanHistoryResponse messages
              // This message provides Plan history information from the mower
              serial_number = source;
              var offset11 = 16;
              try {
                status_len = view.getUint32(16, true);
                offset11 += 4;
                status = "";
                try {
                  for (i = 0; i < status_len; i++) {
                    status += String.fromCharCode(view.getUint8(offset11 + i));
                  }
                } catch (ex) {
                  console.error("Error while constructing status:", ex);
                }
                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.mower_plan_history = status;
              } catch (ex) {
                console.error(ex);
              }
              break;
            case ControllerMessageType["MowerVehicleInfo"]:
              // Process MowerVehicleInfo messages
              // This message provides mower vehicle information from the mower
              serial_number = source;
              var offset12 = 16;
              try {
                status_len = view.getUint32(16, true);
                offset12 += 4;
                status = "";
                try {
                  for (i = 0; i < status_len; i++) {
                    status += String.fromCharCode(view.getUint8(offset12 + i));
                  }
                } catch (ex) {}

                details = GetMowerDetailStruct(serial_number, details_dict_);
                details.mower_vehicle_info = status;
              } catch (ex) {}

              break;
            case ControllerMessageType["MowerUpdate"]:
              // Process MowerUpdate messages
              // This message appears to be the same as AggregateMowerUpdate
              //TODO: Get into contact with Matt to see exact difference

              break;
            case ControllerMessageType["MowerVersionCheck"]:
              // Process MowerUpdate messages
              // This message appears to be the same as AggregateMowerUpdate
              //TODO: Get into contact with Matt to see exact difference
              break;
            case 6518:
              // Not implemented yet
              break;
            default:
              console.error(`Received unhandled message type: ${type}`);
          }
        } catch (ex) {
          console.error(
            `Error processing message of type ${type}: ${ex.message}`
          );
        }
      };

      //The onclose event is triggered when the WebSocket connection is closed.
      websocketRef.current.onclose = (event) => {
        if (!event.wasClean) {
          alert(t("alerts.websocket"), "error");
        }
        //Attempt to reconnect after a second
        if (reconnectAttempt < MAX_ATTEMPTS) {
          setTimeout(() => {
            connectWebSocket();
          }, 1000);
        }
      };

      // The onerror event is triggered when an error occurs with the WebSocket connection.
      websocketRef.current.onerror = (error) => {
        if (error && error.message) {
          //console.error("Detailed WebSocket error:", error.message);
          alert(t("alert.connection"), "info");
        }
        if (
          websocketRef.current.readyState !== WebSocket.CLOSED &&
          websocketRef.current.readyState !== WebSocket.CLOSING
        ) {
          websocketRef.current.close();
          // Assuming you have a method to reconnect
          setTimeout(() => {
            connectWebSocket();
          }, 5000); // 5-second delay before reconnecting
        }
      };
    } catch (error) {
      console.error("Error while initializing Websocket:", error);

      //handle reconnection if there is a failure in the connection attempt
      if (reconnectAttempt < MAX_ATTEMPTS) {
        setReconnectAttempt(reconnectAttempt + 1);
        setTimeout(connectWebSocket, 1000 * reconnectAttempt); // Exponential backoff
      }
    }
  }, []);

  useEffect(() => {
    var authentication_service_ = new AuthService();

    if (authentication_service_.getTokenFromCookie() !== null) {
      connectWebSocket();
    }

    //Clean up the websocket connection when the component unmounts
    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  });

  useEffect(() => {
    if (reconnectAttempt > 0 && reconnectAttempt <= MAX_ATTEMPTS) {
      const delay = Math.min(1024, Math.pow(2, reconnectAttempt)) * 1000;
      const timeoutID = setTimeout(() => {
        console.dir(
          "Reconnecting Websocket (attempt " + reconnectAttempt + "...)"
        );
        if (
          websocketRef.current &&
          websocketRef.current.readyState !== WebSocket.OPEN
        ) {
          websocketRef.current.close();
          //establish a new websocket connection
          connectWebSocket();
        }
      }, delay);
      //cleanup the timeout when either the delay or reconnectAttempt changes
      return () => clearTimeout(timeoutID);
    }
  }, [reconnectAttempt, connectWebSocket]);
//This should get fixed.  This SocketMessage definition is another wrapper abstract layer that changes the message type for no good reason.
// It just confuses the message number and abstracts what is happening
  const SocketMessage = (type, data) => {
    if (type === SocketMessageType.CheckConnected) {
      if (
        websocketRef.current &&
        websocketRef.current.readyState === WebSocket.OPEN
      ) {
        return true;
      } else {
        return false;
      }
    } else if (type === SocketMessageType.GetMowers) {
      var mowers = mowers_;
      return mowers;
    } else if (type === SocketMessageType.DisconnectFromMower) {
      let { serial_number } = data;
      var buffer = new ArrayBuffer(16);
      var msgview = new DataView(buffer);
      var offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["UserDisconnect"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0); //source
      msgview.setUint32((offset += 4), serial_number, true); //dest

      //send if the connection is open
      if (
        websocketRef.current &&
        websocketRef.current.readyState === WebSocket.OPEN
      ) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.ConnectToMower) {
      let { id, pin } = data;
      var username = "Todo User";
      var userpreferences = GetUserPreferences().Serialize();
      var buffer = new ArrayBuffer(
        28 + username.length + userpreferences.length
      );
      var msgview = new DataView(buffer);
      var offset = 0;
      //write header
      msgview.setUint32(offset, buffer.byteLength, true); //length

      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["UserConnect"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser, true); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0); //source
      msgview.setUint32((offset += 4), id, true); //mower dest serial number

      //write the body
      msgview.setUint32((offset += 4), pin, true); //pin
      msgview.setUint32((offset += 4), username.length, true); //username length
      offset += 4;
      for (var i = 0; i < username.length; i++) {
        msgview.setUint8(offset + i, username.charCodeAt(i)); //ascii code for each letter in username
      }
      offset += username.length;
      msgview.setUint32(offset, userpreferences.length, true); //userpreferences length
      offset += 4;
      for (i = 0; i < userpreferences.length; i++) {
        msgview.setUint8(offset + i, userpreferences.charCodeAt(i)); //ascii code for each letter in user preferences
      }
      offset += userpreferences.length;

      if (
        websocketRef.current ||
        websocketRef.current.readyState === WebSocket.OPEN
      ) {
        try {
          websocketRef.current.send(msgview.buffer);
        } catch (ex) {
          console.log(ex);
        }
      }
    } else if (type === SocketMessageType.SendSettings) {
      let { serial_number, settings } = data;
      var buffer = new ArrayBuffer(16 + 4 + settings.length);
      var msgview = new DataView(buffer);
      var offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["SetMowerRuntimeSettings"],
        true
      ); //message type
      msgview.setUint8((offset += 2), Source.IsUser, true); //source flags
      msgview.setUint8((offset += 1), 0); // dest flags
      msgview.setUint32((offset += 1), 0); //source
      msgview.setUint32((offset += 4), serial_number, true); //destination

      //write the body
      msgview.setUint32((offset += 4), settings.length, true); //string length
      offset += 4;
      for (var i = 0; i < settings.length; i++) {
        msgview.setUint8(offset + i, settings.charCodeAt(i)); //ascii code for each char in user preferences
      }
      offset += settings.length;
      //send if the connection is open
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.UpdateUserPreferences) {
      let { preferences } = data;
      mowers_.forEach((mower) => {
        if (mower.connected) {
          var buffer = new ArrayBuffer(16 + 4 + preferences.length);
          var msgview = new DataView(buffer);
          var offset = 0;
          //write the header
          msgview.setUint32(offset, buffer.byteLength, true); //length
          msgview.setUint16(
            (offset += 4),
            ControllerMessageType["SetMowerUserPreferences"],
            true
          ); //message type
          msgview.setUint8((offset += 2), Source.IsUser, true); //source flags
          msgview.setUint8((offset += 1), 0); // dest flags
          msgview.setUint32((offset += 1), 0); //source
          msgview.setUint32((offset += 4), mower.serial_number, true); //destination

          //write the body
          msgview.setUint32((offset += 4), preferences.length, true); //string length
          offset += 4;
          for (var i = 0; i < preferences.length; i++) {
            msgview.setUint8(offset + i, preferences.charCodeAt(i)); //ascii code for each char in user preferences
          }
          offset += preferences.length;
          //send if the connection is open
          if (websocketRef.current.readyState === WebSocket.OPEN) {
            websocketRef.current.send(msgview.buffer);
          }
        }
      });
    } else if (type === SocketMessageType.PairActuatorMower) {
      let { serial_number } = data;
      var buffer = new ArrayBuffer(16);
      var msgview = new DataView(buffer);
      var offset = 0;
      //write the header;
      msgview.setUint32(offset, buffer.byteLength, true); // length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandPairActuators"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.SoftRestartMower) {
      let { serial_number } = data;
      var buffer = new ArrayBuffer(16);
      var msgview = new DataView(buffer);
      var offset = 0;
      //write the header;
      msgview.setUint32(offset, buffer.byteLength, true); // length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandSoftRestart"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.HardRestartMower) {
      let { serial_number } = data;
      var buffer = new ArrayBuffer(16);
      var msgview = new DataView(buffer);
      var offset = 0;
      //write the header;
      msgview.setUint32(offset, buffer.byteLength, true); // length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandHardRestart"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.CheckUpdatesMower) {
      let { serial_number } = data;
      var buffer = new ArrayBuffer(16);
      var msgview = new DataView(buffer);
      var offset = 0;
      //write the header;
      msgview.setUint32(offset, buffer.byteLength, true); // length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandCheckUpdates"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.UpdateMower) {
      let { serial_number } = data;
      var buffer = new ArrayBuffer(16);
      var msgview = new DataView(buffer);
      var offset = 0;
      //write the header;
      msgview.setUint32(offset, buffer.byteLength, true); // length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandUpdate"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
      console.log("Sent update command to mower");
    } else if (type === SocketMessageType.SendConfig) {
      let { serial_number, config } = data;
      var buffer = new ArrayBuffer(16 + 4 + config.length);
      var msgview = new DataView(buffer);
      var offset = 0;
      //Write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["SetMowerConfiguration"],
        true
      ); //message type
      msgview.setUint8((offset += 2), Source.IsUser, true); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0); //source
      msgview.setUint32((offset += 4), serial_number, true); //destination

      //write the body
      msgview.setUint32((offset += 4), config.length, true); //string length
      offset += 4;
      for (var i = 0; i < config.length; i++) {
        msgview.setUint8(offset + i, config.charCodeAt(i)); //ASCII code for each char in configuration
      }
      offset += config.length;

      //send if the connection is open
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.AnyMowersConnected) {
      let connected = false;
      mowers_.forEach((m) => {
        if (m.connected) {
          connected = true;
        }
      });
      return connected;
    } else if (type === SocketMessageType.GetCenterPosition) {
      let pos = new Position(0.0, 0.0);
      mowers_.forEach((m) => {
        if (m.connected || (pos.lat === 0.0 && pos.lon === 0.0)) {
          pos = new Position(m.lat, m.lon);
        }
      });

      if (pos.lat === 0.0 && pos.lon === 0.0) {
        //default to Green Bay instead of the Pacific Ocean
        pos = new Position(44.601484, -88.048759);
      }

      return pos;
    } else if (type === SocketMessageType.RequestMowerPlans) {
      return new Promise((resolve, reject) => {
        const { serial_number } = data;
        var buffer = new ArrayBuffer(16);
        var msgview = new DataView(buffer);
        var offset = 0;
        //write the header;
        msgview.setUint32(offset, buffer.byteLength, true); // length
        msgview.setUint16(
          (offset += 4),
          ControllerMessageType["CommandRequestPlanHistory"],
          true
        );
        msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
        msgview.setUint8((offset += 1), 0); //dest flags
        msgview.setUint32((offset += 1), 0, true); //source
        msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

        // Handle WebSocket response
        const handleMessage = (event) => {
          // Process event.data to get the response
          // Resolve the promise with the response data
          resolve(event.data);

          // Remove event listener after receiving response
          websocketRef.current.removeEventListener("message", handleMessage);
        };

        // Add event listener for WebSocket response
        websocketRef.current.addEventListener("message", handleMessage);

        // Handle WebSocket errors
        websocketRef.current.onerror = (error) => {
          reject(error);

          // Remove event listener in case of error
          websocketRef.current.removeEventListener("message", handleMessage);
        };

        // Send the request
        if (websocketRef.current.readyState === WebSocket.OPEN) {
          websocketRef.current.send(msgview.buffer);
        } else {
          reject(new Error("WebSocket is not open"));
        }
      });
    } else if (type === SocketMessageType.PauseAllMowers) {
      let mowers = mowers_;
      mowers.forEach((m) => {
        if (m.connected) {
          let sn = m.serial_number;
          buffer = new ArrayBuffer(16);
          msgview = new DataView(buffer);
          offset = 0;
          //write the header
          msgview.setUint32(offset, buffer.byteLength, true); //length
          msgview.setUint16(
            (offset += 4),
            ControllerMessageType["CommandPause"],
            true
          );
          msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
          msgview.setUint8((offset += 1), 0); //dest flags
          msgview.setUint32((offset += 1), 0, true); //source
          msgview.setUint32((offset += 4), sn, true); //mower dest serial number
        }

        //write the body
        if (websocketRef.current.readyState === WebSocket.OPEN) {
          websocketRef.current.send(msgview.buffer);
        }
      });
    } else if (type === SocketMessageType.MowerGoTo) {
      const { serial_number, lat, lon } = data;
      buffer = new ArrayBuffer(16 + 8 + 8);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandGoTo"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      msgview.setFloat64((offset += 4), lat, true); //write the lat
      msgview.setFloat64((offset += 8), lon, true); //write the lon

      //send
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.MowerSetHome) {
      const { serial_number, lat, lon } = data;
      buffer = new ArrayBuffer(16 + 8 + 8);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["SetHomePosition"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      msgview.setFloat64((offset += 4), lat, true); //write the lat
      msgview.setFloat64((offset += 8), lon, true); //write the lon

      //send
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.CancelMower) {
      const { serial_number } = data;
      buffer = new ArrayBuffer(16);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandCancelPlan"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.SendPlan) {
      const { serial_number, plan } = data;
      var json_data = JSON.stringify(plan);
      buffer = new ArrayBuffer(16 + 1 + 4 + json_data.length);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header to send the plan to the mower
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16((offset += 4), ControllerMessageType["LoadPlan"], true);
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      msgview.setUint8((offset += 4), 0, true); //plan type, full plan
      msgview.setUint32((offset += 1), json_data.length);
      for (i = 0; i < json_data.length; i++) {
        msgview.setUint8(offset + i, json_data.charCodeAt(i)); //ASCII code for each char in the plan
      }

      //send message
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.SendMowSection) {
      var { serial_number, plan, section } = data;
      try {
        if (typeof section !== "MowSection") {
          section = new MowSection(section.Points, section.ExclusionPoints);
        }

        var pts = section.GetPoints();
        var exclusions = section.GetExclusionPoints();

        let excl_size = 1;
        exclusions.forEach((z) => {
          excl_size = +(4 + z.length * 8 * 2);
        });

        var props =
          plan.Properties === null ? JSON.parse("{}") : plan.Properties;
        //send the plan to the mower
        buffer = new ArrayBuffer(
          16 + pts.length * 8 * 2 + 4 + 4 + 1 + 4 * excl_size + props.length
        );
        msgview = new DataView(buffer);
        offset = 0;
        //write the header to send the plan to the mower
        msgview.setUint32(offset, buffer.byteLength, true); //length
        msgview.setUint16(
          (offset += 4),
          ControllerMessageType["LoadPlan"],
          true
        );
        msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
        msgview.setUint8((offset += 1), 0); //dest flags
        msgview.setUint32((offset += 1), 0, true); //source
        msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

        //write the body
        msgview.setUint8((offset += 4), 1, true); //simple mow plan
        msgview.setUint32((offset += 1), pts.length, true);
        offset += 4;
        //write the perimeter
        pts.forEach((pt) => {
          msgview.setFloat64(offset, pt.lat, true);
          msgview.setFloat64(offset + 8, pt.lng, true);
          offset += 16;
        });
        //Write the exclusion points
        msgview.setUint32(offset, exclusions.length, true);
        offset += 4;
        exclusions.forEach((z) => {
          msgview.setUint32(offset, z.length, true);
          offset += 4;
          z.forEach((pt) => {
            msgview.setFloat64(offset, pt.lat, true);
            msgview.setFloat64(offset + 8, pt.lng, true);
            offset += 16;
          });
        });

        //write the plan properties
        msgview.setUint32(offset, props.length, true);
        offset += 4;
        for (i = 0; i < props.length; i++) {
          msgview.setUint8(offset + i, props.charCodeAt(i));
        }
        offset += props.length;

        if (websocketRef.current.readyState === WebSocket.OPEN) {
          websocketRef.current.send(msgview.buffer);
        }
      } catch (ex) {
        console.dir(ex.message);
      }
    } else if (type === SocketMessageType.GetMower) {
      const { serial_number } = data;
      let foundmower = null;
      mowers_.forEach((mower) => {
        if (serial_number === mower.serial_number) {
          foundmower = mower;
        }
      });
      if (foundmower !== null) {
        return foundmower;
      } else return null;
    } else if (type === SocketMessageType.ReturnHomeMower) {
      const { serial_number } = data;
      buffer = new ArrayBuffer(16);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandGoHome"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.PauseMower) {
      const { serial_number } = data;
      buffer = new ArrayBuffer(16);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandPause"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.ResumeMower) {
      const { serial_number } = data;
      buffer = new ArrayBuffer(16);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandResume"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    } else if (type === SocketMessageType.StartMowerEngine) {
      const { serial_number } = data;
      buffer = new ArrayBuffer(16);
      msgview = new DataView(buffer);
      offset = 0;
      //write the header
      msgview.setUint32(offset, buffer.byteLength, true); //length
      msgview.setUint16(
        (offset += 4),
        ControllerMessageType["CommandStartEngine"],
        true
      );
      msgview.setUint8((offset += 2), SourceFlags.IsUser); //source flags
      msgview.setUint8((offset += 1), 0); //dest flags
      msgview.setUint32((offset += 1), 0, true); //source
      msgview.setUint32((offset += 4), serial_number, true); //mower dest serial number

      //write the body
      if (websocketRef.current.readyState === WebSocket.OPEN) {
        websocketRef.current.send(msgview.buffer);
      }
    }
  };

  function GetMowerDetailStruct(serialNum, details_dict) {
    var details = new MowerDetails();
    if (details_dict.has(serialNum)) {
      details = details_dict.get(serialNum);
      return details;
    } else {
      details_dict.set(serialNum, details);
      return details;
    }
  }

  return (
    <WebSocketContext.Provider value={{ SocketMessage, mowers_ }}>
      {children}
    </WebSocketContext.Provider>
  );
};
