export default class Mower {
  online;
  distance;
  connected;
  otherUserConnected;
  mowersPageImage;
  accentColor;
  canConfigure;
  Id;
  MachineSerialNumber;
  TimePositionUpdates;
  LastOnlineTime;
  CurrentVersion;
  Hours;
  Latitude;
  Longitude;
  CompanyName;
  data;

  /**
   * Computes the distance string representation of the mower.
   * @returns {string} The distance of the mower as a string.
   */
  get distanceString() {
    if (typeof this.distance !== "number" || this.distance === -1) {
      return "Mower Has No Location";
    } else if (this.distance > 0) {
      var feet_per_mile = 5280;
      if (this.distance > feet_per_mile / 4) {
        return "{0:F1} miles away".replace(
          "{0:F1}",
          (this.distance / feet_per_mile).toFixed(1)
        );
      }
      return "{0:F0} miles away".replace("{0:F0}", this.distance.toFixed(0));
    }
    return "";
  }

  /**
   * Determines the connection state of the mower.
   * @returns {string} Returns either "In Use", "Disconnect", or "Connect".
   */
  get connectButtonString() {
    if (this.otherUserConnected) {
      return "In Use";
    }
    return this.connected ? "Disconnect" : "Connect";
  }
}
