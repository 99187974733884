import React from "react";
import { Drawer, IconButton, makeStyles } from "@material-ui/core";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ReorderIcon from "@material-ui/icons/Reorder";
import { Link } from "react-router-dom";
import AuthService from "../services/AuthService";
import { useTranslation } from "react-i18next";

/**
 * Custom styles for Material-UI components.
 * Defines styles specific to the drawer's list width.
 */
const useStyles = makeStyles({
  list: {
    width: 200,
  },
});

export default function MaterialUIDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const { t, i18n } = useTranslation();

  // Check if the current page is the login page.
  const isLoginPage = window.location.pathname === "/";

  /**
   * toggleDrawer function.
   * Handles the opening and closing of the drawer. It prevents the drawer from
   * closing when the 'Tab' or 'Shift' key is pressed.
   */

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  /**
   * sideList function.
   * Returns the contents of the drawer. This includes navigation links to various
   * routes and their corresponding icons. The 'Logout' link also contains logic
   * to handle user logout.
   */
  const sideList = (side) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <Link to="/Home">
        <List to="/Home">
          <ListItem button key="Home">
            <ListItemIcon>
              <img src="/Images/Home.png" width={25} alt="Home icon" />
            </ListItemIcon>
            <ListItemText sx={{ fontWeight: "bold" }} primary={t("nav.home")} />
          </ListItem>
        </List>
      </Link>
      <Link to="/Machines">
        <List>
          <ListItem button key="Machines">
            <ListItemIcon>
              <img src="/Images/Machine.png" width={25} alt="Gear icon" />
            </ListItemIcon>
            <ListItemText primary={t("nav.machines")} />
          </ListItem>
        </List>
      </Link>
      <Link to="/Mow">
        <List>
          <ListItem button key="Mow">
            <ListItemIcon>
              <img src="/Images/mow.png" width={25} alt="Mower icon" />
            </ListItemIcon>
            <ListItemText primary={t("nav.mow")} />
          </ListItem>
        </List>
      </Link>
      <Link to="/Plans">
        <List>
          <ListItem button key="ViewPlans">
            <ListItemIcon>
              <img src="/Images/Plan.png" width={20} alt="Plans icon" />
            </ListItemIcon>
            <ListItemText primary={t("nav.plan")} />
          </ListItem>
        </List>
      </Link>
      <Link to="/Monitor">
        <List>
          <ListItem button key="Monitor">
            <ListItemIcon>
              <img src="/Images/monitor.png" width={25} alt="Monitor icon" />
            </ListItemIcon>
            <ListItemText primary={t("nav.monitor")} />
          </ListItem>
        </List>
      </Link>
      <Link to="/UserSettings">
        <List>
          <ListItem button key="Settings">
            <ListItemIcon>
              <img
                src="/Images/gear_black.png"
                width={25}
                alt="Settings icon"
              />
            </ListItemIcon>
            <ListItemText primary={t("nav.settings")} />
          </ListItem>
        </List>
      </Link>
      <Link to="/Help">
        <List>
          <ListItem button key="Help">
            <ListItemIcon>
              <img src="/Images/helpicon.png" width={25} alt="Settings icon" />
            </ListItemIcon>
            <ListItemText primary={t("nav.help")} />
          </ListItem>
        </List>
      </Link>
      <Link
        onClick={() => {
          var auth = new AuthService();
          auth.logout();
        }}
      >
        <List>
          <ListItem button key="Logout">
            <ListItemIcon>
              <img src="/Images/logout.png" width={25} alt="Logout icon" />
            </ListItemIcon>
            <ListItemText primary={t("nav.logout")} />
          </ListItem>
        </List>
      </Link>
    </div>
  );

  // Component render.
  return (
    <>
      {!isLoginPage && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <IconButton onClick={toggleDrawer("left", true)}>
            <ReorderIcon />
          </IconButton>
          <Link to="/Home">
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                alt="RC Mowers logo"
                src="/Images/logo.png"
                style={{ width: "150px" }}
              />
            </div>
          </Link>
        </div>
      )}

      {/* <Button onClick={toggleDrawer("left", true)}>Open Left</Button> */}
      <Drawer
        BackdropProps={{ invisible: false }}
        open={state.left}
        onClose={toggleDrawer("left", false)}
      >
        {sideList("left")}
      </Drawer>
    </>
  );
}
