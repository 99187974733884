import { CircularProgress, Typography } from "@mui/material";
import { ListGroup } from "react-bootstrap";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { grey, red } from "@mui/material/colors";
import { MowerItem } from "./MowerItem";
import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Represents a React component displaying a list of mowers.
 *
 * The component fetches the list of mowers periodically and updates its state accordingly.
 * The mowers can be fetched from both online connections via WebSockets and from a backend service via API calls.
 * It displays a loading screen when the data is being fetched. If no mowers match the search criteria or no mowers are found, a relevant message is shown to the user.
 *
 * @function DisplayMowerList
 * @returns {JSX.Element} A list of mowers or a relevant status message.
 *
 * @example
 * <DisplayMowerList />
 */
const LoadingScreen = () => {
  return (
    <center>
      <div>
        <CircularProgress className="mt-5" />
      </div>
    </center>
  );
};

export const MowerList = (props) => {
  const {
    connection,
    showLoadingScreen,
    NoneFound,
    displayedMowers,
    error,
    router,
    ViewMowerInfo,
  } = props;
  const { t, i18n } = useTranslation();

  return (
    <ListGroup id="mower-list" className="mt-3">
      {showLoadingScreen && <LoadingScreen />}

      {!NoneFound &&
        displayedMowers !== undefined &&
        [...displayedMowers.entries()].map(([key, mower]) => {
          if (mower.connected) {
            return (
              <MowerItem
                mower={mower}
                key={key}
                router={router}
                ViewMowerInfo={ViewMowerInfo}
                connection={connection}
              />
            );
          } else {
            return (
              <MowerItem
                mower={mower}
                key={key}
                router={router}
                ViewMowerInfo={ViewMowerInfo}
                connection={connection}
              />
            );
          }
        })}
      {NoneFound && (
        <center>
          <Typography style={{ fontSize: "34px", marginTop: "50px" }}>
            <span>{t("machine.noMowers")}</span>
            <SentimentVeryDissatisfiedIcon
              style={{ fontSize: "30px", color: red[600] }}
            />
          </Typography>
          <Typography
            style={{
              fontSize: "16px",
              color: grey[700],
            }}
          >
            <span>
              <em>{t("machine.check")}</em>
            </span>
          </Typography>
        </center>
      )}

      {error && ( // Display the error message
        <center>
          <Typography
            style={{ fontSize: "34px", marginTop: "50px", color: red[600] }}
          >
            {error}
          </Typography>
        </center>
      )}
    </ListGroup>
  );
};
