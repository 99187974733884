import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import Constants from "../../../constants";
import AuthService from "../../services/AuthService";
import { Col, ListGroup, Row } from "react-bootstrap";
import { grey } from "@mui/material/colors";
import { useTranslation } from "react-i18next";

export const MountPointPopup = (props) => {
  const { server, port, showMountPointPopup, closePopup, MountPointClicked } =
    props;
  // State hooks for list of mount points, loading state, and invalid state
  const [mountpointlist, setMountPointList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [invalid, setInvalid] = useState(false);
  const { t, i18n } = useTranslation();

  // UseEffect to make the HTTP request when the component is mounted
  useEffect(() => {
    async function getData() {
      await httpRequestAsync();
    }
    getData();
  }, []);

  /**
   * Asynchronous function that sends an HTTP GET request to the server
   * to retrieve a list of mount points.
   */
  async function httpRequestAsync() {
    let constants = new Constants();
    let baseAddress = constants.BackendURL();
    let requestURI =
      encodeURI(baseAddress) + "/mountpointlist/" + server + "/" + port;
    let auth = new AuthService();
    let token = auth.getTokenFromCookie();
    token = decodeURIComponent(token);
    let Headers = {
      Authorization: "Bearer " + JSON.parse(token),
      "Content-Type": "application/json",
    };
    let mountPointList = [];

    // Try making the HTTP request
    try {
      await fetch(requestURI, {
        method: "GET",
        headers: Headers,
      })
        .then((response) => {
          // Check if the request was successful
          if (!response.ok) {
            setInvalid(true);
          }
          return response.json();
        })
        .then((data) => {
          // If successful, set the mount point list to the data received
          mountPointList = data;
        });
    } catch (ex) {
      // If unsuccessful, alert the user of the error
      setInvalid(true);
      //alert("Failed to retrieve mount points: " + ex.message);
    }

    // Update the mount point list and loading state in the component's state
    setMountPointList(mountPointList);
    setLoading(false);
  }

  // Return the Dialog component
  return (
    <Dialog open={showMountPointPopup} onClose={closePopup}>
      <DialogTitle>
        <Col>
          <Row>
            <center>{t("mowerSettings.mountpointList")}</center>
          </Row>
          <Row>
            {!loading && !invalid && (
              <center>
                <Typography style={{ color: grey[700], fontSize: "12px" }}>
                  <span>{t("mowerSettings.tap")}</span>
                </Typography>
              </center>
            )}
          </Row>
        </Col>
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div>
            <center>
              <CircularProgress className="m-4" />
            </center>
          </div>
        )}
        {invalid && (
          <div>
            <center>
              {/* Displaying the error in UI instead of alert */}
              <Typography style={{ color: grey[700], fontSize: "16px" }}>
                <span>{t("alerts.mountPoints")}</span>
              </Typography>
            </center>
          </div>
        )}
        <ListGroup id="mountpoint-list">
          {mountpointlist.length === 0 && !loading && !invalid && (
            <center>
              <Typography style={{ color: grey[700], fontSize: "14px" }}>
                No mount points available.
              </Typography>
            </center>
          )}

          {mountpointlist.length > 0 &&
            mountpointlist.map((point, i) => {
              return (
                <ListGroup.Item
                  key={i}
                  onClick={() => MountPointClicked({ name: point.name })}
                >
                  <Typography>
                    <span>{point.name}</span>
                  </Typography>
                </ListGroup.Item>
              );
            })}
        </ListGroup>
        <center>
          <Button
            style={{
              textTransform: "capitalize",
              textDecorationLine: "none",
              marginTop: "10px",
            }}
            className="btn amr-btn-primary"
            onClick={closePopup}
          >
            {t("mowerSettings.cancel")}
          </Button>
        </center>
      </DialogContent>
    </Dialog>
  );
};
