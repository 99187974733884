import React, { useCallback, useContext, useEffect, useState } from "react";
import { WebSocketContext } from "../components/services/AppConnection";
import { Row, Col } from "react-bootstrap";
import Constants from "../constants";
import User from "../components/models/user";
import Mower from "../components/models/mower";
import AuthService from "../components/services/AuthService";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import user from "../components/models/user";
import { withRouter } from "../components/withRouter";
import { SocketMessageType } from "../components/models/enums";
import { MowerList } from "../components/pages/machinesPage/MowerList";
import { ConnectMowerDialog } from "../components/pages/machinesPage/ConnectMowerDialog";
import { MowerInfoDialog } from "../components/pages/machinesPage/MowerInfoDialog";
import { useToast } from "../components/customToast/ToastContext";
import { useTranslation } from "react-i18next";

/**
 * This function compares two JavaScript Map objects, map1 and map2, and determines if they are equal.
 * @function MapsAreEqual
 * @param {Map} map1 - The first map to be compared.
 * @param {Map} map2 - The second map to be compared.
 * @returns {boolean | number} - Returns true if the maps are equal in terms of keys and values.
 * Returns false if the maps are not equal. Returns -1 if either of the maps is not provided.
 *
 * @example
 * MapsAreEqual(new Map([["a", 1]]), new Map([["a", 1]])); // returns true
 * MapsAreEqual(new Map([["a", 1]]), new Map([["b", 2]])); // returns false
 * MapsAreEqual(null, new Map([["b", 2]])); // returns -1
 */
function MapsAreEqual(map1, map2) {
  if (!map1 || !map2) {
    return -1;
  }

  if (map1.size !== map2.size) {
    return false;
  }

  for (let [key, value] of map1) {
    if (!map2.has(key) || map2.get(key) !== value) {
      return false;
    }
  }

  return true;
}

/**
 * This function takes a map of mower objects as input and converts them into an array of online mowers.
 * @async
 * @function convertToOnlineMowers
 * @param {Map} mowerObjs - A map containing mower objects.
 * @returns {Array} - An array of Mower instances, representing online mowers.
 * Returns undefined if the provided mowerObjs is null.
 *
 * @throws Will throw an error if there's any issue during fetching data from the user.IsTechnician method or any other async operations.
 */
async function convertToOnlineMowers(mowerObjs) {
  if (!mowerObjs) {
    return;
  }
  var sortedMowers = new Map([...mowerObjs.entries()].sort());
  var user = new User();
  var mowerArray = [];

  for (let m of sortedMowers) {
    var mower = new Mower();
    mower.MachineSerialNumber = m[1].serial_number;
    mower.accentColor = m[1].accent_color;
    if (mower.accentColor === "p") {
      mower.mowersPageImage = "/Images/mower_active_purple.png";
    } else if (mower.accentColor === "w") {
      mower.mowersPageImage = "/Images/mower_active_grey.png";
    } else {
      mower.mowersPageImage = "/Images/mower_active_blue.png";
    }
    mower.details = m[1].details;
    mower.connected = m[1].connected;
    mower.otherUserConnected = m[1].other_user_connected;
    mower.online = true;
    mower.CurrentVersion =
      m[1].details.current_release_channel + " " + m[1].details.current_version;
    mower.canConfigure = (await user.IsTechnician()) || m[1].connected;
    mowerArray.push(mower);
  }

  mowerArray.forEach((m) => {
    if (!m.connected) {
      m.mowersPageImage = "/Images/mower_active.png";
    }
  });
  return mowerArray;
}

/**
 * This function takes a map of mower objects as input and converts them into an array of offline mowers.
 * @async
 * @function convertToOfflineMowers
 * @param {Map} mowersObjs - A map containing mower objects.
 * @returns {Array} - An array of Mower instances, representing offline mowers.
 * Returns undefined if the provided mowersObjs is null.
 *
 * @throws Will throw an error if there's any issue during fetching data from the User.IsTechnician method or any other async operations.
 */
async function convertToOfflineMowers(mowersObjs) {
  if (!mowersObjs) {
    return;
  }
  var sortedMowers = new Map([...mowersObjs.entries()].sort());
  var User = new user();
  var mowerArray = [];

  for (let m of sortedMowers) {
    var mower = new Mower();
    mower.MachineSerialNumber = parseInt(m[1].machineSerialNumber);
    mower.Latitude = m[1].latitude;
    mower.Longitude = m[1].longitude;
    mower.Id = m[1].id;
    mower.CurrentVersion = m[1].currentVersion;
    mower.TimePositionUpdates = m[1].timePositionUpdated;
    mower.CompanyName = m[1].companyName;
    mower.canConfigure = await User.IsTechnician;
    mower.mowersPageImage = "/Images/mower_offline.png";

    mowerArray.push(mower);
  }

  return mowerArray;
}

/**
 * Converts an array of mower objects into a Map, using machineSerialNumber as the key.
 *
 * @function convertToMowersDB
 * @param {Array} mowerObjs - An array of mower objects.
 * @returns {Map} - A Map object containing the mowers, with machineSerialNumber as the key.
 * If mowerObjs is null, the function returns undefined.
 *
 * @throws {TypeError} Throws if mowerObjs is not an array.
 *
 * @example
 * const mowers = [{machineSerialNumber: '123', name: 'Mower A'}];
 * convertToMowersDB(mowers); // Returns Map with keys ['123'] and respective values.
 */
function convertToMowersDB(mowerObjs) {
  const newMowerobj = new Map();
  if (mowerObjs !== null) {
    mowerObjs.forEach((m) => {
      newMowerobj.set(m.machineSerialNumber, m);
    });

    return newMowerobj;
  }
  return;
}

/**
 * Combines two arrays of mower objects into a single array.
 *
 * @function ConcatAllMowers
 * @param {Array} allMowers - An array containing all the mowers.
 * @param {Array} onlineMowers - An array containing the online mowers.
 * @returns {Array} - An array consisting of the combined lists of allMowers and onlineMowers.
 * If allMowers is null, empty or undefined, an empty array is returned.
 * If onlineMowers is null, 0 or undefined, allMowers is returned as is.
 *
 * @throws {TypeError} Throws if either allMowers or onlineMowers is not an array.
 *
 * @example
 * const all = [{MachineSerialNumber: '123', name: 'Mower A'}];
 * const online = [{MachineSerialNumber: '123', name: 'Mower A Online'}];
 * ConcatAllMowers(all, online); // Returns combined array.
 */
function ConcatAllMowers(allMowers, onlineMowers) {
  let final_list;
  if (allMowers !== null || allMowers.length !== 0 || allMowers === undefined) {
    if (
      onlineMowers === null ||
      onlineMowers === 0 ||
      onlineMowers === undefined
    ) {
      return allMowers;
    } else {
      const matchingMowers = onlineMowers.map((onlinemower) =>
        onlineMowers.find(
          (m) => m.MachineSerialNumber === onlinemower.MachineSerialNumber
        )
      );
      if (matchingMowers !== undefined || matchingMowers.length !== 0) {
        matchingMowers.forEach((selectedMower) => {
          const index = allMowers.findIndex(
            (mower) =>
              mower.MachineSerialNumber === selectedMower.MachineSerialNumber
          );
          if (index !== -1) {
            const matchedMower = { ...allMowers[index] };
            const matchedMowerIndex = onlineMowers.findIndex(
              (mower) =>
                mower.MachineSerialNumber === matchedMower.MachineSerialNumber
            );

            const newData = {
              CompanyName: matchedMower.CompanyName,
              TimePositionUpdates: matchedMower.TimePositionUpdates,
              Id: matchedMower.Id,
            };
            const updatedMower = {
              ...onlineMowers[matchedMowerIndex],
              ...newData,
            };

            onlineMowers[matchedMowerIndex] = updatedMower;

            allMowers.splice(index, 1);
          }
        });

        final_list = onlineMowers.concat(allMowers);
      }

      return final_list;
    }
  }
  return [];
}

/**
 * Represents the main page for displaying all mowers (machines).
 *
 * The MachinesPage provides a comprehensive view of all mowers, displaying both online and offline mowers.
 * It provides functionalities such as searching for a specific mower, viewing mower details, connecting to a mower, and configuring a mower.
 * The WebSocket context is used to manage real-time connection state with mowers.
 *
 * @function MachinesPage
 * @param {Object} props - Properties passed to this component.
 * @returns {JSX.Element} A structured and styled page with mowers list, search functionality, and interactive mower details.
 *
 * @example
 * <MachinesPage {...props} />
 */
const MachinesPage = (props) => {
  const { router } = props;
  const { t, i18n } = useTranslation();
  //We can use useContext to get the WebSocket context
  const connection = useContext(WebSocketContext);
  if (!connection) {
    console.error("WebSocket connection context is not available.");
    // Potentially redirect or provide feedback to the user.
  }
  const [searchVal, setSearchVal] = useState("");
  const [state, setState] = useState({
    showLoadingScreen: true,
    displayed_mowers: [],
    all_mowers: [],
    online_mowers: [],
    SearchValue: "",
    showConnectMowerPU: false,
    showMowerInfoPU: false,
    selectedMower: new Mower(),
    user: new User(),
    NoneFound: false,
    showMowerSettingsPage: false,
  });
  sessionStorage.setItem("SelectedMower", "");

  /**
   * Represents a React component displaying a list of mowers.
   *
   * The component fetches the list of mowers periodically and updates its state accordingly.
   * The mowers can be fetched from both online connections via WebSockets and from a backend service via API calls.
   * It displays a loading screen when the data is being fetched. If no mowers match the search criteria or no mowers are found, a relevant message is shown to the user.
   *
   * @function DisplayMowerList
   * @returns {JSX.Element} A list of mowers or a relevant status message.
   *
   * @example
   * <DisplayMowerList />
   */
  const DisplayMowerList = () => {
    const [displayedMowers, setDisplayedMowers] = useState(
      state.displayed_mowers
    );
    const [showLoadingScreen, setShowLoadingScreen] = useState(
      state.showLoadingScreen
    );
    const [NoneFound, setNoneFound] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
      let offlineMowerList = [];

      const fetchMowersInitially = async () => {
        offlineMowerList = await fetchOfflineMowers();
        await fetchOnlineMowers(offlineMowerList);
      };

      fetchMowersInitially();

      const interval = setInterval(async () => {
        await fetchOnlineMowers(offlineMowerList);
      }, 1000); // Consider increasing the interval duration

      return () => clearInterval(interval);
    }, []);

    const fetchOfflineMowers = useCallback(async () => {
      let constants = new Constants();
      let baseAddress = constants.BackendURL();
      let auth = new AuthService();
      let token = auth.getTokenFromCookie();
      token = decodeURIComponent(token);
      let Headers = {
        Accept: "application/json",
        Authorization: "Bearer " + JSON.parse(token),
      };
      let newList = new Map();
      let returnedArray = [];

      var requestUri = encodeURI(baseAddress) + "/api/Mowers/list";
      //TODO add supportingcompanyname check

      try {
        await fetch(requestUri, {
          method: "GET",
          headers: Headers,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("HTTP status " + response.status);
            }
            return response.json();
          })
          .then((data) => {
            newList = convertToMowersDB(data);
          });
      } catch (exception) {
        setError("Failed to fetch mowers from the backend."); // Set error state
        console.log(exception);
      }
      if (!MapsAreEqual(newList, state.all_mowers)) {
        returnedArray = await convertToOfflineMowers(newList);
      }
      return returnedArray;
    }, []);

    const fetchOnlineMowers = useCallback(async (returnedArray) => {
      let onlinemowerObjs, displayedmowerObjs, LoadingScreenBool;

      if (connection.SocketMessage(SocketMessageType.CheckConnected)) {
        LoadingScreenBool = false;
        let mowers_ = connection.SocketMessage(SocketMessageType.GetMowers);
        if (mowers_ !== undefined) {
          if (mowers_.size > 0) {
            onlinemowerObjs = await convertToOnlineMowers(mowers_);
          } else {
            onlinemowerObjs = [];
          }
        }
      }
      let all_mowers = returnedArray;
      if (all_mowers.length !== 0) {
        displayedmowerObjs = ConcatAllMowers(all_mowers, onlinemowerObjs);
      } else {
        all_mowers = [];
      }

      let searchResults = [];
      if (searchVal.length > 0 && displayedmowerObjs !== undefined) {
        searchResults = displayedmowerObjs.filter((mower) =>
          mower.MachineSerialNumber.toString().includes(searchVal.toLowerCase())
        );
      } else searchResults = displayedmowerObjs;

      if (searchResults !== undefined && searchResults.length === 0) {
        setNoneFound(true);
      } else {
        setDisplayedMowers(searchResults);
      }

      setShowLoadingScreen(LoadingScreenBool);
    }, []);

    const ViewMowerInfo = (props) => {
      const { mower } = props;
      setState((prevState) => ({
        ...prevState,
        showMowerInfoPU: true,
        selectedMower: mower,
      }));
    };

    return (
      <div>
        <MowerList
          connection={connection}
          showLoadingScreen={showLoadingScreen}
          NoneFound={NoneFound}
          displayedMowers={displayedMowers}
          error={error}
          router={router}
          ViewMowerInfo={ViewMowerInfo}
        />
      </div>
    );
  };

  /**
   * Represents a React component for a pop-up dialog allowing a user to connect to a mower by entering a PIN.
   *
   * @function ConnectMowerPopUp
   * @returns {JSX.Element} A pop-up dialog prompting user input for mower's PIN to initiate connection.
   *
   * @example
   * <ConnectMowerPopUp />
   */
  const ConnectMowerPopUp = () => {
    const ClosePopup = () => {
      setState({ ...state, showConnectMowerPU: false });
    };

    return (
      <div>
        <ConnectMowerDialog
          ConnectBtnClicked={ConnectBtnClicked}
          isOpen={state.showConnectMowerPU}
          ClosePopup={ClosePopup}
        />
      </div>
    );
  };

  /**
   * Represents a React component for a pop-up dialog displaying additional information about a connected mower.
   * The component assumes that `state.selectedMower` is an object with several mower-specific properties available.
   *
   * @function ConnectedInfoPopUp
   * @returns {JSX.Element} A pop-up dialog rendering additional mower details.
   * @throws {Error} - Throws if the expected mower details are not available in the state.
   *
   * @example
   * <ConnectedInfoPopUp />
   */
  const ConnectedInfoPopUp = () => {
    if (!state.selectedMower) {
      throw new Error("Expected mower details are not available in the state.");
    }
    const inputDate = state.selectedMower.TimePositionUpdates;

    return (
      <div>
        <MowerInfoDialog
          isOpen={state.showMowerInfoPU}
          ClosePopup={() => setState({ ...state, showMowerInfoPU: false })}
          mower={state.selectedMower}
          date={inputDate}
        />
      </div>
    );
  };

  /**
   * Handles the logic for connecting to a mower when the "Connect" button is clicked.
   *
   * @function ConnectBtnClicked
   * @param {string} pin - Pin number entered by the user.
   * @throws {TypeError} - Throws if pin is not a string.
   * @throws {RangeError} - Throws if the pin length is not valid (less than 6 characters).
   *
   * @example
   * ConnectBtnClicked('123456');  // Attempts to connect to the mower with the provided pin.
   */
  const ConnectBtnClicked = (pin) => {
    if (pin.length < 6) {
      alert(t("alerts.pinLength"), "error");
      return;
    } else {
      //send the pin as 0 so that the user can connect to the mower using just the pin #
      try {
        connection.SocketMessage(SocketMessageType.ConnectToMower, {
          id: 0,
          pin: pin,
        });
      } catch (ex) {
        alert(t("alerts.errorConnecting"), "error");
        return; // Exiting the function since an error occurred.
      }
    }
    setState({
      ...state,
      showConnectMowerPU: false,
    });
  };

  return (
    <Row>
      <Col className="m-3">
        <div>
          <center>
            <div className="search-bar">
              <TextField
                id="standard-basic"
                label={t("machine.machineSerialNumber")}
                variant="standard"
                type="search"
                className="search-bar-item"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.currentTarget.value);
                }}
              />
              <p className="btn amr-btn-primary search-bar-button">
                <SearchIcon />
              </p>

              <Link
                onClick={(e) =>
                  setState({ ...state, showConnectMowerPU: true })
                }
                className="w-95 mt-2 btn amr-btn-primary"
              >
                {t("machine.connect")}
              </Link>
            </div>
          </center>

          <div>
            <DisplayMowerList />
          </div>

          {state.showConnectMowerPU && <ConnectMowerPopUp />}

          {state.showMowerInfoPU && <ConnectedInfoPopUp />}
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(MachinesPage);
