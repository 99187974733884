import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DialogContent,
  DialogTitle,
  Dialog,
  Typography,
  TextField,
} from "@material-ui/core";
import { useToast } from "../../customToast/ToastContext";
import { useTranslation } from "react-i18next";

export const ConnectMowerPopup = (props) => {
  const { visible, closePopup, ConnectBtnClicked } = props;
  const [pinValue, setPinValue] = useState("");
  const { t, i18n } = useTranslation();

  /**
   * Validate the pin entered by the user.
   * If the pin is invalid, display an appropriate message and return false.
   *
   * @returns {boolean} True if the pin is valid, otherwise false.
   */
  const validatePin = () => {
    // Assuming a valid pin is numeric and is of length 4
    if (pinValue.length !== 6 || isNaN(pinValue)) {
      alert(t("alerts.validPin"), "error");
      return false;
    }
    return true;
  };

  return (
    <div>
      <Dialog open={visible} onClose={closePopup}>
        <DialogTitle style={{ paddingBottom: "0px" }} className="text-center">
          {t("machine.connectMower")}
        </DialogTitle>
        <DialogContent style={{ width: "300px", paddingTop: "0px" }}>
          <Row>
            <Col>
              <Typography className="text-center">
                <span>{t("machine.enterNumber")}</span>
              </Typography>
              <TextField
                id="standard-basic-2"
                label={t("machine.enterPin")}
                variant="standard"
                value={pinValue}
                onChange={(e) => setPinValue(e.currentTarget.value)}
                margin="none"
                className="d-flex justify-content-center"
              />
              <Row className="pt-3 justify-content-center">
                <Link
                  onClick={(e) => {
                    e.preventDefault();
                    // Validate pin before proceeding
                    if (validatePin()) {
                      ConnectBtnClicked({ pin: pinValue });
                    }
                  }}
                  className="btn amr-btn-primary w-75"
                >
                  {t("machine.connect")}
                </Link>
              </Row>
            </Col>
          </Row>
        </DialogContent>
      </Dialog>
    </div>
  );
};
