import { Col, Row } from "react-bootstrap";
import { InputLabel, FormControl, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * The TopBar component displays a dropdown menu for quick-selection of mowers.
 *
 * @param {Object} props
 * @param {number} props.height - The height of the row containing the dropdown.
 * @param {number} props.selection - The currently selected mower.
 * @param {Function} props.QuickselectMowerChanged - Callback when a new mower is selected.
 * @param {Array} props.mowerlist - List of mowers to be displayed in the dropdown.
 */
const TopBar = (props) => {
  const { height, selection, QuickselectMowerChanged, mowerlist } = props;
  const [dropdown, setDropDown] = useState([]);
  const { t, i18n } = useTranslation();
  /**
   * Updates the dropdown list based on the provided mowers.
   *
   * @param {Array} mowers - The list of mowers.
   */
  function UpdateDropdown(mowers) {
    let newDropdown = [];
    let update = false;

    mowers.forEach((m) => {
      if (!dropdown.includes(m.serial_number)) {
        newDropdown.push(m.serial_number);
        update = true;
      } else {
        newDropdown.push(m.serial_number);
      }
    });

    if (!update && dropdown.length !== newDropdown.length) {
      update = true;
    }

    if (update) {
      setDropDown(newDropdown);
    }
  }

  /**
   * Effect to ensure that the dropdown is in sync with the mowerlist.
   * Also sets up a poll every 2 seconds to check for updates.
   */
  useEffect(() => {
    UpdateDropdown(mowerlist);

    let interval = setInterval(() => {
      UpdateDropdown(mowerlist);
    }, 2000);

    return () => clearInterval(interval);
  }, [mowerlist, dropdown]);

  return (
    <Row style={{ height: height, marginTop: "10px", marginBottom: "10px" }}>
      <Col style={{ width: "fit-content", marginTop: "-5px" }}>
        <FormControl fullWidth>
          <InputLabel id="mower_quick_select_label">
            {t("monitor.quickSelect")}
          </InputLabel>
          <Select
            value={selection}
            size="small"
            labelId="mower_quick_select_label"
            label={"Quick-Select Mower"}
            onChange={(e) => QuickselectMowerChanged(e)}
          >
            <MenuItem value={0} style={{ width: "100%" }}>
              <em>{t("monitor.none")}</em>
            </MenuItem>
            {dropdown !== null &&
              dropdown !== undefined &&
              [...dropdown].map((mower, i) => {
                return (
                  <MenuItem key={i} style={{ width: "100%" }} value={mower}>
                    {t("monitor.mower")} {mower}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Col>
    </Row>
  );
};

export default TopBar;
