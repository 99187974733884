import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "../components/withRouter";
import AuthService from "../components/services/AuthService";
import User from "../components/models/user";
import {
  Typography,
  Select,
  MenuItem,
  Button,
  Switch,
  FormControlLabel,
  FormGroup,
  TextField,
  Slider,
} from "@mui/material";
import { styled } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { green, grey, red } from "@mui/material/colors";
import { WebSocketContext } from "../components/services/AppConnection";
import { SocketMessageType } from "../components/models/enums";
import { useToast } from "../components/customToast/ToastContext";
import { useTranslation } from "react-i18next";

/**
 * Class to represent user preferences for mowing settings.
 *
 * This class provides functionality to serialize its instance to a string and deserialize from a string.
 * It provides default values for user preferences if none are provided during object creation.
 *
 * @class
 * @property {number} defaultOverlap - The default overlap in inches.
 * @property {number} defaultCutPattern - The default cut pattern. (0 represents 'stripe').
 * @property {number} defaultStripeAngle - The default stripe angle.
 * @property {number} defaultMowSpeed - The default mowing speed in percent.
 * @property {number} defaultPerimeterMode - The default perimeter mode (0 represents 'Mow perimeter first').
 */
class UserPreferences {
  constructor(props) {
    this.defaultOverlap = props.defaultOverlap || 10; // in inches
    this.defaultCutPattern = props.defaultCutPattern || 0; // stripe
    this.defaultStripeAngle = props.defaultStripeAngle || 0; // TODO: ADD CODE THAT MAKES THIS WORK WITH THE SUTO STRIPE FEATURE
    this.defaultMowSpeed = props.defaultMowSpeed || 80; // in percent
    this.defaultPerimeterMode = props.defaultPerimeterMode || 0; // Mow perimeter first
  }

  defaultOverlap;
  defaultCutPattern;
  defaultStripeAngle;
  defaultMowSpeed;
  defaultPerimeterMode;

  /**
   * Converts a serialized UserPreferences string back into a UserPreferences object.
   *
   * @param {string} str - The serialized UserPreferences string.
   * @returns {UserPreferences} - A new instance of UserPreferences.
   */
  Deserialize = (str) => {
    try {
      if (str === null || str === "") {
        return new UserPreferences();
      } else {
        return JSON.parse(str);
      }
    } catch (error) {
      console.error("Error deserializing UserPreferences:", error);
      return new UserPreferences(); // Return default UserPreferences on error.
    }
  };

  /**
   * Converts the current UserPreferences object into a string.
   *
   * @returns {string} - The serialized representation of the UserPreferences instance.
   */
  Serialize = () => {
    try {
      return JSON.stringify(this);
    } catch (error) {
      console.error("Error serializing UserPreferences:", error);
      return "{}"; // Return an empty object string on error.
    }
  };
}

/**
 * This function retrieves various user preferences from the local storage
 * and creates a UserPreferences object with those preferences.
 *
 * If a particular preference isn't found in local storage, default values are assigned.
 *
 * @function
 * @name GetUserPreferences
 * @returns {UserPreferences} - An instance of the UserPreferences class populated with the retrieved preference values.
 */
export function GetUserPreferences() {
  try {
    //verify defaultCutPattern
    let defaultcutpattern = localStorage.getItem("cutPattern");
    defaultcutpattern = defaultcutpattern ? parseInt(defaultcutpattern) : 0;

    //verify defaultStripeAngle
    let defaultstripeangle = localStorage.getItem("stripeAngle");
    defaultstripeangle = defaultstripeangle
      ? parseFloat(defaultstripeangle)
      : 0.0;

    //verify defaultOverLap
    let defaultoverlap = localStorage.getItem("overlapDistance");
    defaultoverlap = defaultoverlap ? parseFloat(defaultoverlap) : 10;

    //verify defaultMowSpeed
    let defaultmowspeed = localStorage.getItem("mowSpeed");
    defaultmowspeed = defaultmowspeed ? parseFloat(defaultmowspeed) : 80.0;

    //verify defaultPerimeterMode
    let defaultperimetermode = localStorage.getItem("perimeterMode");
    defaultperimetermode = defaultperimetermode
      ? parseInt(defaultperimetermode)
      : 0;

    var props = new UserPreferences({
      defaultCutPattern: defaultcutpattern,
      defaultStripeAngle: defaultstripeangle,
      defaultOverlap: defaultoverlap,
      defaultMowSpeed: defaultmowspeed,
      defaultPerimeterMode: defaultperimetermode,
    });

    return props;
  } catch (error) {
    console.error("Error getting user preferences:", error);
    return new UserPreferences(); // Return default UserPreferences on error.
  }
}

/**
 * Represents a component that displays a settings page for the user.
 *
 * The SettingsPage component is responsible for loading the user's settings from the backend, identifying if the user is a technician, and rendering appropriate settings options accordingly.
 *
 * @component
 * @name SettingsPage
 * @param {object} props - The properties passed to this component.
 * @returns {JSX.Element} - The rendered JSX element.
 */
const SettingsPage = (props) => {
  const connection = useContext(WebSocketContext);
  const [state, setState] = useState({
    CurrentUser: new User(),
    Preferences: null,
    UserIsTechnicial: false,
  });

  const { t, i18n } = useTranslation();

  useEffect(() => {
    try {
      let auth = new AuthService();
      auth.enforceLogin();
      try {
        if (sessionStorage.getItem("SelectedMower") !== "")
          sessionStorage.setItem("SelectedMower", "");
      } catch (error) {}

      let user = new User();
      let isTech = false;
      try {
        isTech = user.IsTechnician();
      } catch (error) {
        console.warn("Error determining if user is a technician:", error);
        // Here, isTech will remain false.
      }
      setState({
        UserIsTechnicial: isTech,
      });
    } catch (error) {
      console.error("Error initializing settings page:", error);
      // Optionally: You could set some state here to show an error message to the user or take some other recovery action.
    }
  }, []);

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&:after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiButtonBase-root": {
      position: "absolute",
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
      {
        backgroundColor: "#65b713",
      },
    "& .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked": {
      color: "#338216",
    },
  }));

  const SimpleMowSettings = () => {
    const [cutPatternInput, setCutPatternInput] = useState(0);
    const [perimeterModeInput, setPerimeterMode] = useState(0);
    const [autoStripeAngle, setAutoStripeAngle] = useState(true);
    const [stripeAngle, setStripeAngle] = useState("0");
    const [overlapDistance, setOverlapDistance] = useState("10");
    const [mowSpeed, setMowSpeed] = useState(5);
    const [mowSpeedText, setMowSpeedText] = useState("25%");
    const [overridePlan, setOverridePlans] = useState(false);
    const [mowSpeedError, setMowSpeedError] = useState(false);
    const [overlapError, setOverlapError] = useState(false);
    const [stripeAngleError, setStripeAngleError] = useState(false);
    const [success, setSuccess] = useState(false);
    const CutPattern = [
      t("settings.stripe"),
      t("settings.cross"),
      t("settings.spiral"),
    ];
    const PerimeterMode = [
      t("settings.perimeterFirst"), //Mow the perimeter before striping
      t("settings.perimeterLast"), //Mow the perimeter after striping
      t("settings.skip"), //Don't mow the perimeter at all
    ];

    useEffect(() => {
      LoadSettings();
    }, []);

    /**
     * Fetches user preferences from localStorage and updates corresponding state variables.
     *
     * This function invokes the GetUserPreferences function to get user preferences and then maps these preferences to relevant state variables.
     *
     * @function
     * @name LoadSettings
     */
    const LoadSettings = () => {
      var prefs = GetUserPreferences();
      setCutPatternInput(Number(prefs.defaultCutPattern)); //number
      setStripeAngle(Math.abs(Number(prefs.defaultStripeAngle)).toString()); //string
      setOverlapDistance(prefs.defaultOverlap.toString()); //string
      setOverridePlans(parseBool(localStorage.getItem("overridePlan" || true))); //bool
      let numSpeed = Math.round(prefs.defaultMowSpeed / 5);
      setSpeedInfo(numSpeed);
      setPerimeterMode(Number(prefs.defaultPerimeterMode)); //number
      setAutoStripeAngle(parseBool(localStorage.getItem("autoStripe" || true))); //bool
    };

    /**
     * Validates input values and updates localStorage with user preferences.
     *
     * This function first checks if provided user preferences are valid. If all values are valid, updates are saved in the localStorage. The updated preferences are then sent to any connected mowers. After successfully saving the preferences, the `Success` state variable is set to true, indicating the user's changes have been saved.
     *
     * @function
     * @name handleSaveButtonClicked
     */
    const handleSaveButtonClicked = () => {
      let errorFlag = false;
      if (Math.round(mowSpeed) * 5 <= 20) {
        errorFlag = true;
        setMowSpeedError(true);
      } else setMowSpeedError(false);
      if (overlapDistance > 40) {
        errorFlag = true;
        setOverlapError(true);
      } else setOverlapError(false);
      if (stripeAngle < 0) {
        errorFlag = true;
        setStripeAngleError(true);
      } else setStripeAngleError(false);
      if (!errorFlag) {
        if (autoStripeAngle === true) {
          let dangle = -parseFloat(stripeAngle);
          if (dangle === 0.0) {
            dangle = -1;
          }
        }
        localStorage.setItem("cutPattern", cutPatternInput);
        localStorage.setItem("overlapDistance", parseFloat(overlapDistance));
        localStorage.setItem("overridePlan", overridePlan);
        localStorage.setItem("stripeAngle", parseFloat(stripeAngle));
        localStorage.setItem("autoStripe", autoStripeAngle);
        let mowspeed = Math.round(mowSpeed) * 5.0;
        localStorage.setItem("mowSpeed", mowspeed);
        localStorage.setItem("perimeterMode", perimeterModeInput);

        try {
          connection.SocketMessage(SocketMessageType.UpdateUserPreferences, {
            preferences: GetUserPreferences().Serialize(),
          });
        } catch (ex) {
          console.error("Error updating user preferences:", ex);
          alert(t("alerts.updating"), "error");
        }

        setTimeout(() => {
          setSuccess(true);
        }, 500);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      }
    };

    /**
     * Updates the `speedInfo` state variable when a change event is triggered on the plan speed slider.
     *
     * @function
     * @name handlePlanSpeedSliderChange
     * @param {object} event - The event object associated with the slider change.
     * @param {number} num - The numeric value returned from the slider.
     */
    const handlePlanSpeedSliderChange = (event, num) => {
      try {
        setSpeedInfo(num);
      } catch (ex) {
        console.error("Error setting speed info:", ex);
      }
    };

    /**
     * Calculates and sets the mow speed and its corresponding text representation.
     *
     * This function calculates the mow speed percentage and its representation in miles per hour based on a given numeric input. These values are then used to update relevant state variables.
     *
     * @function
     * @name setSpeedInfo
     * @param {number} num - Numeric input to calculate mow speed and its text representation.
     */
    const setSpeedInfo = (num) => {
      try {
        let val = Math.round(num) * 5.0;
        let val2 = 6 * (val / 100);
        val2 = Math.ceil(val2 * 100) / 100;

        let text = val.toString() + "%";

        setMowSpeed(num);
        setMowSpeedText(text);
      } catch (ex) {
        console.error("Error updating mow speed state variables:", ex);
      }
    };

    /**
     * Converts a given input into its boolean representation.
     *
     * If the provided input is a string and matches "true" (case-insensitive) or "1", the function returns `true`. For any other string, it returns `false`. For non-string values, it directly returns the input value.
     *
     * @function
     * @name parseBool
     * @param {*} value - The value to be converted to its boolean representation.
     * @returns {boolean} - Returns the boolean representation of the input value.
     */
    const parseBool = (value) => {
      if (typeof value === "string") {
        return value.toLowerCase() === "true" || value === "1";
      }
      return value;
    };

    return (
      <div>
        <Row style={{ margin: "10px" }}>
          <Typography
            style={{ fontSize: "24px", textDecorationLine: "underline" }}
          >
            <span>
              <b>{t("settings.simpleMow")}</b>
            </span>
          </Typography>
        </Row>
        <Row style={{ margin: "10px", alignItems: "center" }}>
          <Col>
            <Typography style={{ fontSize: "18px" }}>
              <span>{t("settings.cutPattern")}</span>
            </Typography>
          </Col>
          <Col>
            <Row style={{ justifyContent: "end" }}>
              <Select
                onChange={(e) => setCutPatternInput(e.target.value)}
                value={cutPatternInput}
                size="small"
                style={{ width: "146px" }}
              >
                {CutPattern.map((val, i) => {
                  return (
                    <MenuItem value={i} key={i}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </Row>
          </Col>
        </Row>
        {cutPatternInput !== 2 && (
          <div>
            <Row style={{ margin: "10px", alignItems: "center" }}>
              <Col style={{ minWidth: "fit-content" }}>
                <Typography style={{ fontSize: "18px" }}>
                  <span>{t("settings.setStripe")} </span>
                </Typography>
              </Col>
              <Col style={{ alignItems: "end" }}>
                <Row>
                  <FormGroup>
                    <FormControlLabel
                      style={{ justifyContent: "end", margin: "0px" }}
                      control={
                        <CustomSwitch
                          checked={autoStripeAngle}
                          onChange={(e) => setAutoStripeAngle(e.target.checked)}
                        />
                      }
                    />
                  </FormGroup>
                </Row>
              </Col>
            </Row>
            <Row style={{ margin: "10px", alignItems: "center" }}>
              <Col style={{ minWidth: "fit-content" }}>
                <Typography style={{ fontSize: "18px" }}>
                  <span>{t("settings.stripeAngle")} </span>
                </Typography>
              </Col>
              <Col>
                <Row style={{ justifyContent: "end" }}>
                  <TextField
                    error={stripeAngleError}
                    helperText={stripeAngleError ? "Cannot be negative" : ""}
                    required
                    style={{ width: "150px" }}
                    disabled={autoStripeAngle}
                    id="standard"
                    label="°"
                    type="number"
                    min={0.0}
                    max={180.0}
                    value={stripeAngle}
                    onChange={(e) => setStripeAngle(e.currentTarget.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size="small"
                  ></TextField>
                </Row>
              </Col>
            </Row>
          </div>
        )}
        <Row style={{ margin: "10px", alignItems: "center" }}>
          <Col style={{ minWidth: "fit-content" }}>
            <Typography style={{ fontSize: "18px" }}>
              <span>{t("settings.overlap")}</span>
            </Typography>
          </Col>
          <Col>
            <Row style={{ justifyContent: "end" }}>
              <TextField
                error={overlapError}
                helperText={overlapError ? "Must be less than 40" : ""}
                required
                style={{ width: "150px" }}
                id="standard"
                label="in"
                type="number"
                min={5.0}
                max={50.0}
                value={overlapDistance}
                onChange={(e) => setOverlapDistance(e.currentTarget.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              ></TextField>
            </Row>
          </Col>
        </Row>
        <Row style={{ margin: "10px", alignItems: "center" }}>
          <Col style={{ minWidth: "fit-content" }}>
            <Typography style={{ fontSize: "18px" }}>
              <span>{t("settings.mowSpeed")} </span>
            </Typography>
            <Typography style={{ fontSize: "18px" }}>
              <span>{mowSpeedText}</span>
            </Typography>
          </Col>
          <Col>
            <Row style={{ justifyContent: "end", textAlign: "end" }}>
              <Slider
                sx={{ color: red[500], width: "150px" }}
                value={mowSpeed}
                min={0}
                max={20}
                step={1}
                valueLabelDisplay="off"
                onChange={handlePlanSpeedSliderChange}
              ></Slider>
              {mowSpeedError && (
                <Typography style={{ fontSize: "11px", color: red[800] }}>
                  <span>{t("settings.speedError")}</span>
                </Typography>
              )}
            </Row>
          </Col>
        </Row>
        <Row style={{ margin: "10px", alignItems: "center" }}>
          <Col>
            <Typography style={{ fontSize: "18px" }}>
              <span>{t("settings.perimeterMode")} </span>
            </Typography>
          </Col>
          <Col>
            <Row style={{ justifyContent: "end" }}>
              <Select
                onChange={(e) => setPerimeterMode(e.target.value)}
                value={perimeterModeInput}
                size="small"
                style={{ width: "146px" }}
              >
                {PerimeterMode.map((val, i) => {
                  return (
                    <MenuItem value={i} key={i}>
                      {val}
                    </MenuItem>
                  );
                })}
              </Select>
            </Row>
          </Col>
        </Row>
        <Row style={{ margin: "10px", alignItems: "center" }}>
          <Col style={{ minWidth: "fit-content" }}>
            <Typography style={{ fontSize: "18px" }}>
              <span>{t("settings.override")} </span>
            </Typography>
          </Col>
          <Col style={{ alignItems: "end" }}>
            <Row>
              <FormGroup>
                <FormControlLabel
                  style={{ justifyContent: "end", margin: "0px" }}
                  control={
                    <CustomSwitch
                      checked={overridePlan}
                      onChange={(e) => setOverridePlans(e.target.checked)}
                    />
                  }
                />
              </FormGroup>
            </Row>
          </Col>
        </Row>
        {cutPatternInput === 2 && (
          <Row className="text-center" style={{ margin: "10px" }}>
            <Col style={{ minWidth: "fit-content" }}>
              <Typography style={{ fontSize: "12px" }}>
                <span>{t("settings.note")}</span>
              </Typography>
            </Col>
          </Row>
        )}
        <Row
          style={{
            margin: "10px",
            marginTop: "50px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            className="btn amr-btn-primary"
            style={{
              width: "100px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              handleSaveButtonClicked();
            }}
          >
            {t("settings.save")}
          </Button>
        </Row>
        {success && (
          <Row
            style={{
              margin: "10px",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                color: green[600],
              }}
            >
              <b>
                <span>{t("settings.alert")}</span>
              </b>
            </Typography>
          </Row>
        )}
      </div>
    );
  };

  return (
    <Col>
      <SimpleMowSettings />
    </Col>
  );
};

export default withRouter(SettingsPage);
