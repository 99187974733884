import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { withRouter } from "../components/withRouter";
import { WebSocketContext } from "../components/services/AppConnection";
import { red, yellow, grey } from "@mui/material/colors";
import {
  MowSection,
  MowingPlan,
  MowingPlanJsonProperties,
  PlanToSplit,
  Position,
} from "../components/models/mowingPlan";
import {
  MenuItem,
  Button,
  InputLabel,
  Select,
  FormControl,
  Typography,
  IconButton,
} from "@mui/material";
import AuthService from "../components/services/AuthService";
import { MowerState, SocketMessageType } from "../components/models/enums";
import {
  UpdateAvailableDialogBox,
  LoadingBackdrop,
} from "../components/pages/MowPage/SimpleComponents";
import { Row, Col } from "react-bootstrap";
import WbIridescentIcon from "@mui/icons-material/WbIridescent";
import { MowerTab } from "../components/pages/MowPage/MowerTab";
import MapComponent from "../components/map/mapComponent";
import Constants from "../constants";
import { Dangerous, LayersClear } from "@mui/icons-material";
import { ActionButtonState } from "../components/models/enums";
import { MapMower } from "../components/map/mapMower";
import { StatusLightGuide } from "../components/pages/MowPage/StatusLightGuide";
import { DisplayActionSheet } from "../components/pages/MowPage/DisplayActionSheet";
import { SplitActionSheet } from "../components/pages/MowPage/SplitActionSheet";
import { FaultListPopup } from "../components/pages/MowPage/FaultListPopup";
import GoogleApiHOC from "../components/map/GoogleApiHOC";
import { useToast } from "../components/customToast/ToastContext";
import Mower from "../components/models/mower";
import { useTranslation } from "react-i18next";

/**
 * Represents a data structure that contains detailed information about a mower.
 *
 * This class is used to encapsulate various attributes of a mower, such as its visual representation, status, progress, and other metadata.
 *
 * @class
 * @name MowerDetailStruct
 * @property {string} line1Text - The main text or title representing the mower.
 * @property {string} line2Text - The secondary text providing additional information about the mower.
 * @property {string} line2TextColor - The color of the secondary text.
 * @property {string} fuelText - Text providing information about the mower's fuel status.
 * @property {string} serialNum - The serial number of the mower.
 * @property {number} progress - Represents the progress or completion percentage of the mower's current task.
 * @property {string} accentColor - A color that can be used for accenting UI elements related to this mower.
 * @property {boolean} isSelected - Indicates whether the mower is currently selected or active.
 * @property {string} fuelImageSource - The URL or path to an image representing the mower's fuel status.
 * @property {string} mowerImageSource - The URL or path to an image representing the mower.
 * @property {number} opacity - A value between 0 and 1 representing the opacity of the mower's representation in the UI.
 * @property {string} signalImageSource - The URL or path to an image representing the mower's signal or connectivity status.
 * @property {string} actionButtonState - Indicates the current state of an action button related to the mower (e.g., "start", "stop").
 * @property {object} home_position - An object representing the mower's home or base position, possibly with `x` and `y` coordinates.
 */
class MowerDetailStruct {
  line1Text;
  line2Text;
  line2TextColor;
  fuelText;
  serialNum;
  progress;
  accentColor;
  isSelected;
  fuelImageSource;
  mowerImageSource;
  opacity = 1;
  signalImageSource;
  actionButtonState;
  home_position;
}

/**
 * Enumeration representing different types of mower plans.
 *
 * This object provides a set of named constants that can be used to refer to specific types of mower plans throughout the application.
 * By freezing the object, it ensures these values remain constant and cannot be altered, providing type-safety.
 *
 * @typedef {Object} MowerPlanType
 * @constant
 * @namespace
 * @property {number} PlanPreview - Refers to a mower plan that is still in the preview stage and not yet active.
 * @property {number} CurrentPlan - Refers to the mower's currently active and operational plan.
 * @property {number} RecordingPlan - Refers to a plan that is currently being recorded or documented.
 * @property {number} NoPlan - Indicates the absence of an active or selected plan for the mower.
 *
 * @example
 * if(selectedPlanType === MowerPlanType.PlanPreview) {
 *   // Handle logic for plan previews
 * }
 */
export const MowerPlanType = Object.freeze({
  PlanPreview: 0,
  CurrentPlan: 1,
  RecordingPlan: 2,
  NoPlan: 3,
});

const MowPage = (props) => {
  const connection = useContext(WebSocketContext);
  const { router } = props;
  const [selection, setSelection] = useState(0);
  const [selectedSerialNumber, setSelectedSerialNumber] = useState(0);
  const [appeared, setAppeared] = useState(false);
  const mowersListRef = useRef(new Map());
  const [loadedPlan, setLoadedPlan] = useState(null);
  const { t, i18n } = useTranslation();
  var mowerDeselectedText = t("mow.selectMower");
  const [state, setState] = useState({
    updateLabel: "Update available for your mower",
    updateCanDecline: true,
    showDisplayActionSheet: false,
    showSplitActionSheet: false,
    numSplit: null,
    tappedLatLng: null,
    clickedPlan: -1,
    showFaultList: false,
    faults: [],
  });
  const [ScreenDimension, setScreenDimension] = useState({
    ScreenHeight: 0,
    ScreenWidth: 0,
    MapHeight: 0,
    TopBarHeight: 0,
    ToolbarHeight: 0,
  });
  const [ToolBarButtonRef, setToolBar] = useState({
    ButtonWidth: "0px",
    ButtonFontSize: "0px",
    SelectPlanBtnWidth: "0px",
  });
  const MowerInfoRef = useRef(new MowerDetailStruct());
  const TrackingRef = useRef(false);
  const CenteredRef = useRef(false);
  const IsSplitPlanRef = useRef(false);
  const MowerStatusLabelRef = useRef(mowerDeselectedText);
  const selectedSerialNumberRef = useRef(0);

  /**
   * Fetches a specific company mowing plan from the backend API based on the provided plan ID.
   *
   * This function is asynchronous and communicates with the backend API to retrieve detailed information about the mowing plan.
   * It is constructed to make a GET request to the specific endpoint, process the response, and return the retrieved plan details in an array.
   *
   * @async
   * @function
   * @param {string|number} planID - The unique identifier for the desired mowing plan.
   * @returns {Promise<Array<MowingPlan>>} - An array of `MowingPlan` objects, representing the details of the fetched plan.
   *
   * @throws Will throw an error if the fetch request fails or if the response indicates a failed status.
   *
   * @example
   * const mowingDetails = await GetCompanyMowingPlan("12345");
   */
  const GetCompanyMowingPlan = async (planID) => {
    //ensure that a valid Id is passed to the function.
    if (!planID) {
      console.error("Item identifier (planID) missing or invalid.");
      return false;
    }
    //MowingPlans: Initially an empty array, it will hold the fetched plans from the API.
    let MowingPlans = [];
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI = encodeURI(baseAddress) + "/api/mowingplans/get/" + planID;
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    //Fetch the mowing plans from the backend API using the constructed request URI and appropriate headers.
    try {
      await fetch(requestURI, {
        method: "GET",
        headers: Headers,
      })
        .then((response) => {
          //Check for a successful response.
          //If the response is not successful, an error is thrown with the status code.
          if (!response.ok) {
            throw new Error(
              "There was an error getting the plan from the databare. HTTP Status: " +
                response.status
            );
          }
          return response.json();
        })
        .then((data) => {
          //Parse the fetched data as JSON.
          if (data === null) {
            alert(t("alerts.errorLoading"), "error");
            return;
          }
          MowingPlans = data;
        });
    } catch (ex) {
      console.error(ex);
      if (ex.message.includes("NetworkError")) {
        alert(t("alerts.network"), "error");
      } else {
        alert(t("alerts.load"), "error");
      }
      return [];
    }

    //Convert the list of plans into instances of the MowingPlan class.
    //newPlans: Array that will store the converted plans as instances of MowingPlan.
    let newPlans = MowingPlans.map((plan) => {
      return new MowingPlan(plan);
    });

    //Return the array of converted plans.
    return newPlans;
  };

  /**
   * Splits a given mowing plan into multiple sections based on the specified parameters.
   *
   * This function sends a request to the backend to split a mowing plan into a number of sections specified by `numSplits`.
   * The width of each cut and the stripe angle are also provided to ensure that the sections are split accurately.
   * The function returns a list of mowing sections after successful processing.
   *
   * @function
   * @async
   *
   * @param {Object} plan - The mowing plan that needs to be split. It should have all the necessary attributes required by the backend API.
   * @param {number} numSplits - The number of sections the plan should be split into.
   * @param {number} cutWidth - The width of each cut or mow.
   * @param {number} stripeangle - The angle of the stripe.
   *
   * @throws {Error} Throws an error if the parameters are not valid or if there is an issue with the fetch request.
   *
   * @returns {Array} Returns an array of mowing sections after the plan has been split. If the split is unsuccessful, it returns an empty array.
   *
   * @example
   * const plan = { /* ...plan details... * / };
   * const sections = await SplitPlan(plan, 5, 10, 45);
   */
  const SplitPlan = async (plan, numSplits, cutWidth, stripeangle) => {
    //ensure that valid params are passed to the function.
    if (!plan || !numSplits || !cutWidth || !stripeangle) {
      throw new Error("Invalid  properties passed to Split Plan");
    }
    //MowSections: list of mow sections to be returned from the call
    let MowSections = [];
    //Set up necessary variables for the fetch request using the Constants and AuthService classes.
    //constants: Instance of Constants class, which presumably holds constant values like URLs.
    let constants = new Constants();
    //baseAddress: Backend API's base URL.
    let baseAddress = constants.BackendURL();
    //requestURI: Complete URI for the mowing plans list endpoint.
    let requestURI =
      encodeURI(baseAddress) +
      "/api/mowingplans/SplitPlan/" +
      numSplits +
      "/" +
      cutWidth +
      "/" +
      stripeangle;
    //auth: Instance of AuthService class, which provides methods related to authentication.
    let auth = new AuthService();
    //token: Stores the authentication token fetched from cookies.
    let token = auth.getTokenFromCookie();
    //Before making the request, ensure that the token exists
    if (!token) {
      console.error("Authentication token missing.");
      auth.logout();
    }
    //handle potential parsing issues.
    try {
      token = JSON.parse(token);
    } catch (error) {
      throw new Error(
        "There was an error with your login, try logging out and back in and try again"
      );
    }
    //content: the new plan name to be applied to the selected name, sent over as a JSON obj
    let content;
    try {
      content = JSON.stringify(plan);
    } catch (err) {
      console.error("Error serializing property JSON:", err);
      return false;
    }
    //Headers: Contains request headers, including JSON content type and authorization token.
    let Headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };

    //Now attempt the fetch request
    try {
      await fetch(requestURI, {
        method: "POST",
        headers: Headers,
        body: content,
      })
        .then((response) => {
          //Check for a successful response.
          //If the response is not successful, an error is thrown with the status code.
          if (!response.ok) {
            throw new Error(
              `Failed to split plan with Id: ${plan.Id}. HTTP Status: ${response.status}`
            );
          }
          return response.json();
        })
        .then((data) => {
          if (data === null) {
            alert(t("alerts.errorParsing"), "error");
            return [];
          }
          MowSections = JSON.parse(data);
        });
    } catch (ex) {
      console.error(ex);
      alert(
        `${t("alerts.unableSplit")} ${plan.Id}. ${t("alerts.contact")}`,
        "error"
      );
      return [];
    }

    //Return th parsed data
    return MowSections;
  };

  /**
   * Checks for the presence of a selected mower in the session storage and updates the relevant state and references if it exists.
   *
   * This function looks up the session storage for a key named "SelectedMowerMow". If it exists and is not an empty string,
   * the function will parse the mower's details from the session storage and use them to update the component's state
   * and references. After updating the state, it invokes the `UpdateMowerDetails` function to further process the mower's details.
   *
   * Note: There's an ambiguity regarding the attribute name used for the mower's serial number. The function currently
   * assumes it to be `MachineSerialNumber`, but it might also be `serial_number`. Ensure to check the exact attribute name
   * based on the structure of the data stored in the session.
   *
   * @function
   *
   * @example
   * CheckMowerInSesh();
   */
  function CheckMowerInSesh() {
    let mower;
    if (
      sessionStorage.getItem("SelectedMowerMow") &&
      sessionStorage.getItem("SelectedMowerMow") !== ""
    ) {
      try {
        mower = JSON.parse(sessionStorage.getItem("SelectedMowerMow"));
      } catch (error) {
        console.error("Error parsing mower data from session storage:", error);
        return;
      }

      let serialNumber = mower.MachineSerialNumber || mower.serial_number;
      if (!serialNumber) {
        console.error("Serial number not found in mower data");
        return;
      }

      try {
        sessionStorage.setItem("SelectedMowerMow");
      } catch (err) {}
      TrackingRef.current = true;
      setSelectedSerialNumber(serialNumber);
      setSelection(serialNumber);
      selectedSerialNumberRef.current = serialNumber;
      UpdateMowerDetails();
    }
    return;
  }

  /**
   * Checks session storage for selected mower and plan details, then updates the component's state and references accordingly.
   *
   * This function performs two primary tasks:
   * 1. It verifies the presence of a selected mower in the session storage under the key "SelectedMower". If present,
   *    the function will parse the mower's details, update the component's state and references related to the mower,
   *    and invoke the `UpdateMowerDetails` function for further processing.
   * 2. It checks for the presence of a selected plan in the session storage using the key "SelectedPlan". If found,
   *    the function will parse the plan's details and utilize the `LoadPlan` function to process these details.
   *
   * This function makes the assumption that the serial number attribute of the mower object is `serial_number`. Ensure that
   * this aligns with the data structure stored in session storage.
   *
   * @function
   *
   * @example
   * CheckPlanInSesh();
   */
  function CheckPlanInSesh() {
    let plan;
    let mower;
    // Check and process mower details from session storage
    if (
      sessionStorage.getItem("SelectedMower") &&
      sessionStorage.getItem("SelectedMower") !== ""
    ) {
      try {
        mower = JSON.parse(sessionStorage.getItem("SelectedMower"));
      } catch (error) {
        console.error("Error parsing mower data from session storage:", error);
        return;
      }

      if (!mower.serial_number) {
        console.error("Serial number not found in mower data");
        return;
      }

      TrackingRef.current = true;
      setSelectedSerialNumber(mower.serial_number);
      setSelection(mower.serial_number);
      selectedSerialNumberRef.current = mower.serial_number;
      UpdateMowerDetails();
    }

    // Check and process plan details from session storage
    if (
      sessionStorage.getItem("SelectedPlan") &&
      sessionStorage.getItem("SelectedPlan") !== ""
    ) {
      try {
        plan = JSON.parse(sessionStorage.getItem("SelectedPlan"));
      } catch (error) {
        console.error("Error parsing plan data from session storage:", error);
        return;
      }

      LoadPlan(plan);
    }
  }

  function RemoveMowerFromSesh(serial_number) {
    let mower;
    // Check and process mower details from session storage
    if (
      sessionStorage.getItem("SelectedMower") &&
      sessionStorage.getItem("SelectedMower") !== ""
    ) {
      try {
        mower = JSON.parse(sessionStorage.getItem("SelectedMower"));
      } catch (error) {
        console.error("Error parsing mower data from session storage:", error);
        return;
      }

      if (!mower.serial_number) {
        console.error("Serial number not found in mower data");
        return;
      }

      if (serial_number === mower.serial_number) {
        sessionStorage.setItem("SelectedMowerMow", "");
        sessionStorage.setItem("SelectedMower", "");
      }
    }
  }

  /**
   * Processes the mower data received from the server and updates the state and visual representation of the mowers
   * in the application.
   *
   * The function primarily performs the following tasks:
   * 1. Marks all mowers in the application as inactive.
   * 2. Iterates over the received data and either updates an existing mower object or creates a new one.
   * 3. For each mower, it updates its status based on the provided state.
   * 4. Processes various mower states to provide user-friendly information and status messages.
   * 5. Flags any mower that is stuck and prepares the application for additional user feedback.
   * 6. Updates the current list of mowers based on the provided data.
   *
   * This function expects the mower data to include attributes like `serial_number`, `state`, `speed`, `progress`,
   * `mode_change_reason`, `lat`, and `lon`. Ensure that the provided data aligns with these expectations.
   *
   * @function
   * @name AppMowerDataUpdate
   *
   * @param {Object[]} obj - Array of mower objects containing data received from the server.
   *
   * @example
   * let mowerData = [
   *   { serial_number: 123, state: "Idle", ... },
   *   { serial_number: 456, state: "Mowing", ... }
   * ];
   * AppMowerDataUpdate(mowerData);
   */
  function AppMowerDataUpdate(obj) {
    var pins = [];
    FilterOfflineMowers(obj);
    let mowerlist = mowersListRef.current;

    //flag everything as inactive so we can reflag them below
    mowerlist.forEach((m) => {
      m.active = false;
    });
    let stuck_mower = 0;
    obj.forEach((m) => {
      //okay, create the mower if we4 haven't already and mark it as active
      let mower;
      if (mowerlist.has(m.serial_number)) {
        mower = new MapMower(mowerlist.get(m.serial_number));
      } else {
        //create it and insert it
        mower = new MapMower(m);
        mowerlist.set(m.serial_number, mower);
      }

      //update anything necessary in the object
      mower.data = m;
      mower.active = true;
      //Update the status text for this mower
      let s = m.state;
      if (s === MowerState.Idle || s === MowerState.Manual) {
        mower.info_string = t("mow.loadPlan");
      } else if (s === MowerState.Ready) {
        mower.info_string = t("mow.pressYes");
      } else if (s === MowerState.Mowing) {
        mower.info_string = t("mow.pressPause");
      } else if (s === MowerState.Stuck) {
        mower.info_string = t("mow.pressPause");
      } else if (s === MowerState.ReturningHome) {
        mower.info_string = t("mow.pressPause");
      } else if (s === MowerState.Paused) {
        mower.info_string = t("mow.pressResume");
      } else if (s === MowerState.Faulted) {
        mower.info_string = t("mow.faultOccured");
      } else if (s === MowerState.FaultCleared) {
        mower.info_string = t("mow.faultClearedPress");
      } else if (s === MowerState.Recording) {
        mower.info_string = t("mow.display");
      } else if (s === MowerState.Finished) {
        mower.info_string = t("mow.load");
      } else if (s === MowerState.LocalPause) {
        mower.info_string = t("mow.resume");
      } else if (s === MowerState.Updating) {
        mower.info_string = t("mow.please");
      } else {
        mower.info_string = t("mow.unknown");
      }

      //tell the user to start the engine if it is not running
      if ((mower.data.flags & 1) === 0) {
        if ((mower.data.flags & 2) === 0) {
          mower.info_string = t("mow.notRunning");
        } else {
          mower.info_string = t("mow.pleaseStart");
        }
      }

      //if we are selected, update anything necessary here
      var id = m.serial_number;

      //determine the status string for this mower
      var ss = m.serial_number.toString() + ": ";
      //update status string
      if (s === MowerState.Idle) {
        ss += t("mow.idle");
      } else if (s === MowerState.Manual) {
        ss += t("mow.manual");
      } else if (s === MowerState.Ready) {
        ss += t("mow.ready");
      } else if (s === MowerState.Busy) {
        ss += t("mow.busy");
      } else if (s === MowerState.Finished) {
        ss += t("mow.finished");
      } else if (s === MowerState.Mowing) {
        let speedmph = m.speed * 2.236; //to mph
        ss +=
          t("mow.mowing") +
          m.progress.toString() +
          "% " +
          speedmph.toFixed(1) +
          " mph";
      } else if (s === MowerState.Stuck) {
        ss += t("mow.stuck");
        stuck_mower = m.serialNum;
      } else if (s === MowerState.Paused || s === MowerState.LocalPause) {
        ss += t("mow.paused");
      } else if (s === MowerState.Faulted) {
        ss += t("mow.fault");
      } else if (s === MowerState.FaultCleared) {
        ss += t("mow.faultCleared");
      } else if (s === MowerState.ReturningHome) {
        ss += t("mow.returning");
      } else if (s === MowerState.Recording) {
        ss += t("mow.recording");
      } else if (s === MowerState.Updating) {
        ss += t("mow.updating") + m.progress.toString() + "%";
      }

      if (m.mode_change_reason !== null && m.mode_change_reason.length > 0) {
        ss += " (" + m.mode_change_reason + ")";
      }
      mower.status_string = ss;
      mower.status_string2 = m.status_string;

      if (
        mower.status_string2 === t("mow.systemGood") &&
        s === MowerState.Mowing
      ) {
        if (m.time_remaining >= 120) {
          mower.status_string2 =
            (m.time_remaining / 60).toFixed(2).toString() + t("mow.minutes");
        } else {
          mower.status_string2 =
            m.time_remaining.toFixed(2).toString() + t("mow.seconds");
        }
      }

      if (m.lat !== 0.0 && m.lon !== 0.0) {
        pins.push(mower.pin);
      }

      mowerlist.set(m.serial_number, mower);
    });

    if (stuck_mower > 0) {
      //TODO IMPLEMENT STUCK MOWER BAR
    }
    //TODO maybe add to another useeffect the centering and trackinng of the mower stuff?

    //mowersListRef.current = newMowerList;
  }

  /**
   * Filters out and removes mowers from the application that are not present in the received data.
   *
   * The function primarily does the following:
   * 1. Creates a new map, `newMowers`, to store mowers that are still online.
   * 2. Iterates over the provided mower data (`mowerObjects`).
   * 3. If a mower from the received data is found in the current list of mowers (`mowers`), it is added to `newMowers` and removed from `mowers`.
   * 4. Any mower remaining in `mowers` after this process is considered offline. If one of these offline mowers was the currently selected mower in the application, it is deselected.
   * 5. The reference list of mowers (`mowersListRef.current`) is updated to the `newMowers` list.
   *
   * This function is typically used after receiving an updated list of online mowers from the server, to ensure the application's state matches the latest data.
   *
   * @function
   * @name RemoveOfflineMowers
   *
   * @param {Object[]} mowerObjects - Array of mower objects containing data received from the server.
   *
   * @example
   * let onlineMowerData = [
   *   { serial_number: 123, ... },
   *   { serial_number: 456, ... }
   * ];
   * RemoveOfflineMowers(onlineMowerData);
   */
  function FilterOfflineMowers(mowerObjects) {
    let mowers = mowersListRef.current;
    let newMowers = new Map();
    mowerObjects.forEach((m) => {
      if (mowers.has(m.serial_number)) {
        let mower = mowers.get(m.serial_number);

        if (mower.data.connected) {
          newMowers.set(m.serial_number, mower);
          mowers.delete(m.serial_number);
        }
      }
    });
    mowers.forEach((m) => {
      if (m.data.serial_number === selectedSerialNumberRef.current) {
        RemoveMowerFromSesh(m.data.serial_number);
        DeselectMower();
      }
    });

    mowersListRef.current = newMowers;
  }

  useEffect(() => {
    if (!connection) {
      console.error("Connection object is not properly initialized");
      return;
    }
    let mowers = connection.SocketMessage(SocketMessageType.GetMowers);
    if (mowers.size < 0) {
      router.navigate("/MowerConnect");
    }
  }, []);

  useEffect(() => {
    //verify the user is still logged in
    let auth = new AuthService();
    auth.enforceLogin();

    let disconnectInterval = setInterval(() => {
      if (!connection.SocketMessage(SocketMessageType.CheckConnected)) {
        setAppeared(false);
      } else {
        setAppeared(true);
      }
    }, 200);

    CheckMowerInSesh();
    CheckPlanInSesh();

    let AppUpdate = setInterval(() => {
      if (!connection) {
        console.error("Connection object is not properly initialized");
        return;
      }
      let mowers = connection.SocketMessage(SocketMessageType.GetMowers);
      if (mowers.size > 0) {
        AppMowerDataUpdate(mowers);
      } else {
        router.navigate("/MowerConnect");
      }
    }, 200);

    //if nothing is connected, redirect to the connect page
    //check if any mowers are connected
    if (!connection) {
      console.error("Connection object is not properly initialized");
      return;
    }
    try {
      var connected = connection.SocketMessage(
        SocketMessageType.AnyMowersConnected
      );
    } catch (error) {
      console.error("There was an error connecting to the WebSocket");
      return;
    }
    setTimeout(() => {
      if (!connected) {
        router.navigate("/MowerConnect");
      } else {
        if (!document || !document.documentElement) {
          console.error("Cannot access document dimensions.");
          return;
        }
        //First, set the dimensions of the components based on the screen height
        var mapHeight, toolbarHeight, screenWidth, screenHeight, topbarHeight;
        //get the height/width of the screen
        screenHeight = document.documentElement.clientHeight;
        screenWidth = document.documentElement.clientWidth;
        //Set TopBar, Map, and ToolBar height
        topbarHeight = Math.round((screenHeight * 0.05).toString()) + "px";
        mapHeight = Math.round((screenHeight * 0.65).toString()) + "px";
        toolbarHeight = Math.round((screenHeight * 0.3).toString()) + "px";
        //Set entire screen height and width
        screenHeight = screenHeight.toString() + "px";
        screenWidth = Math.round(screenWidth).toString() + "px";
        GenerateButtonSize(screenWidth);
        setScreenDimension({
          ScreenHeight: screenHeight,
          ScreenWidth: screenWidth,
          MapHeight: mapHeight,
          TopBarHeight: topbarHeight,
          ToolbarHeight: toolbarHeight,
        });
      }
    }, 500);

    return () => {
      //Clear all of the intervals so they don't keep running
      clearInterval(disconnectInterval);
      clearInterval(AppUpdate);
    };
  }, [connection]);

  /**
   * Adjusts the size of buttons based on the screen width.
   *
   * This function calculates the width and font size for the buttons based on the screen width provided.
   * Once the sizes are calculated, it updates the toolBar state with these new values.
   *
   * @function
   * @name GenerateButtonSize
   *
   * @param {string} ScreenWidth - The width of the screen as a string with a "px" suffix (e.g., "1024px").
   *
   * @example
   *
   * GenerateButtonSize("1920px");
   * // This will set the button sizes based on a screen width of 1920 pixels.
   */
  function GenerateButtonSize(ScreenWidth) {
    const DEFAULT_BUTTON_SIZE = 113;
    const DEFAULT_SELECTPLAN_BUTTON_SIZE = 118;
    const DEFAULT_FONT_SIZE = 15;
    const SCREEN_PERCENT = 3.68;
    const FONT_SIZE_RATIO = 7.5333;
    if (typeof ScreenWidth === "string") {
      var w = Number(ScreenWidth.replace("px", ""));
    }
    if (w !== 0) {
      var SizeNums = {
        ButtonWidth: 0,
        ButtonFontSize: 0,
        SelectPlanBtnWidth: 0,
      };
      var calculateWidth = 0;
      calculateWidth = w / SCREEN_PERCENT;
      if (calculateWidth > DEFAULT_BUTTON_SIZE) {
        SizeNums.ButtonWidth = DEFAULT_BUTTON_SIZE;
        SizeNums.SelectPlanBtnWidth = DEFAULT_SELECTPLAN_BUTTON_SIZE;
      } else {
        SizeNums.ButtonWidth = Math.round(calculateWidth);
        SizeNums.SelectPlanBtnWidth = Math.round(calculateWidth + 5);
      }
      calculateWidth = Math.round(calculateWidth) / FONT_SIZE_RATIO;

      if (calculateWidth > 15) {
        SizeNums.ButtonFontSize = DEFAULT_FONT_SIZE;
      } else {
        SizeNums.ButtonFontSize = Math.round(calculateWidth);
      }

      setToolBar({
        ...ToolBarButtonRef,
        ButtonWidth: SizeNums.ButtonWidth.toString() + "px",
        ButtonFontSize: SizeNums.ButtonFontSize.toString() + "px",
        SelectPlanBtnWidth: SizeNums.SelectPlanBtnWidth.toString() + "px",
      });
    }
  }

  /**
   * Selects a specific mower based on its serial number.
   *
   * This function sets the state of the selected mower in the application,
   * based on the provided serial number. It also updates the mower details and
   * manages tracking. If the provided serial number is the same as the current
   * selected mower, the mower's details are just updated.
   *
   * @function
   * @name SelectMower
   *
   * @param {number} serial_num - The serial number of the mower to select.
   *
   * @returns {boolean} - Returns `true` if the mower with the provided serial number
   *                      is selected successfully, otherwise returns `false`.
   *
   * @example
   *
   * SelectMower(12345);
   * // This will select and update the mower with serial number 12345.
   */
  function SelectMower(serial_num) {
    if (serial_num !== selectedSerialNumberRef.current) {
      MowerStatusLabelRef.current = "Status Loading...";
      let selected_mower;

      if (mowersListRef.current.has(serial_num)) {
        selected_mower = mowersListRef.current.get(serial_num);
        if (
          selected_mower.pin.position.lat !== 0.0 ||
          selected_mower.pin.position.lng
        ) {
          TrackingRef.current = true;
          setSelectedSerialNumber(serial_num);
          setSelection(serial_num);
          selectedSerialNumberRef.current = serial_num;
        }
      } else {
        TrackingRef.current = false;
        setSelection(0);
        setSelectedSerialNumber(0);
        selectedSerialNumberRef.current = 0;
      }
    } else {
      selectedSerialNumberRef.current = serial_num;
    }
    UpdateMowerDetails();
  }

  /**
   * Deselects the currently selected mower.
   *
   * This function resets the selected mower's state in the application to indicate
   * that no mower is currently selected. It also updates the mower details after
   * the selection is cleared. If the current selection matches the stored serial
   * number in the reference, the selection is reset and the display is updated.
   *
   * @function
   * @name DeselectMower
   *
   * @example
   *
   * DeselectMower();
   * // This will clear the currently selected mower and update the display.
   */
  function DeselectMower() {
    TrackingRef.current = false;

    if (
      selection === selectedSerialNumberRef.current ||
      selection === selectedSerialNumber
    ) {
      setSelection(0);
      selectedSerialNumberRef.current = 0;
      setSelectedSerialNumber(0);
    }
    sessionStorage.setItem("SelectedMower", "");
    sessionStorage.setItem("SelectedMowerMow", "");
    UpdateMowerDetails();
  }

  /**
   * Checks if an update is available for the provided mower and updates the state to show an update prompt if necessary.
   *
   * The function checks if there's an available update for the specified mower. If an update is available and the mower isn't already in the updating state,
   * it sets the appropriate state to show an update prompt to the user. If the update is required, the user won't have an option to decline.
   *
   * @function
   * @name CheckForUpdate
   *
   * @param {object} selected_mower - The mower object for which the update check needs to be performed.
   *
   * @example
   *
   * CheckForUpdate(selectedMower);
   * // Checks if there's an update available for `selectedMower` and updates the state to show an update prompt if necessary.
   */
  function CheckForUpdate(selected_mower) {
    let showupdatebar = false;
    let updatebarlabel = "";
    let candeclineupdate = false;

    if (
      selected_mower.update_already_asked === false &&
      selected_mower.data.details.update_available &&
      selected_mower.data.state !== MowerState.Updating
    ) {
      showupdatebar = true;
      if (selectedSerialNumber !== 0) {
        if (selected_mower.data.details.required_update) {
          updatebarlabel =
            t("monitor.mower") +
            selectedSerialNumber.toString() +
            ": Required update available. Update now? \nMower will not go into autonomy mode until updated. \nThis process may take a while, do not power off while updating.";
          candeclineupdate = false;
        } else {
          updatebarlabel =
            t("monitor.mower") +
            selectedSerialNumber.toString() +
            ": Update available. Update now? \nThis process may take a while, do not power off while mower is updating";
          candeclineupdate = true;
        }
      }
    }
  }

  /**
   * Updates the detailed view of the selected mower to be shown in the mower tabs. This function prepares and updates
   * various properties of the mower such as status, action buttons, accent colors, progress, and signal strength.
   *
   * The function takes into account the mower's state, fuel level, flags, faults, accent color, and other properties to
   * customize its representation on the UI. These details get saved to the `MowerInfoRef` reference, and a serialized
   * version of the mower is stored in the session storage for future reference.
   *
   * @function
   * @name UpdateMowerTabsDetails
   *
   * @param {object} selected_mower - The mower object whose details need to be updated and shown in the mower tabs.
   *
   * @example
   *
   * UpdateMowerTabsDetails(selectedMower);
   * // Processes and updates detailed properties of `selectedMower` for representation in the mower tabs.
   */
  function UpdateMowerTabsDetails(selected_mower) {
    try {
      sessionStorage.setItem(
        "selectedMowerToolBar",
        JSON.stringify(selected_mower)
      );
    } catch (err) {
      console.error("There was an error parsing the selected mower info", err);
    }

    let s = new MowerDetailStruct();
    s.actionButtonState = ActionButtonState.Nothing;
    s.line1Text = selected_mower.status_string;
    s.line2Text = selected_mower.status_string2;
    s.line2TextColor = grey[900];
    if (
      selected_mower.data.details.faults !== null &&
      selected_mower.data.details.faults.length > 2
    ) {
      s.line2Text = "(Tap Mower for Faults)";
      s.line2TextColor = red[500];
    } else if (s.line2Text.includes("Warning")) {
      s.line2TextColor = yellow[900];
    } else if (s.line2Text.includes("Error")) {
      s.line2TextColor = red[500];
    } else if (s.line2Text.includes("Fault")) {
      s.line2TextColor = red[500];
    }
    s.serialNum = selected_mower.data.serial_number;
    switch (selected_mower.data.accent_color) {
      case "p":
        s.accentColor = "#800080";
        break;
      case "w":
        s.accentColor = "#FFD3D3D3";
        break;
      case "b":
        s.accentColor = "#2196F3";
        break;
      default:
        s.accentColor = grey[900];
    }

    //progress for mower
    s.progress = selected_mower.data.progress;
    s.isSelected = true;
    s.opacity = selected_mower.recently_tapped ? 0.5 : 1;

    var signal_strength = (selected_mower.data.flags & 0b00011100) >> 2;

    if (signal_strength == 0) {
      s.signalImageSource = "/Images/signal_strength_0.png";
    } else if (signal_strength == 1) {
      s.signalImageSource = "/Images/signal_strength_1.png";
    } else if (signal_strength == 2) {
      s.signalImageSource = "/Images/signal_strength_2.png";
    } else if (signal_strength == 3) {
      s.signalImageSource = "/Images/signal_strength_3.png";
    } else if (signal_strength == 4) {
      s.signalImageSource = "/Images/signal_strength_4.png";
    }

    //update mower state
    let state = selected_mower.data.state;
    if (
      state === MowerState.Mowing ||
      state === MowerState.ReturningHome ||
      state === MowerState.Busy
    ) {
      s.actionButtonState = ActionButtonState.Pause;
      s.mowerImageSource = "/Images/mowerpause.png";
    } else if ((selected_mower.data.flags & 0b00000001) === 0) {
      // if mower isnt running let the user start it
      s.actionButtonState = ActionButtonState.StartEngine;
      s.mowerImageSource = "/Images/mower_start_engine.png";

      // If we cant start the engine right now, disable the start button
      if ((selected_mower.data.flags & 0b00000010) === 0) {
        s.opacity = 1.0;
        s.actionButtonState = ActionButtonState.Nothing;
        s.mowerImageSource = "/Images/mowerwait.png";
      }
    } else if (state === MowerState.Paused) {
      s.actionButtonState = ActionButtonState.Resume;
      s.mowerImageSource = "/Images/mowerplay.png";
    } else if (state === MowerState.Ready || state === MowerState.LocalPause) {
      s.mowerImageSource = "/Images/mowerwait.png";
    } else if (state === MowerState.FaultCleared) {
      s.actionButtonState = ActionButtonState.Resume;
      s.mowerImageSource = "/Images/mowerplay.png";
    } else if (state === MowerState.Faulted) {
      s.mowerImageSource = "/Images/MowerFault.png";
    } else {
      s.mowerImageSource = "/Images/mower.png";
    }

    var fuel_fraction = selected_mower.data.fuel_level;
    if (fuel_fraction >= 95) {
      s.fuelImageSource = "/Images/gascangreen.png";
    } else if (fuel_fraction >= 40) {
      s.fuelImageSource = "/Images/gascanyellow.png";
    } else {
      s.fuelImageSource = "/Images/gascanred.png";
    }

    MowerInfoRef.current = s;
  }

  /**
   * Updates the status label of the selected mower based on its current state and the loaded plan (if any).
   *
   * The function primarily checks if a plan is loaded and if the mower suggests loading a plan. If a plan is
   * loaded but not a section of the plan, it updates the status label to prompt the user to select a section of
   * the plan. If there's no such condition met, it simply displays the information string provided by the mower.
   * If the mower isn't connected, it displays a default `mowerDeselectedText`.
   *
   * @function
   * @name UpdateStatusLabel
   *
   * @param {object} selected_mower - The mower object whose status label needs to be updated.
   *
   * @example
   *
   * UpdateStatusLabel(selectedMower);
   * // Updates the status label for `selectedMower` based on its state and the loaded plan.
   */
  function UpdateStatusLabel(selected_mower) {
    if (
      !selected_mower ||
      selected_mower === null ||
      !selected_mower?.data?.connected
    ) {
      MowerStatusLabelRef.current = mowerDeselectedText;
      return;
    }
    if (
      selected_mower.info_string === t("mow.loadPlan") &&
      loadedPlan !== null &&
      loadedPlan.SelectedPolygon === 0 &&
      loadedPlan.MowSections !== null &&
      loadedPlan.MowSections.length > 1
    ) {
      MowerStatusLabelRef.current = "Select a Plan Section to Continue";
    } else {
      MowerStatusLabelRef.current = selected_mower.info_string;
    }
  }

  /**
   * This function acts as a central point to update various details and status related to the selected mower.
   * @name UpdateMowerDetails
   */
  function UpdateMowerDetails() {
    let selected_mower;
    selected_mower = mowersListRef.current.get(selectedSerialNumber);
    //selected_mower = new MapMower(selected_mower);
    if (selected_mower && selected_mower.data.connected) {
      UpdateStatusLabel(selected_mower);
      CheckForUpdate(selected_mower);
      UpdateMowerTabsDetails(selected_mower);
    } else {
      MowerStatusLabelRef.current = mowerDeselectedText;
    }
  }

  /**
   * Clears both the path plan preview (if exists) and the loaded path plan from the user interface.
   *
   * This function is primarily designed to ensure that the preview and loaded path plans are reset
   * or removed when called. The function checks if the `previewPolygon` or `loadedPlan` are currently
   * set, and if so, updates their values to null, essentially clearing them from the UI.
   *
   * @function
   * @name ClearPathPlanPreview
   *
   * @example
   *
   * ClearPathPlanPreview();
   * // Clears both the path plan preview and the loaded path plan from the UI.
   */
  function ClearPathPlanPreview() {
    if (loadedPlan) {
      setLoadedPlan(null);
    }
  }

  /**
   * Loads a mowing plan into the application based on its ID.
   *
   * This function is designed to load a mowing plan based on the passed plan's ID. It begins by validating
   * the existence of the plan, followed by checking if it's a predefined plan or a newly created one.
   * For predefined plans, the plan data is retrieved using its ID. For new plans, a new mowing plan is created
   * based on the provided plan data.
   *
   * The function checks if the plan is segmented into multiple sections. If yes, it validates each section
   * to ensure that they form valid polygons. If no, it validates the entire plan's points. In case the
   * validation fails, an alert is displayed and the loading process is terminated.
   *
   * At the end of the function, the loaded plan is displayed in the UI and any previously loaded plans
   * or previews are removed.
   *
   * @function
   * @name LoadPlan
   * @async
   *
   * @param {Object} plan - The mowing plan to load.
   *
   * @throws {Error} If the passed plan is invalid or the areas defined in the plan don't form valid polygons.
   *
   * @example
   *
   * LoadPlan(planData);
   * // Loads the specified mowing plan, displays it in the UI, and removes any previously loaded plans or previews.
   */
  async function LoadPlan(plan) {
    const RECENTLY_MOWED_PLAN_ID = -255;
    if (plan === null || plan === undefined) {
      throw new Error("Invalid Plan provided");
    }
    if (plan.Id !== RECENTLY_MOWED_PLAN_ID) {
      // try {
      //   var newplans = await GetCompanyMowingPlan(plan.Id);
      // } catch (error) {
      //   console.error(
      //     `There was an unexpected error while trying to get the plan ${plan.ID}: Error ${error}`
      //   );
      // }
      var newplan = new MowingPlan(plan);
    } else {
      newplan = new MowingPlan(plan);
    }
    if (newplan.MowSections) {
      //Grab splitted plan from DB then check??
      newplan.MowSections.forEach((mow_section) => {
        mow_section = new MowSection(
          mow_section.Points,
          mow_section.ExclusionPoints === ""
            ? undefined
            : mow_section.ExclusionPoints
        );
        var planpoints = mow_section.GetPoints();

        //Make sure the plan is a valid polygon
        if (planpoints.length < 3) {
          alert(t("alerts.area"), "error");
          UnloadPlan();
          return;
        }
      });
    } else {
      var planpoints = newplan.GetPoints();
      //Make sure the plan is a valid polygon
      if (planpoints.length < 3) {
        alert(t("alerts.area"), "error");
        UnloadPlan();
        return;
      }
    }
    UnloadPlan();
    //TODO SHOW PLAN NAME IN THE TITLE BAR
    setLoadedPlan(newplan);
    UpdatePlanPreview(true);
    TrackingRef.current = false;
    CenteredRef.current = true;
  }

  /**
   * Unloads a currently loaded mowing plan from the application.
   *
   * The `UnloadPlan` function is responsible for removing any mowing plan that is currently loaded into
   * the application. It achieves this by resetting related state variables, updating session storage,
   * and making necessary UI updates.
   *
   * The function starts by removing the selected plan from the session storage. It then sets the state
   * variable `loadedPlan` to `null`, effectively indicating that no plan is currently loaded. The UI is
   * then updated to reflect the absence of a loaded plan by calling the `UpdatePlanPreview` function with
   * the argument `false`.
   *
   * Additionally, the function updates references related to tracking and centering. After unloading a plan,
   * tracking is enabled (`TrackingRef.current` is set to `true`), and centering is disabled
   * (`CenteredRef.current` is set to `false`).
   *
   * @function
   * @name UnloadPlan
   *
   * @example
   *
   * UnloadPlan();
   * // Clears any loaded mowing plan, resets relevant state variables, and updates the UI to remove the plan preview.
   */
  function UnloadPlan() {
    //IsSplitPlanRef.current = false;
    sessionStorage.setItem("SelectedPlan", "");
    setLoadedPlan(null);
    UpdatePlanPreview(false);
    TrackingRef.current = true;
    CenteredRef.current = false;
  }

  /**
   * Updates the preview of a loaded mowing plan on the UI.
   *
   * The `UpdatePlanPreview` function is dedicated to modifying the appearance of the mowing plan
   * preview on the user interface. It initiates by clearing the current plan polygons and any existing
   * path plan preview.
   *
   * If no mowing plan is loaded (as denoted by `loadedPlan` being `null`), the function will reset
   * the `CenteredRef` to `false` and exit without making further changes.
   *
   * If there's an active plan, the function will retrieve the properties of the loaded plan using the
   * `GetProperties` method available on the loaded plan. Depending on what you want to do next with
   * these properties, further actions might be taken.
   *
   * The function also accepts a parameter `center` that specifies whether the map should be centered
   * on the loaded plan. How this is utilized is not evident in the given code snippet but it can be
   * inferred that it is used to adjust the view of the map for better user experience.
   *
   * @function
   * @name UpdatePlanPreview
   * @param {boolean} center - Indicates if the map should be centered on the loaded mowing plan.
   *
   * @example
   *
   * UpdatePlanPreview(true);
   * // Updates the mowing plan preview and centers the map on the loaded plan.
   */
  function UpdatePlanPreview(center) {
    // ClearPathPlanPreview();
    if (loadedPlan === null) {
      CenteredRef.current = false;
      return;
    }
  }

  /**
   * Handles the change event for the quick selection of mowers.
   *
   * The `QuickselectMowerChanged` function responds to the change events typically coming from
   * a dropdown or selection menu where mowers can be quickly chosen for further operations.
   *
   * If a specific mower is selected (as indicated by the selection being not equal to 0),
   * the function will iterate through the `mowersListRef` to find the corresponding mower.
   * When the selected mower is identified, the `TrackingRef` is set to `true` which presumably
   * indicates that the application should begin tracking this mower. The mower is then selected
   * by calling the `SelectMower` function, and the state of the selection is updated using
   * the `setSelection` function.
   *
   * If the selection corresponds to `0`, this indicates that no specific mower is selected.
   * As a result, tracking is turned off by setting `TrackingRef` to `false`, any selected mower
   * is deselected using the `DeselectMower` function, and the selection state is updated accordingly.
   *
   * @function
   * @name QuickselectMowerChanged
   * @param {Object} event - The event object, typically generated from a UI element like a dropdown.
   * @param {number} event.target.value - The value corresponding to the selected mower or 0 for no selection.
   *
   * @example
   *
   * // Assume a dropdown where value 0 indicates no selection, and other values correspond to specific mowers.
   * <select onChange={QuickselectMowerChanged}>
   *    <option value="0">Select a mower</option>
   *    <option value="1">Mower 1</option>
   *    ...
   * </select>
   */
  const QuickselectMowerChanged = (event) => {
    var selection = event.target.value;
    if (selection !== 0) {
      [...mowersListRef.current].forEach((m) => {
        if (m[0] === selection) {
          TrackingRef.current = true;
          SelectMower(selection);
          setSelection(selection);
        }
      });
    } else {
      TrackingRef.current = false;
      DeselectMower();
      setSelection(selection);
    }
  };

  /**
   * The `TopBar` component provides a navigation bar at the top of the UI interface.
   * It offers users the ability to:
   *   1. Connect to a mower using the "Connect" button.
   *   2. Quickly select a mower from a dropdown list.
   *   3. View a guide for status lights using an icon button.
   *
   * Internally, the component maintains a state called `showStatusLightsGuide`,
   * which toggles the visibility of the status lights guide.
   *
   * The `Connect` button uses a routing mechanism to redirect the user to a page
   * or modal where they can initiate a connection to a mower. This is achieved
   * by the `router.navigate` method.
   *
   * The quick-select dropdown populates its list based on the `mowersListRef`
   * ref. It only displays mowers that are connected, as determined by the
   * `data.connected` attribute.
   *
   * The button with the `<WbIridescentIcon />` icon toggles the visibility of
   * the `StatusLightGuide` component which presumably displays information about
   * different status lights and their meanings.
   *
   * @component
   * @name TopBar
   *
   * @example
   *
   * // Include TopBar component in a page
   * <div>
   *   <TopBar />
   *   // Rest of the components...
   * </div>
   *
   * @returns {ReactElement} Renders the top navigation bar.
   */
  const TopBar = () => {
    const [showStatusLightsGuide, setShowStatusLightsGuide] = useState(false);

    const closePopup = () => {
      setShowStatusLightsGuide(false);
    };

    return (
      <Row
        style={{
          height: ScreenDimension.TopBarHeight,
          marginTop: "10px",
          marginBottom: "10px",
        }}
      >
        {showStatusLightsGuide && (
          <StatusLightGuide
            visible={showStatusLightsGuide}
            closePopup={closePopup}
          />
        )}
        <Col style={{ maxWidth: "fit-content" }}>
          <Button
            style={{
              width: "fit-content",
              marginLeft: "5px",
              textTransform: "capitalize",
            }}
            onClick={(e) => {
              e.preventDefault();
              router.navigate("/MowerConnect");
            }}
            className="btn amr-btn-primary"
          >
            {t("mow.connect")}
          </Button>
        </Col>
        <Col style={{ width: "fit-content", marginTop: "-5px" }}>
          <FormControl fullWidth>
            <InputLabel id="mower_quick_select_label">
              {t("monitor.quickSelect")}
            </InputLabel>
            <Select
              value={selection}
              size="small"
              labelId="mower_quick_select_label"
              label={t("mow.quickSelect")}
              onChange={(e) => QuickselectMowerChanged(e)}
            >
              <MenuItem value={0} style={{ width: "100%" }}>
                <em>{t("mow.none")}</em>
              </MenuItem>
              {mowersListRef.current !== null &&
                mowersListRef !== undefined &&
                [...mowersListRef.current].map((mower, i) => {
                  if (mower[1].data.connected) {
                    return (
                      <MenuItem
                        key={i}
                        style={{ width: "100%" }}
                        value={mower[0]}
                      >
                        {t("mow.mower")} {mower[0]}
                      </MenuItem>
                    );
                  }
                })}
            </Select>
          </FormControl>
        </Col>
        <Col
          style={{
            textAlign: "end",
            marginRight: "5px",
            maxWidth: "fit-content",
          }}
        >
          <Button
            style={{ width: "fit-content" }}
            className="btn amr-btn-primary"
            onClick={() => {
              setShowStatusLightsGuide(true);
            }}
          >
            <WbIridescentIcon />
          </Button>
        </Col>
      </Row>
    );
  };

  /**
   * The `MowerAction` component is designed to provide user interactions with
   * a selected mower on a map interface. It offers an action sheet, which
   * includes two primary interactions:
   *   1. **Go To Position**: This sends a command to a selected mower to move to
   *      a specific geographical position.
   *   2. **Set Home Position**: This designates a certain location as the 'home'
   *      position for a selected mower.
   *
   * Both interactions utilize the latitude and longitude of a location tapped
   * on the map (denoted as `state.tappedLatLng`) to send the appropriate
   * command via a socket connection.
   *
   * The component makes use of the `SocketMessage` method from the `connection`
   * object to send commands via the socket. The type of message being sent
   * (e.g., `MowerGoTo` or `MowerSetHome`) determines the action that will be
   * taken by the mower.
   *
   * The action sheet's visibility can be toggled using the `showDisplayActionSheet`
   * state variable. The `closePopup` function sets this state variable to `false`,
   * thereby hiding the action sheet.
   *
   * @component
   * @name MowerAction
   *
   * @example
   *
   * // Use the MowerAction component in a UI
   * <div>
   *   <MowerAction />
   *   // Other components...
   * </div>
   *
   * @returns {ReactElement} Renders the action sheet for mower interactions.
   */
  const MowerAction = () => {
    const GoToClicked = () => {
      if (selectedSerialNumber !== 0) {
        if (state.tappedLatLng !== null) {
          connection.SocketMessage(SocketMessageType.MowerGoTo, {
            serial_number: selectedSerialNumberRef.current,
            lat: state.tappedLatLng.lat,
            lon: state.tappedLatLng.lng,
          });
        }
      }
    };

    const SetHomeClicked = () => {
      if (selectedSerialNumberRef.current !== 0) {
        if (state.tappedLatLng !== null) {
          connection.SocketMessage(SocketMessageType.MowerSetHome, {
            serial_number: selectedSerialNumberRef.current,
            lat: state.tappedLatLng.lat,
            lon: state.tappedLatLng.lng,
          });
        }
      }
    };

    const closePopup = () => {
      setState({ ...state, showDisplayActionSheet: false });
    };

    return (
      <DisplayActionSheet
        visible={state.showDisplayActionSheet}
        closePopup={closePopup}
        SetHomeClicked={SetHomeClicked}
        GoToClicked={GoToClicked}
      />
    );
  };

  /**
   * The `SplitAction` component offers a user interface action sheet
   * that facilitates the division (or "splitting") of a loaded mowing
   * plan into separate, smaller sections. This functionality is especially
   * useful when one wishes to divide a large mowing area into multiple,
   * manageable segments.
   *
   * The component encapsulates several integral functions:
   *   1. **SplitPlanAction**: Given a number of splits desired, this function
   *      performs the splitting operation on the currently loaded mowing plan.
   *      It retrieves the properties of the loaded plan, prepares it for the
   *      split operation, and then attempts the split. After the split operation,
   *      the newly formed mow sections replace the old ones in the loaded plan.
   *   2. **closePopup**: This function closes the split action sheet, resetting
   *      any state related to the number of desired splits.
   *
   * The `SplitAction` component utilizes the `SplitActionSheet` child component
   * to visually render the action sheet for the user. The action sheet's visibility
   * can be controlled using the `showSplitActionSheet` state variable.
   *
   * To ensure performance optimization and to prevent unnecessary re-renders,
   * the `SplitPlanAction` function is wrapped with React's `useCallback` hook.
   *
   * Note: The splitting mechanism relies on various utility classes and functions
   * (e.g., `MowingPlanJsonProperties`, `PlanToSplit`, and `SplitPlan`) that handle
   * the logistics of the splitting operation.
   *
   * @component
   * @name SplitAction
   *
   * @example
   *
   * // Use the SplitAction component in a UI
   * <div>
   *   <SplitAction />
   *   // Other components...
   * </div>
   *
   * @returns {ReactElement} Renders the split action sheet for mowing plan interactions.
   */
  const SplitAction = () => {
    const SplitPlanAction = useCallback(async (splits) => {
      let temp = [];
      if (loadedPlan === null) {
        return;
      }
      let props = new MowingPlanJsonProperties();
      props = props.Deserialize(loadedPlan.Properties);
      let planSplitter = new PlanToSplit(
        loadedPlan.OriginalMowPlan,
        loadedPlan.Properties
      );

      try {
        temp = await SplitPlan(planSplitter, splits, 0.5, props.stripeangle);
      } catch (ex) {
        console.error("Error splitting plans", ex);
      }

      if (temp.length > 0) {
        let tempmowsections = [];
        temp.forEach((plan) => {
          tempmowsections.push(
            new MowSection(plan.Points, plan.ExclusionPoints)
          );
        });
        let newPlans = loadedPlan;
        newPlans.MowSections = tempmowsections;
        //IsSplitPlanRef.current = true;
        setLoadedPlan(newPlans);
      }

      //UpdatePlanPreview(false);
      setState({ ...state, showSplitActionSheet: false });
    }, []);

    const closePopup = () => {
      setState({ ...state, numSplit: null, showSplitActionSheet: false });
    };

    return (
      <SplitActionSheet
        visible={state.showSplitActionSheet}
        closePopup={closePopup}
        SplitPlanAction={SplitPlanAction}
      />
    );
  };

  /**
   * The `FaultList` component presents a user interface dialog box that
   * showcases a list of faults or issues. The list of faults is received
   * as a prop (`fault_array`) and is then forwarded to the `FaultListPopup`
   * component for visual rendering.
   *
   * Key features of this component include:
   *   1. **Fault Listing**: This component acts as a container for displaying
   *      a series of faults, allowing users to be aware of and possibly
   *      address these faults.
   *   2. **UI Components**: The actual visual representation of the faults is
   *      managed by the `FaultListPopup` child component. This design follows
   *      the principle of separation of concerns, where `FaultList` is responsible
   *      for data handling and `FaultListPopup` manages the display.
   *   3. **Popup Management**: The visibility of the dialog box is controlled
   *      by the `showFaultList` state variable. A closure function is provided
   *      to the `FaultListPopup` component to close the popup.
   *
   * @component
   * @name FaultList
   * @param {Array} fault_array - An array of faults or issues to be displayed.
   *
   * @example
   *
   * // Use the FaultList component in a UI, passing an array of faults
   * <div>
   *   <FaultList fault_array={['Fault 1', 'Fault 2', 'Fault 3']} />
   *   // Other components...
   * </div>
   *
   * @returns {ReactElement} Renders a dialog box containing a list of faults.
   */
  const FaultList = ({ fault_array }) => {
    return (
      <FaultListPopup
        visible={state.showFaultList}
        closePopup={() => setState(() => ({ ...state, showFaultList: false }))}
        fault_array={fault_array}
      />
    );
  };

  /**
   * The `MapObject` component provides a map visualization with interactive
   * capabilities such as mowers, mowing plans, and user interactions. Users can
   * tap on pins, drag the map, zoom in/out, and interact with various map entities.
   *
   * Key features of this component include:
   *  1. **State Management**: The component uses React `useState` hooks to manage
   *     various local state variables such as the list of mowers, selected mower,
   *     user position, and many more.
   *  2. **Interactive Map**: The component handles various interactive events
   *     such as map drag, pin taps, and polygon mouse interactions.
   *  3. **Dynamic Visualization**: Depending on the user's interactions and
   *     mower state, the displayed plan on the map can dynamically change.
   *  4. **Long Tap Interaction**: The component recognizes a long tap (or click)
   *     on polygons based on the time difference between mouse down and up events.
   *
   * @component
   * @name MapObject
   * @param {number} height - The height of the map.
   * @param {number} width - The width of the map.
   * @param {Array} mowers - An array of mower objects to be displayed.
   * @param {Object} plan - The mowing plan to be displayed.
   * @param {boolean} stripes - Indicator for displaying stripes on the map.
   *
   * @example
   *
   * // Use the MapObject component in a UI, passing dimensions, mowers, plan, and stripes
   * <div>
   *   <MapObject height={500} width={800} mowers={mowersData} plan={planData} stripes={true} />
   *   // Other components...
   * </div>
   *
   * @returns {ReactElement} Renders an interactive map visualization with mowers and plans.
   */
  const MapObject = ({ height, width, mowers, plan, stripes }) => {
    const [mower_list, setMowerList] = useState(null);
    const [selected_mower, setSelectedMower] = useState(null);
    const [userpos, setUserPosition] = useState(null);
    const [tracking, setTracking] = useState(TrackingRef.current);
    const [centered, setCentered] = useState(CenteredRef.current);
    const [homePosition, setHomePosition] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [recordingPlan, setRecordingPlan] = useState(null);
    const [planType, setPlanType] = useState(null);
    let mouseDownTime = null;
    const onMapDrag = () => {
      setTracking(false);
      setCentered(false);
    };
    const onPinSingleTap = (event, marker) => {
      if (selectedSerialNumberRef.current === marker.mower.serial_number) {
        setTracking(true);
      } else {
        setTracking(true);
        setSelectedSerialNumber(marker.mower.serial_number);
        selectedSerialNumberRef.current = marker.mower.serial_number;
        setSelection(marker.mower.serial_number);
      }
    };

    let touchCount = 0;

    const onPolyMouseDown = (event) => {
      //record the current time when mouse down
      mouseDownTime = new Date().getTime();
      touchCount = event.touches ? event.touches.length : 1;
    };
    const onPolyMouseUp = (event, polygon) => {
      //Check the time difference
      if (new Date().getTime() - mouseDownTime > 500 && touchCount === 1) {
        // 500 is the threshold for a long tap
        if (selectedSerialNumberRef.current !== 0) {
          setState(() => ({
            ...state,
            showDisplayActionSheet: true,

            tappedLatLng: { lat: event.latLng.lat(), lng: event.latLng.lng() },
          }));
        }
      } else {
        if (polygon.id !== undefined) {
          setCentered(true);
          setState({ ...state, clickedPlan: polygon.id - 1 });
        }
      }
      setTracking(false);
    };
    const onZoomChanged = () => {
      setCentered(false);
    };

    /**
     * Determines the type of plan being passed to the Map
     *
     * @function
     * @name DeterminePlanType
     * @param {Object} mower - The current mower object.
     */
    function DeterminePlanType(mower) {
      if (loadedPlan !== null) {
        SetPlanDisplay(MowerPlanType.PlanPreview, mower);
        setCentered(true);
      } else if (mower !== null) {
        if (
          mower.state !== MowerState.Idle &&
          mower.state !== MowerState.Manual &&
          mower.state !== MowerState.Recording
        ) {
          if (
            mower.details.current_plan ||
            mower.state ===
              (MowerState.Ready && mower.details.current_plan_is_new)
          ) {
            SetPlanDisplay(MowerPlanType.CurrentPlan, mower);
          }
        } else if (mower.state === MowerState.Recording) {
          SetPlanDisplay(MowerPlanType.RecordingPlan, mower);
        } else {
          SetPlanDisplay(MowerPlanType.NoPlan, mower);
        }
      }
    }

    /**
     * Sets the appropriate plan to be displayed on the map.
     *
     * @function
     * @name SetPlanDisplay
     * @param {string} plantype - The type of plan to set.
     * @param {Object} mower - The current mower object.
     */
    function SetPlanDisplay(plantype, mower) {
      if (loadedPlan !== null) {
        setPlanType(MowerPlanType.PlanPreview);
      } else if (plantype === MowerPlanType.CurrentPlan) {
        //Set home position first
        setCurrentPlan(mower.details.current_plan);
        setRecordingPlan(null);
        setPlanType(MowerPlanType.CurrentPlan);
      } else if (plantype === MowerPlanType.RecordingPlan) {
        setCurrentPlan(null);
        setRecordingPlan(mower.details.recorded_plan);
        setPlanType(MowerPlanType.RecordingPlan);
      } else if (plantype === MowerPlanType.NoPlan) {
        setPlanType(MowerPlanType.NoPlan);
        setCurrentPlan(null);
        setRecordingPlan(null);
      }
    }

    /**
     * Updates the home position state if the provided position is different.
     *
     * @function
     * @name DisplayHomePosition
     * @param {Object} homepos - The new home position to set.
     */
    function DisplayHomePosition(homepos) {
      if (homePosition !== homepos) {
        setHomePosition(homepos);
      }
    }

    /**
     * Sets the selected mower and enables tracking if there's a selection.
     *
     * @function
     * @name SelectMapMower
     */
    function SelectMapMower() {
      if (selection !== 0) {
        setSelectedMower(selectedSerialNumberRef.current);
        setTracking(true);
        setCentered(false);
      }
    }

    /**
     * Processes and sets the mowers to be displayed on the map.
     *
     * @function
     * @name SetUpMowerMarkers
     */
    const SetUpMowerMarkers = useCallback(() => {
      try {
        //Init empty array for mowers that are not selected
        let temp = [];

        //Loop through each mower
        mowers.forEach((m) => {
          //Instantiate a MapMower object using the current Mower Data
          let t = new MapMower(m);

          //If the serial number of the current mower matched the selected serial number
          //Set the current mower as the selected serial mower
          if (t.data.serial_number === selectedSerialNumberRef.current) {
            setSelectedMower(t.data.serial_number);
            DisplayHomePosition(t.data.details.home_position);
            temp.push(t);
          } else {
            //If the current mower is not the selected mower but the user is connected to it, add it to the temp array
            if (t.data.connected) {
              temp.push(t);
            }
          }
        });

        //Set the list of mowers to the temp array, which contains all mowers not selected
        setMowerList(temp);
      } catch (ex) {
        console.error(ex.message);
      }
    }, []);

    /**
     * Retrieves and sets the user's geolocation as their position.
     *
     * @function
     * @name GetUserPosition
     */
    const GetUserPosition = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          //if geolocation is available, set the center of the map to the user location

          const { latitude: lat, longitude: lng } = position.coords;
          setUserPosition(new Position(lat, lng));
        });
      } else {
        //If geolocation isn't available, set to null
        console.log("Geolocation is not supported by this browser");
        setUserPosition(null);
      }
    };

    useEffect(() => {
      SetUpMowerMarkers();
      GetUserPosition();
      SelectMapMower();
      DeterminePlanType(selected_mower);
    }, []);

    useEffect(() => {
      let markerList = [];

      let MarkerUpdateInterval = setInterval(() => {
        markerList = [...mowers.entries()].map((m) => {
          return new MapMower(m[1]);
        });
        let temp = [];

        markerList.forEach((m) => {
          if (m.data.connected) {
            temp.push(m);

            if (selectedSerialNumberRef.current === m.data.serial_number) {
              DisplayHomePosition(m.data.details.home_position);
              DeterminePlanType(m.data);
            }
          }
        });

        setMowerList(temp);
      }, 1000);

      return () => clearInterval(MarkerUpdateInterval);
    }, []);

    return (
      <MapComponent
        google={props.google}
        height={height}
        width={width}
        centerLat={
          mowers !== undefined && mowers !== null && mowers.size > 0
            ? [...mowers.entries()][0][1].lat
            : null
        }
        centerLng={
          mowers !== undefined && mowers !== null && mowers.size > 0
            ? [...mowers.entries()][0][1].lon
            : null
        }
        mowers={mower_list}
        plan={plan}
        onMapDrag={onMapDrag}
        onPinSingleTap={onPinSingleTap}
        onPolyMouseDown={onPolyMouseDown}
        onPolyMouseUp={onPolyMouseUp}
        onZoomChanged={onZoomChanged}
        selectedMower={selected_mower}
        isTracking={tracking}
        homePosition={homePosition}
        currentPlan={currentPlan}
        recordingPlan={recordingPlan}
        planType={planType}
        userPosition={userpos}
        containerId={"map1"}
        centered={centered}
        clickedPoly={state.clickedPlan + 1}
      />
    );
  };

  /**
   * `ToolBar` Component:
   * A React functional component that displays a toolbar with buttons that enable various interactions
   * with the selected mower and loaded plan.
   *
   * - **Rendered Components**:
   *   - Typography: To display the status of the selected mower.
   *   - Buttons: Functional buttons to interact with the mower and loaded plan.
   *
   * - **Methods**:
   *   - `UpdateButtons`: Updates the state of button disable attributes based on the current state.
   *   - `SettingsButtonClicked`: Redirects to mower settings page with the selected mower's data.
   *   - `SelectPlanClicked`: Redirects to select a plan for the selected mower.
   *   - `EditPlanClicked`: Redirects to edit the currently loaded plan.
   *   - `SendPlanClicked`: Sends the loaded plan to the mower.
   *   - `SendHomeClicked`: Sends the mower home.
   *   - `SplitPlanClicked`: Displays a split plan action sheet.
   *
   * @component
   * @name ToolBar
   * @returns JSX.Element
   */
  const ToolBar = () => {
    const [statusLabel, setStatusLabel] = useState(MowerStatusLabelRef.current);
    const [settingsDisable, setSettingsDisable] = useState(false);
    const [editDisable, setEditDisable] = useState(false);
    const [homeDisable, setHomeDisable] = useState(false);
    const [splitDisable, setSplitDisable] = useState(false);
    const [sendDisable, setSendDisable] = useState(false);

    const UpdateButtons = useCallback(() => {
      if (selectedSerialNumber === 0) {
        setSettingsDisable(true);
        setHomeDisable(true);
      }
      if (loadedPlan === null) {
        setEditDisable(true);
        setSplitDisable(true);
      }
      if (loadedPlan === null || selectedSerialNumber === 0) {
        setSendDisable(true);
      }
    }, []);

    useEffect(() => {
      let InfoStringInterval = setInterval(() => {
        setStatusLabel(MowerStatusLabelRef.current);
      }, 505);
      UpdateButtons();
      return () => clearInterval(InfoStringInterval);
    }, [UpdateButtons]);

    const SettingsButtonClicked = () => {
      if (selectedSerialNumber !== 0) {
        if (mowersListRef.current.has(selectedSerialNumber)) {
          let mower = mowersListRef.current.get(selectedSerialNumber);
          var mowerObj = new Mower();
          mowerObj.MachineSerialNumber = mower.data.serial_number;
          mowerObj.details = mower.data.details;
          mowerObj.state = mower.data.state;
          mowerObj.connected = mower.data.connected;
          let mowerdata = JSON.stringify(mowerObj);
          sessionStorage.setItem("configureMower", mowerdata);
          if (!router) {
            console.error("Router is not defined. Unable to navigate.");
            return;
          }
          router.navigate("/MowerSettings/" + mowerObj.serial_number);
        }
      }
    };

    const SelectPlanClicked = () => {
      let selectedmower = null;
      if (selectedSerialNumber !== 0) {
        if (mowersListRef.current.has(selectedSerialNumber)) {
          selectedmower = mowersListRef.current.get(selectedSerialNumber);
        }
      }

      if (selectedmower !== null) {
        sessionStorage.setItem(
          "SelectedMower",
          JSON.stringify(selectedmower.data)
        );

        if (!router) {
          console.error("Router is not defined. Unable to navigate.");
          return;
        }
        router.navigate("/SelectPlan");
      } else {
        sessionStorage.setItem("SelectedMower", "");
        if (!router) {
          console.error("Router is not defined. Unable to navigate.");
          return;
        }
        router.navigate("/SelectPlan");
      }
    };

    const EditPlanClicked = () => {
      if (loadedPlan !== null) {
        if (!router) {
          console.error("Router is not defined. Unable to navigate.");
          return;
        }
        router.navigate("/Plans/" + loadedPlan.Id);
      }
    };

    const SendPlanClicked = () => {
      if (!connection) {
        console.error("Socket connection is not active. Unable to send data.");
        return;
      }
      //If we have a loaded plan, send it to the mower
      if (!loadedPlan || loadedPlan === null) {
        return;
      }
      let m = null;
      //Another possibility is a nack from the mower
      if (mowersListRef.current.has(selectedSerialNumberRef.current)) {
        m = mowersListRef.current.get(selectedSerialNumberRef.current);
      }
      if (m !== null && m.active !== false) {
        if (
          m.data.state === MowerState.Paused ||
          m.data.state === MowerState.Mowing ||
          m.data.state === MowerState.ReturningHome ||
          m.data.state === MowerState.FaultCleared ||
          m.data.state === MowerState.Faulted ||
          m.data.state === MowerState.LocalPause
        ) {
          //prompt the user if they should reset
          let cancel = window.confirm(t("window.mower"));

          if (cancel) {
            connection.SocketMessage(SocketMessageType.CancelMower, {
              serial_number: selectedSerialNumberRef.current,
            });
          } else return;
        }
      }

      //Send it
      if (loadedPlan.MowedPlan !== null) {
        connection.SocketMessage(SocketMessageType.SendPlan, {
          serial_number: selectedSerialNumberRef.current,
          plan: loadedPlan.MowedPlan,
        });
      } else {
        if (
          loadedPlan.MowSections &&
          loadedPlan.MowSections !== null &&
          loadedPlan.MowSections.length > 1
        ) {
          if (state.clickedPlan !== -1) {
            sessionStorage.setItem("SplitPlan", JSON.stringify(loadedPlan));

            let section = loadedPlan.MowSections[state.clickedPlan];
            connection.SocketMessage(SocketMessageType.SendMowSection, {
              serial_number: selectedSerialNumberRef.current,
              plan: loadedPlan,
              section: section,
            });
          } else {
            alert(t("alerts.split"), "info");
          }
        } else {
          connection.SocketMessage(SocketMessageType.SendMowSection, {
            serial_number: selectedSerialNumberRef.current,
            plan: loadedPlan,
            section: loadedPlan.OriginalMowPlan,
          });
        }
      }

      ClearPathPlanPreview();
      UpdatePlanPreview();
    };

    const SendHomeClicked = () => {
      if (selectedSerialNumber !== 0) {
        connection.SocketMessage(SocketMessageType.ReturnHomeMower, {
          serial_number: selectedSerialNumber,
        });
      }
    };

    const SplitPlanClicked = () => {
      setState({ ...state, showSplitActionSheet: true });
    };

    return (
      <div>
        <Col>
          <Row className="my-3">
            <center>
              {selectedSerialNumber === 0 && (
                <Typography>
                  <span>{mowerDeselectedText}</span>
                </Typography>
              )}
              {selectedSerialNumber !== 0 && (
                <Typography>
                  <span>{statusLabel}</span>
                </Typography>
              )}
            </center>
          </Row>
          <Row style={{ textAlign: "center" }}>
            <Col>
              <Button
                style={{
                  width: "113px",
                  textTransform: "capitalize",
                  fontSize: ToolBarButtonRef.ButtonFontSize,
                }}
                className="btn amr-btn-primary"
                disabled={homeDisable}
                onClick={() => SendHomeClicked()}
              >
                {t("mow.goHome")}
              </Button>
            </Col>
            <Col className="p-0">
              <Button
                style={{
                  width: "113px",
                  textTransform: "capitalize",
                  fontSize: ToolBarButtonRef.ButtonFontSize,
                }}
                className="btn amr-btn-primary"
                disabled={sendDisable}
                onClick={() => SendPlanClicked()}
              >
                {t("mow.sendPlan")}
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  width: "113px",
                  textTransform: "capitalize",
                  fontSize: ToolBarButtonRef.ButtonFontSize,
                }}
                className="btn amr-btn-primary"
                onClick={(e) => {
                  SettingsButtonClicked();
                }}
                disabled={settingsDisable}
              >
                {t("mow.settings")}
              </Button>
            </Col>
          </Row>
          <Row style={{ textAlign: "center", marginTop: "5px" }}>
            <Col>
              <Button
                style={{
                  width: "113px",
                  textTransform: "capitalize",
                  fontSize: ToolBarButtonRef.ButtonFontSize,
                }}
                className="btn amr-btn-primary"
                onClick={() => SplitPlanClicked()}
                disabled={splitDisable}
              >
                {t("mow.split")}
              </Button>
            </Col>
            <Col className="p-0">
              <Button
                style={{
                  width: "113px",
                  textTransform: "capitalize",
                  fontSize: ToolBarButtonRef.ButtonFontSize,
                }}
                className="btn amr-btn-primary"
                onClick={(e) => EditPlanClicked()}
                disabled={editDisable}
              >
                {t("mow.editPlan")}
              </Button>
            </Col>
            <Col>
              <Button
                onClick={(e) => {
                  SelectPlanClicked();
                }}
                style={{
                  width: "118px",
                  textTransform: "capitalize",
                  textAlign: "center",
                  fontSize: ToolBarButtonRef.ButtonFontSize,
                }}
                className="btn amr-btn-primary"
              >
                {t("mow.selectPlan")}
              </Button>
            </Col>
          </Row>
        </Col>
      </div>
    );
  };

  /**
   * `MowerTabObect` Component:
   * A React functional component that manages the selected mower's details and
   * actions within a tab-based user interface.
   *
   * - **Rendered Component**:
   *   - `MowerTab`: Renders the selected mower's details and actions within a tab interface.
   *
   * - **Methods**:
   *   - `MowerActionTapped`: Handles actions like pausing, resuming, starting the engine,
   *     and displaying faults of the mower.
   *
   * - **Props**:
   *   - `mowers`: An array of mower objects to display on the map.
   *   - `screenwidth`: The screen width which may be used for responsive design inside MowerTab.
   *
   * @component
   * @name MowerTabObect
   * @param {Array} mowers - The list of mowers to display on the map.
   * @param {Number} screenwidth - The width of the user's screen.
   * @returns JSX.Element
   */
  const MowerTabObect = ({ mowers, screenwidth }) => {
    const [mowertabmower, setMowerTabMower] = useState(null);

    const MowerActionTapped = () => {
      if (!connection) {
        console.error("Socket connection is not active. Unable to send data.");
        return;
      }
      if (MowerInfoRef.current === null) {
        return;
      }
      let mower = MowerInfoRef.current;
      if (mower.actionButtonState === ActionButtonState.Nothing) {
        return; //Don't do anything when showing nothing
      }

      //Check the mower details to see if the mower is mowing and needs to be paused
      //if so, pause
      if (mower.actionButtonState === ActionButtonState.Pause) {
        console.log("Pausing mower");
        connection.SocketMessage(SocketMessageType.PauseMower, {
          serial_number: selectedSerialNumber,
        });
      }
      //if the mower is paused then the next step is resume. if so, resume
      else if (mower.actionButtonState === ActionButtonState.Resume) {
        console.log("Resuming mower");
        connection.SocketMessage(SocketMessageType.ResumeMower, {
          serial_number: selectedSerialNumber,
        });
      } else if (mower.actionButtonState === ActionButtonState.StartEngine) {
        console.log("Starting mower engine");
        connection.SocketMessage(SocketMessageType.StartMowerEngine, {
          serial_number: selectedSerialNumber,
        });
        //TODO DISABLE THE BUTTON FOR TWO SECONDS TO ALLOW FOR THE MOWER TO RESPOND
      } else if (mower.actionButtonState === ActionButtonState.Faulted) {
        let fts = mowertabmower.data.details.faults;
        fts.shift();
        setState({ ...state, showFaultList: true, faults: fts });
      }
    };

    useEffect(() => {
      let update_interval;

      if (mowers !== undefined && mowers !== null)
        if (
          selectedSerialNumberRef.current !== 0 &&
          mowers.has(selectedSerialNumber)
        ) {
          update_interval = setInterval(() => {
            let mower = mowers.get(selectedSerialNumber.current);
            mower = new MapMower(mower);
            UpdateMowerDetails(mower);
            setMowerTabMower(mower);
          }, 200);
        }

      return () => {
        if (update_interval) clearInterval(update_interval);
      };
    });
    return (
      <MowerTab
        mowertapped={MowerActionTapped}
        mower={mowertabmower}
        details={MowerInfoRef.current}
        screenwidth={screenwidth}
      />
    );
  };

  /**
   * `PauseAllButtonClicked` Function:
   * A function that sends a socket message to the server with a request to pause all connected mowers simultaneously.
   *
   * - **Purpose**:
   *   - The main intent of this function is to halt the operation of all mowers connected to the system with a single command.
   *
   * - **Implementation**:
   *   - This function leverages a WebSocket connection to send a specific message type (`PauseAllMowers`) to the server.
   *
   * @function
   * @name PauseAllButtonClicked
   * @returns void
   */
  const PauseAllButtonClicked = () => {
    if (!connection) {
      console.error("Socket connection is not active. Unable to send data.");
      return;
    }
    try {
      connection.SocketMessage(SocketMessageType.PauseAllMowers);
    } catch (error) {
      console.error(`There was a websocket connection error: ${error}`);
    }
  };

  return (
    <div>
      <SplitAction />
      <FaultList fault_array={state.faults} />
      <LoadingBackdrop loading={!appeared} />
      <MowerAction />
      <Row style={{ height: ScreenDimension.ScreenHeight }}>
        <Col>
          <TopBar />
          <Row
            style={{
              zIndex: 1,
              position: "absolute",
              marginTop: "10px",
              marginLeft: "0px",
              marginRight: "0px",
              marginBottom: "0px",
              paddingRight: "0px",
              paddingLeft: "0px",
              width: "inherit",
            }}
          >
            <Row
              style={{
                marginLeft: "0px",
                marginRight: "0px",
                paddingRight: "0px",
                paddingLeft: "0px",
              }}
            >
              <MowerTabObect
                mowers={connection.mowers_}
                screenwidth={ScreenDimension.ScreenWidth}
              />
              <Col
                style={{
                  marginLeft: "10px",
                  marginRight: "0px",
                  paddingRight: "0px",
                  paddingLeft: "0px",
                }}
              >
                <IconButton onClick={() => PauseAllButtonClicked()}>
                  <Dangerous
                    sx={{
                      color: red[500],
                      backgroundColor: "white",
                      borderRadius: "16px",
                    }}
                    fontSize="large"
                  />
                </IconButton>

                {loadedPlan !== null && (
                  <IconButton onClick={() => UnloadPlan()}>
                    <LayersClear
                      sx={{
                        color: "white",
                        backgroundColor: red[500],
                        borderRadius: "16px",
                      }}
                      fontSize="large"
                    />
                  </IconButton>
                )}
              </Col>
            </Row>
          </Row>
          <Row style={{ height: ScreenDimension.MapHeight }}>
            <Col>
              <MapObject
                height={ScreenDimension.MapHeight}
                width={ScreenDimension.ScreenWidth}
                mowers={connection.mowers_}
                plan={loadedPlan}
              />
            </Col>
          </Row>
          <Row style={{ height: ScreenDimension.ToolbarHeight }}>
            <Col>
              <ToolBar />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default GoogleApiHOC(withRouter(MowPage));
