import {
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export const ConnectMowerDialog = (props) => {
  const { ConnectBtnClicked, isOpen, ClosePopup } = props;
  const [pinValue, setPinValue] = useState("");
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();

  const handlePinChange = (e) => {
    if (e.currentTarget.value.length <= 6) {
      // Assuming the PIN should be a maximum of 6 characters
      setPinValue(e.currentTarget.value);
      setError(null); // Reset error on new input
    }
  };

  const handleConnect = () => {
    if (pinValue.length < 6) {
      setError("PIN must be 6 characters long.");
      return;
    }

    try {
      ConnectBtnClicked(pinValue);
    } catch (ex) {
      setError("Failed to connect to the mower.");
      console.error(ex);
    }
  };

  return (
    <Dialog open={isOpen} onClose={ClosePopup}>
      <DialogTitle className="text-center">
        {t("machine.connectMower")}
      </DialogTitle>
      <DialogContent style={{ width: "300px" }}>
        <Row className="justify-items-center">
          <Col>
            <Typography className="text-center">
              <span>{t("machine.enterNumber")}</span>
            </Typography>
            <TextField
              id="standard-basic-2"
              label={t("machine.enterPin")}
              variant="standard"
              value={pinValue}
              onChange={handlePinChange}
              margin="none"
              className="justify-items-center d-flex"
              error={error !== null}
              helperText={error}
            />
            <Row className="pt-3 justify-content-center">
              <Link
                onClick={handleConnect}
                className="btn amr-btn-primary w-75"
              >
                {t("machine.connect")}
              </Link>
            </Row>
          </Col>
        </Row>
      </DialogContent>
    </Dialog>
  );
};
