import * as React from "react";
import {
  Backdrop,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

export const LoadingBackdrop = (props) => {
  const { loading } = props;

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UpdateAvailableDialogBox = (props) => {
  const {
    updateAvailable,
    handleUpdateDialogResponse,
    updateLabel,
    canDecline,
  } = props;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={updateAvailable}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Update Available"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {updateLabel}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {canDecline && (
            <Button className="btn amr-btn-primary" onClick={handleClose}>
              Later
            </Button>
          )}
          <Button className="btn amr-btn-primary" onClick={handleClose}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
