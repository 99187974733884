export default class Constants {
  ProtocolVersion() {
    return 10;
  }

  LoginSecretKey() {
    return "Yaso1982asduP";
  }

  BackendURL() {
    //return "https://127.0.0.1:443"; //localhost
    //return "https://216.129.149.126"; // wan ip
    //return "https://rcmrobotics.net:544"; // beta ip
    return "https://rcmrobotics.net:543"; // production ip
  }

  BackendPublicKey() {
    return "3082010A0282010100C645717474860CC080EDA7FA30A07E8FB117BE61CDA534703DAA936D51B91C7265FBD0820EC9DE974BC93324DAB0DBB78CD82434E34962D8A27751DFAD46A2D44A51519757A1AC95C9391CBA666F805DD1AA2290706F5D5FDC3523F68A2FE23BE15FCFBB2F20859FA1B1B04AAAF30606DAEC0B616095CDA63C59BF8A2E2C205DEF97EAE906313B9430938487C942F382676C7112BEB512F6B333FBD95502E21A03064A3863FF5B16916415C6BBD5656AE9A764D66F76D4855416F01B8B175B2D82F2C57ED5023E908F0601AFEDBBA51FBD1B881D6CCB0518AEAA14A1918C23EEE458D43AF8C9E38BF75A85CE5C638D20C25CDFC87905E25F27932177D6A8ABB10203010001";
  }

  Version() {
    return 0.9;
  }

  DataServerThumbprint() {
    return "5D1BAC25BC198059F04922A006339D037DECA351";
  }

  DataServerAddress() {
    return "wss://rcmrobotics.net:5964/socket"; //production
    //return "wss://rcmrobotics.net:5963/socket"; //beta
    //return "wss://127.0.0.1:5964/socket"; //localhost
  }
}
