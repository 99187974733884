import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import "./CustomModal.css";
import React from "react";

const CustomModal = ({ isVisible, title, message, onConfirm, onCancel }) => {
  if (!isVisible) return null;

  return (
    <Dialog open={isVisible}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <p>{message}</p>
        <Button className="btn amr-btn-primary" onClick={onConfirm}>
          Confirm
        </Button>
        {onCancel && (
          <Button className="btn amr-btn-primary" onClick={onCancel}>
            Cancel
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CustomModal;
