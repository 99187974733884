import { MowerData } from "../services/AppConnection";
import { Polyline, Circle } from "google-maps-react";
import { red, yellow } from "@mui/material/colors";
import { Position } from "../models/mowingPlan";

export class MapMower {
  constructor(mdata) {
    if (!mdata) {
      return null;
    }

    this.plan_line = new Polyline({ strokeWidth: 4, strokeColor: red[500] });
    this.recording_line = new Polyline({
      strokeWidth: 4,
      strokeColor: yellow[500],
    });
    this.complete_line = new Polyline({
      strokeWidth: 7,
      strokeColor: "#00FF0080",
    });
    this.next_waypoint = new Circle({
      strokeWidth: 4,
      strokeColor: yellow[500],
      fillColor: "#FFFF0033",
    });
    let pinpos = new Position(mdata.lat, mdata.lon);
    let pinrot = mdata.heading;
    let pincolor = mdata.accent_color;
    this.pin = {
      position: pinpos,
      rotation: pinrot,
      accent_color: pincolor,
    };
    this.data = mdata;
  }
  data = new MowerData();
  pin;
  plan_line;
  complete_line;
  recording_line;
  recording_exclusion_lines = [];
  object_lines = [];
  next_waypoint = new Circle();
  recently_tapped;

  // Used as a flag to see if we should remove this after an update due to being disconnected.
  active;
  update_already_asked = false;

  // Shown in each mower blurb.
  status_string; // First line.
  status_string2; // Second line.
  fuel_string; // Fuel monitor line.

  // Shown at the bottom for the selected mower.
  info_string;
}
