import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

/**

 * i18next is a framework for internationalization (i18n) in React / JavaScript.

 * this file translate the text in the app to the language of the user

 */

i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: "en",
  debug: true,
});

//i18n.changeLanguage("es");
export default i18n;
