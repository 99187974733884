import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/homePage";
import MowPage from "./pages/mowPage";
import PlanSelectPage from "./pages/planSelectPage";
import PlanPage from "./pages/planPage";
import MachinesPage from "./pages/machinesPage";
import MonitorPage from "./pages/monitorPage";
import LoginPage from "./pages/loginPage";
import PlanManagePage from "./pages/planManagePage";
import MowerSettingsPage from "./pages/mowerSettingsPage";
import MaterialUIDrawer from "./components/drawer/drawer";
import MowerConnectPage from "./pages/mowerConnectPage";
import SettingsPage from "./pages/SettingsPage";
import { WebSocketProvider } from "./components/services/AppConnection";
import { ToastProvider } from "./components/customToast/ToastContext";
import "./fonts.css";
import HelpPage from "./pages/helpPage";
import ErrorBoundary from "./components/errorBoundary/ErrorBoundary";
import { useTranslation } from "react-i18next";

function App() {
  return (
    <ErrorBoundary>
      <ToastProvider>
        <WebSocketProvider>
          <div className="Home">
            <div>
              <MaterialUIDrawer />
            </div>
            <div>
              <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route exact path="/Home" element={<Home />} />
                <Route path="/Mow" element={<MowPage />} />
                <Route path="/MowerConnect" element={<MowerConnectPage />} />
                <Route path="/SelectPlan" element={<PlanSelectPage />} />
                <Route path="/ManagePlans" element={<PlanManagePage />} />
                <Route path="/Machines" element={<MachinesPage />} />
                <Route path="/Plans" element={<PlanPage />} />
                <Route path="/Plans/:id" element={<PlanPage />} />
                <Route path="/Monitor" element={<MonitorPage />} />
                <Route
                  path="/MowerSettings/:id"
                  element={<MowerSettingsPage />}
                />
                <Route path="/Help" element={<HelpPage />} />
                <Route path="/UserSettings" element={<SettingsPage />} />
              </Routes>
            </div>
          </div>
        </WebSocketProvider>
      </ToastProvider>
    </ErrorBoundary>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...loading">
      <App />
    </Suspense>
  );
}
